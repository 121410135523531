import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  AprimaCacheInstance,
} from 'webcommon/legacy-common';
import { PrmHttpClientService } from '../PrmHttpClientService';

@Injectable({
providedIn: 'root',
})
export class ListRepository {
  static readonly ajsFactoryName = 'ListRepository';

  constructor(
    private httpClientService: PrmHttpClientService,
  ) { }

  getListJs(listName: string, params?: any, cache?: AprimaCacheInstance): Promise<any> {
    return this.getList(listName, params, cache).toPromise();
  }

  getList(listName: any, params?: any, cache?: AprimaCacheInstance): Observable<any> {
    const endpointUrl = 'lists/' + listName;
    let httpParams: HttpParams | undefined;
    if (params) {
      httpParams = new HttpParams({ fromObject: params });
    }
    return this.httpClientService.sendGetRequest(endpointUrl, httpParams, { cache });
  }
}
