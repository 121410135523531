import { Injectable } from '@angular/core';

import { environment } from 'source/web/src/environments/environment';
import { AppConfiguration, GatewayConfiguration } from 'webcommon/shared';

@Injectable({
  providedIn: 'root',
})
export class PatientPortalConfigurationRepository implements AppConfiguration {
  public get API_KEY(): string {
    return environment.API_KEY;
  }

  public get CLIENT_VERSION(): string {
    return environment.CLIENT_VERSION;
  }

  public get DEV_HOSTED(): boolean {
    return environment.devHosted;
  }

  public get GATEWAYS(): GatewayConfiguration[] {
    return environment.GATEWAYS;
  }

  public get GIT_COMMIT_HASH(): string {
    return environment.GIT_COMMIT_HASH;
  }

  public get SERVER_API_VERSION(): string {
    return environment.SERVER_API_VERSION;
  }
}
