import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
} from '@angular/core';

import {
  ApHammerInput,
  ElementZoomState,
} from 'webcommon/shared';

// The main logic for this class was ported from apImageViewer.js in angularjs into this generic directive.
@Directive({
  selector: '[apZoomable]',
})
export class ApZoomableDirective implements OnChanges {
  private readonly element: HTMLElement;

  // this input is meant to be changed by the parent when you need to trigger a reset of the position and scale of the element
  // ex: for an <img> element, this input could be bound to the same value as the [src]
  @Input() resetSrc: any;

  private readonly zoomState: ElementZoomState = {
    initScale: 1,
    // The initial x and y coordinates will hold the offset for the image before any drag or zoom events occur.
    // Once a touch event is released, these values will get updated with the new position, but during a drag, they won't be updated.
    initialXCoordinate: 0,
    initialYCoordinate: 0,
    minOffsetX: 0,
    // tslint:disable-next-line:object-literal-sort-keys
    maxOffsetX: 0,
    minOffsetY: 0,
    maxOffsetY: 0,
    transform: {
      scale: 1,
      translate: {
        x: 0,
        y: 0,
      },
    },
  };

  constructor(
    elementRef: ElementRef,
    private readonly renderer: Renderer2,
  ) {
    this.element = elementRef.nativeElement as HTMLElement;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.resetSrc && changes.resetSrc.currentValue) {
      this.resetElement(this.element, this.zoomState);
    }
  }

  // Listening for these events and calling 'preventDefault' is necessary to make sure the
  // touch events don't interfere with these hammer events below.
  // Some mobile browsers (like on Android) allow doubletap to zoom or pinch to zoom for the page, and this logic will prevent that.
  @HostListener('touchstart', ['$event'])
  @HostListener('touchmove', ['$event'])
  @HostListener('touchend', ['$event'])
  // This is supposed to receive a TouchEvent, but TouchEvent isn't universally supported
  // across browsers, so it's using the parent of TouchEvent instead (UIEvent) to be safe.
  // TouchEvent as the type causes 'ReferenceError: TouchEvent is not defined.' in FireFox: https://stackoverflow.com/q/51124242/5335355
  // I THINK using TouchEvent as the event type in HostListener can cause issues because the transpiled js
  // of this includes the type of the parameter. (for FireFox, and i think Safari as well this type doesn't exist)
  touchHandlerDummy(e: UIEvent) {
    e.preventDefault();
    return false;
  }

  // on pinch, scale the element according to the scale from the event
  @HostListener('pinch', ['$event'])
  onPinch(ev: ApHammerInput) {
    const currentState = this.zoomState;
    const elem = this.element;

    this.renderer.removeClass(elem, 'animate');

    currentState.transform.scale = Math.max(currentState.initScale * ev.scale, 1);

    this.requestElementUpdate(elem, currentState);
  }

  @HostListener('pinchend', ['$event'])
  @HostListener('panend', ['$event'])
  onRelease(ev?: ApHammerInput) {
    // It was checking for the existence of this event in the previous version of this in angularjs,
    // but I'm not sure why.
    if (!ev) {
      return;
    }

    const currentState = this.zoomState;
    const elem = this.element;

    currentState.initScale = currentState.transform.scale;
    currentState.initialXCoordinate = currentState.transform.translate.x;
    currentState.initialYCoordinate = currentState.transform.translate.y;

    currentState.minOffsetX = -1 * (elem.offsetWidth * ((currentState.transform.scale - 1) / 2));
    currentState.maxOffsetX = currentState.minOffsetX + (elem.offsetWidth * (currentState.transform.scale - 1));
    currentState.minOffsetY = -1 * (elem.offsetHeight * ((currentState.transform.scale - 1) / 2));
    currentState.maxOffsetY = currentState.minOffsetY + (elem.offsetHeight * (currentState.transform.scale - 1));

    this.snapToContainer(elem, currentState);
  }

  // on doubletap, reset the element back to its original state
  @HostListener('doubletap')
  onDoubleTap() {
    this.resetElement(this.element, this.zoomState);
  }

  // on pan, move the element around
  // (makes more sense when you're zoomed in, and you want to see a different part of the
  // element that is off screen)
  @HostListener('panmove', ['$event'])
  onDrag(ev: ApHammerInput) {
    const currentState = this.zoomState;
    const elem = this.element;

    this.renderer.removeClass(elem, 'animate');

    const targetX = currentState.initialXCoordinate + ev.deltaX;
    const targetY = currentState.initialYCoordinate + ev.deltaY;

    currentState.transform.translate.x = targetX;
    currentState.transform.translate.y = targetY;

    this.requestElementUpdate(elem, currentState);
  }

  private snapToContainer(elem: HTMLElement, state: ElementZoomState) {
    state.transform.translate.x = state.initialXCoordinate =
      Math.min(
        Math.max(state.transform.translate.x, state.minOffsetX),
        state.maxOffsetX,
      );

    state.transform.translate.y = state.initialYCoordinate =
      Math.min(
        Math.max(state.transform.translate.y, state.minOffsetY),
        state.maxOffsetY,
      );

    this.requestElementUpdate(elem, state);
  }

  private resetElement(elem: HTMLElement, state: ElementZoomState) {
    this.renderer.addClass(elem, 'animate');

    state.initScale = 1;
    state.initialXCoordinate = 0;
    state.initialYCoordinate = 0;
    state.minOffsetX = 0;
    state.maxOffsetX = 0;
    state.minOffsetY = 0;
    state.maxOffsetY = 0;
    state.transform = {
      scale: 1,
      translate: {
        x: state.initialXCoordinate,
        y: state.initialYCoordinate,
      },
    };

    this.requestElementUpdate(elem, state);
  }

  private requestElementUpdate(elem: HTMLElement, state: ElementZoomState) {
    this.updateElementTransform(elem, state);
  }

  private updateElementTransform(elem: HTMLElement, state: ElementZoomState) {
    // This comment below was added years ago to the angularjs apIamgeViewer.js, and I'm not 100% sure it still applies,
    // but setting the css properties in this way seems to still work fine, so I am leaving this logic the same.
    // ============================================================================================================================
    // Even though we're only translating in 2 dimensions, we want to continue using the translate3D function.  The browsers in
    // Android and iOS use GPU acceleration for the 3D transforms but not for 2D ones, so translate3D ends up being more performant
    // than just calling translate. Run http://jsperf.com/translate3d-vs-xy/28 to verify.
    // ============================================================================================================================

    const translate = `translate3d(${state.transform.translate.x}px, ${state.transform.translate.y}px, 0)`;
    const scale = `scale(${state.transform.scale}, ${state.transform.scale})`;
    const fullTransform = translate + ' ' + scale;

    this.renderer.setStyle(elem, 'webkit-transform', fullTransform);
    this.renderer.setStyle(elem, 'transform', fullTransform);
    this.renderer.setStyle(elem, 'moz-transform', fullTransform);
  }
}
