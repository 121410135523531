import {
  Directive,
  HostListener,
  Input,
} from '@angular/core';

import { ApBaseDirective } from '../ap-base-component/index';

import { takeUntil } from 'rxjs/operators';

import { DownloadableDocument } from 'webcommon/shared';

import { DocumentService } from './DocumentService';

import { Observable } from 'rxjs';

// this is used if there is no specified FileName
const defaultFileName = 'document';

@Directive({
  selector: '[apSaveToFile]',
})
export class ApSaveToFileDirective extends ApBaseDirective {
  @Input() documentDownload: () => Observable<DownloadableDocument>;
  @Input() fileName?: string;

  constructor(
    private readonly documentService: DocumentService,
  ) {
    super();
  }

  @HostListener('click')
  onClick() {
    this.documentDownload().pipe(
      takeUntil(this.onDestroy$),
    ).subscribe(
      (doc) => this.documentLoaded(doc),
      (err) => console.error(err),
    );
  }

  private documentLoaded(doc: DownloadableDocument) {
    // if a fileName is passed in, it will be used instead of the FileName from the document
    if (this.fileName) {
      doc.FileName = this.fileName;
    }
    this.documentService.tryDownloadToFile(doc, defaultFileName);
  }
}
