import {ApDocumentBase} from './ApDocumentBase';
import {ApDocumentType} from './ApDocumentType';

import {SafeResourceUrl} from '@angular/platform-browser';

// This is prefixed so that it should never conflict with some built-in type.
export class ApPdfDocument extends ApDocumentBase {
  constructor(
    dataUrl: string,
    // this property refers to whether this dataUrl is created using URL.createObjectURL
    dataIsObjectUrl: boolean,
    extension: string,
    pageCount?: number | null,
    readonly trustedUrl?: SafeResourceUrl,
  ) {
    super(
      dataUrl,
      dataIsObjectUrl,
      ApDocumentType.pdf,
      extension,
      pageCount,
      trustedUrl,
    );
  }
}
