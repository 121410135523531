// This does not necessarily correspond to the file extension of the document
// but instead it refers to what type of data is in the document (because it could have been converted).
// This is prefixed so that it should never conflict with some built-in type.
export enum ApDocumentType {
  html,
  image,
  pdf,
  text,
  unknown,
  CCD,
  audio,
}
