import {
  Injectable, Injector, StaticProvider, Type, ɵstringify as stringify,
} from '@angular/core';
import {from, Observable} from 'rxjs';
import {ApModalTokens} from './ApModalComponentType';
import {AnyModalComponent} from './AnyModalComponent';

import {ApActiveModal} from './ApActiveModal';
import {ApModalComponentOptions} from './ApModalComponentOptions';

import {BsModalService} from 'ngx-bootstrap/modal';
import {ExactlyPartial} from 'webcommon/shared';

import {ScopeProvider} from 'webcommon/legacy-common';

// This is a type useful for situations where you need to reference the generic type of a class via type inferencing
// when there are multiple generic types declared on a function or class.
// Ideally, the function below would have the type parameters like this:
// <TComponent extends ApBaseModalComponent<TResult>, TResult>
// but then TResult will not get inferred correctly, meaning you would have to tell the function
// what type it needs to be in the <> when calling it.
// see https://stackoverflow.com/q/58847047/5335355 for an example
type ExtractGeneric<T> = T extends AnyModalComponent<infer TResult> ? TResult : never;

@Injectable({
  providedIn: 'root',
})
// This is a wrapper around BsModalService to provide more specific
// for our use of the modals.
// Also, it provides typing around stuff that ngx-boostrap doesn't have types for!
export class ApModalService {
  constructor(
    private bsModalService: BsModalService,
    // For a root service like this, this is a module Injector, which is actually an instance of R3Injector at runtime.
    // Unless it's provided separately than the 'root' module, then that specific case have a different instance.
    // (https://github.com/angular/angular/blob/main/packages/core/src/di/r3_injector.ts)
    private moduleInjector: Injector,
  ) {
  }

  // If you see an error for
  // "Types of property 'someProperty' are incompatible. Type 'someType' is not assignable to type 'never'",
  // then it's because 'someProperty' doesn't exist on TComponent.
  // So it's probably a typo, or you haven't yet added that property to 'TComponent'.
  //
  // If you see an error for
  // "Types of property 'someProperty2' are incompatible. Type 'someType2' is not assignable to type 'someOtherType'",
  // then it's because 'someProperty2' probably does exist on 'TComponent', but you're giving it the wrong type.
  //
  // This is essentially making sure that the properties passed on the 'initialState' match 'TComponent',
  // which is most likely necessary for 'TComponent' to function properly.
  // The type that 'TComponentState' extends from has to be an intersection with some variant of a 'Partial<TComponent>'
  // so as to not require the instance methods to be filled in on the partial type.
  //
  // More notes:
  // If 'TComponentState' just only extended 'Partial<TComponent>',
  // then it would allow excess properties on the 'initialState' that don't match 'TComponent'
  // (This is intended behavior by TypeScript: https://github.com/microsoft/TypeScript/issues/31872).
  // I can't think of a reason for why it would ever be necessary to allow excess properties on the 'initialState'
  // for whatever modal component is being used here, but i guess this could be changed in the future.
  showComponentModal<TComponent extends AnyModalComponent<any>, TComponentState extends ExactlyPartial<TComponent, TComponentState>>(
    component: Type<TComponent>,
    config?: ApModalComponentOptions<TComponentState>,
  ): Promise<ExtractGeneric<TComponent>> {
    const resultPromise = new Promise<ExtractGeneric<TComponent>>((resolve, reject) => {
      const activeModal = new ApActiveModal<ExtractGeneric<TComponent>>(resolve, reject);

      // Create a new simulated element Injector specifically for UpgradeComponents that may exist inside of this modal,
      // so that they can access the $scope properly.
      // (I'm calling it a simulated element injector, because it's not really an element injector,
      // like the one that would get injected into a component, but it does the necessary task of providing $scope)
      // And by creating a new Injector each time through here, means that the ScopeProvider will provide a new child scope
      // linked to the $rootScope for each new modal, which is what we want.
      // Then each UpgradeComponent inside of this modal will have this new scope as a parent, which is correct.
      // (as opposed to depending on the ScopeProvider being a provider for the whole module, which means a single scope gets created
      // the first time an UpgradeComponent needs one, and then is shared with all future ones after that in the same module,
      // which feels wrong)
      // The Injector that is injected into this class, and into the modal class is a module injector,
      // which doesn't provide $scope.
      const elementInjector = Injector.create({
        name: 'ApModalService-ElementInjector',
        parent: this.moduleInjector,
        providers: [
          ScopeProvider,
        ],
      });

      // ApActiveModal always needs to get filled in on the initialState for the modal component to return properly back to the caller
      const completeConfig = config || new ApModalComponentOptions<TComponentState>();
      completeConfig.elementInjector = elementInjector;
      const providers = completeConfig.providers = completeConfig.providers || [];
      providers.unshift({
        provide: ApActiveModal,
        useValue: activeModal,
      });

      try {
        this.bsModalService.show(component, completeConfig);
      } catch (e) {
        const messagePrefix = `Error trying to open modal '${stringify(component)}'`;
        console.error(messagePrefix, e);
        activeModal.dismiss(e);
        throw e;
      }
    });

    return resultPromise;
  }

  showComponentModal$<TComponent extends AnyModalComponent<any>, TComponentState extends ExactlyPartial<TComponent, TComponentState>>(
    component: Type<TComponent>,
    config?: ApModalComponentOptions<TComponentState>,
  ): Observable<ExtractGeneric<TComponent>> {
    return from(this.showComponentModal(component, config));
  }

  // use this to more easily fill in providers property in ModalOptions with stateful services
  provideClasses(
    elementInjector: Injector,
    ...tokens: ApModalTokens
  ): StaticProvider[] {
    return tokens.map((token) => {
      return {
        provide: token,
        useFactory: () => {
          return elementInjector.get(token);
        },
      };
    });
  }
}
