import {Inject, Injectable, OnDestroy} from '@angular/core';

import {BsModalService, ModalContainerComponent} from 'ngx-bootstrap/modal';
import {takeUntil} from 'rxjs/operators';

import {ApBaseClass, BsComponentLoader, lastDismissReasonT} from 'webcommon/shared';

import {ApActiveModal} from './ApActiveModal';

// the actual type for BsModalService.loaders for the current version of ngx-bootstrap
export type loadersT = Array<BsComponentLoader<ModalContainerComponent>>;

// This class contains common logic that needs to be run inside of ngx-bootstrap modals.
// It previously existed in class ApBaseModalComponent, but was moved here to allow it to be shared
// with ApLockingBaseModalComponent (previously FndBaseModalComponent), etc.
// This class is not actually injectable because FakeHideHandlerInjectionToken doesn't have a service registered for it.
// The decorator is required because it relies on the OnDestroy lifecycle hook and Angular requires a decorator to implement the hook.
@Injectable()
export class ApModalHelper<T> extends ApBaseClass implements OnDestroy {

  private containerComponent: ModalContainerComponent | undefined;
  private isShownPreviousVal = false;

  // Default this to true to assume the normal case where there is a ModalContainerComponent,
  // and we need to wait for it to be shown before closing this modal.
  private readonly hasContainerComponent: boolean;

  constructor(
    // i guess these can be changed to be not private, if necessary
    private activeModal: ApActiveModal<T>,
    private bsModalService: BsModalService,
    @Inject('FakeHideHandlerInjectionToken') private hideHandler: (reason: lastDismissReasonT) => void,
  ) {
    super();

    // There is a ComponentLoader created by ngx-bootstrap for each modal component we load.
    // The one used for the current class is the last one in the 'loaders' property.
    // This is definitely hacky, but I couldn't figure out another way to keep the modals from trying to close too early.
    // I would argue this is a bug with ngx-bootstrap that it doesn't handle this case.
    // tslint:disable-next-line:no-string-literal
    const loaders = this.bsModalService['loaders'] as loadersT;
    // todo: use no-uncheckedindexed access flag in TS 4.1 to have this type be correct
    const thisLoader = loaders[loaders.length - 1] as typeof loaders[number] | undefined;
    // We need to get access to the ModalContainerComponent for this component,
    // and to do that, we can subscribe to 'onShown' for the loader.
    // This allows us to check the value of 'isShown' on the ModalContainerComponent,
    // to keep from calling hide() until it is === true.
    if (thisLoader) {
      this.hasContainerComponent = true;
      thisLoader.onShown.pipe(
        takeUntil(this.onDestroy$),
      ).subscribe(() => {
        this.containerComponent = thisLoader.instance;
      });

      // This event will get emitted only when this modal is hiding, instead of when any modal is hiding.
      thisLoader.onBeforeHide.pipe(
        takeUntil(this.onDestroy$),
      ).subscribe(() => {
        // tslint:disable-next-line:no-string-literal
        this.hideHandler(this.bsModalService['lastDismissReason'] as lastDismissReasonT);
      });
    } else {
      this.hasContainerComponent = false;

      // I don't actually think there's a real scenario where there won't be a container component,
      // which would then cause this to be used.
      this.activeModal.bsModalRef.onHide.pipe(
        takeUntil(this.onDestroy$),
      ).subscribe(
        () => {
          // tslint:disable-next-line:no-string-literal
          this.hideHandler(this.bsModalService['lastDismissReason'] as lastDismissReasonT);
        },
      );
    }
  }

  // call from ngDoCheck in component
  doCheck(): void {
    // When the ModalContainerComponent.isShown is true, then we can successfully hide it.
    // (if 'isShown' is still false when calling BsModalRef.hide(), it just won't even try to hide the modal))
    // We should only need to mark the modal readyToHide once.
    if (this.hasContainerComponent && !this.isShownPreviousVal && this.containerComponent && this.containerComponent.isShown) {
      this.activeModal.markReadyToHide();
      this.isShownPreviousVal = true;
    }
  }

  // call from ngOnInit in component
  onInit(): void {
    if (!this.hasContainerComponent) {
      // If there is no container component to wait on for whatever reason,
      // then just mark the modal readyToHide now.
      this.activeModal.markReadyToHide();
    }
  }

  // resolve the promise because the user is done and there is a successful result
  emitAndClose(result: T | PromiseLike<T>): void {
    return this.activeModal.emitAndClose(result);
  }
  // reject the promise
  dismiss(reason?: any): void {
    return this.activeModal.dismiss(reason);
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
