import {
  HttpClient,
  HttpContext,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { APRIMA_HTTP_CONTEXT_CACHE_INSTANCE } from '../http-interceptors/index';
import { AprimaCacheInstance } from './AprimaCacheService';
import { BridgeService } from './BridgeService';
import { BrowserService } from './BrowserService';
import { ConfigurationRepository } from './ConfigurationRepository';
import { WebCommonStorageRepository } from './WebCommonStorageRepository';

export interface ApRequestConfig {
  cache?: AprimaCacheInstance;
  ignoreCache?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AprimaHttpClientService {
  constructor(
    private bridge: BridgeService,
    private browserService: BrowserService,
    private configurationRepository: ConfigurationRepository,
    private httpClient: HttpClient,
    private webCommonStorageRepository: WebCommonStorageRepository,
  ) { }

  getFullUrl(endpointBase?: string, endpointUrl?: string): string {
    const urlComponents = [
      this.webCommonStorageRepository.getClientUrl(),
      'api',
    ];
    if (endpointBase) {
      urlComponents.push(endpointBase);
    }
    urlComponents.push(this.configurationRepository.SERVER_API_VERSION);
    if (endpointUrl) {
      urlComponents.push(endpointUrl);
    }

    const baseUrl = urlComponents.join('/');
    return baseUrl;
  }

  private buildRequestParams(endpointBase: string, endpointUrl: string, params?: HttpParams, config?: ApRequestConfig) {
    const url = this.getFullUrl(endpointBase, endpointUrl);
    const jwt = this.webCommonStorageRepository.getJsonWebToken();
    const context = new HttpContext();
    let headers = new HttpHeaders({
      ApiKey: this.configurationRepository.API_KEY,
    });
    if (jwt != null) {
      headers = headers.append('Auth-Token', jwt);
    }

    if (this.browserService.shouldAlwaysIgnoreCache() || config?.ignoreCache) {
      this.bridge.fireEvent('clear-cache');
      headers = headers.append('Cache-Control', 'no-cache, no-store, must-revalidate');
      headers = headers.append('Pragma', 'no-cache');
      headers = headers.append('Expires', new Date().toString());
    } else if (config?.cache) {
      context.set(APRIMA_HTTP_CONTEXT_CACHE_INSTANCE, config.cache);
    }

    const options = {
      context,
      headers,
      params,
    };
    return {
      options,
      url,
    };
  }

  sendGetRequest<T>(endpointBase: string, endpointUrl: string, params?: HttpParams, config?: ApRequestConfig): Observable<T> {
    const requestParams = this.buildRequestParams(endpointBase, endpointUrl, params, config);
    return this.httpClient.get<T>(requestParams.url, requestParams.options);
  }

  sendPostRequest<T>(endpointBase: string, endpointUrl: string, body: any, params?: HttpParams): Observable<T> {
    const requestParams = this.buildRequestParams(endpointBase, endpointUrl, params);
    return this.httpClient.post<T>(requestParams.url, body, requestParams.options);
  }

  sendPutRequest<T>(endpointBase: string, endpointUrl: string, body: any, params?: HttpParams): Observable<T> {
    const requestParams = this.buildRequestParams(endpointBase, endpointUrl, params);
    return this.httpClient.put<T>(requestParams.url, body, requestParams.options);
  }

  sendDeleteRequest<T>(endpointBase: string, endpointUrl: string, params?: HttpParams): Observable<T> {
    const requestParams = this.buildRequestParams(endpointBase, endpointUrl, params);
    return this.httpClient.delete<T>(requestParams.url, requestParams.options);
  }
}
