import { Injectable } from '@angular/core';
import {
  CacheFactory,
  DeleteOnExpire,
  GetPutOptions,
  ItemInfo,
  OnExpireCallback,
  StorageImpl,
  StorageMode,
} from 'cachefactory';
import {
  assign,
  cloneDeep,
  defaults,
  isArray,
  mapValues,
} from 'lodash-es';

import { ObjUtilService } from './ObjUtilService';

export interface AprimaCacheOptions {
  // From CacheOptions
  cacheFlushInterval?: number;
  capacity?: number;
  deleteOnExpire?: DeleteOnExpire;
  enable?: boolean;
  maxAge?: number;
  onExpire?: OnExpireCallback;
  recycleFreq?: number;
  storageMode?: StorageMode;
  storageImpl?: StorageImpl;
  storagePrefix?: string;
  storeOnResolve?: boolean;
  storeOnReject?: boolean;

  // Custom
  description: string;
  name: string;
}

export interface AprimaAdditionalCacheOptions {
  [key: string]: AprimaCacheOptions;
}

export interface AprimaCacheFactoryInfo extends AprimaCacheOptions {
  // From CacheFactoryInfo with some custom types
  size: number;
  caches: {[id: string]: AprimaCacheInfo};
}

export interface AprimaCacheInfo extends AprimaCacheOptions {
  // From CacheInfo
  id: string;
  size: number;
}

export interface AprimaCacheInstance {
  // From Cache with some custom types
  id: string;
  destroy(): void;
  disable(): void;
  enable(): void;
  get(key: string|number, options?: GetPutOptions): any;
  info(): AprimaCacheInfo;
  info(key: string|number): ItemInfo;
  keys(): Array<(string|number)>;
  keySet(): {[key: string]: string|number};
  put(key: string|number, value: any, options?: GetPutOptions): any;
  remove(key: string|number): any;
  removeAll(): void;
  removeExpired(): {[key: string]: any};
  setCacheFlushInterval(cacheFlushInterval: number): void;
  setCapacity(capacity: number): void;
  setDeleteOnExpire(deleteOnExpire: DeleteOnExpire, setRecycleFreq?: boolean): void;
  setMaxAge(maxAge: number): void;
  setOnExpire(onExpire: () => void): any;
  setOptions(cacheOptions: AprimaCacheOptions, strict?: boolean): void;
  setRecycleFreq(recycleFreq: boolean): void;
  setStorageMode(storageMode: StorageMode, storageImpl?: StorageImpl): void;
  touch(key: string|number): void;
  values(): any[];

  // Custom
  getClone(key: string|number, options?: GetPutOptions): any;
}

// One of the reasons this is necessary is because sometimes it is useful to have more control over the caching,
// and because iOS doesn't handle Cache-Control headers correctly

@Injectable({
  providedIn: 'root',
})
export class AprimaCacheService {
  static readonly ajsFactoryName = 'AprimaCache';

  cacheFactory: CacheFactory | null = null;
  disableAllCaches = false;

  constructor(
    private objUtilService: ObjUtilService,
  ) {
    // If you want to configure one of these caches to be different than what is set here
    // or to add another cache then do something like this example in your root project:
    // AprimaCacheService.initialize({
    //   Lists: {
    //     maxAge: 10 * 60 * 1000,
    //   },
    //   Default: {
    //     maxAge: 5 * 60 * 1000,
    //     deleteOnExpire: 'aggressive',
    //   },
    //   NewCache: {
    //     maxAge: 10 * 60 * 1000,
    //     deleteOnExpire: 'passive',
    //   },
    //   NewCache2: {
    //     maxAge: 10 * 60 * 1000,
    //     deleteOnExpire: 'passive',
    //   }
    // });
  }

  initialize(additionalCaches?: AprimaAdditionalCacheOptions, disableAllCaches: boolean = false): void {
    if (this.cacheFactory !== null) {
      throw new Error('AprimaCacheService has already been intialized');
    }

    this.cacheFactory = new CacheFactory();
    this.disableAllCaches = disableAllCaches;

    // IMPORTANT:
    // Using localStorage or sessionStorage for storageMode doesn't work right with promises,
    // because of a bug where the item never gets removed from storage
    // even after it's supposed to be expired (https://github.com/jmdobry/angular-cache/issues/224)

    // These caches are global, so if you change one, it will be changed for everywhere that uses it
    // These below are the common ones
    const cacheSettings: AprimaAdditionalCacheOptions = {
      // cache to be used for lists
      Lists: {
        maxAge: 1 * 60 * 60 * 1000, // 60 minutes (1 hour)
        deleteOnExpire: 'passive',
        capacity: 200,
        description: 'All Lists',
        name: 'Lists',
      },
      // default http cache
      Default: {
        maxAge: 2 * 60 * 60 * 1000, // 2 hours
        deleteOnExpire: 'passive',
        capacity: 200,
        description: 'General Data Cache',
        name: 'Default',
      },
      Permissions: {
        maxAge: 30 * 60 * 1000, // 30 minutes
        deleteOnExpire: 'passive',
        capacity: 100,
        description: 'Security Permissions',
        name: 'Permissions',
      },

      // I don't believe any IMO data will change more than once per day (24 hours)
      ImoTerms: {
        maxAge: 24 * 60 * 60 * 1000, // 24 hours
        deleteOnExpire: 'passive',
        storageMode: 'localStorage',
        capacity: 200,
        description: 'Diagnosis Terms',
        name: 'ImoTerms',
      },
      ImoDetails: {
        maxAge: 24 * 60 * 60 * 1000, // 24 hours
        deleteOnExpire: 'passive',
        storageMode: 'localStorage',
        capacity: 100,
        description: 'Diagnosis Details',
        name: 'ImoDetails',
      }
    };

    if (additionalCaches) {
      assign(cacheSettings, additionalCaches);
    }

    const caches = mapValues(cacheSettings, (settings, cacheName) => {
      const newCache = this.createNew(cacheName, settings);
      return newCache;
    });

    this.objUtilService.defineReadonlyProps(this, caches);
  }

  private validateConfiguration(): CacheFactory {
    if (this.cacheFactory === null) {
      throw new Error('AprimaCacheService has not been intialized');
    }
    return this.cacheFactory;
  }

  clearAll(): void {
    const cacheFactory = this.validateConfiguration();
    cacheFactory.clearAll();
  }

  createNew(cacheName: string, options?: AprimaCacheOptions | undefined): AprimaCacheInstance {
    const cache = this.createNewClean(cacheName, options);
    if (this.disableAllCaches) {
      cache.disable();
    }
    return cache;
  }

  createNewOverride(cacheName: string, options?: AprimaCacheOptions | undefined): AprimaCacheInstance {
    return this.createNewClean(cacheName, options);
  }

  destroy(id: string): void {
    const cacheFactory = this.validateConfiguration();
    try {
      cacheFactory.destroy(id);
    } catch (err: any) {
      if (err instanceof ReferenceError) {
        return undefined;
      }
      throw err;
    }
  }

  destroyAll(): void {
    const cacheFactory = this.validateConfiguration();
    cacheFactory.destroyAll();
  }

  disableAll(): void {
    const cacheFactory = this.validateConfiguration();
    cacheFactory.disableAll();
  }

  enableAll(): void {
    const cacheFactory = this.validateConfiguration();
    cacheFactory.enabledAll();
  }

  get(id: string): AprimaCacheInstance | undefined {
    const cacheFactory = this.validateConfiguration();

    try {
      return cacheFactory.get(id) as AprimaCacheInstance;
    } catch (err: any) {
      if (err instanceof ReferenceError) {
        return undefined;
      }
      throw err;
    }
  }

  getOrCreate(cacheName: string, options?: AprimaCacheOptions | undefined): AprimaCacheInstance {
    const cache = this.getOrCreateClean(cacheName, options);
    if (this.disableAllCaches) {
      cache.disable();
    }
    return cache;
  }

  getOrCreateOverride(cacheName: string, options?: AprimaCacheOptions | undefined): AprimaCacheInstance {
    return this.getOrCreateClean(cacheName, options);
  }

  info(): AprimaCacheFactoryInfo {
    const cacheFactory = this.validateConfiguration();
    return cacheFactory.info() as AprimaCacheFactoryInfo;
  }

  keys() {
    const cacheFactory = this.validateConfiguration();
    return cacheFactory.keys();
  }

  keySet() {
    const cacheFactory = this.validateConfiguration();
    return cacheFactory.keySet();
  }

  removeExpiredFromAll() {
    const cacheFactory = this.validateConfiguration();
    return cacheFactory.removeExpiredFromAll();
  }

  touchAll(): void {
    const cacheFactory = this.validateConfiguration();
    cacheFactory.touchAll();
  }

  private createNewClean(cacheName: string, options?: AprimaCacheOptions | undefined): AprimaCacheInstance {
    this.destroy(cacheName);

    const cacheFactory = this.validateConfiguration();

    // TODO: figure out how to make this work without casting.
    // This code was ported from AngularJS so it needs to continue to work as it did before.
    const cache = cacheFactory.createCache(cacheName, options) as AprimaCacheInstance;

    // This code below used to be in a decorator of CacheFactory,
    // but because CacheFactory is defined as a singleton outside of angular,
    // the singleton is not re-created each time the modules are reloaded.
    // Because of this, making changes to methods on the singleton was causing issues in the unit tests,
    // since those changes were persisting between tests.
    // That is why it was just simply moved to this wrapper service.
    const description = options?.description ?? cacheName;
    const name = options?.name ?? cacheName;

    const infoFn = cache.info;
    // tslint:disable-next-line:only-arrow-functions
    cache.info = function() {
      const infoFnResult = infoFn.apply(cache, arguments);
      if (infoFnResult) {
        infoFnResult.description = description;
        infoFnResult.name = name;
      }
      return infoFnResult;
    };

  // tslint:disable-next-line:only-arrow-functions
    cache.getClone = function() {
      const getFnResult = cache.get.apply(cache, arguments);
      const clone = cloneDeep(getFnResult);
      return clone;
    };

    this.addHttpModes(cache, cacheName);

    return cache;
  }

  private getOrCreateClean(cacheName: string, options?: AprimaCacheOptions | undefined) {
    const cache = this.get(cacheName) || this.createNewClean(cacheName, options);
    return cache;
  }

  // adds httpMode and httpIgnoreMode properties to the cache
  // that will add more logging when passing in to restangular and/or $http
  // These are only for AngularJS so remove this when WebCommon is pure Angular.
  private addHttpModes(cache: AprimaCacheInstance, cacheName: string) {
    if (!cache) {
      return;
    }

    const httpSubset = {
      get: getWithHttpLogging,
      put: wrap(cache, 'put'),
      remove: wrap(cache, 'remove'),
      removeAll: wrap(cache, 'removeAll'),
      info: wrap(cache, 'info'),
      destroy: wrap(cache, 'destroy'),
    };

    const httpIgnoreSubset = defaults({
      get: getIgnoreCacheWithHttpLogging,
    }, httpSubset);

    // pass these into $http or restangular, instead of just the normal cache object
    this.objUtilService.defineReadonlyProps(cache, {
        httpMode: httpSubset,
        httpIgnoreMode: httpIgnoreSubset,
    });

    return cache;

    // adds logging for when it is using a cached value
    function getWithHttpLogging(url: string) {
      const result = cache.get.apply(cache, arguments);
      if (typeof result !== 'undefined') {
        const msg = isArray(result) ? '($http format: [statusCode, data, headers, statusText])' : '';

        // tslint:disable-next-line:no-console
        console.debug(httpLogPrefix(cacheName), '-- Retrieved "' + url + '" from cache. Result' + msg + ':', result);
      }

      return result;
    }

    // have $http ignore the cached value by just returning undefined instead
    // and log when a value is ignored
    function getIgnoreCacheWithHttpLogging(url: string) {
      // tslint:disable-next-line:no-console
      console.debug(httpLogPrefix(cacheName), '-- Ignored cache for "' + url + '".');
      return undefined;
    }
  }
}

function httpLogPrefix(cacheId: string) {
  return 'AprimaCache-' + cacheId + '-Http';
}

function wrap(obj: any, methodName: string) {
  // tslint:disable-next-line:only-arrow-functions
  return function() {
    return obj[methodName].apply(obj, arguments);
  };
}
