import { Injectable } from '@angular/core';

import { PortalUrlComponents } from 'webcommon/shared';

import { BrowserService } from './BrowserService';

@Injectable({
  providedIn: 'root',
})
export class UrlParsingService {
  static readonly ajsFactoryName = 'UrlParsingService';

  constructor(
    browserService: BrowserService,
  ) {
    this.locationObj = browserService.getLocation();
  }

  private locationObj: Location;

  getParameterByName(name: string): string {
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    const results = regex.exec(this.locationObj.search);

    return results == null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }

  parsePortalUrl(marker: string): Promise<PortalUrlComponents> {
    const url = this.locationObj.href;
    const markerIndex = url.indexOf(marker);
    const restOfUrlIndex = markerIndex + marker.length;
    if (markerIndex > 0 && restOfUrlIndex < url.length) {
      const hostUrl = url.substring(0, markerIndex);
      const restOfUrl = url.substring(restOfUrlIndex);
      const firstSlashIndex = restOfUrl.indexOf('/');
      if (firstSlashIndex > 0) {
        const clientId = restOfUrl.substring(0, firstSlashIndex);
        const clientConfig = {
          clientId,
          hostUrl,
        };
        return Promise.resolve(clientConfig);
      }
    }
    return Promise.reject();
  }
}
