<div class="body">
  <!-- ap-login-background client-Id="model.clientID" -->
  <div id="th-login-container" class="container-fluid">
    <form #thLoginForm="ngForm" name="thLoginForm" (ngSubmit)="telehealthLogin()" novalidate>
      <div class="row">
        <div class="col-xs-12 col-xs-flush col-md-6 col-md-offset-3">
          <div id="login-header" class="logo-group">
            <div class="row">&nbsp;</div>
            <div class="modal-dialog modal-dialog-login">
              <div class="row">
                <div class="col-xs-12 col-xs-flush col-md-10">
                  <div class="modal-content">
                    <div class="modal-header">
                      <div class="text-center">
                        <div class="login-payment-portal header-padding-5">
                          Payment P<em>o</em>rtal
                        </div>
                        <div class="modal-subtitle header-padding-5" id="thModalLabel2" *ngIf="practiceName">
                          {{practiceName}}
                        </div>
                        <div class="modal-tagline" id="thModalLabel3">
                          In order to protect your privacy and preserve security, we require authentication
                          for access to our secure payment form.
                        </div>
                      </div>
                    </div>
                    <div class="modal-body">

                      <div class="form-group" [class.has-error]="thLoginForm.form.controls.zipCode && !thLoginForm.form.controls.zipCode.valid && submitted">
                        <label for="zipCode">ZipCode</label>

                        <input class="form-control" name="zipCode" [(ngModel)]="zipCode" id="zipCode"
                          placeholder="12345" [pattern]="zipCodeRegex" [required]="true">
                        <span *ngIf="thLoginForm.form.controls.zipCode && !thLoginForm.form.controls.zipCode.valid && submitted" class="help-block">
                          Not valid format.<br />
                          12345
                        </span>
                      </div>
                      <div class="form-group"
                        [class.has-error]="thLoginForm.form.controls.birthDate && !thLoginForm.form.controls.birthDate.valid && submitted">

                        <label for="birthDate">BirthDate</label>

                        <input type="text" class="form-control" name="birthDate" [(ngModel)]="birthDate"
                          placeholder="yyyy-mm-dd" id="birthDate" [pattern]="birthDateRegex" [required]="true">

                        <span *ngIf="thLoginForm.form.controls.birthDate && !thLoginForm.form.controls.birthDate.valid && submitted"
                          class="text-danger">
                          Not valid format.<br />
                          yyyy-mm-dd
                        </span>
                      </div>

                      <div class="text-center">
                        <div class="col-xs-12 col-flush">
                          <div class="row">
                            <div *ngIf="hasLoginError" class="text-danger">
                              <div class="pre">The information provided is incorrect.<br />Please try agian.</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <button type="submit" class="btn btn-primary btn-lg btn-block btn-login" [disabled]="submitting">
                          <span *ngIf="submitting">
                            <ap-spinner></ap-spinner>
                          </span>
                          Login
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <!-- Below is a hack to get the android webview to behave properly while logging in.
      If this is removed, it will cause random whitespace to appear after login.  Please don't remove. -->
    <div class="row" style="overflow: hidden; min-height: 1px;">
      <div class="col-xs-12 col-sm-10 col-sm-offset-1 col-md-6 col-md-offset-3"
        style="margin-bottom: -99999px; padding-bottom: 99999px;"></div>
    </div>
  </div>
</div>
