import {Directive, OnDestroy} from '@angular/core';
import {MonoTypeOperatorFunction, Observable, Subject} from 'rxjs';

import { takeUntil } from 'rxjs/operators';
import {throwAsyncError} from '../errors';

// This is an interface to expose an Observable that emits when the implementing class is destroyed
export interface Destroyable$ {
  onDestroy$: Observable<any>;
}

// This can contain stuff shared across classes for services, components, etc
@Directive()
// tslint:disable-next-line:directive-class-suffix
export class ApBaseClass implements OnDestroy, Destroyable$ {
  readonly onDestroy$: Subject<void> = new Subject<void>();

  throwAsyncError(error: unknown): void {
    throwAsyncError(error);
  }

  takeUntilDestroy<T>(): MonoTypeOperatorFunction<T> {
    return takeUntil(this.onDestroy$);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
