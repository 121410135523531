import { Injectable } from '@angular/core';

import {
  ConfigurationRepository,
} from './ConfigurationRepository';

import { DeviceSettings } from 'webcommon/shared';
import { BrowserService } from './BrowserService';

@Injectable({
  providedIn: 'root',
})
export class DeviceSettingsRepository implements DeviceSettings {
  static readonly ajsFactoryName = 'DeviceSettingsRepository';

  constructor(
    private browserService: BrowserService,
    private configurationRepository: ConfigurationRepository,
  ) { }

  private deviceSettings: DeviceSettings | null = null;

  loadConfiguration(configuration: DeviceSettings): void {
    if (this.deviceSettings !== null) {
      throw new Error('deviceSettings has already been loaded');
    }
    this.deviceSettings = configuration;
  }

  // Fill in DeviceSettings based on the current browser environment
  // (navigator.userAgent, navigator.platform, etc)
  loadBasedOnBrowser(): void {
    // overrides provided for easy testing in a browser. Do not ever commit changes with true values for these properties.
    const isPhoneOverride = false;
    const isTabletOverride = false;
    const isBrowserOverride = false;

    const browserConfig = this.browserService.getConfig();
    const isIos = browserConfig.isIosOS;
    const isAndroid = browserConfig.isAndroidOS;
    const isPhone = browserConfig.isPhone;
    const isTablet = browserConfig.isTablet;
    const isDevice = browserConfig.isMobileDevice;

    const isBrowser = !isDevice;
    const isStandaloneBrowser = isBrowser || this.configurationRepository.DEV_HOSTED;

    const configuration: DeviceSettings = {
      isBrowser,
      isBrowserOrOverride: isBrowser || isBrowserOverride,
      isDevice,
      isDeviceOrOverride: isDevice || isPhoneOverride || isTabletOverride,
      isPhone,
      isPhoneOrOverride: isPhone || isPhoneOverride,
      isTablet,
      isTabletOrOverride: isTablet || isTabletOverride,

      isAndroid,
      isIos,
      isPrm: false,
      isStandaloneBrowser,
    };
    this.loadConfiguration(configuration);
  }

  private validateConfiguration(): DeviceSettings {
    if (this.deviceSettings === null) {
      throw new Error('deviceSettings has not been loaded');
    }
    return this.deviceSettings;
  }

  public get isDevice(): boolean {
    const deviceSettings = this.validateConfiguration();
    return deviceSettings.isDevice;
  }

  public get isDeviceOrOverride(): boolean {
    const deviceSettings = this.validateConfiguration();
    return deviceSettings.isDeviceOrOverride;
  }

  public get isPhone(): boolean {
    const deviceSettings = this.validateConfiguration();
    return deviceSettings.isPhone;
  }

  public get isPhoneOrOverride(): boolean {
    const deviceSettings = this.validateConfiguration();
    return deviceSettings.isPhoneOrOverride;
  }

  public get isTablet(): boolean {
    const deviceSettings = this.validateConfiguration();
    return deviceSettings.isTablet;
  }

  public get isTabletOrOverride(): boolean {
    const deviceSettings = this.validateConfiguration();
    return deviceSettings.isTabletOrOverride;
  }

  public get isBrowser(): boolean {
    const deviceSettings = this.validateConfiguration();
    return deviceSettings.isBrowser;
  }

  public get isBrowserOrOverride(): boolean {
    const deviceSettings = this.validateConfiguration();
    return deviceSettings.isBrowserOrOverride;
  }

  public get isIos(): boolean {
    const deviceSettings = this.validateConfiguration();
    return deviceSettings.isIos;
  }

  public get isAndroid(): boolean {
    const deviceSettings = this.validateConfiguration();
    return deviceSettings.isAndroid;
  }

  public get isPrm(): boolean {
    const deviceSettings = this.validateConfiguration();
    return deviceSettings.isPrm;
  }

  public get isStandaloneBrowser(): boolean {
    const deviceSettings = this.validateConfiguration();
    return deviceSettings.isStandaloneBrowser;
  }

  // This should correspond to the environment when the app is hosted inside of prm,
  // which can affect how things like sigwriter or fnc plan work.
  // Currently though, this property is passed into the plan and sigwriter
  // via a binding that is just always set to true in PrmWeb.
  // If this doesn't cover all cases, then it could be updated.
  public get hostedFromDesktop(): boolean {
    return this.isPrm && !this.isStandaloneBrowser;
  }

  // ported from old BrowserService.js
  // TODO: this should really be determined based on whether the OS is Android or iOS,
  // not just whether we are in the Android app or iOS app, which is what the logic is currently.
  // It was left this way when being ported from angularjs to keep the logic consistent for now, but should be refactored at some point.
  supportsInlinePdf(): boolean {
    if (!this.browserService.supportsInlinePdf() || this.isIos || this.isAndroid) {
      return false;
    }
    return true;
  }
}
