import {
  Directive,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
} from '@angular/core';

import { SpinnerService } from '../services/index';

// ApSpinner is an unusual directive since it needs to be an element but can't be a component.
@Directive({
  // tslint:disable-next-line
  selector: 'ap-spinner',
})
export class ApSpinnerDirective implements OnInit {
  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private spinnerService: SpinnerService,
  ) { }

  @Input() icon: string;
  @Input() size: string;

  ngOnInit() {
    const specifiedSpinnerName = this.icon;
    const specifiedSize = this.size;
    const element = this.elementRef.nativeElement as Element;
    this.spinnerService.applySpinner(element, this.renderer, specifiedSpinnerName, specifiedSize);
  }
}
