import { Injectable } from '@angular/core';
import { assign, defaults } from 'lodash-es';

import {
  ActivePatient,
  AppointmentListSettings,
  AppointmentsBadgeSettings,
  MessagesListSettings,
  ProviderDashboardSettingsDto,
  ProviderSettingsDto,
  SiteSettingsDto,
} from 'webcommon/shared';

import { StorageAcrossSessionRepository } from './StorageAcrossSessionRepository';
import { StorageInMemoryRepository } from './StorageInMemoryRepository';
import { StoragePerSessionRepository } from './StoragePerSessionRepository';

const trueRegex = /^true$/i;
const falseRegex = /^false$/i;

@Injectable({
  providedIn: 'root',
})
export class WebCommonStorageRepository {
  static readonly ajsFactoryName = 'WebCommonStorageRepository';

  constructor(
    private storageAcrossSessionRepository: StorageAcrossSessionRepository,
    private storageInMemoryRepository: StorageInMemoryRepository,
    private storagePerSessionRepository: StoragePerSessionRepository,
  ) { }

  private remove(key: string): void {
    this.storageAcrossSessionRepository.removeItem(key);
  }

  private setOrRemove(key: string, value: string | null): void {
    if (!value) {
      this.remove(key);
      return;
    }

    this.set(key, value);
  }

  private setOrRemoveObj<T>(key: string, value: T): void {
    if (!value) {
      this.remove(key);
      return;
    }

    this.setObj(key, value);
  }

  private setObj<T>(key: string, value: T): void {
    const json = JSON.stringify(value);
    this.set(key, json);
  }

  private set(key: string, value: string): void {
    this.storageAcrossSessionRepository.setItem(key, value);
  }

  private get(key: string): string | null {
    return this.storageAcrossSessionRepository.getItem(key);
  }

  private getOrThrow(key: string): string {
    return this.storageAcrossSessionRepository.getItemOrThrow(key);
  }

  private getObj<T>(key: string): T | null {
    const obj = this.get(key);
    const value = obj ? JSON.parse(obj) : null;
    return value;
  }

  private getObjOrDefault<T>(key: string, defaultValue: T): T {
    const obj = this.get(key);
    if (!obj) {
      return defaultValue;
    }

    const value = JSON.parse(obj) as T;
    return value;
  }

  private overwritePropertiesAndSave<T>(destinationGetter: () => T | null, source: T, storageKey: string) {
    let destination = destinationGetter() || {};
    destination = assign(destination, source);
    this.setObj(storageKey, destination);
  }

  private defaultPropertiesAndSave<T>(destinationGetter: () => T | null, source: T, storageKey: string) {
    let destination = destinationGetter() || {};
    destination = defaults(destination, source);
    this.setObj(storageKey, destination);
  }

  clearAll(): void {
    this.storageAcrossSessionRepository.clear();
    this.storagePerSessionRepository.clear();
    this.storageInMemoryRepository.clear();
  }

  getUserIdOrThrow(): string {
    return this.getOrThrow('userId');
  }

  getUserId(): string | null {
    return this.get('userId');
  }

  removeUserId(): void {
    this.remove('userId');
  }

  setUserId(userId: string | null): void {
    this.setOrRemove('userId', userId);
  }

  getIsActiveDirectoryUser(): boolean {
    return this.getObjOrDefault('activeDirectoryUser', false);
  }

  setIsActiveDirectoryUser(isActiveDirectoryUser: boolean): void {
    this.setObj('activeDirectoryUser', isActiveDirectoryUser);
  }

  getPatientPortalActivePatient(): ActivePatient | null {
    return this.getObj<ActivePatient>('activePatient');
  }

  getDeviceId(): string | null {
    return this.get('deviceId');
  }

  setDeviceId(deviceId: string): void {
    this.setOrRemove('deviceId', deviceId);
  }

  getUserFullName(): string | null {
    return this.get('userFullName');
  }

  setUserFullName(fullName: string | null): void {
    this.setOrRemove('userFullName', fullName);
  }

  getClientId(): string | null {
    return this.get('clientID');
  }

  setClientId(clientId: string): void {
    this.set('clientID', clientId);
  }

  private formatClientUrl(clientUrl: string | null): string | null {
    return clientUrl ? clientUrl.replace(/\/$/, '') : null;
  }

  getClientUrl(): string | null {
    const clientUrl = this.get('clientURL');
    return this.formatClientUrl(clientUrl);
  }

  setClientUrl(clientUrl: string | null): void {
    if (!clientUrl) {
      this.remove('clientURL');
    } else {
      // tslint:disable-next-line:no-non-null-assertion
      const formattedClientUrl = this.formatClientUrl(clientUrl)!;
      this.set('clientURL', formattedClientUrl);
    }
  }

  getJsonWebToken(): string | null {
    return this.get('json-web-token');
  }

  removeJsonWebToken(): void {
    this.remove('json-web-token');
  }

  setJsonWebToken(jwt: string): void {
    this.set('json-web-token', jwt);
  }

  setProviderSettings(providerSettings: ProviderSettingsDto): void {
    this.setOrRemoveObj('ProviderSettings', providerSettings);
  }

  getProviderSettings(): ProviderSettingsDto | null {
    return this.getObj('ProviderSettings');
  }

  getPrmAssemblyVersion(): string | null {
    return this.get('prmAssemblyVersion');
  }

  setPrmAssemblyVersion(assemblyVersion: string | null): void {
    this.setOrRemove('prmAssemblyVersion', assemblyVersion);
  }

  getPrmDatabaseVersion(): string | null {
    return this.get('prmDatabaseVersion');
  }

  setPrmDatabaseVersion(databaseVersion: string | null): void {
    this.setOrRemove('prmDatabaseVersion', databaseVersion);
  }

  getPrmDatabaseBuild(): string | null {
    return this.get('prmDatabaseBuild');
  }

  setPrmDatabaseBuild(databaseBuild: string | null): void {
    this.setOrRemove('prmDatabaseBuild', databaseBuild);
  }

  getMobileAssemblyVersion(): string | null {
    return this.get('mobileAssemblyVerion');
  }

  setMobileAssemblyVersion(assemblyVersion: string | null): void {
    this.setOrRemove('mobileAssemblyVerion', assemblyVersion);
  }

  getSupportedFeatures(): string[] | null {
    return this.getObj('apiSupportedFeatures');
  }

  setSupportedFeatures(supportedFeatures: string[]): void {
    this.setOrRemoveObj('apiSupportedFeatures', supportedFeatures);
  }

  getDatabaseName(): string | null {
    return this.get('databasename');
  }

  setDatabaseName(databaseName: string | null): void {
    this.setOrRemove('databasename', databaseName);
  }

  getUserName(): string | null {
    return this.get('userName');
  }

  setUserName(userName: string): void {
    this.setOrRemove('userName', userName);
  }

  setSiteSettings(siteSettings: SiteSettingsDto): void {
    this.setOrRemoveObj('SiteSettings', siteSettings);
  }

  getSiteSettings(): SiteSettingsDto | null {
    return this.getObj('SiteSettings');
  }

  getUserPermissions(): { [key: string]: boolean } | null {
    return this.getObj('userPermissions');
  }

  setUserPermissions(permissions: { [key: string]: string }) {
    const mappedPermissions = this.mapPermissions(permissions);
    this.setObj('userPermissions', mappedPermissions);
  }

  private mapPermissions(permissions: any): any {
    const permissionObj: any = permissions || {};

    // fix the issue with the backend not returning actually bool values, for some properties here
    // it can return things like 'True' and 'False'
    // this will coerce those to actual bool values
    for (const prop in permissionObj) {
      if (trueRegex.test(permissionObj[prop])) {
        permissionObj[prop] = true;
      }
      else if (falseRegex.test(permissionObj[prop])) {
        permissionObj[prop] = false;
      }
    }

    return permissionObj;
  }

  getDashboardTileSettings(): ProviderDashboardSettingsDto | null {
      return this.getObj('settings.dashboard.tiles');
  }

  setDashboardTileSettings(settings: ProviderDashboardSettingsDto): void {
      return this.overwritePropertiesAndSave(() => this.getDashboardTileSettings(), settings, 'settings.dashboard.tiles');
  }

  setDefaultDashboardTileSettings(settings: ProviderDashboardSettingsDto): void {
      return this.defaultPropertiesAndSave(() => this.getDashboardTileSettings(), settings, 'settings.dashboard.tiles');
  }

  getAppointmentsListSettings(): AppointmentListSettings | null {
    return this.getObj('settings.appointmentslist');
  }

  setAppointmentsListSettings(settings: AppointmentListSettings): void {
    this.overwritePropertiesAndSave(() => this.getAppointmentsListSettings(), settings, 'settings.appointmentslist');
  }

  setDefaultAppointmentsListSettings(settings: AppointmentListSettings): void {
    this.defaultPropertiesAndSave(() => this.getAppointmentsListSettings(), settings, 'settings.appointmentslist');
  }

  getMessagesListSettings(): MessagesListSettings | null {
    return this.getObj('settings.messageslist');
  }

  setMessagesListSettings(settings: MessagesListSettings): void {
    return this.overwritePropertiesAndSave(() => this.getMessagesListSettings(), settings, 'settings.messageslist');
  }

  setDefaultMessagesListSettings(settings: MessagesListSettings): void {
    const messageSettings = this.getMessagesListSettings() as any;
    if (messageSettings?.messageFilterId) {
      // for legacy
      this.setOrRemoveObj('settings.messageslist', settings);
    } else {
      return this.defaultPropertiesAndSave(() => this.getMessagesListSettings(), settings, 'settings.messageslist');
    }
  }

  getAppointmentsBadgeSettings(): AppointmentsBadgeSettings | null {
    return this.getObj('settings.appointments.badge.settings');
  }

  setAppointmentsBadgeSettings(settings: AppointmentsBadgeSettings): void {
    this.overwritePropertiesAndSave(() => this.getAppointmentsBadgeSettings(), settings, 'settings.appointments.badge.settings');
  }

  setDefaultAppointmentsBadgeSettings(settings: AppointmentsBadgeSettings): void {
    this.defaultPropertiesAndSave(() => this.getAppointmentsBadgeSettings(), settings, 'settings.appointments.badge.settings');
  }

  getPhoneMessageGeneralNoteTypeId(): string | null {
    return this.get('settings.phoneMessageGeneralNoteTypeId');
  }

  setPhoneMessageGeneralNoteTypeId(id: string | null): void {
    this.setOrRemove('settings.phoneMessageGeneralNoteTypeId', id);
  }

  getTaskMessageGeneralNoteTypeId(): string | null {
    return this.get('settings.taskMessageGeneralNoteTypeId');
  }

  setTaskMessageGeneralNoteTypeId(id: string | null): void {
    this.setOrRemove('settings.taskMessageGeneralNoteTypeId', id);
  }

  getPharmacyGeneralNoteTypeId(): string | null {
    return this.get('settings.pharmacyGeneralNoteTypeId');
  }

  setPharmacyGeneralNoteTypeId(id: string | null): void {
    this.setOrRemove('settings.pharmacyGeneralNoteTypeId', id);
  }

  getPortalMessageGeneralNoteTypeId(): string | null {
    return this.get('settings.portalMessageGeneralNoteTypeId');
  }

  setPortalMessageGeneralNoteTypeId(id: string | null): void {
    this.setOrRemove('settings.portalMessageGeneralNoteTypeId', id);
  }

  getRxRefillMessageGeneralNoteTypeId(): string | null {
    return this.get('settings.rxRefillMessageGeneralNoteTypeId');
  }

  setRxRefillMessageGeneralNoteTypeId(id: string | null): void {
    this.setOrRemove('settings.rxRefillMessageGeneralNoteTypeId', id);
  }

  getPharmacyRefillMessageGeneralNoteTypeId(): string | null {
    return this.get('settings.pharmacyRefillMessageGeneralNoteTypeId');
  }

  setPharmacyRefillMessageGeneralNoteTypeId(id: string | null): void {
    this.setOrRemove('settings.pharmacyRefillMessageGeneralNoteTypeId', id);
  }

  getMedicationOverrideGeneralNoteTypeId(): string | null {
    return this.get('settings.medicationOverrideGeneralNoteTypeId');
  }

  setMedicationOverrideGeneralNoteTypeId(id: string | null): void {
    this.setOrRemove('settings.medicationOverrideGeneralNoteTypeId', id);
  }

  getPatientVitalsDefaultSettingId(): string | null {
    return this.get('settings.patientVitalsDefaultSettingId');
  }

  setPatientVitalsDefaultSettingId(id: string | null): void {
    this.setOrRemove('settings.patientVitalsDefaultSettingId', id);
  }

  getIsCacheClient(): boolean {
    return this.getObjOrDefault('settings.iscacheclient', false);
  }

  setIsCacheClient(isCacheClient: boolean): void {
    this.setObj('settings.iscacheclient', isCacheClient);
  }
}
