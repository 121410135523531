import { Injectable } from '@angular/core';
import { ApModalResult, ApModalService } from 'webcommon/core-ui';
import { ApPersonalHealthAppsModalComponent } from './ap-personal-health-apps-modal.component';

@Injectable({
  providedIn: 'root',
})

export class ApPersonalHealthAppsModalService {
  static readonly ajsFactoryName = 'ApPersonalHealthAppsModalService';

  constructor(
    private apModalService: ApModalService,
  ) { }

  openModal(
    baseId: string,
    patientId: string,
    isPatientRegisteredForFHIR: boolean,
  ): Promise<ApModalResult<boolean>> {

    const initialState = {
      baseId,
      patientId,
      isPatientRegisteredForFHIR,
    };

    const p = this.apModalService.showComponentModal(ApPersonalHealthAppsModalComponent, {
      class: 'modal-lg',
      ignoreBackdropClick: true,
      initialState,
    });

    return p;
  }
}
