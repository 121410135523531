import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProviderDto, ProviderSignatureDto } from 'webcommon/shared';
import { PrmHttpClientService } from '../PrmHttpClientService';

@Injectable({
  providedIn: 'root',
})
export class ProviderRepository {
  constructor(
    private httpClientService: PrmHttpClientService,
  ) { }

  getProviders(): Observable<ProviderDto[]> {
    const endpointUrl = `providers`;
    return this.httpClientService.sendGetRequest(endpointUrl);
  }

  getProvidersInternal(): Observable<ProviderDto[]> {
    const endpointUrl = `providersInternal`;
    return this.httpClientService.sendGetRequest(endpointUrl);
  }

  GetCurrentProviderSigningSignature(): Observable<ProviderSignatureDto> {
    const endpointUrl = `provider/signing-signature`;
    return this.httpClientService.sendGetRequest(endpointUrl);
  }

  getProviderCCMLicensing(): Observable<boolean> {
    const endpointUrl = `provider/licensed-ccm-cpo-professional`;
    return this.httpClientService.sendGetRequest(endpointUrl);
  }
}
