import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {AprimaHttpClientService, ApRequestConfig} from 'webcommon/legacy-common';

const endpointBase = '';

@Injectable({
  providedIn: 'root',
})
export class PrmHttpClientService {
  constructor(
    private aprimaHttpClientService: AprimaHttpClientService,
  ) { }

  getFullUrl(endpointUrl?: string): string {
    const baseUrl = this.aprimaHttpClientService.getFullUrl(endpointBase, endpointUrl);
    return baseUrl;
  }

  sendGetRequest<T>(endpointUrl: string, params?: HttpParams, config?: ApRequestConfig): Observable<T> {
    return this.aprimaHttpClientService.sendGetRequest<T>(endpointBase, endpointUrl, params, config);
  }

  sendPostRequest<T>(endpointUrl: string, body: any, params?: HttpParams): Observable<T> {
    return this.aprimaHttpClientService.sendPostRequest<T>(endpointBase, endpointUrl, body, params);
  }

  sendPutRequest<T>(endpointUrl: string, body: any, params?: HttpParams): Observable<T> {
    return this.aprimaHttpClientService.sendPutRequest<T>(endpointBase, endpointUrl, body, params);
  }

  sendDeleteRequest<T>(endpointUrl: string, params?: HttpParams): Observable<T> {
    return this.aprimaHttpClientService.sendDeleteRequest<T>(endpointBase, endpointUrl, params);
  }
}
