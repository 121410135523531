import {
  enableProdMode,
  StaticProvider,
} from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { downgradeModule } from '@angular/upgrade/static';

import { PatientPortalAngularJSModule } from '@app/ajs/ajs.module';
import { AppModule } from '@app/app.module';
import { environment } from './environments/environment';

// TODO: use npm package for hammerjs and propagating-hammerjs, and delete the bower packages,
// and most likely load them up here:
// import 'hammerjs';
// import 'propagating-hammerjs';
// also, angular-hammer bower pkg depends on hammerjs, so I think it will complain that
// it's missing a dependency if hammerjs bower pkg is deleted, but maybe there's a way around that.
// also could install @types/hammerjs if the types need to be referenced somewhere
// also need to migrate app.config.hammer to typescript as well

declare var angular: angular.IAngularStatic;

if (environment.production) {
  console.log('enabling ProdMode');
  enableProdMode();
} else {
  console.log('not enabling ProdMode');
}

function bootstrapFn(extraProviders: StaticProvider[]) {
  // Angular CLI automatically converts this into platformBrowser().bootstrapModule when AOT compiled.
  const promise = platformBrowserDynamic(extraProviders).bootstrapModule(AppModule);
  promise.catch((err) => console.error(err));
  return promise;
}

const ngModule = angular.module('ngPatientPortal', [
  downgradeModule(bootstrapFn),
]);
PatientPortalAngularJSModule.registerAngularJS(ngModule);

angular.bootstrap(document.body, ['all']);
