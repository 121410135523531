<div class="panel panel-default panel-flush table-responsive" id="patient-documents-panel">
    <div class="panel-content">

        <div class="panel-heading">
            {{'Documents' | translate}}
            <div class="dropdown header-menu pull-right">
                <button class="btn btn-default dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                    <span>{{'View' | translate }}&nbsp;&nbsp;</span>
                    <span class="title-text">{{ activeFilter.name| translate }}</span>
                    <span class="caret"></span>
                </button>
                <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu1">
                  <li *ngFor="let filter of filters">
                    <a (click)="updateFilter(filter)">{{filter.name}}</a>
                  </li>
                </ul>
          </div>
        </div>

        <table id="ap-patient-documents-table" class="table table-striped ap-patient-documents-table">
            <thead>
                <tr>
                    <th>{{'Date' | translate}}</th>
                    <th>{{'Name' | translate}}</th>
                    <th>{{'Document' | translate}}</th>
                    <th>{{'' | translate}}</th> <!-- download -->
                </tr>
            </thead>
            <tbody>
                <ng-template [ngIf]="hasDocuments()" [ngIfElse]="noDocumentsNotice">
                  <tr *ngFor="let document of documentClone">
                      <td>
                          {{document.FileDate | date : 'MMMM d, yyyy'}}
                      </td>
                      <td>
                          <div>
                            {{document.FileName}}
                          </div>
                          <div class="column-subtext">
                            {{document.AttachmentTypeName}}
                          </div>
                      </td>
                      <td>
                        <span class="view-document">
                          <i class="fa fa-file fa-lg"></i>
                          <div>
                            <a class="underline" (click)="viewDocument(document)">View Document</a>
                            <div class="column-subtext">{{document.FileExtension | fileExt}}</div>
                          </div>
                        </span>
                      </td>
                      <td>
                        <button
                          (click)="setDocumentDownloading(document)"
                          apSaveToFile
                          [documentDownload]="onDocumentDownloadRef.bind(undefined, document.AttachmentUid)"
                          [fileName]="document.FileName">
                            <i *ngIf="!document.Downloading" class="fa fa-download"></i>
                            <ap-spinner *ngIf="document.Downloading"></ap-spinner>
                        </button>
                      </td>
                  </tr>
                </ng-template>
                <ng-template #noDocumentsNotice>
                  <tr>
                    <td colspan="4"><p>No documents were found.</p></td>
                  </tr>
                </ng-template>
            </tbody>
        </table>
    </div>
</div>
