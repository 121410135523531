import { Injectable } from '@angular/core';
import { AttachmentRequestConfig, Document } from 'webcommon/shared';

import { ApModalService } from 'webcommon/core-ui';
import { PatientDocumentViewerModalComponent } from './patient-document-viewer-modal.component';

@Injectable({
  providedIn: 'root',
})
export class PatientDocumentsViewerModalService {
  constructor(private apModalService: ApModalService) { }

  openModal(patientId: string, document: Document, requestConfig: AttachmentRequestConfig): Promise<string> {
    const initialState = { patientId, document, requestConfig };
    const p = this.apModalService.showComponentModal(PatientDocumentViewerModalComponent, {
      class: 'modal-lg',
      initialState,
    });
    return p;
  }
}
