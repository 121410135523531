import {InjectionToken} from '@angular/core';
import { STATE_HANDLER_NAMES, ViewModes } from 'webcommon/foxit';

// this is necessary, to just allow this as a constructor parameter
export const FoxitLicenseDtoToken = new InjectionToken<FoxitLicenseDto>('FoxitLicenseDto');

export interface FoxitLicenseDto {
  LicenseSN: string;
  LicenseKey: string;
  DefaultTool: STATE_HANDLER_NAMES;
  DefaultViewMode: ViewModes;
}
