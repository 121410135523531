import {
  Injectable,
} from '@angular/core';

import {
  ApBaseClass,
  DocumentHelper,
} from 'webcommon/shared';

import {BridgeService} from 'webcommon/legacy-common';

import {Observable} from 'rxjs';

import {
  take,
  takeUntil,
} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class IosDocumentHelper extends ApBaseClass implements DocumentHelper {
  constructor(
    private readonly bridge: BridgeService,
  ) {
    super();
  }

  openUrlInSeparateTab(url$: Observable<string>) {
    url$.pipe(
      take(1),
      takeUntil(this.onDestroy$),
    ).subscribe(
      (url) => this.openInSeparateTab(url),
    );
  }

  private openInSeparateTab(url: string) {
    this.bridge.fireEvent('OpenLinkInBrowser', {
      url,
    });
  }

  supportsOpenInSeparateTab(): boolean {
    return true;
  }
}
