import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  ApplyPaymentToAppointmentRequestDto,
  PaymentResponseDto,
  ReceiptDto,
  ReceiptRequestDto,
  TelehealthBillingOptionsDto,
} from 'webcommon/shared';
import { PatientPortalHttpClientService } from '../PatientPortalHttpClientService';

@Injectable({
  providedIn: 'root',
})
export class BillingRepository {
  constructor(
    private readonly httpClientService: PatientPortalHttpClientService,
  ) { }

  getTelehealthBilling(appointmentId: string, redirectUrl: string): Observable<TelehealthBillingOptionsDto> {
    const endpointUrl = `billing/telehealth/${appointmentId}/payment-options`;
    const params = new HttpParams({fromObject: {redirectUrl}});

    return this.httpClientService.sendGetRequest(endpointUrl, params);
  }

  postApplyTelehealthPayment( request: ApplyPaymentToAppointmentRequestDto): Observable<PaymentResponseDto> {
    const endpointUrl = `billing/${request.LoginUserPatientId}/apply-appointment-payment`;
    return this.httpClientService.sendPostRequest(endpointUrl, request);
  }

  getReceiptHtml(request: ReceiptRequestDto): Observable<ReceiptDto> {
    const endpointUrl = `billing/${request.LoginUserPatientId}/receipt`;
    const params = new HttpParams({fromObject: request as any});

    return this.httpClientService.sendGetRequest(endpointUrl, params);
  }
}
