import { Injectable } from '@angular/core';
import {
  EMPTY,
  Observable,
} from 'rxjs';

import { Bridge } from 'webcommon/shared';

@Injectable({
  providedIn: 'root',
})
export class WebBridgeService implements Bridge {
  fireEvent(): void {
    // Do nothing because the only thing listening for a bridge event fired from JS should not be JS.
  }

  addEventListener(): void {
    // Do nothing because the only thing listening for a bridge event fired from JS should not be JS.
  }

  removeEventListener(): void {
    // Do nothing because the only thing listening for a bridge event fired from JS should not be JS.
  }

  on<T>(): Observable<T> {
    // Do nothing useful because the only thing listening for a bridge event fired from JS should not be JS.
    return EMPTY;
  }

  once<T>(): Observable<T> {
    // Do nothing useful because the only thing listening for a bridge event fired from JS should not be JS.
    return EMPTY;
  }
}
