export interface StorageRepository {
  /**
   * Empties the list associated with the object of all key/value pairs, if there are any.
   */
  clear(): void;
  /**
   * value = storage[key] ?? throw
   * This method allows the return type to be string if there's some situation
   * where we don't want to have to handle null.
   */
  getItemOrThrow(key: string): string;
  /**
   * value = storage[key]
   */
  getItem(key: string): string | null;
  /**
   * delete storage[key]
   */
  removeItem(key: string): void;
  /**
   * storage[key] = value
   */
  setItem(key: string, value: string): void;
}

export function throwInvalidKeyError(key: string): never {
  throw new Error(`key ${key} does not exist`);
}
