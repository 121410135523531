import { ElementRef, EventEmitter, OnDestroy, Output, ViewChild, Directive } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, skipWhile, take, takeUntil } from 'rxjs/operators';
import { ApBaseClass } from 'webcommon/shared';

// This can contain stuff shared across ui components
@Directive()
// tslint:disable-next-line:directive-class-suffix
export class ApBaseComponent extends ApBaseClass implements OnDestroy {
  readonly MAX_HEIGHT = '100%';
  // Base class for all components
  @Output() loadingChange: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('loadElement') el: ElementRef;

  firstHeightSet = false; // used to tell when first load has been called
  delayObs: Observable<any> | null;
  delaySub: Subscription;

  tempHeight = 0;
  _HEIGHT = this.MAX_HEIGHT;
  _LOADING = false;
  get loading(): boolean {
    return this._LOADING;
  }
  set loading(value: boolean) {
    if (this._LOADING === value) { return; }
    this.setLoadingHeight(value);
    this._LOADING = value;
    this.loadingChange.emit(this.loading);
  }

  setLoadingHeight(value: boolean) {
    if (!this.el) { return; }

    if (!this.firstHeightSet) {
      this.firstHeightSet = true;
      return;
    }

    if (value) {
      this._HEIGHT = this.el.nativeElement.offsetHeight + 'px';
    } else {
      this.setMaxHeightWhenDone();
    }
  }

  getHeight() {
    return this._HEIGHT;
  }

  waitForLoadingHeightChange(): Observable<any> | null { // This is meant to be overridden
    return null;
  }

  setMaxHeightWhenDone(): void {
    if (this.delaySub && !this.delaySub.closed) {
      this.delaySub.unsubscribe();
    }
    this.delayObs = this.waitForLoadingHeightChange();
    if (!this.delayObs) {
      this._HEIGHT = this.MAX_HEIGHT;
      return;
    }

    this.delaySub = this.delayObs.pipe(
      debounceTime(0),
      skipWhile(() => this.loading),
      take(1),
      takeUntil(this.onDestroy$),
    ).subscribe(
      () => this._HEIGHT = this.MAX_HEIGHT,
      () => {/*Error*/},
    );
  }

  override ngOnDestroy() {
    if (this.loading) {
      this.loadingChange.emit(false);
    }
    super.ngOnDestroy();
  }
}
