import {
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';

import {
  BrowserWebViewManagerJs,
  TransitionManagerJs,
  XamarinWebViewManagerJs,
} from './services/index';
import {
  TransitionManagerService,
  WebViewManagerService,
} from 'webcommon/legacy-common';

@Component({
  selector: 'ap-force-load-angular',
  template: '',
})
export class ForceLoadAngularComponent implements OnInit {
  static readonly ajsComponentName = 'apForceLoadAngular';
  @Output() angularInitialized = new EventEmitter();

  constructor(
    private browserWebViewManagerJs: BrowserWebViewManagerJs,
    private transitionManagerJs: TransitionManagerJs,
    private transitionManagerService: TransitionManagerService,
    private webViewManagerService: WebViewManagerService,
    private xamarinWebViewManagerJs: XamarinWebViewManagerJs,
  ) {
    this.transitionManagerService.loadConfiguration(this.transitionManagerJs);
    this.webViewManagerService.loadConfiguration(this.browserWebViewManagerJs, this.xamarinWebViewManagerJs);
  }

  ngOnInit() {
    this.angularInitialized.emit();
  }
}
