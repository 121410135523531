// Put common functions for Foxit library in here, so that it's not referenced everywhere,
// and it will allow it to be easier to use and more reusable.
// Don't statically import or export anything other than types here though, or it will cause the whole library to get included,
// which defeats the purpose of lazy-loading this in the first place.

// It is important to note that tree-shaking doesn't work the same for dynamic imports,
// so they should ideally be used to only import exactly what you need.
// So when adding them, you kinda have to analyze exactly everything you're pulling in.
// References: https://github.com/webpack/webpack.js.org/issues/2684
// Also, foxit-pdf-sdk-for-web isn't even tree-shakeable at all in its current state,
// so we could re-evaluate this code if that actually changes at some point.

import {InjectionToken} from '@angular/core';
import type {Annot, Markup} from '@wrappers/foxit-pdf-sdk-for-web/UIExtension.full';

export type FoxitModule = typeof import('@wrappers/foxit-pdf-sdk-for-web/UIExtension.full');

// this is necessary, to just allow FoxitModule as a constructor parameter
export const FoxitModuleToken = new InjectionToken<FoxitModule>('FoxitModule');

export type {
  Annot,
  AnnotationIcon,
  AnnotJson,
  DeviceRect,
  Markup,
  PDFDoc,
  PDFPage,
  PDFPageRender,
  PDFRect,
  PDFUI,
  PDFViewCtrl,
  STATE_HANDLER_NAMES,
  ViewModes,
} from '@wrappers/foxit-pdf-sdk-for-web/UIExtension.full';

// this is pointing to where the lib folder for foxit will be available at runtime
// (where it's copied to)
export const foxitLibPath = './node_modules_assets/foxit-pdf-sdk-for-web/lib';

// todo: could use /* webpackPrefetch: true */ if necessary
// todo: use webpackExports to only include part of dynamic import
// when we are on webpack 5.x
// https://webpack.js.org/api/module-methods/#magic-comments
export function importFoxit(): Promise<FoxitModule> {
  return import(
    /* webpackChunkName: "foxit-UIExtension-full" */
    '@wrappers/foxit-pdf-sdk-for-web/UIExtension.full'
  );
}

export function isMarkup(value: Annot): value is Markup {
  return value && 'getIntent' in value;
}

// This has to be the name of the chunk that has this css file
// It will be bundleName in the "styles" attribute in angular.json
export const foxitCssChunkName = 'foxit-UIExtension';



//
// Previously, this was doing a dynamic import of a css file,
// but since that is webpack specific behavior that was never fully supported,
// it became a build error in ng 14 to do this.
// for reference:
// https://github.com/angular/angular-cli/issues/22358
// https://github.com/angular/angular-cli/issues/23273
// https://github.com/angular/angular-cli/issues/12552
// https://github.com/ag-grid/ag-grid/issues/5381

// Leaving this here for documentation purposes:
// export const foxitCssUniqueId = '@wrappers/foxit-pdf-sdk-for-web/UIExtension.css';
// // todo: could use /* webpackPrefetch: true */ if necessary
// export function importFoxitCss(): Promise<string> {
//   return import(
//     /* webpackChunkName: "foxit-UIExtension-css" */
//     '@wrappers/foxit-pdf-sdk-for-web/UIExtension.css'
//   ).then((x) => x.default);
// }
