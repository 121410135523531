import {
  Injectable,
  NgZone,
} from '@angular/core';
import {
  fromEventPattern,
  Observable,
} from 'rxjs';
import {take} from 'rxjs/operators';

import { BrowserService } from 'webcommon/legacy-common';
import { Bridge } from 'webcommon/shared';

@Injectable({
  providedIn: 'root',
})
export class HostedBridgeService implements Bridge {
  static readonly ajsFactoryName = 'HostedBridgeService';

  constructor(
    browserService: BrowserService,
    private ngZone: NgZone,
  ) {
    this.windowObj = browserService.getWindow();
  }

  private bridge: Bridge | null = null;
  private readonly windowObj: any;

  private getBridge(): Bridge {
    if (this.bridge !== null) {
      return this.bridge;
    }

    this.bridge = this.windowObj.bridge && this.windowObj.bridge.app ? this.windowObj.bridge.app : null;
    if (this.bridge === null) {
      throw new Error('bridge has not been set');
    }
    return this.bridge;
  }

  fireEvent(eventName: string, data: any = null): void {
    const bridge = this.getBridge();
    bridge.fireEvent(eventName, data === null ? {} : data);
  }

  addEventListener(eventName: string, callbackFn: (data: any) => void): void {
    const bridge = this.getBridge();
    bridge.addEventListener(eventName, callbackFn);
  }

  removeEventListener(eventName: string, callbackFn: (data: any) => void): void {
    const bridge = this.getBridge();
    bridge.removeEventListener(eventName, callbackFn);
  }

  // this not multicast, so it would need to be piped to share() or something similar to achieve that
  on<T>(eventName: string): Observable<T> {
    let ngZoneHandler: (data: any) => void;
    const observable = fromEventPattern<T>(
      (handler) => {
        ngZoneHandler = (data: any) => {
          this.ngZone.run(() => {
            handler(data);
          });
        };
        this.addEventListener(eventName, ngZoneHandler);
      },
      () => {
        if (!ngZoneHandler) return;
        this.removeEventListener(eventName, ngZoneHandler);
      },
    );
    return observable;
  }

  // this not multicast, so it would need to be piped to share() or something similar to achieve that
  once<T>(eventName: string): Observable<T> {
    return this.on<T>(eventName).pipe(
      take(1),
    );
  }
}
