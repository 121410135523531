import {
  Injectable,
  NgZone,
  OnDestroy,
} from '@angular/core';
import {Subject} from 'rxjs';
import {StyleService} from '../../services/index';
import {FoxitPdfRenderer} from './FoxitPdfRenderer';
import {
  foxitCssChunkName,
  importFoxit,
} from 'webcommon/foxit';
import { BrowserService } from 'webcommon/legacy-common';

import {
  ApBaseClass,
  FoxitLicenseDto,
} from 'webcommon/shared';

import {
  AttachmentRepository, ProviderRepository,
} from 'webcommon/webapi';

import {IPdfRenderer} from './IPdfRenderer';
import {RuntimeUtils} from 'webcommon/runtime-info';

export interface DeferredFoxitPdfRendererBase {
  // use result when you want to take an action on the renderer if it's initialized,
  // but if it's not, then you're fine with skipping the action
  result?: FoxitPdfRenderer;

  // use resultPromise, if you want to wait until the renderer is initialized (it could already be initialized),
  // then take some action on it,
  // This promise resolves to the same instance as what is returned from init().
  resultPromise: Promise<FoxitPdfRenderer>;

  // initialize the renderer, returns a promise that resolves when it's done
  // element: HTMLElement to mount the pdf viewer on
  // The returned promise resolves to the same instance as resultPromise.
  init: (
    foxitSetup: FoxitLicenseDto,
    element: HTMLElement,
  ) => Promise<FoxitPdfRenderer>;
}

export type DeferredFoxitPdfRenderer = Readonly<DeferredFoxitPdfRendererBase>;

@Injectable({
  providedIn: 'root',
})
export class PdfRendererFactory extends ApBaseClass implements OnDestroy {
  private pdfRenderers = new Set<IPdfRenderer>();

  constructor(
    private readonly zone: NgZone,
    private readonly browserService: BrowserService,
    private readonly attachmentRepository: AttachmentRepository,
    private readonly providerRepository: ProviderRepository,
    private readonly styleService: StyleService,
    private readonly runtimeUtils: RuntimeUtils
  ) {
    super();
  }

  // This method should be preferred over createOnElement() because the result is easier to work with.
  // return initialization function to be called in ngOnInit, when HtmlElement is available
  // This allows this function to be called in the constructor, and immediately provide a promise
  // that can be referenced elsewhere in the code
  createDeferred(): DeferredFoxitPdfRenderer {
    const subject = new Subject<FoxitPdfRenderer>();
    const pdfRendererPromise = subject.toPromise();
    const deferred: DeferredFoxitPdfRendererBase = {
      resultPromise: pdfRendererPromise,
      init: async (
        foxitSetup: FoxitLicenseDto,
        element: HTMLElement,
      ) => {
        if (deferred.result) {
          return deferred.result;
        }

        const pdfRenderer = await this.createOnElement(
          foxitSetup,
          element,
        ).catch((e) => {
          subject.error(e);
          return Promise.reject(e);
        });

        subject.next(pdfRenderer);
        subject.complete();
        deferred.result = pdfRenderer;
        return pdfRenderer;
      },
    };
    return deferred;
  }

  // element: HTMLElement to mount the pdf viewer on
  // IMPORTANT: when taking actions based on data coming from this viewer, it will not be in ng zone.
  // It's allowing the import() of the library, and the creation of the PDFUI class to occur outside the ng zone,
  // because it's not related to angular at all, and could have performance issues if run in the ng zone.
  // In classes using this viewer where you're affecting bindings or other Angular stuff,
  // you need to call NgZone.run().
  async createOnElement(
    foxitSetup: FoxitLicenseDto,
    element: HTMLElement,
  ): Promise<FoxitPdfRenderer> {
    const pdfRenderer = await this.zone.runOutsideAngular(async () => {
      // await dynamic import resolution in parallel
      const [foxitModule] = await Promise.all([
        importFoxit(),
        this.styleService.addStyleUrlToDom(foxitCssChunkName, this.runtimeUtils.getFileUrlByChunk(foxitCssChunkName)),
      ]);

      return new FoxitPdfRenderer(
        this.zone,
        this.browserService,
        this.attachmentRepository,
        this.providerRepository,
        foxitModule,
        foxitSetup,
        element,
      );
    });

    this.pdfRenderers.add(pdfRenderer);
    pdfRenderer.onDestroy$.subscribe(() => {
      this.pdfRenderers.delete(pdfRenderer);
    });
    return pdfRenderer;
  }

  override ngOnDestroy(): void {
    this.pdfRenderers.forEach((pdfRenderer, _key, set) => {
      pdfRenderer.ngOnDestroy();
      set.delete(pdfRenderer);
    });
    super.ngOnDestroy();
  }
}
