import { Component, Injector, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { PatientPortalAttachmentRepository } from 'webcommon/webapi';
import { AttachmentDto, AttachmentRequestConfig, DownloadableDocument, Document } from 'webcommon/shared';
import { ApBaseModalComponent } from 'webcommon/core-ui';

@Component({
  styleUrls: [
    'patient-document-viewer-modal.component.less',
  ],
  templateUrl: './patient-document-viewer-modal.component.html',
})
export class PatientDocumentViewerModalComponent extends ApBaseModalComponent<string> implements OnInit {
  @Input() patientId: string;
  @Input() document: Document;
  @Input() requestConfig: AttachmentRequestConfig;

  constructor(
    elementInjector: Injector,
    private patientPortalAttachmentRepository: PatientPortalAttachmentRepository,
  ) {
    super(elementInjector);
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  loadAttachmentRef = (documentId: string, requestConfig: AttachmentRequestConfig) => {
    return this.loadAttachment(documentId, requestConfig);
  }

  private loadAttachment(documentId: string, requestConfig: AttachmentRequestConfig): Observable<AttachmentDto> {
    const { height, width, page } = requestConfig;
    const loadAttachment$ = this.patientPortalAttachmentRepository
    .getAttachmentForViewing(this.patientId, documentId, height, width, page);
    return loadAttachment$;
  }

  downloadAttachmentRef = () => {
    return this.downloadAttachment();
  }

  private downloadAttachment(): Observable<DownloadableDocument> {
    return this.patientPortalAttachmentRepository.getAttachmentForDownload(this.patientId, this.document.AttachmentUid);
  }

  close(): void {
    super.closeModal('');
  }

  cancel(): void {
    super.dismissModal();
  }
}
