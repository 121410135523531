import { Injectable } from '@angular/core';

import {
  AprimaTransitionRequest,
  TransitionManager,
} from 'webcommon/shared';

@Injectable({
  providedIn: 'root',
})
export class TransitionManagerService implements TransitionManager {
  static readonly ajsFactoryName = 'TransitionManager';

  private transitionManager: TransitionManager | null = null;

  public loadConfiguration(configuration: TransitionManager): void {
    if (this.transitionManager !== null) {
      throw new Error('transitionManager has already been set');
    }
    this.transitionManager = configuration;
  }

  private validateConfiguration(): TransitionManager {
    if (this.transitionManager === null) {
      throw new Error('transitionManager has not been set');
    }
    return this.transitionManager;
  }

  public transition(transition: AprimaTransitionRequest, replaceState?: boolean): boolean {
    const transitionManager = this.validateConfiguration();
    return transitionManager.transition(transition, replaceState);
  }

  replaceCurrentState(transition: AprimaTransitionRequest): void {
    const transitionManager = this.validateConfiguration();
    transitionManager.replaceCurrentState(transition);
  }

  goBack(): void {
    const transitionManager = this.validateConfiguration();
    transitionManager.goBack();
  }

  getPreviousTransition(): AprimaTransitionRequest {
    const transitionManager = this.validateConfiguration();
    return transitionManager.getPreviousTransition();
  }
}
