import { NgModule } from '@angular/core';
import { ApPaymentFrameComponent } from './components/index';

const ajsComponents = [
  ApPaymentFrameComponent,
];

const exportComponents = [
  ...ajsComponents,
];

const declareComponents = [
  ...exportComponents,
];

@NgModule({
  declarations: declareComponents,
  exports: [
    exportComponents,
  ],
  providers: [
  ],
})
export class CoreModule {
}
