import { Injectable } from '@angular/core';
import { isObject, mapValues } from 'lodash-es';

// TODO: research how to avoid using the any type here
@Injectable({
  providedIn: 'root',
})
export class ObjUtilService {
  static readonly ajsFactoryName = 'ObjUtil';

  defineReadonlyProps(obj: any, propertiesObj: any): any {
    if (!isObject(propertiesObj)) {
      throw new Error('Expected defineReadonlyProps to be called with object instead of ' + propertiesObj);
    }

    const properties = mapValues(propertiesObj, (val) => {
      return {
        value: val,
        writable: false,
        enumerable: true,
        configurable: false,
      };
    }) as any;
    return Object.defineProperties(obj, properties);
  }

  defineStaticProps(obj: any, propertiesObj: any): any {
    if (!isObject(propertiesObj)) {
      throw new Error('Expected defineStaticProps to be called with object instead of ' + propertiesObj);
    }

    const properties = mapValues(propertiesObj, (val) => {
      return {
        value: val,
        writable: false,
        enumerable: false,
        configurable: false,
      };
    }) as any;
    return Object.defineProperties(obj, properties);
  }
}
