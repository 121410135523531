import {Injectable, NgZone} from '@angular/core';
import {ApZoneAwareObject} from './ApZoneAwareObject';

// This is a layer of abstraction on top of NgZone, that can provide more specific functionality for interacting with it
@Injectable({
  providedIn: 'root',
})
export class NgZoneService {
  static readonly ajsFactoryName = 'NgZoneService';

  constructor(
    private readonly zone: NgZone,
  ) {
  }

  wrapToRun<T>(fn: (...args: any[]) => T): (...args: any[]) => T {
    return (...innerArgs: any[]) => {
      return this.zone.run(() => {
        return fn(...innerArgs);
      });
    };
  }

  wrapToRunOutsideNg<T>(fn: (...args: any[]) => T): (...args: any[]) => T {
    return (...innerArgs: any[]) => {
      return this.zone.runOutsideAngular(() => {
        return fn(...innerArgs);
      });
    };
  }

  // This function does contextual zone invoking, to allow 'fn' to be invoked outside of the Ng Zone (if necessary),
  // while callbacks will be able to re-enter the zone properly (if necessary).
  //
  // There are 2 cases to handle:
  // 1. If we are currently in the Ng Zone, then 'fn' IS invoked in runOutsideAngular(),
  // and it will provide functions to 'fn' to be able to enter back into the Ng Zone for a callback
  // that happens inside of 'fn' (like a click handler).
  //
  // 2. If we are not currently in the Ng Zone, then 'fn' IS NOT invoked in runOutsideAngular(), because we are already outside.
  // Consequently, the functions that are then provided to 'fn'
  // don't try to re-enter the Ng Zone for a callback, instead they just invoke the callback normally.
  // This is mainly because in this case, we were not in Ng Zone to start with, so it doesn't really make sense
  // to enter it for a callback.
  contextRun<T>(fn: (zoneAwareObject: ApZoneAwareObject) => T): T {
    if (!NgZone.isInAngularZone()) {
      const zoneObj: ApZoneAwareObject = {
        runInZoneIfNecessary: <U>(callback: () => U) => callback(),
        wrapToRunInZoneIfNecessary: <U>(callback: (...args: any[]) => U) => callback,
      };
      return fn(zoneObj);
    }

    const enterZoneObj: ApZoneAwareObject = {
      runInZoneIfNecessary: <U>(callback: () => U) => this.run(callback),
      wrapToRunInZoneIfNecessary: <U>(callback: (...args: any[]) => U) => this.wrapToRun(callback),
    };
    return this.zone.runOutsideAngular(() => {
      return fn(enterZoneObj);
    });
  }

  run<T>(fn: (...args: any[]) => T): T {
    return this.zone.run(fn);
  }

}
