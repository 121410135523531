export abstract class UnitConversionServiceUpgrade {
  abstract usToKg(lbs: any, oz: any): any;
  abstract usToKgRounded(lbs: any, oz: any): any;
  abstract roundKg(kg: any): any;
  abstract usToCm(ft: any, inches: any): any;
  abstract usToCmRounded(ft: any, inches: any): any;
  abstract roundCm(cm: any): any;
  abstract kgToUs(kg: any): {pounds: number, ounces: any};
  abstract kgToUsRounded(kg: any): {ounces: number, pounds: any};
  abstract cmToUsRounded(cm: any): {feet: number, inches: any};
  abstract cmToUs(cm: any): {feet: number, inches: any};
  abstract getBodySurfaceArea(heightCm: any, weightKg: any): any;
}

export function UnitConversionServiceFactory(i: angular.auto.IInjectorService) {
  return i.get<UnitConversionServiceUpgrade>('UnitConvert');
}

export const UnitConversionServiceAjsProvider = {
  deps: ['$injector'],
  provide: UnitConversionServiceUpgrade,
  useFactory: UnitConversionServiceFactory,
};
