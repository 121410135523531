import {NgModule, Optional, SkipSelf} from '@angular/core';
import type {
  IModule,
} from 'angular';
import {
  downgradeComponent,
  downgradeInjectable,
} from '@angular/upgrade/static';
import { TranslateService } from '@ngx-translate/core';
import {
  AppRoutesAjsProvider,
  AprimaAnalyticsService,
  AprimaCacheService,
  ConfigurationRepository,
  CurrentUserService,
  DeviceSettingsRepository,
  ErrorServiceAjsProvider,
  LogInService,
  LogOffService,
  ObjUtilService,
  ServerInfoRepository,
  TransitionManagerService,
  UnitConversionServiceAjsProvider,
  WebCommonStorageRepository,
  WebViewManagerService,
} from 'webcommon/legacy-common';
import {
  NgZoneService,
  SystemGuid,
} from 'webcommon/shared';
import {
  SpinnerService,
} from 'webcommon/core-ui';
import {
  HostedBridgeService,
} from 'webcommon/common-api';
import {
  ListRepository,
} from 'webcommon/webapi';

import { ForceLoadAngularComponent } from './force-load-angular.component';
import {
  BrowserWebViewManagerJsFactoryAjsProvider,
  TransitionManagerJsFactoryAjsProvider,
  XamarinWebViewManagerJsFactoryAjsProvider,
} from './services/index';

@NgModule({
  declarations: [
    ForceLoadAngularComponent,
  ],
  providers: [
    // AjsProviders are meant to be singletons, so they should be added here for now, instead of spread across other modules.
    // This will allow these to even be singletons across lazy-loaded modules.
    // This is so that they are NOT instantiated via the useFactory multiple times,
    // which can happen if the module they are provided in is imported in multiple feature modules.
    // (Although, this only appears to cause an issue with lazy-loaded modules, not eager modules)
    // Currently, this module is not imported multiple times, and the constructor below is used to enforce that.
    // See https://angular.io/guide/singleton-services
    // This type of behavior is still possible to achieve if the module is imported multiple times, but then you
    // have to use the forRoot() pattern (https://angular.io/guide/singleton-services#the-forroot-pattern).
    // I guess if for some reason, we don't want an upgraded ajs service to have this behavior,
    // then it could be provided elsewhere, but there aren't any of those currently.
    AppRoutesAjsProvider,
    BrowserWebViewManagerJsFactoryAjsProvider,
    ErrorServiceAjsProvider,
    TransitionManagerJsFactoryAjsProvider,
    UnitConversionServiceAjsProvider,
    XamarinWebViewManagerJsFactoryAjsProvider,
  ],
})
export class WebCommonAngularJSModule {
  // This is using the same constructor pattern as BrowserModule
  constructor(
    @Optional() @SkipSelf() parentModule: WebCommonAngularJSModule | null,
  ) {
    if (parentModule) {
      throw new Error(
        `WebCommonAngularJSModule is already loaded. Import it only once, usually in the AppModule only`);
    }
  }

  // tslint:disable:max-line-length
  static registerAngularJS(module: IModule): void {
    module
      .factory(AprimaAnalyticsService.ajsFactoryName, downgradeInjectable(AprimaAnalyticsService))
      .factory(AprimaCacheService.ajsFactoryName, downgradeInjectable(AprimaCacheService))
      .factory(ConfigurationRepository.ajsFactoryName, downgradeInjectable(ConfigurationRepository))
      .factory(CurrentUserService.ajsFactoryName, downgradeInjectable(CurrentUserService))
      .factory(DeviceSettingsRepository.ajsFactoryName, downgradeInjectable(DeviceSettingsRepository))
      .factory(HostedBridgeService.ajsFactoryName, downgradeInjectable(HostedBridgeService))
      .factory(ListRepository.ajsFactoryName, downgradeInjectable(ListRepository))
      .factory(LogInService.ajsFactoryName, downgradeInjectable(LogInService))
      .factory(LogOffService.ajsFactoryName, downgradeInjectable(LogOffService))
      .factory(ObjUtilService.ajsFactoryName, downgradeInjectable(ObjUtilService))
      .factory(ServerInfoRepository.ajsFactoryName, downgradeInjectable(ServerInfoRepository))
      .factory(SpinnerService.ajsFactoryName, downgradeInjectable(SpinnerService))
      .factory(SystemGuid.ajsFactoryName, downgradeInjectable(SystemGuid))
      .factory('TranslateService', downgradeInjectable(TranslateService))
      .factory(WebCommonStorageRepository.ajsFactoryName, downgradeInjectable(WebCommonStorageRepository))
      .factory(TransitionManagerService.ajsFactoryName, downgradeInjectable(TransitionManagerService))
      .factory(WebViewManagerService.ajsFactoryName, downgradeInjectable(WebViewManagerService));

    // NgZone is downgraded here, so it can be injected in AngularJS to keep certain parts
    // of the application from running in the Angular zone, mainly for performance reasons.
    // The only use case for it right now is so that the Hammer stuff (and a few other event listeners) will
    // not be impacted by the Angular change detection.
    // If at some point the files using NgZoneService in ajs were ported to Angular or something,
    // then this obviously would not need to be downgraded anymore.
    // But also, when porting those files over, they wouldn't need to retain a reference to this service most likely.
    // The main reason is because those files using it in ajs need it because
    // of the fact that they are in a hybrid app that uses UpgradeComponents and stuff,
    // but if they exist in Angular instead of ajs, they shouldn't need these zone utilities to work.
    module.factory(NgZoneService.ajsFactoryName, downgradeInjectable(NgZoneService));

    // Currently, ForceLoadAngularComponent is downgraded with propagateDigest = true,
    // which causes the Angular change detection to run on every $digest.
    // If the downgradeComponent logic changes below to be propagateDigest: false, then there are some places
    // in AngularJS that need to be updated to be wrapped in NgZoneService.run(),
    // so that they work correctly with the Angular change detection.
    // This includes places where we are injecting NgZoneService,
    // and specifically running certain code outside of the Angular Zone,
    // and then calling scope.$apply, or maybe even $eval, etc to initiate the AngularJS change detection.
    // For reference: https://angular.io/api/upgrade/static/downgradeComponent#downgradecomponent
    module.directive(ForceLoadAngularComponent.ajsComponentName, downgradeComponent({ component: ForceLoadAngularComponent }));
  }
  // tslint:enable:max-line-length
}
