import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

@Injectable({
  providedIn: 'root',
})
export class AprimaTranslateLoader extends TranslateHttpLoader {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }
}
