import { Component, OnInit } from '@angular/core';
import { PatientPortalAttachmentRepository } from 'webcommon/webapi';
import { WebCommonStorageRepository } from 'webcommon/legacy-common';
import { PatientDocumentsViewerModalService } from './patient-document-viewer-modal/index';
import { AttachmentType, Filter, Guid, PatientDocumentSet, Document, DownloadableDocument } from 'webcommon/shared';
import { ApBaseComponent } from 'webcommon/core-ui';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';

const ALL_FILTER: Filter = { name: 'All', id: Guid.empty() };

class DocumentWithDownload implements Document {
  Downloading = false;
  AttachmentTypeName: string;
  AttachmentTypeUid: string;
  PatientUid: string;
  AttachmentUid: string;
  FileName: string;
  FileExtension: string;
  FileDate: Date;
  LastModifiedDate: Date;

  constructor(doc: Document) {
    this.AttachmentTypeName = doc.AttachmentTypeName;
    this.AttachmentTypeUid = doc.AttachmentTypeUid;
    this.AttachmentUid = doc.AttachmentUid;
    this.PatientUid = doc.PatientUid;
    this.FileName = doc.FileName;
    this.FileExtension = doc.FileExtension;
    this.FileDate = doc.FileDate;
    this.LastModifiedDate = doc.LastModifiedDate;
  }
}

// tslint:disable-next-line: max-classes-per-file
@Component({
  selector: 'patient-documents',
  templateUrl: './patient-documents.component.html',
  styleUrls: ['./patient-documents.component.less']
})
export class PatientDocumentsComponent extends ApBaseComponent implements OnInit {
  static ajsComponentName = 'apPatientDocuments';

  activePatientId: string;
  title = 'Patient Documents';
  activeFilter = ALL_FILTER;
  documentClone: DocumentWithDownload[] = [];

  // DO NOT MODIFY documents
  readonly documents: Document[] = [];
  readonly filters: Filter[] = [];

  constructor(
    private patientDocumentViewerModalService: PatientDocumentsViewerModalService,
    private webCommonStorageRepository: WebCommonStorageRepository,
    private patientPortalAttachmentRepository: PatientPortalAttachmentRepository,
  ) {
    super();
    this.activePatientId = this.webCommonStorageRepository.getPatientPortalActivePatient()?.id ?? '';
  }

  private restoreDocuments(): void {
    Object.assign(this.documentClone, this.documents.map((doc) => new DocumentWithDownload(doc)));
  }

  private populateFilters(attachmentTypes: AttachmentType[]): void {
    this.filters.push(ALL_FILTER);
    this.filters.push(...attachmentTypes.map((x) => {
      return {
        name: x.AttachmentTypeName,
        id: x.AttachmentTypeUid,
      };
    }));
  }

  ngOnInit() {
    this.patientPortalAttachmentRepository.getDocumentList(this.activePatientId)
    .pipe(
      this.takeUntilDestroy(),
    ).subscribe(
      (response: PatientDocumentSet) => {
        this.documents.push(...response.Documents);
        this.restoreDocuments();
        this.populateFilters(response.AttachmentTypes);
      },
      () => { /* noop */ },
    );
  }

  updateFilter(filter: Filter): void {
    this.activeFilter = filter;

    if (filter.id === ALL_FILTER.id) {
      this.restoreDocuments();
      return;
    }

    this.documentClone = this.documents.filter(
      (x) => filter.id === x.AttachmentTypeUid,
    ).map(
      (doc) => new DocumentWithDownload(doc),
    );
  }

  viewDocument(document: Document): void {
    this.patientDocumentViewerModalService.openModal(this.activePatientId, document, {
      page: 1,
      height: 800,
      width: 800,
    }).then(
      () => { /* noop */ },
      () => { /* noop */ },
    );
  }

  onDocumentDownload(attachmentId: string): Observable<DownloadableDocument> {
    return this.patientPortalAttachmentRepository.getAttachmentForDownload(this.activePatientId, attachmentId)
    .pipe(finalize(() => {
      const document = this.documentClone.find((doc) => doc.AttachmentUid === attachmentId);
      if (!document) return;
      document.Downloading = false;
    }));
  }

  onDocumentDownloadRef = (attachmentId: string) => {
    return this.onDocumentDownload(attachmentId);
  }

  setDocumentDownloading(document: DocumentWithDownload): void {
    document.Downloading = true;
  }

  hasDocuments(): boolean {
    return (this.documentClone && (this.documentClone.length > 0));
  }

}
