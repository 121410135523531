import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ViewportService } from '../services/index';

import {
  ApDocumentBase,
  AttachmentDto,
  AttachmentRequestConfig, SystemGuid,
} from 'webcommon/shared';

import { ApBaseComponent } from '../ap-base-component/index';

import {
  finalize,
  takeUntil,
} from 'rxjs/operators';

import {
  Observable,
  Subscription,
} from 'rxjs';

import { DeviceSettingsRepository } from 'webcommon/legacy-common';
import { DocumentService } from './DocumentService';
@Component({
  selector: 'ap-document-viewer',
  templateUrl: './ap-document-viewer.component.html',
})
export class ApDocumentViewerComponent extends ApBaseComponent implements OnChanges, OnDestroy, OnInit {
  @Input() disableFullscreen = false;
  @Input() disableInlinePdf?: boolean;
  @Input() documentId: string;
  @Input() loadDocument: (documentId: string, requestConfig: AttachmentRequestConfig) => Observable<AttachmentDto>;

  private documentValue: ApDocumentBase | null = null;
  @Output() currentDocumentChange = new EventEmitter<ApDocumentBase>();

  private readonly pageWidth: number;
  private readonly pageHeight: number;

  loadError: any = null;

  @Output() loadingDocumentChange = new EventEmitter<boolean>(true);
  private loadingDocumentValue = false;
  get loadingDocument(): boolean {
    return this.loadingDocumentValue;
  }
  set loadingDocument(val) {
    this.loadingDocumentValue = val;
    this.loadingDocumentChange.emit(val);
  }

  get document(): ApDocumentBase | null {
    return this.documentValue;
  }
  set document(val) {
    this.documentValue = val;
    if (val) {
      this.currentDocumentChange.emit(val);
    }
  }

  constructor(
    viewportService: ViewportService,
    private readonly documentService: DocumentService,
    private readonly deviceSettingsRepository: DeviceSettingsRepository,
    private readonly systemGuid: SystemGuid,
  ) {
    super();
    this.pageWidth = viewportService.getWidth();
    this.pageHeight = viewportService.getHeight();
  }

  ngOnChanges(changes: SimpleChanges) {
    const documentIdChanges = changes.documentId;
    if (documentIdChanges) {
      this.loadDocumentInternal(documentIdChanges.currentValue, documentIdChanges.previousValue, 1);
    }
  }

  ngOnInit() {
    // If there is no value specified for disableInlinePdf,
    // then use the default logic to determine the value.
    // This essentially means that if you want to use the default logic, then don't fill in this binding.
    if (this.disableInlinePdf === undefined) {
      this.disableInlinePdf = !this.deviceSettingsRepository.supportsInlinePdf();
    }
  }

  loadPage(page: number) {
    this.loadDocumentInternal(this.documentId, this.documentId, page);
  }

  override ngOnDestroy() {
    this.documentService.disposeDocument(this.document);
    super.ngOnDestroy();
  }

  loadDocumentInternal(documentId: string, previousDocumentId: string, page: number): Subscription {
    if (documentId !== previousDocumentId) {
      // dispose of previous document, since we are replacing it
      this.documentService.disposeDocument(this.document);

      this.loadError = null;
      this.document = null;
    }

    if (!documentId || documentId === this.systemGuid.GuidEmpty) {
      return Subscription.EMPTY;
    }

    this.loadingDocument = true;

    // Request double the device's resolution so we have a little give in the zooming department.
    // ^ this comment came from legacy ap-document-viewer in angularjs
    const request: AttachmentRequestConfig = {
      height: this.pageHeight * 2,
      page,
      width: this.pageWidth * 2,
    };

    const sub = this.loadDocument(documentId, request).pipe(
      finalize(() => this.documentLoadComplete()),
      takeUntil(this.onDestroy$),
    ).subscribe(
      (data) => this.documentLoadSuccess(data),
      (error) => this.documentLoadError(error),
    );

    return sub;
  }

  private documentLoadSuccess(attachment: AttachmentDto) {
    // dispose of previous document, since we are replacing it
    this.documentService.disposeDocument(this.document);

    this.loadError = null;
    this.document = this.documentService.mapFromAttachment(attachment);
  }

  private documentLoadComplete() {
    this.loadingDocument = false;
  }

  private documentLoadError(error: any) {
    this.loadError = error;
  }
}
