import { ModalOptions } from 'ngx-bootstrap/modal';

import {Injectable, Injector} from '@angular/core';

// This class adds typing around the initial state so that it will have to actually match the component
// you are using for the modal.
@Injectable()
export class ApModalComponentOptions<T> extends ModalOptions<T> {
  override initialState?: T;

  // injector that can provide $scope for UpgradeComponents
  elementInjector?: Injector;
}
