import {
  Injectable,
  Injector,
} from '@angular/core';
import {
  BsModalRef,
  BsModalService,
} from 'ngx-bootstrap/modal';
import { ApBsModalRef, lastDismissReasonT } from 'webcommon/shared';
import {ApActiveModal} from './ApActiveModal';

import {ApModalHelper} from './ApModalHelper';

@Injectable({
  providedIn: 'root',
})
export class ApModalHelperFactory {

  // This should be the injector from the component, not an Injector
  // injected directly into a service.
  create<T>(
    elementInjector: Injector,
    hideHandler: (reason: lastDismissReasonT) => void,
  ): ApModalHelper<T> {

    const bsModalService = elementInjector.get(BsModalService);
    const bsModalRef = elementInjector.get(BsModalRef) as ApBsModalRef;
    const activeModal = elementInjector.get<ApActiveModal<T>>(ApActiveModal);
    activeModal.bsModalRef = bsModalRef;
    const modalHelper = new ApModalHelper<T>(activeModal, bsModalService, hideHandler);
    return modalHelper;
  }
}
