<div class="modal-default">
  <div class="modal-header">
      <button id="{{Id}}-close-button" type="button" class="close" (click)="close()"><span>&times;</span></button>
      <span id="{{Id}}-header-text" class="modal-subtitle" translate>Link Personal Health Apps - FHIR Integration</span>
  </div>

  <div class="modal-body modal-max-height body-text">
    <div *ngIf="!loading && !loadError">
      <div id="{{Id}}-registration-text">
        <p translate>
          You can link your personal medical-records data to one or more health apps on your smartphone or other digital devices, through the FHIR protocol.
        </p>
      </div>

      <div *ngIf="!isPatientRegistered">
        <div id="{{Id}}-registration-directions-text">
          <p translate>
            In order to give health apps access to your medical records you must first create a FHIR account.
          </p>
        </div>

        <div *ngIf="!displayRegistrationResponse">
          <button id="{{Id}}-registration-button"
                  class="btn btn-primary"
                  (click)="register()"
                  [disabled]="registeringPatient"
                  translate>
            <span translate [hidden]="registeringPatient">Register</span>
            <ap-spinner *ngIf="registeringPatient" size="1"></ap-spinner>
          </button>
        </div>

        <div *ngIf="displayRegistrationResponse" id="{{Id}}-registration-response-text">
          <p class="registration-success" translate>
            Thank you!  You will receive a registration email shortly.
          </p>
        </div>

        <div *ngIf="registrationError" id="{{Id}}-registration-error-text">
          <p class="registration-failure" translate>
            {{ registrationErrorMessage }}
          </p>
        </div>
      </div>
    </div>

    <div *ngIf="loading">
      <div class="text-center">
        <ap-spinner size="3"></ap-spinner>
      </div>
    </div>

    <div *ngIf="!loading && loadError">
      <alert type="danger" translate>An error has occurred while checking FHIR registration.</alert>
    </div>
    <div *ngIf="isPatientRegistered" class="registeredMessage">
      <p>As a Registered FHIR account user, you may copy/paste this URL into your health app to grant it access to your medical records.</p>
      <div>
        <div class="copy-field">{{ fhirResourceUrl }}</div> 
        <button id="{{Id}}-copy-button"
              class="btn btn-primary"
              (click)="copyToClipboard()"
              translate title="COPY LINK">
          <span translate>COPY LINK</span>
        </button>
      </div>
      <div class="revoke-field"><a (click)="revoke()" title="View your linked apps - or - unlink apps">View your linked apps - or - unlink apps</a></div>
    </div>
  </div>

  <div class="modal-footer"></div>
</div>
