import { HammerGestureConfig } from '@angular/platform-browser';
import { Injectable } from '@angular/core';

@Injectable()
export class BaseHammerConfig extends HammerGestureConfig {
  // In this class, put overrides for events, or add other events that should be handled by Hammer,
  // or default options for Hammer.
  // Also this can be configured further by implementing buildHammer like this:
  // buildHammer(element: HTMLElement): HammerInstance {
  //   return super.buildHammer(element);
  // }

  // This adds the ability to listen for the 'doubletap' event.
  // Simply adding this event to the list of ones that this config supports works for the 'doubletap' event
  // because the current version of hammerjs already has a default recognizer setup for the 'doubletap' event.
  // This means it won't necessarily be this simple for other custom events we may want to handle in hammerjs.
  override events = ['doubletap'];

  // Currently we are not using the 'rotate' event anywhere, so this will disable it for now
  override overrides = {
    rotate: { enable: false },
  };
}
