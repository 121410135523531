import {
  Component,
  OnInit,
} from '@angular/core';
import { StateService, Transition } from '@uirouter/core';

import {
  ContentAttributeServiceUpgrade,
} from '@app/core';

import {
  finalize,
  takeUntil,
} from 'rxjs/operators';
import {
  ApBaseComponent,
} from 'webcommon/core-ui';
import {
  WebCommonStorageRepository,
} from 'webcommon/legacy-common';
import {
  BillingRepository,
} from 'webcommon/webapi';
import {
  ApplyPaymentToAppointmentRequestDto,
  LoginAssistanceResultDto,
  PaymentResponseDto,
  TelehealthBillingOptionsDto,
} from 'webcommon/shared';

@Component({
  selector: 'th-payment',
  templateUrl: './th-payment.component.html',
})
export class ThPaymentComponent extends ApBaseComponent implements OnInit {
  loadError = false;
  errorMessage = '';
  loginAssistanceInfo: LoginAssistanceResultDto;
  url = '';
  appointmentId: string;
  userId: string | null;
  billingCoPayAmount: number;
  billingBalance: number;
  billingBalanceString: string;
  hasPaymentError = false;
  isNegativeBalance = false;

  constructor(
    private webCommonStorageRepository: WebCommonStorageRepository,
    private billingRepository: BillingRepository,
    private transition: Transition,
    private stateService: StateService,
    private contentAttributeService: ContentAttributeServiceUpgrade,
  ) {
    super();
    this.loading = false;
  }

  ngOnInit() {
    this.appointmentId = this.transition.params().apptId;
    this.userId = this.webCommonStorageRepository.getUserId();
    this.load();
  }

  private load() {
    this.loading = true;
    this.loadBilling();
  }

  private loadBilling() {
    const redirectUrl = this.contentAttributeService.getPaymentRedirectUrl();
    this.billingRepository.getTelehealthBilling(this.appointmentId, redirectUrl).pipe(
      finalize(() => this.onLoadComplete()),
      takeUntil(this.onDestroy$),
    ).subscribe(
      (data: TelehealthBillingOptionsDto) => this.onLoadSuccess(data),
      () => this.onLoadError(),
    );
  }

  private onLoadComplete() {
    this.loading = false;
  }

  private onLoadError() {
    this.loadError = true;
  }

  private onLoadSuccess(result: TelehealthBillingOptionsDto) {
    this.loadError = false;
    this.url = result.PaymentUrl;
    this.billingCoPayAmount = result.BillingCoPayAmount;
    if (result.BillingBalance) {
      this.billingBalance = result.BillingBalance;
      this.billingBalanceString = this.billingBalance.toString();
    } else {
      this.billingBalance = 0;
    }

    if (this.billingBalance && this.billingBalanceString.startsWith('-')) {
      this.isNegativeBalance = true;
      this.billingBalanceString = this.billingBalanceString.substring(1);
    }
  }

  applyPayment(redirectUrlString: string) {

    const ApplicationSubmittingPayment = 4;

    const parameters: ApplyPaymentToAppointmentRequestDto = {
      ApplicationSubmittingPayment,
      AppointmentGuid: this.appointmentId,
      LoginUserPatientId: this.userId,
      PaymentResponseString: redirectUrlString,
      RemoteSessionId: this.userId + new Date().toLocaleDateString(),
    };
    const result = this.billingRepository.postApplyTelehealthPayment(parameters);
    result.pipe(
      finalize(() => { this.url = redirectUrlString; }),
      takeUntil(this.onDestroy$),
    ).subscribe(
      (data: PaymentResponseDto) => {
        if (data.IsCanceled) {
          window.close();
        } else {
          this.stateService.go('thPaymentSummary', {apptId: this.appointmentId, txId: data.TxId});
        }
      },
      () => {
        this.hasPaymentError = true;
      },
    );
  }
}
