import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Bridge } from 'webcommon/shared';

@Injectable({
  providedIn: 'root',
})
export class BridgeService implements Bridge {
  private bridge: Bridge | null = null;

  public loadConfiguration(configuration: Bridge): void {
    if (this.bridge !== null) {
      throw new Error('bridge has already been set');
    }
    this.bridge = configuration;
  }

  private validateConfiguration(): Bridge {
    if (this.bridge === null) {
      throw new Error('bridge has not been set');
    }
    return this.bridge;
  }

  public hasConfiguration(): boolean {
    return this.bridge !== null;
  }

  public fireEvent(eventName: string, data: any = null): void {
    const bridge = this.validateConfiguration();
    bridge.fireEvent(eventName, data === null ? {} : data);
  }

  addEventListener(eventName: string, callbackFn: (data: any) => void): void {
    const bridge = this.validateConfiguration();
    bridge.addEventListener(eventName, callbackFn);
  }

  removeEventListener(eventName: string, callbackFn: (data: any) => void): void {
    const bridge = this.validateConfiguration();
    bridge.removeEventListener(eventName, callbackFn);
  }

  on<T>(eventName: string): Observable<T> {
    const bridge = this.validateConfiguration();
    return bridge.on<T>(eventName);
  }

  once<T>(eventName: string): Observable<T> {
    const bridge = this.validateConfiguration();
    return bridge.once<T>(eventName);
  }
}
