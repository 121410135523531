import {
  AprimaTransitionRequest,
  TransitionManager,
} from 'webcommon/shared';

export abstract class TransitionManagerJs implements TransitionManager {
  abstract transition(transition: AprimaTransitionRequest, replaceState?: boolean): boolean;
  abstract replaceCurrentState(transition: AprimaTransitionRequest): void;
  abstract goBack(): void;
  abstract getPreviousTransition(): AprimaTransitionRequest;
}

export function TransitionManagerJsFactory(i: angular.auto.IInjectorService) {
  return i.get<TransitionManagerJs>('TransitionManagerJs');
}

export const TransitionManagerJsFactoryAjsProvider = {
  deps: ['$injector'],
  provide: TransitionManagerJs,
  useFactory: TransitionManagerJsFactory,
};
