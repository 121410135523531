import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

import { ApHammerInput } from 'webcommon/shared';

// The main logic for this class was ported from apImageViewer.js in angularjs
@Component({
  selector: 'ap-image-viewer',
  templateUrl: './ap-image-viewer.component.html',
})
export class ApImageViewerComponent implements OnChanges {
  @Input() imageData: string;
  @Input() pageCount?: number | null = 1;
  @Input() disableFullscreen = false;

  private currentPageValue = 1;

  // 2-way binding
  @Output() currentPageChange = new EventEmitter<number>();
  @Input()
  get currentPage() {
    return this.currentPageValue;
  }
  set currentPage(val) {
    if (val === this.currentPageValue) return;
    this.currentPageValue = val;
    this.loadingImage = true;
    this.currentPageChange.emit(this.currentPageValue);
  }

  isFullscreenMode = false;
  loadingImage = false;

  pageChanged() {
    // this.loadingImage = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.imageData && changes.imageData.currentValue) {
      this.loadingImage = false;

    }
  }

  // on swipe, change the page of the image to be current + 1, or current - 1
  onSwipeImg(ev: ApHammerInput) {
    const pageCount = this.pageCount;
    // if there is no pageCount, or it's <= 1, or the swipe was more of a vertical swipe,
    // then don't do anything
    if (!pageCount || pageCount <= 1 || Math.abs(ev.deltaX) <= Math.abs(ev.deltaY)) {
      return;
    }

    // swipe right => prev page, swipe left => next page
    // This is because when you're swiping to the right, you are pulling the image off the right side
    // of the screen, which means that you want to to go to the prev page.
    if (ev.deltaX > 0 && this.currentPage > 1) {
      this.currentPage--;
      this.pageChanged();
    } else if (ev.deltaX < 0 && this.currentPage < pageCount) {
      this.currentPage++;
      this.pageChanged();
    }
  }
}
