<div class="container-center">
  <div class="paper paper-th">
    <div class="paper-header hidden-print">
      <div class="paper-title" translate>
        Your payment has been submitted.
      </div>
    </div>
    <hr/>
    <div class="paper-body">
      <div *ngIf="!loading">
        <div *ngIf="!continueClicked" class="receipt-padding" [innerHTML]="receiptHtml"></div>
        <div *ngIf="continueClicked">
          Please close this tab and return to the Aprima Telehealth Visit tab of your browser to continue your telehealth call.
        </div>
        <div class="hidden-print">
          <button class="btn btn-primary pull-right" *ngIf="!continueClicked" (click)="done()" translate>
            Continue
          </button>
          <button class="btn" *ngIf="!continueClicked" (click)="print()" translate>
            Print
          </button>
        </div>
      </div>
      <div *ngIf="loading">
        <div class="text-center">
          <ap-spinner size="3"></ap-spinner>
        </div>
      </div>
      <div *ngIf="!loading && loadError">
        <alert type="danger" translate>An error has occurred and payment could not be loaded.</alert>
      </div>
    </div>
  </div>
</div>
