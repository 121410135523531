import {
  Injectable,
} from '@angular/core';

import {DocumentHelper} from 'webcommon/shared';

@Injectable({
  providedIn: 'root',
})
export class DefaultDocumentHelper implements DocumentHelper {
  openUrlInSeparateTab() {
    throw new Error('opening documents in a separate tab is not supported on this device');
  }

  supportsOpenInSeparateTab(): boolean {
    return false;
  }
}
