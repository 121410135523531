import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import {ApDocumentBase, AttachmentDto, AttachmentRequestConfig, SystemGuid} from 'webcommon/shared';
import { FoxitLicenseRepository } from 'webcommon/webapi';
import { ApBaseComponent } from '../../ap-base-component/index';
import { ViewportService } from '../../services/index';
import { DocumentService } from '../DocumentService';
import {DeferredFoxitPdfRenderer, PdfRendererFactory} from './PdfRendererFactory';

@Component({
  selector: 'ap-sign-pdf',
  templateUrl: './ap-sign-pdf.component.html',
})
export class ApSignPdfComponent extends ApBaseComponent implements OnInit, OnDestroy, OnChanges {
  static readonly ajsComponentName = 'apSignPdf';

  get document(): ApDocumentBase | null {
    return this.documentValue;
  }
  set document(val) {
    this.documentValue = val;
    if (val) {
      this.currentDocumentChange.emit(val);
    }
  }

  constructor(
    viewportService: ViewportService,
    private readonly pdfRendererFactory: PdfRendererFactory,
    private readonly documentService: DocumentService,
    private readonly foxitLicenseRepository: FoxitLicenseRepository,
    private readonly systemGuid: SystemGuid,
  ) {
    super();
    this.pageWidth = viewportService.getWidth();
    this.pageHeight = viewportService.getHeight();
    this.deferredPdfRenderer = this.pdfRendererFactory.createDeferred();
  }

  private readonly pageWidth: number;
  private readonly pageHeight: number;
  @Input() disableFullscreen = false;
  @Input() disableInlinePdf?: boolean;
  @Input() documentId: string;
  @Input() loadDocument: (documentId: string, requestConfig: AttachmentRequestConfig) => Observable<AttachmentDto>;
  @Input() documentGetStaticUrl?: (documentId: string) => Observable<string>;
  @Input() documentLoad: (documentId: string, requestConfig: AttachmentRequestConfig) => Observable<AttachmentDto>;

  downloadingDocument = false;

  // ap-document-viewer
  private documentValue: ApDocumentBase | null = null;
  @Output() currentDocumentChange = new EventEmitter<ApDocumentBase>();

  loadError: any = null;
  loadingDocument = false;
  initialized = false;

  readonly deferredPdfRenderer: DeferredFoxitPdfRenderer;
  private currentAttachment: AttachmentDto;

  startEvent?: MouseEvent;

  @ViewChild('foxitPdfViewer', { static: true }) pdfViewerRef: ElementRef<HTMLElement>;

  // IMPORTANT: this returned promise from ngOnInit is not waited on
  async ngOnInit(): Promise<void> {
    const foxitLicense = await this.foxitLicenseRepository.getFoxitLicense(
      this.systemGuid.ProductParameterFoxitLicenseSN,
      this.systemGuid.ProductParameterFoxitLicenseKey,
      this.systemGuid.ProductParameterFoxitDefaultTool,
      this.systemGuid.ProductParameterFoxitDefaultViewMode,
    ).toPromise();

    this.deferredPdfRenderer.init(
      foxitLicense,
      this.pdfViewerRef.nativeElement,
    );

    console.log('Document ID: ' + this.documentId);
    this.initialized = true;
    this.loadDocumentInternal(this.documentId, '', 1);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.initialized) {
      return;
    }
    const documentIdChanges = changes.documentId;
    if (documentIdChanges) {
      this.loadDocumentInternal(documentIdChanges.currentValue, documentIdChanges.previousValue, 1);
    }
  }

  onMouseDown($event: MouseEvent) {
    // console.log('Mouse Down at:' + this.startEvent.clientX, this.startEvent.clientY);
    this.startEvent = $event;
  }

  onMouseUp($event: MouseEvent) {
    // console.log('Mouse Up at: ' + this.endEvent.clientX, this.endEvent.clientY);
    if (this.startEvent) {
      this.calculateRectangle(this.startEvent, $event);
    }
  }

  override ngOnDestroy(): void {
    this.deferredPdfRenderer.result?.ngOnDestroy();
    super.ngOnDestroy();
  }

  loadDocumentInternal(documentId: string, previousDocumentId: string, page: number): Subscription {
    if (documentId !== previousDocumentId) {
      // dispose of previous document, since we are replacing it
      this.documentService.disposeDocument(this.document);
      this.loadError = null;
      this.document = null;
    }

    if (!documentId) {
      return Subscription.EMPTY;
    }

    this.loadingDocument = true;

    // Request double the device's resolution so we have a little give in the zooming department.
    // ^ this comment came from legacy ap-document-viewer in angularjs
    const request: AttachmentRequestConfig = {
      height: this.pageHeight * 2,
      page,
      width: this.pageWidth * 2,
    };

    const sub = this.loadDocument(documentId, request).pipe(
      finalize(() => this.documentLoadComplete()),
      takeUntil(this.onDestroy$),
    ).subscribe(
      (data) => this.documentLoadSuccess(data),
      (error) => this.documentLoadError(error),
    );
    return sub;
  }

  getAttachment() {
    return this.currentAttachment;
  }

  onDocumentLoadRef = (documentId: string, requestConfig: AttachmentRequestConfig) => {
    return this.onDocumentLoad(documentId, requestConfig);
  }

  private onDocumentLoad(documentId: string, requestConfig: AttachmentRequestConfig): Observable<AttachmentDto> {
    this.loadingDocument = true;
    const load$ = this.documentLoad(documentId, requestConfig).pipe(
      finalize(() => this.documentLoadComplete()),
    );
    return load$;
  }

  private documentLoadComplete() {
    this.loadingDocument = false;
  }

  private async documentLoadSuccess(attachment: AttachmentDto): Promise<void> {
    // dispose of previous document, since we are replacing it
    this.currentAttachment = attachment;
    this.documentService.disposeDocument(this.document);
    this.loadError = null;
    this.document = this.documentService.mapFromAttachment(attachment);

    const pdfRenderer = await this.deferredPdfRenderer.resultPromise;
    if (pdfRenderer.attachmentsToSave.has(this.documentId)) {
      const existingData = pdfRenderer.attachmentsToSave.get(this.documentId);
      if (existingData) {
        pdfRenderer.openPDFData(existingData).then(() => {
          pdfRenderer.setAttachment(this.documentId);
        });
      }
    } else {
      pdfRenderer.openPDFData(attachment.Data).then(() => {
        pdfRenderer.setAttachment(this.documentId);
      });
    }
  }

  blobToBase64(data: Blob) {
    const reader = new FileReader();
    let base64data: string;
    reader.readAsDataURL(data);
    reader.onloadend = () => {
      if (reader.result) {
        if (typeof reader.result === 'string') {
          base64data = reader.result;
        } else {
          base64data = reader.result.toString();
        }
      } else {
        console.log('Reader result is empty');
      }
      return base64data;
    };
  }

  private documentLoadError(error: any) {
    this.loadError = error;
  }

  calculateRectangle(startEvent: MouseEvent, endEvent: MouseEvent) {
    this.deferredPdfRenderer.result?.setSelection(startEvent, endEvent);
  }
}
