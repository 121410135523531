import {
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import {
  Provider,
} from '@angular/core';

import {
  CacheInterceptor,
} from './index';

export const webcommonHttpInterceptorProviders: Provider[] = [
  { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
];
