<div class="attachment-viewer">
  <div *ngIf="document?.isImage"
       name="attachment-viewer-image">
    <ap-image-viewer
        (currentPageChange)="loadPage($event)"
        [disableFullscreen]="disableFullscreen"
        [imageData]="document?.data"
        [pageCount]="document?.pageCount"
    ></ap-image-viewer>
  </div>

  <div *ngIf="document?.isText"
       name="attachment-viewer-text">
    {{document?.data}}
  </div>

  <div *ngIf="document?.isHtml"
        name="attachment-viewer-html">
    <!-- tried to dynamically set the height of the frame using http://stackoverflow.com/questions/25880671/how-to-set-the-iframe-content-height-in-my-case
      This did not work because this page is loaded over http:// and the iframe is loaded with data: and browsers do not allow you to access
      the contents of iframes when the protocols don't match. -->
    <iframe [src]="document?.trustedData" 
            class="attachment-viewer-html">
    </iframe>
  </div>

  <div *ngIf="document?.isCCD"
       name="attachment-viewer-html">
    <iframe [src]="document?.trustedData"
            class="attachment-viewer-html">
    </iframe>
  </div>

  <div *ngIf="document?.isPdf"
       name="attachment-viewer-pdf"
       class="attachment-viewer-pdf">
    <p *ngIf="disableInlinePdf" class="text-center">
      This pdf document is unable to be displayed.
    </p>
    <ap-pdf *ngIf="!disableInlinePdf" [src]="document?.trustedData"></ap-pdf>
  </div>

  <div *ngIf="document?.isAudio"
       name="attachment-viewer-audio"
       class="attachment-viewer-audio">
    <span>Audio File:<br></span>
    <audio id="audio" controls>
      <source id="source" [src]="document?.trustedData"/>
    </audio>
  </div>

  <div *ngIf="!document?.isSupported && !loadingDocument && !loadError"
       name="attachment-viewer-unsupported"
       class="attachment-viewer-unsupported">

    <span *ngIf="document?.extension" class="warning">
      Attachment file type is not supported. Type: ({{document?.extension}}).
    </span>
    <span *ngIf="!document?.extension" class="warning">
      Attachment file type is not known and cannot be displayed.
    </span>
  </div>

  <div *ngIf="loadingDocument && !document?.isImage" class="flex-col center-h">
    <ap-spinner size="3"></ap-spinner>
  </div>

  <div *ngIf="loadError"
       name="attachment-viewer-error">
    <alert type="danger">
      An error has occurred and the document could not be loaded.
    </alert>
  </div>
</div>
