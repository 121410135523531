import {
  AppRoutesDeclaration,
} from 'webcommon/shared';

export abstract class AppRouteService {
  abstract getAppRoutes(): AppRoutesDeclaration;
  abstract setupAppRoutes(newAppRoutes: AppRoutesDeclaration): void;
}

export function AppRouteServiceFactory(i: angular.auto.IInjectorService) {
  return i.get<AppRouteService>('AppRoutes');
}

export const AppRoutesAjsProvider = {
  deps: ['$injector'],
  provide: AppRouteService,
  useFactory: AppRouteServiceFactory,
};
