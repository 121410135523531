import { Injectable } from '@angular/core';

import { AppConfiguration, GatewayConfiguration } from 'webcommon/shared';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationRepository implements AppConfiguration {
  static readonly ajsFactoryName = 'ConfigurationRepository';

  private appConfiguration: AppConfiguration | null = null;

  loadConfiguration(configuration: AppConfiguration): void {
    if (this.appConfiguration !== null) {
      throw new Error('configuration has already been loaded');
    }
    this.appConfiguration = configuration;
  }

  private validateConfiguration(): AppConfiguration {
    if (this.appConfiguration === null) {
      throw new Error('configuration has not been loaded');
    }
    return this.appConfiguration;
  }

  public get API_KEY(): string {
    const appConfiguration = this.validateConfiguration();
    return appConfiguration.API_KEY;
  }

  public get CLIENT_FEATURES(): string[] | undefined {
    const appConfiguration = this.validateConfiguration();
    return appConfiguration.CLIENT_FEATURES;
  }

  public get CLIENT_VERSION(): string {
    const appConfiguration = this.validateConfiguration();
    return appConfiguration.CLIENT_VERSION;
  }

  public get DEV_HOSTED(): boolean {
    const appConfiguration = this.validateConfiguration();
    return appConfiguration.DEV_HOSTED;
  }

  public get EXPERIMENTAL_FEATURES(): string[] | undefined {
    const appConfiguration = this.validateConfiguration();
    return appConfiguration.EXPERIMENTAL_FEATURES;
  }

  public get GATEWAYS(): GatewayConfiguration[] {
    const appConfiguration = this.validateConfiguration();
    return appConfiguration.GATEWAYS;
  }

  public get GIT_COMMIT_HASH(): string {
    const appConfiguration = this.validateConfiguration();
    return appConfiguration.GIT_COMMIT_HASH;
  }

  public get MIN_PRM_DASHBOARD_VERSION(): string | undefined {
    const appConfiguration = this.validateConfiguration();
    return appConfiguration.MIN_PRM_DASHBOARD_VERSION;
  }

  public get SERVER_API_VERSION(): string {
    const appConfiguration = this.validateConfiguration();
    return appConfiguration.SERVER_API_VERSION;
  }
}
