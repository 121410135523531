import {ApDocumentType} from './ApDocumentType';

// This is prefixed so that it should never conflict with some built-in type.
export class ApDocumentBase {
  readonly isImage: boolean = false;
  readonly isText: boolean = false;
  readonly isHtml: boolean = false;
  readonly isPdf: boolean = false;
  readonly isCCD: boolean = false;
  readonly isAudio: boolean = false;
  readonly isSupported: boolean;

  // should reference this in html in places that need
  // trusted urls, like <iframe>, <object>, etc.
  readonly trustedData: any;

  constructor(
    readonly data: string,
    readonly dataIsObjectUrl: boolean,
    readonly documentType: ApDocumentType,
    readonly extension: string,
    readonly pageCount?: number | null,
    trustedData?: any,
  ) {
    if (documentType === ApDocumentType.html) {
      this.isHtml = true;
    }
    if (documentType === ApDocumentType.image) {
      this.isImage = true;
    }
    if (documentType === ApDocumentType.pdf) {
      this.isPdf = true;
    }
    if (documentType === ApDocumentType.text) {
      this.isText = true;
    }
    if (documentType === ApDocumentType.CCD) {
      this.isCCD = true;
    }
    if (documentType === ApDocumentType.audio) {
      this.isAudio = true;
    }
    this.isSupported = documentType !== ApDocumentType.unknown;

    this.trustedData = trustedData || data;
  }
}
