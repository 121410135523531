import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  PatientIdentityResponseDto,
} from 'webcommon/shared';
import { PatientPortalHttpClientService } from '../PatientPortalHttpClientService';

@Injectable({
  providedIn: 'root',
})
export class PatientIdentityRepository {
  constructor(
    private readonly httpClientService: PatientPortalHttpClientService,
  ) { }

  getPersonIdentitySubject(personId: string): Observable<PatientIdentityResponseDto> {
    const endpointUrl = `identity/person/${personId}`;
    return this.httpClientService.sendGetRequest(endpointUrl);
  }

  registerPersonIdentity(personId: string): Observable<PatientIdentityResponseDto> {
    const endpointUrl = `identity/person/${personId}/register`;
    return this.httpClientService.sendPostRequest(endpointUrl, null);
  }
}
