import { Injectable } from '@angular/core';

import { LogInService } from './LogInService';
import { LogOffService } from './LogOffService';
import { TransitionManagerService } from './TransitionManagerService';
import { WebCommonStorageRepository } from './WebCommonStorageRepository';

@Injectable({
  providedIn: 'root',
})
export class CurrentUserService {
  static readonly ajsFactoryName = 'CurrentUser';

  constructor(
    private logInService: LogInService,
    private logOffService: LogOffService,
    private transitionManagerService: TransitionManagerService,
    private webCommonStorageRepository: WebCommonStorageRepository,
  ) {
  }

  forceLogoffUser(): void {
    const previousTransition = this.transitionManagerService.getPreviousTransition();
    this.logInService.setRedirect(previousTransition);

    this.logOffService.logoff().then(() => {
      this.transitionManagerService.transition({appRoute: 'login', opts: {replaceCurrentState: true}});
    });
  }

  isAuthenticated(): boolean {
    const jwt = this.webCommonStorageRepository.getJsonWebToken();
    return !!jwt;
  }

  get() {
    return {
        name: this.webCommonStorageRepository.getUserName(),
        fullName: this.webCommonStorageRepository.getUserFullName(),
        userId: this.webCommonStorageRepository.getUserId(),
        token: this.webCommonStorageRepository.getJsonWebToken(),
        clientId: this.webCommonStorageRepository.getClientId(),
        clientURL: this.webCommonStorageRepository.getClientUrl(),
        deviceId: this.webCommonStorageRepository.getDeviceId(),
        permissions: this.webCommonStorageRepository.getUserPermissions(),
    };
  }
}
