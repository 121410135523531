import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  LoginAssistanceDto,
  LoginAssistanceResultDto,
} from 'webcommon/shared';
import { PatientPortalHttpClientService } from '../PatientPortalHttpClientService';

@Injectable({
  providedIn: 'root',
})
export class LoginAssistanceRepository {
  static readonly ajsFactoryName = 'NgxLoginAssistanceRepository';

  constructor(
    private httpClientService: PatientPortalHttpClientService,
  ) { }

  getLoginAssistanceInformation(request: LoginAssistanceDto): Observable<LoginAssistanceResultDto> {
    const params = new HttpParams({ fromObject: request as any });
    return this.httpClientService.sendGetRequest('login/login-assistance', params);
  }
}
