import { DoCheck, ElementRef, Injector, OnDestroy, OnInit, Renderer2, Type, Directive } from '@angular/core';

import { lastDismissReasonT } from 'webcommon/shared';

import { ApBaseComponent } from '../ap-base-component/index';

import {ApModalHelper} from './ApModalHelper';
import {ApModalHelperFactory} from './ApModalHelperFactory';

@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class ApBaseModalComponent<T> extends ApBaseComponent implements OnInit, DoCheck, OnDestroy {
  private readonly modalHelper: ApModalHelper<T>;

  protected constructor(
    // For a component, this Injector is actually an instance of NodeInjector at runtime
    // which will have providers specific to this element or parents, and the providers in the root module Injector.
    // (https://github.com/angular/angular/blob/main/packages/core/src/render3/di.ts)
    elementInjector: Injector,
  ) {
    super();
    this.modalHelper = elementInjector.get(ApModalHelperFactory).create<T>(elementInjector, r => this.onHide(r));
    // Add a class here, so that flex styles could be applied to this element,
    // in case it needs to contain a scrollable container, which could require nested flex containers all the way down.
    // ex: making the .modal-body element scrollable
    // (theoretically this could be used for other situations as well, that don't have to do with scrolling)
    elementInjector.get<Renderer2>(Renderer2 as Type<Renderer2>).addClass(elementInjector.get(ElementRef).nativeElement, 'ap-modal-component-host');
  }

  ngDoCheck(): void {
    this.modalHelper.doCheck();
  }

  ngOnInit(): void {
    this.modalHelper.onInit();
  }

  override ngOnDestroy(): void {
    this.modalHelper.ngOnDestroy();
    super.ngOnDestroy();
  }

  // close the activeModal because the user is done and there is a successful result
  closeModal(result: T | PromiseLike<T>): void {
    return this.modalHelper.emitAndClose(result);
  }

  // dismiss the activeModal (which rejects the promise)
  dismissModal(reason?: any): void {
    return this.modalHelper.dismiss(reason);
  }

  protected onHide(reason: lastDismissReasonT): void {
    // if the reason is a string, then this hide was called because of a user action, like escape, or back button
    if (reason) {
      this.modalHelper.dismiss(`Modal dismissed because of: '${reason}'`);
      return;
    }

    // else, we programmatically called hide, so we don't need to do anything
  }
}
