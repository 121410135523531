import {
  Injectable,
} from '@angular/core';

import {
  ApBaseClass,
  DocumentHelper,
} from 'webcommon/shared';

import {BrowserService} from 'webcommon/legacy-common';

import {Observable} from 'rxjs';

import {
  take,
  takeUntil,
} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class WebDocumentHelper extends ApBaseClass implements DocumentHelper {
  private readonly windowObj: Window;

  constructor(
    browserService: BrowserService,
  ) {
    super();
    this.windowObj = browserService.getWindow();
  }

  openUrlInSeparateTab(url$: Observable<string>) {
    // Need to open the window directly from click handler to avoid popup blockers.
    // Go ahead and open tab in IE even though it probably won't load,
    // because other browsers might behave the same way in certain configurations.

    const newWindow = this.windowObj.open('');
    if (!newWindow) {
      // window.open() can return null if the window wasn't able to open.
      // For now, just return back if that's the case.
      return;
    }
    newWindow.document.write('loading...');
    newWindow.document.close();

    url$.pipe(
      take(1),
      takeUntil(this.onDestroy$),
    ).subscribe(
      (url) => {
        // IE will leave the window/tab open, so clear the loading message.
        newWindow.document.write('');
        newWindow.document.close();
        newWindow.location.href = url;
      },
      () => {
        newWindow.close();
      },
    );
  }

  supportsOpenInSeparateTab(): boolean {
    return true;
  }
}
