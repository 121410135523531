<!-- using fnd modal styling here as it matches what is being used in the portal currently -->
<div class="fnd-modal">
  <div class="fnd-modal-header">{{document.FileName}}
    <div class="fnd-close" (click)="cancel()">&times;</div>
  </div>
  <div class="fnd-modal-body">
    <div>
      <ap-enhanced-document-viewer
      [documentId]="document.AttachmentUid"
      [documentLoad]="loadAttachmentRef"
      [documentDownload]="downloadAttachmentRef"
      [isReadOnly]="'true'"
      [useViewerOverride]="2"
      [enableDownload]="'true'"
      ></ap-enhanced-document-viewer>
    </div>

  </div>
  <div class="fnd-modal-footer">
    <button class="center-btn-lg btn btn-default2 btn-lg" (click)="close()">Close</button>
  </div>
</div>
