import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreUiModule } from 'webcommon/core-ui';
import { ApPersonalHealthAppsModalComponent } from './third-party/ap-personal-health-apps-modal.component';

const declareComponents = [
  ApPersonalHealthAppsModalComponent,
];

@NgModule({
  declarations: [
    declareComponents,
  ],
  exports: [
  ],
  imports: [
    CommonModule,
    CoreUiModule,
  ],
  providers: [
  ],
})
export class DashboardModule {
}
