// essentially a port of ViewportInfo that we created in AngularJS, with some minor changes

import {
  Injectable,
} from '@angular/core';

import { BrowserService } from 'webcommon/legacy-common';

@Injectable({
  providedIn: 'root',
})
export class ViewportService {
  private readonly documentObj: Document;
  private readonly windowObj: Window;

  // todo: inject window and document here
  constructor(
    browserService: BrowserService,
  ) {
    this.documentObj = browserService.getDocument();
    this.windowObj = browserService.getWindow();
  }

  // page width
  getWidth(): number {
    const bodyElement = this.documentObj.body;
    const docElement = this.documentObj.documentElement;
    const pageWidth = Math.max(
      Math.max(bodyElement.scrollWidth, docElement.scrollWidth),
      Math.max(bodyElement.offsetWidth, docElement.offsetWidth),
      Math.max(bodyElement.clientWidth, docElement.clientWidth));

    return pageWidth;
  }

  // page height
  getHeight(): number {
    const bodyElement = this.documentObj.body;
    const docElement = this.documentObj.documentElement;
    const pageHeight = Math.max(
      Math.max(bodyElement.scrollHeight, docElement.scrollHeight),
      Math.max(bodyElement.offsetHeight, docElement.offsetHeight),
      Math.max(bodyElement.clientHeight, docElement.clientHeight));

    return pageHeight;
  }

  getWindowWidth(): number {
    return this.windowObj.innerWidth;
  }

  getWindowHeight(): number {
    return this.windowObj.innerHeight;
  }

  getScrollY(): number {
    return this.windowObj.scrollY;
  }
}
