<!-- Non-PDF attachments are being converted to PDF, but unless the message gets reloaded from the DB, the extensions don't get updated.
     Despite this, the converted attachements need to load in Foxit. It checks which extensions are being converted, if it's being converted,
     it will open in Foxit.  -->
<!-- <div *ngIf="!attachmentHandlerLoading"> -->
<div class="ap-enhanced-document-viewer" *ngIf="!attachmentHandlerLoading" [ngSwitch]="currentView">
  <ap-sign-pdf *ngSwitchCase="1"
      [disableFullscreen]="disableFullscreen"
      [disableInlinePdf]="disableInlinePdf"
      [loadDocument]="onDocumentLoadRef"
      [documentId]="documentId"
      (currentDocumentChange)="currentDocumentChanged($event)">
  </ap-sign-pdf>
  <ap-document-viewer *ngSwitchCase="2"
  [disableFullscreen]="disableFullscreen"
  [disableInlinePdf]="disableInlinePdf"
  [loadDocument]="onDocumentLoadRef"
  [documentId]="documentId"
  (currentDocumentChange)="currentDocumentChanged($event)"
  (loadingDocumentChange)="loadingDocument = $event"
  ></ap-document-viewer>
  <div *ngSwitchCase="0">
    <button class="windows-viewer-button" mat-raised-button *ngIf="isPrm; else notPrm" (click)="openWindowsViewer()">Open in external viewer</button>
    <ng-template #notPrm>
      <span>Windows viewer is not available in FND</span>
    </ng-template>
  </div>
  <ng-template *ngSwitchCase="null">
    <!-- Remove this in the future -->
  </ng-template>
</div>
<div class="text-center" *ngIf="canOpenInSeparateTab() && !loadingDocument">
  <span>
    <a (click)="onDocumentOpenInSeparateTab(documentId)"
       class="clickable">View in a separate tab</a>
  </span>
</div>
<div class="text-center" *ngIf="canDownloadDocument() && !loadingDocument">
  <span [hidden]="downloadingDocument">
    Download
    <a class="clickable"
       apSaveToFile
       [documentDownload]="onDocumentDownloadRef"
       [fileName]="downloadFileName">here</a>.
  </span>
  <ap-spinner *ngIf="downloadingDocument"></ap-spinner>
</div>
