import { Injectable } from '@angular/core';
import {intersection, isArray} from 'lodash-es';

import { WebCommonStorageRepository } from './WebCommonStorageRepository';
import {
  ServerFeature,
} from 'webcommon/shared';

@Injectable({
  providedIn: 'root',
})
export class ServerInfoRepository {
  static readonly ajsFactoryName = 'ServerInfoRepository';

  constructor(
    private readonly webCommonStorageRepository: WebCommonStorageRepository,
  ) {
  }

  // ==========================================================================================
  // All of this below is currently used in angularjs as well, so be careful when changing the interface of it.
  getPrmAssemblyVersion(): string | null {
    return this.webCommonStorageRepository.getPrmAssemblyVersion();
  }

  setPrmAssemblyVersion(assemblyVersion: string | null): void {
    this.webCommonStorageRepository.setPrmAssemblyVersion(assemblyVersion);
  }

  getPrmDatabaseVersion(): string | null {
    return this.webCommonStorageRepository.getPrmDatabaseVersion();
  }

  setPrmDatabaseVersion(databaseVersion: string | null): void {
    this.webCommonStorageRepository.setPrmDatabaseVersion(databaseVersion);
  }

  getPrmDatabaseBuild(): string | null {
    return this.webCommonStorageRepository.getPrmDatabaseBuild();
  }

  setPrmDatabaseBuild(databaseBuild: string | null): void {
    this.webCommonStorageRepository.setPrmDatabaseBuild(databaseBuild);
  }

  getMobileAssemblyVersion(): string | null {
    return this.webCommonStorageRepository.getMobileAssemblyVersion();
  }

  setMobileAssemblyVersion(assemblyVersion: string | null): void {
    this.webCommonStorageRepository.setMobileAssemblyVersion(assemblyVersion);
  }

  getSupportedFeatures(): string[] | null {
    return this.webCommonStorageRepository.getSupportedFeatures();
  }

  setSupportedFeatures(supportedFeatures: string[]): void {
    this.webCommonStorageRepository.setSupportedFeatures(supportedFeatures);
  }

  getDatabaseName(): string | null {
    return this.webCommonStorageRepository.getDatabaseName();
  }

  setDatabaseName(databaseName: string | null): void {
    this.webCommonStorageRepository.setDatabaseName(databaseName);
  }

  isFeatureSupported(features: string[] | string): boolean {
    const supportedFeatures = this.getSupportedFeatures();
    if (!supportedFeatures) {
      return false;
    }

    const featuresToCheck = isArray(features) ? features : [features];
    const matchedValues = intersection(featuresToCheck, supportedFeatures);

    return matchedValues.length === featuresToCheck.length;
  }

  // End of stuff currently used in angularjs
  // ==========================================================================================

  isSupported(...features: ServerFeature[]): boolean {
    const supportedFeatures = this.getSupportedFeatures();
    if (!supportedFeatures) {
      return false;
    }

    const featureStrings = features.map((x) => ServerFeature[x]);
    const matchedValues = intersection(featureStrings, supportedFeatures);

    return matchedValues.length === features.length;
  }
}
