// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular.json`.

export const baseEnvironment = {
  API_KEY: 'C83BBF42-DA17-4F58-9AA0-68F417419313',
  CLIENT_VERSION: '0.2.0.1069',
  GATEWAYS: [{url: 'https://gateway.aprima.com/api', prefix: 'prod'}, {url: 'http://gateway-dev.aprima.com:8310/api', prefix: 'dev'}],
  GIT_COMMIT_HASH: 'c1dc24340c364285e28724ef38cd2c42eb28d211',
  SERVER_API_VERSION: 'v1',
};
