import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  NgHybridStatesModule,
  UIRouterUpgradeModule,
} from '@uirouter/angular-hybrid';

import {
  CoreUiModule,
} from 'webcommon/core-ui';
import {
  AppRouteService,
} from 'webcommon/legacy-common';
import {
  AppRoutesDeclaration,
} from 'webcommon/shared';

import {
  ContactUsComponent,
} from './index';

const declareComponents = [
  ContactUsComponent,
];

const loginAppRoutes: AppRoutesDeclaration = {
  contactus: {
    portalSettings: {
        hideHeader: false,
        hideToolbar: false,
        toolbarItem: 'blank',
    },
    route: '/contactus',
    state: 'contactus',
  },
};

const loginRoutes: NgHybridStatesModule = {
  states: [
    {
      component: ContactUsComponent,
      name: loginAppRoutes.contactus.state,
      resolve: [
        {
          deps: [ TranslateService ],
          provide: '$title',
          useFactory: (translateService: TranslateService) => {
            return translateService.get('Contact Us').toPromise();
          },
        },
      ],
      url: loginAppRoutes.contactus.route,
    },
  ],
};

@NgModule({
  declarations: declareComponents,
  imports: [
    CommonModule,
    CoreUiModule,
    UIRouterUpgradeModule.forChild(loginRoutes),
  ],
})
export class LoginModule {
  constructor(appRouteService: AppRouteService) {
    appRouteService.setupAppRoutes(loginAppRoutes);
  }
}
