import {
  AppRoute,
  AprimaTransitionRequest,
  WebViewManager,
} from 'webcommon/shared';

export abstract class BrowserWebViewManagerJs implements WebViewManager {
  abstract activateView(appRoute: AppRoute, transition: AprimaTransitionRequest): boolean;
  abstract goBack(): void;
}

export function BrowserWebViewManagerJsFactory(i: angular.auto.IInjectorService) {
  return i.get<BrowserWebViewManagerJs>('BrowserWebViewManager');
}

export const BrowserWebViewManagerJsFactoryAjsProvider = {
  deps: ['$injector'],
  provide: BrowserWebViewManagerJs,
  useFactory: BrowserWebViewManagerJsFactory,
};
