
import {
  AddressDto,
  ConditionDto,
  PatientProgramDto,
  PharmacyDto,
  PhoneNumberDto,
} from '../../webapi';
import {ContactPersonDto} from './ContactPersonDto';

export class PatientDemographicsDto {
  Address: AddressDto;
  Address2: AddressDto;
  Age: string | null;
  AkaFirstName: string | null;
  AkaLastName: string | null;
  Condition: ConditionDto[];
  Contacts: ContactPersonDto[];
  DateOfBirth: Date | null;
  DominantHand: string | null;
  DriversLicenseNumber: string | null;
  EmailAddress1: string | null;
  EmailAddress2: string | null;
  Ethnicity: string | null;
  ExternalId: string | null;
  FirstName: string;
  Gender: string | null;
  HasPortalAccount: boolean;
  Id: string;
  Language: string | null;
  LastName: string;
  MaritalStatus: string | null;
  MedicalRecordNumber: string | null;
  Notes: string | null;
  OriginalChartScanned: Date | null;
  PatientStatus: string | null;
  Pharmacies: PharmacyDto[];
  Phone1Number: string | null;
  Phone1Type: string | null;
  PhoneNumber1: PhoneNumberDto;
  PhoneNumber2: PhoneNumberDto;
  PhoneNumber3: PhoneNumberDto;
  PhoneNumber4: PhoneNumberDto;
  PrimaryCareProviderName: string | null;
  PrimaryInsuranceCarrier: string | null;
  PrimaryInsurancePlan: string | null;
  PrimaryProviderName: string | null;
  Programs: PatientProgramDto[];
  Race: string | null;
  ReferringProviderName: string | null;
  ReleaseSignedDate: Date | null;
  SocialSecurityNumber: string | null;
}
