import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileExt',
})
export class FileExtension implements PipeTransform {
  transform(extension: string | null): string | null {
    if (!extension || extension.length < 1) return extension;
    if (extension[0] === '.') {
      extension = extension.substring(1);
    }
    return extension.toUpperCase();
  }
}
