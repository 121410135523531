import { Injector, Directive } from '@angular/core';
import { lastDismissReasonT } from 'webcommon/shared';
import {ApBaseModalComponent} from './ap-base-modal.component';
import {apModalCancelled, apModalClosed, ApModalResult} from './ApModalResult';

import {ApModalReason} from './ApModalReason';

// Allows modal to return a more comprehensive result, including how it was closed, etc
@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class ApComplexModal<T> extends ApBaseModalComponent<ApModalResult<T>> {
  protected constructor(
    elementInjector: Injector,
  ) {
    super(elementInjector);
  }

  // I prefixed these methods, so they are less likely
  // to conflict with methods defined in inheriting classes

  // close the activeModal because the user is done and there is a successful result
  apClose(result: T): void {
    const modalResult = apModalClosed(result);
    return this.closeModal(modalResult);
  }

  // close the activeModal because the user is done and there is a successful result (which is a promise)
  apCloseWithPromise(result: PromiseLike<T>): void {
    const modalResultPromise = result.then((x) => {
      const modalResult = apModalClosed(x);
      return modalResult;
    });

    return this.closeModal(modalResultPromise);
  }

  // close the activeModal because the user has cancelled the operation,
  // but there is not an error that should be passed along
  apUserCancel(): void {
    const modalResult = apModalCancelled<T>(
      ApModalReason.UserCancel,
    );
    return this.closeModal(modalResult);
  }

  protected override onHide(reason: lastDismissReasonT): void {
    // if the reason is a string, then this hide was called because of a user action, like escape, or back button
    if (reason) {
      const modalResult = apModalCancelled<T>(
        ApModalReason.Unknown,
      );
      return this.closeModal(modalResult);
    }

    // else, we programmatically called hide, so we don't need to do anything
  }
}
