import {
  HttpClientModule,
} from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import {BrowserModule, HammerModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';
import {
  UIRouterUpgradeModule,
} from '@uirouter/angular-hybrid';
import {
  AlertModule,
} from 'ngx-bootstrap/alert';
import {
  CollapseModule,
} from 'ngx-bootstrap/collapse';
import {
  BsDatepickerModule,
} from 'ngx-bootstrap/datepicker';
import {
  ModalModule,
} from 'ngx-bootstrap/modal';
import {
  PaginationModule,
} from 'ngx-bootstrap/pagination';
import {
  TabsModule,
} from 'ngx-bootstrap/tabs';
import {
  ToastaConfig,
  ToastaModule,
} from 'ngx-toasta';
import {
  AprimaTranslateLoader,
  webcommonHttpInterceptorProviders,
} from 'webcommon/legacy-common';

import { HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';

import { BaseHammerConfig } from 'webcommon/core-ui';

const defaultToastaConfig: ToastaConfig = {
  limit: 5,
  position: 'top-right',
  showClose: true,
  showDuration: true,
  theme: 'bootstrap',
  timeout: 5000,
};

@NgModule({
  imports: [
    // For now, all these imports are only supposed to be imported a single time in
    // the app.
    // The intent is that the AppModule in the app can just import this module, and get these.
    // And this shouldn't be imported anywhere else.
    //
    // The main problem to avoid becomes obvious if BrowserModule, or some of these other modules
    // also get imported in a lazy-loaded module;
    // then an error will get thrown when navigating to that lazy-loaded module.
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AlertModule.forRoot(),
    BsDatepickerModule.forRoot(),
    CollapseModule.forRoot(),
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    TabsModule.forRoot(),
    ToastaModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: AprimaTranslateLoader,
      },
    }),
    UIRouterUpgradeModule.forRoot(),
    HammerModule,
  ],
  providers: [
    {
      // This overrides the default Hammer config.
      // This may need to be moved up to the root project module if this config
      // isn't getting loaded up correctly there, but for now, it should work across all of webcommon.
      provide: HAMMER_GESTURE_CONFIG,
      useClass: BaseHammerConfig,
    },
    {
      provide: ToastaConfig,
      useValue: defaultToastaConfig,
    },
    ...webcommonHttpInterceptorProviders,
  ],
})
export class WebCommonModule {
  // This is using the same constructor pattern as BrowserModule
  constructor(
    @Optional() @SkipSelf() parentModule: WebCommonModule | null,
  ) {
    if (parentModule) {
      throw new Error(
        `WebCommonModule is already loaded. Import it only once, usually in the AppModule only`);
    }
  }
}
