import { Injectable } from '@angular/core';

import { AprimaTransitionRequest } from 'webcommon/shared';

import { DeviceSettingsRepository } from './DeviceSettingsRepository';

@Injectable({
  providedIn: 'root',
})
export class LogInService {
  static readonly ajsFactoryName = 'LogInService';

  private redirectState = 'landing';
  private redirectParams: any | null = null;
  private isCustomRedirect = false;
  private customTransition: AprimaTransitionRequest | undefined;

  constructor(
    private deviceSettingsRepository: DeviceSettingsRepository,
  ) {
  }

  setDefaultRedirect(): void {
    this.isCustomRedirect = false;

    if (!this.deviceSettingsRepository.isDevice) {
      this.redirectState = 'landing';
    }
    else {
      this.redirectState = 'dashboard';
    }

    this.redirectParams = null;
  }

  setRedirect(transition: AprimaTransitionRequest): void {
    this.isCustomRedirect = true;
    this.customTransition = transition;

    if (!this.customTransition.opts) {
      this.customTransition.opts = {
        replaceCurrentState: true,
      };
    } else {
      this.customTransition.opts.replaceCurrentState = true;
    }
  }

  getRedirect(): AprimaTransitionRequest {
    if (this.isCustomRedirect && this.customTransition) {
      return this.customTransition;
    }

    const redirect = {
      appRoute: this.redirectState,
      opts: this.redirectParams || {},
    };

    redirect.opts.replaceCurrentState = true;

    return redirect;
  }
}
