import {
  Inject,
  Injectable,
  NgZone,
  OnDestroy,
} from '@angular/core';
import {
  AnnotationIcon,
  AnnotJson,
  DeviceRect,
  foxitLibPath,
  FoxitModule, FoxitModuleToken,
  isMarkup,
  PDFDoc,
  PDFPage,
  PDFPageRender,
  PDFRect,
  PDFUI,
} from 'webcommon/foxit';
import { BrowserService } from 'webcommon/legacy-common';

import {
  ApBaseClass,
  EditAttachmentDto,
  FoxitLicenseDto,
  FoxitLicenseDtoToken,
} from 'webcommon/shared';

import {
  AttachmentRepository, ProviderRepository,
} from 'webcommon/webapi';

import { forkJoin, from, Observable, of, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { pdfMimeType } from '../DocumentService';
import {IPdfRenderer} from './IPdfRenderer';
import moment from 'moment';

@Injectable()
export class FoxitPdfRenderer extends ApBaseClass implements IPdfRenderer, OnDestroy {
  protected readonly pdfUI: PDFUI;
  attachmentsToSave = new Map<string, string>();
  isDirty = false;
  annotationEventTriggered = false;
  pageCount = 0;
  pageIndex = 0;
  currentAttachmentUid?: string;
  providerSignatureWidth = 0;
  providerSignatureHeight = 0;
  providerName: string;

  readonly template = `
  <webpdf>
    <toolbar name="toolbar" class="fv__ui-toolbar-scrollable">
      <tabs name="toolbar-tabs">
      <tab title="toolbar.tabs.home.title" name="home-tab"><group-list name="home-toolbar-group-list">
        <group name="home-tab-group-hand" retain-count="10">
          <hand-button></hand-button>
          <selection-button></selection-button>
          <create-typewriter-button></create-typewriter-button>
          <create-stamp-dialog></create-stamp-dialog>
          <download-file-button></download-file-button>
        </group>
        <group name="comment-tab-group-stamp" retain-count="5">
            <single-page-button id="spb"></single-page-button>
            <continuous-page-button></continuous-page-button>
            <double-page-button></double-page-button>
            <stamp-dropdown></stamp-dropdown>
            <!-- <editable-zoom-dropdown></editable-zoom-dropdown> -->
        </group>
      </tab>
      </tab>
    </toolbar>
    <viewer></viewer>
    <template name="template-container">
    <create-stamp-dialog></create-stamp-dialog>
    <! -- contextmenus -->
    <markup-contextmenu name="fv--stamp-contextmenu"></markup-contextmenu>
    </template>
  </webpdf>`;

  constructor(
    private readonly zone: NgZone,
    private readonly browserService: BrowserService,
    private readonly attachmentRepository: AttachmentRepository,
    private readonly providerRepository: ProviderRepository,
    @Inject(FoxitModuleToken) foxitModule: FoxitModule,
    @Inject(FoxitLicenseDtoToken) foxitSetup: FoxitLicenseDto,
    element: HTMLElement,
  ) {
    super();
    this.pdfUI = this.createPDFUI(
      foxitModule,
      foxitSetup,
      element,
    );
  }

  // element: HTMLElement to mount the pdf viewer on
  protected createPDFUI(
    foxitModule: FoxitModule,
    foxitSetup: FoxitLicenseDto,
    element: HTMLElement,
  ): PDFUI {
    this.isDirty = false;
    this.annotationEventTriggered = false;

    const pdfUI = new foxitModule.PDFUI({
      addons: [],
      customs: { defaultStateHandler: foxitSetup.DefaultTool },
      fragments: [
        {
          config: {
            callback: this.saveAttachment.bind(this),
            tooltip: {
              title: 'Save PDF',
            },
          },
          target: 'download-file-button',
        },
      ],
      renderTo: element,
      template: this.template,
      viewerOptions: {
        // If we want to reference stuff from 'external' folder in foxit package (like in the examples),
        // then that needs to be setup to be copied to somewhere in the output directory,
        // just like the 'lib' folder currently is.
        // Currently, we aren't referencing anything in that folder.
        jr: {
          licenseKey: foxitSetup.LicenseKey,
          licenseSN: foxitSetup.LicenseSN,
        },
        libPath: foxitLibPath,
      },
    });
    pdfUI.eventEmitter.on('page-number-change', (newPageNumber) => {
      this.pageIndex = newPageNumber - 1;
    });
    pdfUI.getPDFViewer().then((viewer) => {
      viewer.eventEmitter.on('annotation-add', (annotAdd) => {
        console.log('Event annotation-add: ', annotAdd);
        if ((annotAdd[0].getTitle() === 'Foxit Web')
          && (annotAdd[0].getType() === 'stamp')) {
          const stampId = annotAdd[0].getUniqueID();
          pdfUI.getPDFDocRender().then((docRender) => {
            if (!docRender) return;
            const pageRender = docRender.getPDFPageRender(this.pageIndex);
            if (!pageRender) return;
            pageRender.getPDFPage().then((page) => {
              const signatureRect = annotAdd[0].getRect();
              page.removeAnnotById(stampId);
              this.addProviderSignatureWithTimestampMessage(page, signatureRect, pageRender);
            });
          });
        }
        else if (annotAdd[0].getTitle() === 'ProviderSignature' ||
          annotAdd[0].getTitle() === 'DateSigned' ||
          annotAdd[0].getTitle() === 'ElectronicSignatureTimestamp') {
          this.annotationEventTriggered = true;
        }
        this.isDirty = true;
      });
    });
    pdfUI.addViewerEventListener('render-file-success', () => {
      pdfUI.getPDFViewer().then((viewer) => {
        this.pageCount = viewer.currentPDFDoc.getPageCount();

        // Temp fix to satisfy customer for hotfix
        // This should more than likely be addressed in another way but this will perform the same
        // function.
        if (this.pageCount === 1) {
          const singlePageModeButton = document.getElementById('spb');
          if (!singlePageModeButton) return;
          (singlePageModeButton as HTMLAnchorElement).setAttribute('style',
            (singlePageModeButton as HTMLAnchorElement).style + ';pointer-events: none; opacity: 50%');
        }
      });

      pdfUI.getViewModeManager().then((viewModeMgr) =>  {
        if (this.pageCount === 1) {
          viewModeMgr.switchTo('continuous-view-mode');
        } else {
          viewModeMgr.switchTo(foxitSetup.DefaultViewMode);
        }
      });
    });
    pdfUI.addUIEventListener('active-annotation', (/* annotRender */) => {
      this.annotationEventTriggered = true;
    });
    pdfUI.getPDFViewer().then((viewer) => {
      viewer.eventEmitter.on('unactive-annotation', (/* annotUnactive */) => {
        this.annotationEventTriggered = false;
      });
    });
    pdfUI.getPDFViewer().then((viewer) => {
      viewer.eventEmitter.on('annotation-removed', (/* annotDelete */) => {
        this.annotationEventTriggered = false;
      });
    });
    pdfUI.getPDFViewer().then((viewer) => {
      viewer.eventEmitter.on('annotation-appearance-updated', (annotUpdate) => {
        console.log('Event annotation-appearance-updated: ', annotUpdate);
        const firstAnnot = annotUpdate[0];
        if (isMarkup(firstAnnot)) {
          if (firstAnnot.getIntent() === 'FreeTextTypewriter' &&
              firstAnnot.getTitle() !== 'DateSigned') {
            this.annotationEventTriggered = false;
          }
          else if (firstAnnot.getTitle() === 'ProviderSignature') {
            this.annotationEventTriggered = true;
            const groupElements = firstAnnot.getGroupElements();
            const signatureAnnot = groupElements[0];
            const datetimeStampAnnot = groupElements[1];
            const signatureRect = signatureAnnot.getRect();
            const datetimeStampAnnotRect = datetimeStampAnnot.getRect();
            datetimeStampAnnotRect.top = signatureRect.bottom;
            datetimeStampAnnotRect.left = signatureRect.left;
            datetimeStampAnnotRect.right = signatureRect.right;
            datetimeStampAnnotRect.bottom = datetimeStampAnnotRect.top - 30;
            datetimeStampAnnot.setRect(datetimeStampAnnotRect).then((success) => {
              if (success) {
                pdfUI.getPDFDocRender().then((docRender) => {
                  if (!docRender) return;
                  const pageRender = docRender.getPDFPageRender(this.pageIndex);
                  if (!pageRender) return;
                  const component = pageRender.getAnnotRender(datetimeStampAnnot.getUniqueID())?.getComponent();
                  component?.active();
                });
              }
            });
          }
        }
      });
    });
    pdfUI.getPDFViewer().then((viewer) => {
      viewer.eventEmitter.on('change-view-mode-success', () => {
        this.annotationEventTriggered = false;
      });
    });
    pdfUI.registerMatchRule((annot, ParentClass) => {
      if ('stamp' === annot.getType()) {
        // tslint:disable-next-line:max-classes-per-file
        return class CustomMarkupAnnotClass extends ParentClass {
          constructor(...args: any[]) {
            super(...args);
          }
          onDoubleTap(e: any) {
            // console.info(e, e.center);
            console.log(e);
            return false; // to prevent default behavior
          }
          showReplyDialog() {
            return false;
          }
        };
      }
      return ParentClass;
    });

    pdfUI.getPDFViewer().then((viewer) => {
      viewer.setDefaultAnnotConfig((type) => {
        const config: Partial<AnnotJson> = {};
        config.borderInfo = {};
        config.defaultAppearance = {};
        switch (type) {
          case 'freetext':
            config.color = 'ffffff';
            config.opacity = 1;
            config.fillColor = 0x000000;
            config.borderInfo.width = 0;
            config.intent = 'FreeTextTypewriter';
            config.defaultAppearance.textColor = 0;
            config.defaultAppearance.textSize = 14;
            break;
        }
        return config;
      });
    });
    // These events are not in use but could be useful in the future
    // pdfUI.getPDFViewer().then((viewer) => {
    //   viewer.eventEmitter.on('annotation-updated', (annotUpdate: any) => {
    //     console.log('Annot updated: ', annotUpdate);
    //     // this.annotationEventTriggered = true;
    //   });
    // });
    // pdfUI.getPDFViewer().then((viewer) => {
    //   viewer.eventEmitter.on('annotation-appearance-updated', (annotAppearanceUpdated: any) => {
    //     console.log('Event fired: annotation-appearance-updated');
    //     // this.annotationEventTriggered = true;
    //     console.log('Annotation Appearance Updated', annotAppearanceUpdated);
    //   });
    // });
    // pdfUI.getPDFViewer().then((viewer) => {
    //   viewer.eventEmitter.on('annotation-moved-position', (annotMovedPosition: any) => {
    //     // this.annotationEventTriggered = true;
    //     console.log('Annotation Moved Position', annotMovedPosition);
    //   });
    // });
    return pdfUI;
  }

  override ngOnDestroy(): void {
    this.destroyViewer(this.pdfUI);
    super.ngOnDestroy();
  }

  async openPDFData(data: string): Promise<void> {
    this.pageIndex = 0;
    const blob = this.browserService.base64ToBlob(data, pdfMimeType);
    await this.pdfUI.openPDFByFile(blob);
    await this.removeDefaultStamps();
    await this.setProviderStamp();
  }

  async setProviderStamp(): Promise<void> {
    const providerSignatureData = await this.getProviderSignatureBase64();

    this.providerName = providerSignatureData[1];
    const src = 'data:image/bmp;base64,' + providerSignatureData[0];
    const i = await this.loadImage(src);
    const icon: AnnotationIcon = {
      annotType: 'stamp',
      category: 'Provider Signature',
      fileType: 'bmp',
      height: i.height,
      name: 'Signature Icon',
      url: i.src,
      width: i.width,
    };
    this.providerSignatureHeight = i.height;
    this.providerSignatureWidth = i.width;
    await this.pdfUI.pdfViewer.addAnnotationIcon(icon);
  }

  // currently this appears to be used to just get the height and width
  // of the Image, but it's possible it could be used for other things
  loadImage(src: string): Promise<HTMLImageElement> {
    return new Promise<HTMLImageElement>((resolve, reject) => {
      const i = new Image();
      i.onload = () => {
        resolve(i);
      };
      i.onerror = (e) => {
        reject(e);
      };
      i.src = src;
    });
  }

  async removeDefaultStamps(): Promise<void> {
    // this.pdfUI.removeAnnotationIcon('stamp', null, null);
    await this.pdfUI.pdfViewer.removeAnnotationIcon('stamp', null, null);
    // this.pdfUI.pdfViewer.removeAnnotationIcon('stamp', 'Dynamic Stamps', 'Approved');
    // this.pdfUI.pdfViewer.removeAnnotationIcon('stamp', 'Dynamic Stamps', 'Revised');
    // this.pdfUI.pdfViewer.removeAnnotationIcon('stamp', 'Dynamic Stamps', 'Reviewed');
  }

  async pdfDocumentStream(pdfDoc: PDFDoc): Promise<Blob> {
    const bufferArray: BlobPart[] = [];
    await pdfDoc.getStream(({ arrayBuffer }) => {
      bufferArray.push(arrayBuffer);
    });
    return new Blob(bufferArray, { type: 'application/pdf' });
  }

  async saveAttachment(): Promise<void> {
    const currentAttachmentUid = this.currentAttachmentUid;
    if (!currentAttachmentUid) {
      return;
    }

    const pdfDoc = await this.pdfUI.getCurrentPDFDoc();
    if (!pdfDoc) {
      return;
    }

    await pdfDoc.flatten(0);
    const pdfBlob = await this.pdfDocumentStream(pdfDoc);
    const pdfBase64 = await this.blobToBase64(pdfBlob);
    const editedAttachment: EditAttachmentDto = {
      Data: pdfBase64,
      AttachmentUid: currentAttachmentUid,
    };
    await this.attachmentRepository.setAttachmentContent(
      (editedAttachment.AttachmentUid).toString(),
      editedAttachment,
    ).toPromise();
    this.annotationEventTriggered = false;
  }

  setAttachment(id: string) {
    this.currentAttachmentUid = id;
  }

  blobToBase64(data: Blob): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (reader.result) {
          const base64dataUrl = reader.result.toString();

          // Note: The blob's result cannot be directly decoded as Base64 without first
          // removing the Data-URL declaration preceding the Base64-encoded data.
          // To retrieve only the Base64 encoded string, first remove data:/;base64, from the result.
          const base64data = base64dataUrl.substring(base64dataUrl.indexOf(',') + 1);

          resolve(base64data);
        } else {
          reject();
        }
      };
      reader.onerror = (e) => {
        reject(e);
      };
      reader.readAsDataURL(data);
    });
  }

  // todo: foxit PDFUI.destroy returns a promise;
  // may have to await that at some point if necessary
  private destroyViewer(pdfViewer: PDFUI | null): void {
    if (pdfViewer) {
      this.zone.runOutsideAngular(() => {
        pdfViewer.destroy();
      });
    }
  }

  async getProviderSignatureBase64(): Promise<[string, string]> {
    const { Signature: signatureString, ProviderName: providerName } =
      await this.providerRepository.GetCurrentProviderSigningSignature().toPromise();
    if (!signatureString) {
      throw new Error();
    }

    return [ signatureString, providerName ];
  }

  setSelection(startEvent: MouseEvent, endEvent: MouseEvent) {
    if (this.annotationEventTriggered) {
      return;
    } else {
      const startPoints = [startEvent.offsetX, startEvent.offsetY] as const;
      const endPoints = [endEvent.clientX, endEvent.clientY] as const;
      let pageScale = 1;
      this.pdfUI.getPDFDocRender().then((docRender) => {
        if (!docRender) {
          return;
        }

        if (this.pdfUI.pdfViewer.stateHandlerManager.currentStateHandler.getStateName() !== 'CreateFreeTextTypewriterHandler') {
          const pageRender = docRender.getPDFPageRender(this.pageIndex);
          if (!pageRender) {
            return;
          }

          const eHandler = pageRender.getHandlerDOM();
          const pageRect = eHandler.getBoundingClientRect();
          const rect = this.getRect(startPoints, pageRect, endPoints);
          pageScale = pageRender.getScale();
          pageRender.getPDFPage().then((page) => {
            const reversedRect = page.reverseDeviceRect(rect, pageScale);
            if ((startEvent.clientY < endEvent.clientY) && (startEvent.clientX < endEvent.clientX)) {
              reversedRect.right = reversedRect.left + this.providerSignatureWidth;
              reversedRect.bottom = reversedRect.top - this.providerSignatureHeight;
              this.addProviderSignatureWithTimestampMessage(page, reversedRect, pageRender);
            } else if ((startEvent.clientY > endEvent.clientY) && (startEvent.clientX < endEvent.clientX)) {
              this.addDate(page, reversedRect, pageRender);
            }
          });
        }
      });
    }
  }

  addProviderSignatureWithTimestampMessage(page: PDFPage, rect: PDFRect, pageRender: PDFPageRender) {
    const providerSignature: AnnotJson = {
      icon: 'Signature Icon',
      iconCategory: 'Provider Signature',
      opacity: 1,
      rect: {
        bottom: rect.bottom,
        left: rect.left,
        right: rect.right,
        top: rect.top,
      },
      rotate: page.getRotation(),
      title: 'ProviderSignature',
      type: 'stamp',
    };
    const timestampMessage: AnnotJson = {
      color: 'ffffff',
      contents: 'Electronically signed by ' + this.providerName + ' on ' + this.formatDate(true),
      'interior-color': 0x00000000,
      rect: {
        bottom: rect.bottom - 30,
        left: rect.left,
        right: rect.right,
        top: rect.bottom,
      },
      rotate: page.getRotation(),
      subject: 'FreeTextTypeWriter',
      'text-color': 0x000000,
      title: 'ElectronicSignatureTimestamp',
      type: 'freetext',
      defaultAppearance: {
        textColor: 0,
        textSize: 10
      }
    };

    const annotArray = [ providerSignature, timestampMessage ];
    page.addAnnotGroup(annotArray, 0).then((annots) => {
      annots.forEach((a) => {
        const component = pageRender.getAnnotRender(a.getUniqueID())?.getComponent();
        component?.active();
      });
    });
  }

  addDate(page: PDFPage, rect: PDFRect, pageRender: PDFPageRender) {
    const date = this.formatDate();
    const annotJson: AnnotJson = {
      color: 'ffffff',
      contents: date,
      'interior-color': 0x00000000,
      rect: {
        bottom: rect.bottom,
        left: rect.left,
        right: rect.right,
        top: rect.top,
      },
      rotate: page.getRotation(),
      subject: 'FreeTextTypeWriter',
      'text-color': 0x000000,
      title: 'DateSigned',
      type: 'freetext',
    };
    page.addAnnot(annotJson).then((annots) => {
      const annot = annots[0];
      const component = pageRender.getAnnotRender(annot.getUniqueID())?.getComponent();
      component?.active();
    });
    this.isDirty = true;
  }

  addTextBox(page: PDFPage, rect: PDFRect, pageRender: PDFPageRender) {
    const annotJson: AnnotJson = {
      color: 'ffffff',
      defaultAppearance: {
        textColor: 0,
        textSize: 24,
      },
      'interior-color': 0x00000000,
      rect: {
        bottom: rect.bottom,
        left: rect.left,
        right: rect.right,
        top: rect.top,
      },
      rotate: page.getRotation(),
      subject: 'FreeTextTypeWriter',
      'text-color': 0x000000,
      type: 'freetext',
    };
    page.addAnnot(annotJson).then((annots) => {
      const annot = annots[0];
      const component = pageRender.getAnnotRender(annot.getUniqueID())?.getComponent();
      component?.active();
    });
    this.isDirty = true;
  }

  // Takes each attachment for the current message that has been modified (signed or dated), and saves it to the database
  batchSaveAttachments(): Observable<string[]> {
    const obv: Array<Observable<string>> = [];
    this.attachmentsToSave.forEach((value: string, key: string) => {
      const editedAttachment: EditAttachmentDto = {
        Data: value,
        AttachmentUid: key,
      };
      obv.push(this.attachmentRepository.setAttachmentContent((editedAttachment.AttachmentUid).toString(), editedAttachment));
    });
    this.isDirty = false;
    this.attachmentsToSave.clear();
    return forkJoin(obv);
  }

  // If an attachment has been modified, we want to add it to our map (attachmentsToSave) to keep the modified data stored until we save
  // it to the database.
  attachmentModified(docId: string): Observable<boolean> {
    return this.getModifiedAttachmentData().pipe(
      switchMap((modifiedData: string) => {
        if (modifiedData) {
          this.attachmentsToSave.set(docId, modifiedData);
          this.isDirty = false;
          return of(true);
        } else {
          return throwError(new Error('Modified attachment data not retrieved'));
        }
      }),
    );
  }

  // To get the new attachment data that includes signatures/dates, we need to use several Foxit methods/objects to pull the final data.
  // The attachment must also be flattened, otherwise faxes and other attachment export functions will not include the signature/date,
  // despite the attachment displaying normally in PRM.
  getModifiedAttachmentData(): Observable<string> {
    const promise = new Promise<string>((resolve, reject) => {
      this.pdfUI.getCurrentPDFDoc().then((pdfDoc) => {
        if (!pdfDoc) {
          reject();
          return;
        }

        pdfDoc.flatten(0).then(() => {
          this.pdfDocumentStream(pdfDoc).then((pdfBlob) => {
            this.blobToBase64(pdfBlob).then((pdfBase64) => {
              if (pdfBase64 != null) {
                resolve(pdfBase64);
              } else {
                reject();
              }
            });
          });
        });
      });
    });
    return from(promise);
  }

  // Used when switching between attachments in a message
  checkIfAttachmentDirty(docId: string): Promise<void> {
    return new Promise<void>((resolve) => {
      if (this.isDirty === true) {
        return this.attachmentModified(docId).subscribe(() => resolve());
      }
      resolve();
    });
  }

  // Used when moving to the next message
  checkIfAttachmentGroupDirty(docId: string): Observable<string[]> {
    if (this.isDirty) {
      return of(docId).pipe(
        switchMap(() => this.attachmentModified(docId)),
        switchMap(() => this.batchSaveAttachments()),
      );
    } else if (this.attachmentsToSave.size > 0) {
      return of(docId).pipe(
        switchMap(() => this.batchSaveAttachments()),
      );
    } else {
      return of([]);
    }
  }

  getRect(
    startPoints: readonly [x: number, y: number],
    pageRect: DOMRect,
    endPoints: readonly [x: number, y: number],
  ): DeviceRect {
    let left = startPoints[0];
    let top = startPoints[1];
    let right = endPoints[0] - pageRect.left;
    let bottom = endPoints[1] - pageRect.top;
    let temp: number;
    if (right < left) {
      temp = left;
      left = right;
      right = temp;
    }
    if (bottom < top) {
      temp = top;
      top = bottom;
      bottom = temp;
    }
    return {
      left,
      top,
      right,
      bottom,
    };
  }

  formatDate(includeTime?: boolean): string {
    const formatString = 'MM/DD/YYYY' + ((includeTime) ? ' hh:mm A' : '');
    return moment().format(formatString);
  }

  adjustZoom() {
    this.pdfUI.pdfViewer.zoomTo('fitHeight').then(() => {
      this.pdfUI.pdfViewer.zoomTo('fitWidth');
    });
    this.annotationEventTriggered = false;
  }
}
