import {Injectable} from '@angular/core';

// Currently, Guids coming from the server that are serialized in a dto will be lowercase,
// so really these could and should all be lowercase.
// However, there are situations where that would break current functionality.
// Example: there is JSON in the db for FND and Homepage which has the Guids as uppercase,
// so the constants in here kinda need to match that.
// Updating 1 would require updating both, and possibly could cause other unknown issues.
@Injectable({
  providedIn: 'root',
})
/* tslint:disable:variable-name comment-format max-line-length */
export class SystemGuid {
  static readonly ajsFactoryName = 'SystemGuid';

  readonly GuidEmpty = '00000000-0000-0000-0000-000000000000';
  readonly CareTeam = '10101010-0000-0000-0000-000000000001';
  readonly Everyone = '00000101-0000-0000-0000-000000000000';

  // Messaging module guids
  readonly BuildInMessageImplementation = '10101010-0000-0000-0000-000000000006';
  readonly ApproveMessageImplementation = '10101010-0000-0000-0000-000000000007';

  // Message Urgency
  readonly RoutineMessageUrgency = 'c2a64d38-3be1-4f8a-8947-ef9e9ad861a4';

  // Message Types
  readonly AAMessageType = '10101010-0000-0000-0000-000000000002';
  readonly TCMessageType = '10101010-0000-0000-0000-000000000003';
  readonly RxMessageType = '10101010-0000-0000-0000-000000000004';
  readonly TaskMessageType = '10101010-0000-0000-0000-000000000005';
  readonly TaskMessageType_Procedure = '10101010-0000-0000-1111-000000000005';
  readonly HL7MessageType = '10101010-0000-0000-0000-000000000008';
  readonly ANMessageType = '10101010-0000-0000-0000-000000000009';
  readonly ClaimErrorMessageType = '10101010-0000-0000-0000-000000000010';
  readonly MessageAttachHTML = '10101010-0000-0000-0000-000000000011';
  readonly ERxRefillMessageType = 'd7716f70-61d6-4ec0-8ba6-01afc95e99f2';
  readonly RxRefillMessageType = 'a2b88bc2-e7c8-4990-92f6-ae28b5c0dc77';
  readonly ChangeRxMessageType = '2C259B74-444F-4F29-9634-5768DDA9E572';
  readonly ChangeRxPriorAuthorizationMessageType = '61A96124-6977-491E-9390-EF506DBBC05F';
  readonly RxRefillRequestMessageType = 'EB31DF6B-3685-4639-81F6-026F6278E1DC';
  readonly SurveyMessageType = '5e9280e5-3388-49e8-a5ae-4dffbb0a9b89';
  readonly AuthorizationMessageType = '34e136de-85d4-4bdf-a487-7972ea3ce105';
  readonly MessageAttachmentType = '10101010-0000-0000-0000-000000000012';
  readonly InstantMessageMessageType = '3c2fa842-9097-483a-bff8-2e7e47f2b6e2';
  readonly CollectionMessageMessageType = '7a0ea261-29e8-44be-9e0a-13bdaa7b282a';
  readonly StrikeOutMessageType = 'e3226583-1509-4ef1-9647-e5d5991dd41a';
  readonly LabMessageType = 'f05f9c71-a893-46c3-a7fe-37988f48a782';
  readonly PatientLabOrderMessageType = '77882a75-7c9d-430b-9da6-f8ed12058ddb';
  readonly ERxChangeMessageType = 'f4bee48e-2975-46d5-acc8-4a04126f5275';
  readonly ERxEligibilityMessageType = '10101010-0000-0000-0000-000000000014';
  readonly ERxMedicationHistoryMessageType = '10101010-0000-0000-0000-000000000013';
  readonly PatientEmergencyChartAccessMessageType = '07dfc802-0ce9-4381-bdae-959315458476';
  readonly VisitBillingReviewMessageType = '81ad57ba-e3f1-4b8f-b3f2-23a189fba3e5';
  readonly VaccineAdministrationMessageType = '3a68f4d0-b93d-4f9d-8036-1a89db86cdd5';
  readonly PatientReferralMessageType = 'b2a91010-cd37-4391-a850-683d230c177f';
  readonly RefundTaskMessageType = '52249495-41c2-4c68-b027-eac66218599f';
  readonly FinancialChangeAlertMessageType = '2cc02936-d3d5-4887-8f8f-45831ce1a46a';
  readonly AmendmentMessageType = 'b1f8b643-cd55-410f-bc5d-649fb64e98ba';
  readonly FacilityPortalMessageType = 'aa62132e-6f9b-46b1-bcba-987ce73bee89';
  readonly EPCSSignatureRightsMessageType = '35A799B9-AB5C-4E16-B527-C4FC0C2072B0';
  // Message SubTypes
  readonly MessageRxRequestSubType = '136d8762-82ec-487d-9448-f52469a3cdcd';
  readonly MessageRxResponseSubType = 'd0ddd888-de71-4156-a2d8-6f1f4068b249';
  readonly MessageRxElectronicTransmissionErrorSubType = 'f28b3d6a-2244-4197-8133-aeef881354fa';
  readonly JobEngineSubMessageType = '00000000-0000-0000-7777-200000000001';
  readonly DocumentFaxErrorSubMessageType = '77ef2a24-7e5e-436c-b689-8427ae45e7bf';
  readonly PatientSurveySubMessageType = '0266be8e-73d2-452c-89fb-e16786ebdd94';
  readonly PatientDemographicsSubMessageType = 'd1d54662-29c2-44da-a0cf-28fefdf5d825';
  readonly PatientAppointmentRequestSubMessageType = '4778614c-4594-4d1b-851d-5c67d21c5776';
  readonly PatientRxRefillRequestSubMessageType = 'b1b7d286-1fb2-43d0-a883-7946b1cd8751';
  readonly PatientAmendmentSubMessageType = 'e9fc14a7-0f2a-47ab-8fc3-4a04b7a20247';
  readonly AttachmentSubMessageType = '458dadad-5879-43d0-b94e-603d312b1ca7';
  readonly PatientPortalRxRefillMessageSubType = 'F4404BED-406D-4E46-B514-93D5D81F73D2';
  //
  //
  readonly PatientGeneralQuestionSubMessageType = '9af1089b-e98b-4758-80f8-0435b143426d';
  readonly PatientAppointmentQuestionSubMessageType = 'fd4e2c5d-29c6-4a51-bd20-551d6ed90843';
  readonly PatientRxQuestionSubMessageType = 'e94b7146-407d-4f92-a393-de8429b7bfbf';
  readonly PatientDemographicsQuestionSubMessageType = 'dffb40d0-2318-41a9-8eef-94c58babb9b9';
  readonly PatientBillingQuestionSubMessageType = '73366ba8-0149-47e0-875b-cb12bb4cb250';
  readonly PatientEmailNewPatientAccountSubMessageType = '897562e2-94cd-4a0f-9f8c-2fcff3fe95ff';
  readonly PatientEmailNewPortalAccountSubMessageType = '28652a5e-19e3-45ea-a6ba-f546d50e7fe7';
  readonly PatientPortalErrorSubMessageType = '786e319f-01ef-48b8-a5a2-8499334a660d';
  readonly PatientSubmitLoginAssistanceType = 'eca5b8a3-8371-4c08-bf43-386bac737d3f';
  readonly CDSReminderSubMessageType = '45d56c6b-f843-4e12-8d41-dc0f728c8b41';
  readonly PatientRecallSubMessageType = 'af799cc9-343c-4b2e-876d-36ce0ebdbf5a';
  readonly PatientFamilyHistorySubMessageType = '2a00a8fb-4a0e-49c0-9dfa-c94a9ae6f165';
  readonly PatientServiceOrderSubMessageType = '8080c9d5-be1c-478b-af6c-e179102e0c46';
  readonly PatientWelcomeMessagePracticeDefaultSubMessageType = 'd9a50836-98bb-4157-94ff-10a61c4f8355';
  readonly PatientWelcomeMessageProviderOverrideSubMessageType = '3ff6ab41-02f2-4835-90d0-7093311e7ca0';
  //
  readonly HMRuleMessageSubType = '10101010-0000-0000-0000-000000000050';
  readonly ReplicationMessageSubType = '594d621b-cbf4-4e1f-bafc-5195abc0ea9e';
  readonly MessageProcedureRequestSubType = '6116da32-d833-4b3d-ba27-e7bec468fb6f';
  readonly PatientVisitNoteAccessSubMessageType = '2a470344-8bb2-46a5-8893-73701911fc96';
  readonly PatientChartAccessSubMessageType = '015a35fc-7c0e-4ac8-8ec9-3946a302028b';
  readonly CPOBillingMessageSubType = '5214f054-5369-4102-97e4-9abbae205b66';
  readonly SecurityAlertMessageSubType = '99810285-c0da-4e4a-8f23-8f6100fbdec3';
  readonly ScheduledReportMessageSubType = '99b8ef67-faf4-4a15-abc5-76d95d826093';
  readonly ClaimReportMessageSubType = '02430101-0067-485e-8431-842a420c2d41';
  readonly CancelledServiceOrderSubType = 'cba8e1a7-e65c-4eca-b3a3-c2cbedf6d7d3';
  readonly CarePlanMessageSubType = '18cb87f2-291e-47b6-b1eb-8c2ad96a9b6c';
  readonly CCMMessageSubType = '65e1a474-3ef4-41a0-ba88-473ec2c11011';
  //BEGIN MICROSELECT CHANGES
  readonly CollectionStatusExpirationMessageSubType = '806a2155-acf7-4d0a-b83f-915232530e50';
  //END MICROSELECT CHANGES
  readonly AuthorizationRequestSubType = '3febe005-809b-4c15-ae4a-3e7bcf12f2b1';
  readonly AuthorizationResponseSubType = '34830710-2326-468c-a58c-355165a7d5e1';
  //
  //
  readonly AccountChangeAlertSubType = '43580720-de8c-4ccb-8c8a-170a3d8cda65';
  //
  //
  readonly PatientLabOrderRequestSubType = '22a2b2b0-d3c5-4229-84a4-9db792836f58';
  readonly PatientLabOrderResponseSubType = '73fc9cfd-777d-497b-8c18-a9c09bc489c4';
  readonly DocumentGenerationInstructionsSubType = '25fb8f0e-22cf-41dc-a63a-e10d49a34e8e';
  readonly CompleteChartPrintSubType = '5b6f1708-e35c-4264-b4aa-a53a5dca7446';
  readonly ProviderIDVerificationSubType = '00cdcaeb-d65b-4a1d-976e-b349a3e48da1';
  readonly RequestGrantProviderEPCSRightsSubType = '082a7b1b-9a44-408b-87fd-6508b90544fe';
  readonly RequestRevokeProviderEPCSSubType = '77e7df51-c19f-45ba-88a0-9f3492159ffd';

  readonly DirectMailWithoutAttachmentMessageSubType = '613f69c5-42f4-4f81-ae64-deedb3670490';
  readonly DirectMailWithAttachmentMessageSubType = '6347edde-54ee-4da1-b912-ea742030efc3';
  readonly UrlLinkMessageSubType = 'fd530fb4-05e3-46fe-8abb-6bfc3c1cd531';
  readonly ElectronicResultMessageSubType = '43bca388-7d6c-466b-b859-0480156ec4f2';

  readonly ERxMessageDenyReasonRefillNotAppropriate = 'fffa4de9-d931-4311-8969-0dbd4e9c25ee';
  readonly ERxMessageDenyReasonPatientUnknownToPractice = 'b2464f3f-7ab4-4c18-b36f-96bf046b1c00';

  // KS - These were commented out, but I had to add them back in when re-generating prm.xml from scratch
  readonly MessageStatusNew = '10101010-0000-0000-0000-000000000010';
  readonly MessageStatusView = '10101010-0000-0000-0000-000000000011';
  readonly MessageStatusComplete = '10101010-0000-0000-0000-000000000012';
  readonly MessageStatusWait = '10101010-0000-0000-0000-000000000013';

  readonly KdbSecurityGroup = '10101010-0000-0000-0000-000000000021';
  // fixed bug #17788.
  //KdbSecurityGroupAllow = '82276eab-504d-46b6-8aea-4d9ab1c0bab8';
  readonly ClinicalDelgateSecurityAllow = '82276eab-504d-46b6-8aea-4d9ab1c0bab8';
  readonly SecurityListEditorObjects = '10101010-0000-0000-0000-000000000022';
  readonly SecurityListReportObjects = '10101010-0000-0000-0000-000000000023';
  readonly SecurityListFilterObjects = '10101010-0000-0000-0000-000000000024';
  readonly ReportSecurity = '10101010-0000-0000-0000-000000000025';

  readonly AttachmentHandler_GraphicNote = '10101010-0000-0000-0000-000000000100';
  readonly AttachmentHandler_AllFiles = 'fc724a17-fdc7-4a0c-89a2-6a02f6b3e701';
  readonly AttachmentHandler_Bmp = '1d037286-73d1-4ca3-b9f8-3dff4fb459a0';

  // List Report
  readonly Report_CQM = '153cab39-18ff-416b-bd31-178f92fc23ff';
  readonly Report_HEDIS = '3d2bda74-02bf-45d3-ad7f-06e3ae7bd173';

  /* reserve Uid's 102 - 109 for ListJobScheduleTimeBase */
  readonly HL7ListenerJob = '10101010-0000-0000-0000-000000000110';

  // Financial center
  readonly MainFinancialCenterUid = '14b99c94-edac-47bd-97b9-cf421ac16018';

  // Claims
  readonly ClaimReportStatusNewUid = '00000000-0000-0000-0000-000000000010';
  readonly ClaimReportStatusReviewedUid = '00000000-0000-0000-0000-000000000011';
  readonly PaperClaimOptionDefaultToElectronicUid = '5b36f9c3-b1cf-4e80-8541-11397058b723';
  readonly PaperClaimOptionSecondaryClaimsPrintUid = '9cb84c44-19f5-4f32-809c-3350bbe99aca';
  readonly PaperClaimOptionAllClaimsPrintUid = '09aa94e4-e72b-499e-bf6e-e5fe9e05d8b8';

  // Statements
  readonly ETacticsHL7PartnerUid = '905317b4-d36e-4b19-9d79-244a67b9274e';
  readonly ETacticsCreateHL7PartnerMessageUid = '76c48549-a2fb-4396-a0d9-6dc9c05dbff6';
  readonly PrintStatementActionUid = 'a88f5a75-0ad5-4c90-b006-8a007a7fa13c';
  readonly SubmitStatementActionUid = 'dd9e9acd-0a49-44f3-b0b0-ece163fd8d42';
  readonly PrintPreviewStatementActionUid = '067ab4ce-7bc3-4da5-8fa8-f8f5db24057e';
  readonly PracticeInsightElectronicStatementsHL7PartnerUid = '66f598ce-0658-4ab4-814f-d2aa59cded6c';
  readonly PracticeInsightElectronicStatementsHL7PartnerMessageUid = 'd7c2b2b0-6c9f-4676-b5df-04d8c6bf44a9';
  readonly ProcessStatementActionUid = '6fcad410-fe20-426f-b996-1b39650bc409';

  // Payment types
  readonly PaymentTypeCheckUid = '97270636-da7a-4e41-9fe9-e42b8dfa2f13';
  readonly PaymentTypePureAdjustmentUid = '4a3eea98-802c-45f8-b3a1-d9957eafd075';

  // Deposit macros
  readonly ListDepositMacro_Visit = '2f480610-bc75-4193-9072-1d4cd9e5788c';
  readonly ListDepositMacro_Account = '1dea5e1c-440d-4177-84b0-26aa78103fae';
  readonly ListDepositMacro_RPOldestFirst = '2318d5e2-cc3f-428f-89a3-fe57338333cf';

  // payer credit refund status
  readonly ListPayerCreditRefundStatus_Pending = '66872612-1a69-4c93-90a2-819288479ab2';
  readonly ListPayerCreditRefundStatus_Approved = 'b0f4343b-ac05-492a-91f7-2e41d5e9d197';
  readonly ListPayerCreditRefundStatus_Rejected = '2049316f-4819-4d51-8d04-488b000b85bc';
  readonly ListPayerCreditRefundStatus_Open = '5279aee2-ef6a-44e5-8a3c-a6a4a916db40';
  readonly ListPayerCreditRefundStatus_Closed = '296b823d-78dc-4f60-a24d-e000e162e3cb';

  // Appointment
  readonly ScheduledAppointmentStatusUid = '17982339-1dfe-4d69-ac0e-8f3af4087255';
  readonly CancelledAppointmentStatusUid = '9c52e394-e4b6-450c-aba2-87b2a75fdb13';
  readonly NoShowAppointmentStatusUid = 'e7be0240-f633-4271-9054-50f1b5c1e9d4';
  readonly CheckedInAppointmentStatusUid = 'cd2a0578-0c89-4151-b4e9-66ed9d5b4995';
  readonly DischargedAppointmentStatusUid = '31886389-631f-4d03-bc28-b4db87e9b172';
  readonly ReadyToDischageAppointmentStatusUid = 'c8f3cd36-ff13-4088-8181-81ed9b14dbd1';
  readonly AppointmentCallStatusConfirmed = '20af514b-517b-4419-88c9-6725b2469989';
  readonly NeedToRescheduleAppointmentStatusUid = '923ed792-581d-4610-9f17-9fa915df97b2';

  // Fee schedules
  readonly DefaultAmountAllowedScheduleUid = '00000000-0000-0000-0fee-000000000001';

  readonly UserDefinedGroupType = '10101010-0000-0000-0000-000000000099';

  // Attachments
  readonly AttachmentType_Dictation = '00000000-0000-0000-8888-000000000004';
  readonly AttachmentType_CCDCDataExchangExport = '82e352da-9dea-48ff-9ee6-108aff424bb5';

  // Reports
  readonly ReportAppointment = '9e8a3f49-d368-48dc-a65f-dbd3d96ed4b9';
  readonly ReportBalanceAndCloseCharges = 'ee20411b-a90f-4327-b145-7a358b332bae';
  readonly ReportBalanceAndCloseTransactions = 'a1eb2c50-66eb-4e28-8a22-073e000a9fa7';
  readonly ReportCapitation = '729b0f35-d190-458e-ae64-91edd24b1d6e';
  readonly ReportCMS1500 = '7e372962-6870-41d2-9162-76d0e643fea1';
  readonly ReportCMS1500NPI = '58ea30f7-d52f-4aee-92cd-3701204d763b';
  readonly ReportCMS1500NPI5010 = '09676db6-dfb9-4d9a-a204-f0c7465231f4';
  readonly ReportCollectionsInsurance = '38373452-9546-46fb-901f-d5e4d7be8703';
  readonly ReportCollectionsPatient = 'ece75489-f96e-4a5c-b6d1-cd1d8840dadc';
  readonly ReportDepositSlip = 'eb6ae34c-af06-4cdc-ac77-a1151ee4848b';
  readonly ReportHL7LabReport = 'a26cda78-dd9f-4253-b4cc-e26e227877cf';
  readonly ReportInsuranceAging = '4e5703f5-6389-4981-ad1e-d152d8025045';
  readonly ReportNoSB_Encounters = '9b35b7d0-d8a6-46be-94bc-711550e7a6fc';
  readonly ReportPatientLedger = 'c7aa9366-ba87-41bf-8895-9cfd6a1a9968';
  readonly ReportPracticeFinancials = '5d890d9f-c967-4f95-b280-8ed488dcf853';
  readonly ReportPrescription = 'f8eaa510-83c6-4575-a124-ed5ddecc1b76';
  readonly ReportProcedureProductivity = '70d9290a-2265-4059-a1b8-071b353f4327';
  readonly ReportStatementBatch = '53bebc35-28b7-4ef9-b479-21e0ff8d1767';
  readonly ReportStatementDetails = 'ab980a4e-d02d-402c-b88b-16cfc14e54d8';
  readonly ReportStatementBatchMainAccount = '9b3ecd3c-e695-43b0-a5d7-e4524dc33b4a';
  readonly ReportStatementDetailsMainAccount = 'ab08221b-61bf-4f7d-9554-d0a84deecd67';
  readonly ReportTotalAR = '9d6625bf-4f6b-4944-a402-8d1c5083789d';
  readonly ReportVisitsWithoutSBs = 'f8414e3f-9aa2-42c7-a4f2-0cd060e30bb5';
  readonly ReportMedicationTimeFlow = 'e8fcf679-a654-4b12-85ad-fb7e107ad377';
  readonly ReportSuperbill = 'bb768412-3b62-425b-9e70-4becc6783702';
  readonly ReportERA = '92256504-356b-41ae-b68d-84076d3d2e2c';
  readonly ReportStatementBatchByFinancialCenter = 'aa491038-ac02-458d-b5d9-682e229b3e8c';
  readonly ReportStatementDetailsByFinancialCenter = 'a77ef1c5-f9ec-4597-9646-b96b8fc0054b';
  readonly ReportStatementBatchMainAccountByFinancialCenter = 'cd3c82a1-ae62-49c0-8587-6f859029fa2b';
  readonly ReportStatementDetailsMainAccountByFinancialCenter = 'e57f4dce-a24d-470c-b163-42060726cf91';
  readonly ReportPrintAttachmentText = 'a363aed0-4a54-4576-b79e-d8fc90bc52fe';
  readonly ReportPrintAttachmentImage = 'd541d42f-b4a2-4dba-be41-52ddbec8facf';
  readonly ReportPrintAttachmentNoNote = 'f5777afe-de62-4959-a3b1-08779dac8341';
  readonly ReportPrintAttachmentImageOnly = '363e08f6-ad10-4b07-827e-097d13af5537';
  readonly ReportGroupVisitRosterSignInSheet = '75ee272a-0890-40c4-944c-3e865ecd0042';

  readonly ReportPatientRecordDisclosure = 'f7b11729-c421-4b92-b177-343018f4a81a';
  readonly ReportCDSReminders = 'b9ba795f-71fa-4e33-a9dd-40f09819388b';
  readonly ReportCallpointeMU = '6cdf8b90-0071-49a8-a534-64134f00599c';

  // fixed bug #15134.
  readonly ReportPrintAttachmentTIF = '1f505b4d-9b89-44c2-8924-c09133fc7a15';
  readonly ReportMedicationReviewForm = '900377c9-d12c-4f73-b5f0-429ff928def8';
  readonly AppointmentChargeTicket = 'd8b62581-8cc1-4d09-b078-6372e9cfdaf0';
  readonly ListFindTable_Reports = '6c57e4a8-1314-4e42-9100-24534d01b887';
  readonly ListFindTable_DiagnosisCode10 = '48ac27ee-cb5a-4be6-b43c-d98534fb484e';
  //Refund reports
  readonly ReportRefundCheck = '69305c0d-881f-4b24-bbe6-4dbdc6af8523';
  readonly ReportRefundCheckRegister = 'e85955b5-b04f-4e1d-9668-3e8d8154f69e';

  //CYS SC
  readonly ReportUB04 = '4ee0fb9c-41cc-4da9-964c-ddbb588c7a72';

  //Added for ABN
  readonly ReportCMSR131L = 'b7c160c6-dbc9-4380-a2c2-c18a9f0dbec2';
  //BEGIN MICROSELECT CHANGES
  readonly ReportResponsiblePartyAccountPage = '90c232e9-7941-4629-bb86-a0be2a1325d4';
  //END MICROSELECT CHANGES

  // Vitals
  readonly Vital_BMI = '00000000-0000-0000-7777-100000000007';
  readonly Vital_Temperature = '00000000-0000-0000-7777-100000000008';
  readonly Vital_Temperature_Method = '00000000-0000-0000-7777-100000000009';
  readonly Vital_RR = '00000000-0000-0000-7777-100000000010';
  readonly Vital_RR_Condition = '00000000-0000-0000-7777-100000000011';
  readonly Vital_SpO2 = '00000000-0000-0000-7777-100000000012';
  readonly Vital_SpO2_Condition = '00000000-0000-0000-7777-100000000013';
  readonly Vital_SpO2_Method = '00000000-0000-0000-7777-100000000014';
  readonly Vital_Comment = '00000000-0000-0000-7777-100000000015';

  readonly Vital_Metric_Head_Circ = '00000000-0000-0000-7777-100000000069';

  readonly Vital_HR_Method = '00000000-0000-0000-7777-100000000016';
  readonly Vital_HR_Value_Sitting = '00000000-0000-0000-7777-100000000017';
  readonly Vital_HR_Value_Standing = '00000000-0000-0000-7777-100000000018';
  readonly Vital_HR_Value_Supine = '00000000-0000-0000-7777-100000000019';
  readonly Vital_HR_Location_Sitting = '00000000-0000-0000-7777-100000000020';
  readonly Vital_HR_Location_Standing = '00000000-0000-0000-7777-100000000021';
  readonly Vital_HR_Location_Supine = '00000000-0000-0000-7777-100000000022';
  readonly Vital_HR_Regularity_Sitting = '00000000-0000-0000-7777-100000000023';
  readonly Vital_HR_Regularity_Standing = '00000000-0000-0000-7777-100000000024';
  readonly Vital_HR_Regularity_Supine = '00000000-0000-0000-7777-100000000025';

  readonly Vital_BP_Location_1 = '00000000-0000-0000-7777-100000000051';
  readonly Vital_BP_Position_1 = '00000000-0000-0000-7777-100000000052';
  readonly Vital_BP_Sys_1 = '00000000-0000-0000-7777-100000000053';
  readonly Vital_BP_Dias_1 = '00000000-0000-0000-7777-100000000054';
  readonly Vital_BP_Location_2 = '00000000-0000-0000-7777-100000000055';
  readonly Vital_BP_Position_2 = '00000000-0000-0000-7777-100000000056';
  readonly Vital_BP_Sys_2 = '00000000-0000-0000-7777-100000000057';
  readonly Vital_BP_Dias_2 = '00000000-0000-0000-7777-100000000058';
  readonly Vital_BP_Location_3 = '00000000-0000-0000-7777-100000000059';
  readonly Vital_BP_Position_3 = '00000000-0000-0000-7777-100000000060';
  readonly Vital_BP_Sys_3 = '00000000-0000-0000-7777-100000000061';
  readonly Vital_BP_Dias_3 = '00000000-0000-0000-7777-100000000062';
  readonly Vital_BP_Location_4 = '00000000-0000-0000-7777-100000000063';
  readonly Vital_BP_Position_4 = '00000000-0000-0000-7777-100000000064';
  readonly Vital_BP_Sys_4 = '00000000-0000-0000-7777-100000000065';
  readonly Vital_BP_Dias_4 = '00000000-0000-0000-7777-100000000066';

  // blood pressure locations in table "ListMisc" to find BP locations
  readonly Vital_BP_Location_NotSelected = '00000000-0000-0000-7777-000000000101';
  readonly Vital_BP_Location_LeftArm = '00000000-0000-0000-7777-000000000102';
  readonly Vital_BP_Location_RightArm = '00000000-0000-0000-7777-000000000103';
  readonly Vital_BP_Location_LeftLeg = '00000000-0000-0000-7777-000000000104';
  readonly Vital_BP_Location_RightLeg = '00000000-0000-0000-7777-000000000105';

  // blood pressure positions in table "ListMisc" to find BP positions
  readonly Vital_BP_Position_NotSelected = '00000000-0000-0000-7777-000000000106';
  readonly Vital_BP_Position_Sitting = '00000000-0000-0000-7777-000000000107';
  readonly Vital_BP_Position_Standing = '00000000-0000-0000-7777-000000000108';
  readonly Vital_BP_Position_Supine = '00000000-0000-0000-7777-000000000109';

  // Heart rate guids (same as orginal sitting, standing and supine)
  readonly Vital_Heart_Rate_1 = '00000000-0000-0000-7777-100000000017';
  readonly Vital_Heart_Rate_2 = '00000000-0000-0000-7777-100000000018';
  readonly Vital_Heart_Rate_3 = '00000000-0000-0000-7777-100000000019';

  // Heart Rate and Blood Pressure Position in guids in table "ListMisc" (same as the orginal blood pressure)
  readonly Vital_Position_Sitting = '00000000-0000-0000-7777-000000000107';
  readonly Vital_Position_Standing = '00000000-0000-0000-7777-000000000108';
  readonly Vital_Position_Supine = '00000000-0000-0000-7777-000000000109';

  // weight dress options in table "ListMisc" to find weight dress options
  readonly Vital_Weight_Dress_NotSelected = '00000000-0000-0000-7777-000000000001';
  readonly Vital_Weight_Dress_Clothed = '00000000-0000-0000-7777-000000000002';
  readonly Vital_Weight_Dress_Unclothed = '00000000-0000-0000-7777-000000000003';

  // respiratory rate conditions in table "ListMisc" to find respiratory rate conditions
  readonly Vital_Respiratory_Rate_NotSelected = '00000000-0000-0000-7777-000000000021';
  readonly Vital_Respiratory_Rate_Quiet = '00000000-0000-0000-7777-000000000022';
  readonly Vital_Respiratory_Rate_Sleeping = '00000000-0000-0000-7777-000000000023';
  readonly Vital_Respiratory_Rate_Crying = '00000000-0000-0000-7777-000000000024';

  // heart regularity conditions in table "ListMisc" to find heart regularity conditions
  readonly Vital_Heart_Regularity_Regular = '00000000-0000-0000-7777-000000000081';
  readonly Vital_Heart_Regularity_Irregular = '00000000-0000-0000-7777-000000000082';
  readonly Vital_Heart_Regularity_RegularRegular = '00000000-0000-0000-7777-000000000083';
  readonly Vital_Heart_Regularity_RegularIrregular = '00000000-0000-0000-7777-000000000084';
  readonly Vital_Heart_Regularity_IrregularRegular = '00000000-0000-0000-7777-000000000085';
  readonly Vital_Heart_Regularity_IrregularIrregular = '00000000-0000-0000-7777-000000000086';

  //vitals weight and height guids
  readonly Vital_MetricWeight = '00000000-0000-0000-7777-100000000070';
  readonly Vital_MetricHeight = '00000000-0000-0000-7777-100000000071';
  readonly Vital_MetricTemp = '00000000-0000-0000-7777-100000000072';
  readonly Vital_ImperialTemp = '00000000-0000-0000-7777-100000000077';

  // vitals blood pressure guids
  readonly Vital_BloodPressureMethod = '00000000-0000-0000-7777-100000000026';
  readonly Vital_MetricBloodPressure1 = '00000000-0000-0000-7777-100000000073';
  readonly Vital_MetricBloodPressure2 = '00000000-0000-0000-7777-100000000074';
  readonly Vital_MetricBloodPressure3 = '00000000-0000-0000-7777-100000000075';
  readonly Vital_MetricBloodPressure4 = '00000000-0000-0000-7777-100000000076';
  readonly Vital_HeadCircumference = '00000000-0000-0000-7777-100000000006';
  readonly Vital_WaistMeasure = '2a11dc70-be5f-40aa-a34a-caef718d0513';

  // List Signature Source
  readonly ListSignatureSource_Medical_Records_Release = '42de70f4-9f0f-4a08-b446-e1e5027e7e00'; // Signature on file for medical records release

  // ListSuperbillStatus
  readonly ListSuperbillStatus_AprimaVaultDataConversionComplete = '0e755dd2-6a40-47b4-a60c-d06257ee58e0';
  readonly ListSuperbillStatus_Cancelled = 'fc8ad61b-139a-4cfa-9580-cdacfc3acf90';
  readonly ListSuperbillStatus_Complete = '30f64d66-55cb-4438-bb2f-7a6250399312';
  readonly ListSuperbillStatus_Filed = '9ffe6214-7c7f-4516-9899-43d37fabe21d';
  readonly ListSuperbillStatus_Hold = '5d149ced-a42f-4a23-a236-f1c9c48ee8b1';
  readonly ListSuperbillStatus_Insurance_Action = 'b4d6b17e-a8a1-425d-95d3-5d29b465d682';
  readonly ListSuperbillStatus_Account_Change_Alert = 'b42f00ab-32ea-4d81-81e9-b4ad186fac4f';
  readonly ListSuperbillStatus_Overpaid = '43c2c635-184b-4e5e-a893-d2030bde7df3';
  readonly ListSuperbillStatus_Pending_Patient_Payment = 'd2bf0f3c-6e03-478f-bdae-4dcac5eb9410';
  readonly ListSuperbillStatus_Preliminary = 'df81be7c-71cf-47f4-8573-733e81e30ea8';
  readonly ListSuperbillStatus_Queued = '0b42c4c4-6d7e-41f2-9d08-b28f17404e79';
  readonly ListSuperbillStatus_Ready_to_Review = 'd2291c14-3f4e-4381-80a7-883c76c9499b';
  readonly ListSuperbillStatus_Ready_to_Submit = '55ed3239-89f3-4488-89c9-71be5a541cc4';
  readonly ListSuperbillStatus_Rejected = '2c4a6e7d-eb6d-4370-a836-5937363c6b69';
  readonly ListSuperbillStatus_Succeeded = 'd2341cba-6f5c-47d9-95a5-16d5680565c3';
  readonly ListSuperbillStatus_Failed_Validation = '695681c6-f9b3-45f6-a8fe-5fa3b1d07469';
  readonly ListSuperbillStatus_Passed_CodeValidation = '96d656a3-fea4-4aa4-9cc4-e39640ab5808';
  readonly ListSuperbillStatus_ERA_Claim_Rejected = '2352f0fb-708b-437e-9809-f80128195f9e';
  readonly ListSuperbillStatus_Queued_Rejected = '8bca307f-e59f-42f2-925b-b1c102a985f9';
  readonly ListSuperbillStatus_Batched = '3796674a-6a9e-45b3-abf3-0c3b606159dd';
  readonly ListSuperbillStatus_Batching = '55f1c3f2-2384-4d54-a9e5-fb60ddb28f76';

  // History group anchor questions for multirow groups - used to sync between Survey and PatientHistory tables
  readonly PatientHistory_AnchorQuestion_Immunization = '00000000-0000-0000-0000-000000111201';
  readonly PatientHistory_AnchorQuestion_Menstrual = 'ffffffff-0000-0000-0000-000000000001';
  readonly PatientHistory_AnchorQuestion_Pregnancy = '078dc26e-5ac4-4901-a7b9-cf310b9610eb';

  //  History group uids from the OPS options panel
  readonly HistoryGroupMedicalHistory = '00000000-0000-0000-0000-000000000101';
  readonly HistoryGroupInfectionHistory = '00000000-0000-0000-0000-000000000102';
  readonly HistoryGroupSurgicalHistory = '00000000-0000-0000-0000-000000000103';
  readonly HistoryGroupFamilyHistory = '00000000-0000-0000-0000-000000000104';
  readonly HistoryGroupGeneticScreening = '00000000-0000-0000-0000-000000000105';
  readonly HistoryGroupSocialHistory = '00000000-0000-0000-0000-000000000106';
  readonly HistoryGroupMedicationHistory = '00000000-0000-0000-0000-000000000107';
  readonly HistoryGroupFoodAllergy = '00000000-0000-0000-0000-000000000108';
  readonly HistoryGroupDrugAllergy = '00000000-0000-0000-0000-000000000109';
  readonly HistoryGroupEnvironmentAllergy = '00000000-0000-0000-0000-000000000110';
  readonly HistoryGroupImmunizationHistory = '00000000-0000-0000-0000-000000000111';
  readonly HistoryGroupSpecialtyHistory = '00000000-0000-0000-0000-000000000112';
  readonly HistoryGroupMenstrualHistory = '00000000-0000-0000-0000-000000000114';
  readonly HistoryGroupPregnancyHistory = '00000000-0000-0000-0000-000000000115';
  readonly HistoryGroupFlowsheet = '00000000-0000-0000-0000-000000000011';
  readonly HistoryGroupObservationHistory = '00000000-0000-0000-0000-000000000116';
  readonly HistoryGroupProblemDiagnosis = '0238a133-904e-4e49-bdba-4eb3cb3b39a7';
  readonly HistoryGroupAddressHistory = '49283214-0137-45ed-893e-19fb6b688ebb';
  // added for new family history
  readonly HistoryGroupNewFamilyHistory = 'a3faf896-b328-4364-9621-012f8c6643bb';
  readonly HistoryGroupDSMIV = '167473e6-7f98-4840-870b-a5a5313b022e';
  readonly HistoryGroupCognitiveAssessment = '00000000-0000-0000-0000-000000000120';
  readonly HistoryGroupFunctionalAssessment = '00000000-0000-0000-0000-000000000121';
  // fix for senario #34484
  readonly HistoryGroupProcedureHistory = 'fa2b8214-a2ef-419d-8ef4-ce42ac4e8191';

  //freetext
  readonly HistoryGroupGenericFreeText = '00000000-0000-0000-0000-000000000267';

  //GUID constants for Immunization History
  readonly HistoryGroup_Immunization_Question_Vaccine = '00000000-0000-0000-0000-000000111201';
  readonly HistoryGroup_Immunization_Question_TypeofVaccine = '00000000-0000-0000-0000-000000111202';
  readonly HistoryGroup_Immunization_Question_DateGiven = '00000000-0000-0000-0000-000000111203';
  readonly HistoryGroup_Immunization_Question_Route = '00000000-0000-0000-0000-000000111204';
  readonly HistoryGroup_Immunization_Question_SiteGiven = '00000000-0000-0000-0000-000000111205';
  readonly HistoryGroup_Immunization_Question_LotNum = '00000000-0000-0000-0000-000000111206';
  readonly HistoryGroup_Immunization_Question_Manufacture = '00000000-0000-0000-0000-000000111207';
  readonly HistoryGroup_Immunization_Question_ExpDate = '00000000-0000-0000-0000-000000111208';
  readonly HistoryGroup_Immunization_Question_VISDate = '00000000-0000-0000-0000-000000111209';
  readonly HistoryGroup_Immunization_Question_VISGivenDate = '00000000-0000-0000-0000-000000111210';
  readonly HistoryGroup_Immunization_Question_Initial = '00000000-0000-0000-0000-000000111211';
  readonly HistoryGroup_Immunization_Question_Notes = '00000000-0000-0000-0000-000000111212';
  readonly HistoryGroup_Immunization_Question_FundingSource = '1d524fe4-8ac3-43f8-bd00-c08cec100ffa';

  readonly HistoryGroup_Immunization_Answer_Date = '00000000-0000-0000-0000-000000000171';
  readonly HistoryGroup_Immunization_Answer_Text = '00000000-0000-0000-0000-000000000288';

  readonly HistoryGroup_Immunization_VaccineFunding_AnswerGroup = 'a33cdd2b-67e3-4cc1-afd0-76a3e3d0fc0f';

  //unsupported questionnaire section
  readonly HistoryGroup_Immunization_FamilyImmunizationSection = '417ed290-1967-4f05-be18-1242b37d3f75';

  //Guid constants for vaccine route
  readonly VaccineRoute_PO = '47c0dd4e-cfa1-4a72-b623-0ad9d501fa81';
  readonly VaccineRoute_NS = 'c6e14630-95c2-4c47-ad37-4abbf79a9ed5';
  readonly VaccineRoute_OTH = '0ee77772-27ed-4b40-98ef-c4f1e3a4caec';

  // Answer Group
  readonly HistoryGroup_ANSWERGOUP_TIMEFRAME = '00000000-0000-0000-0000-000000101000';
  readonly HistoryGroup_ANSWERGOUP_FAMILY_DISEASE = '00000000-0000-0000-0000-000000104000';
  readonly HistoryGroup_ANSWERGOUP_REACTION = '00000000-0000-0000-0000-000000108000';

  // Question, Patient Takes No Medications
  readonly HistoryGroup_MedicationHistory_PatientTakesNoMeds = 'a9a4e0a0-443a-4734-8e3f-592abea0061b';

  //PQRI Related History Questions
  //Smoking
  readonly SocialHistory_TobaccoHistory = '00000000-0000-0000-0000-000000000008';
  readonly SocialHistory_TobaccoHistory_NeverSmoked = '00000000-0000-0000-0000-000000000047';

  readonly SocialHistory_TobaccoHistory_QuitSmoking5 = '00000000-0000-0000-0000-000000000051';
  readonly SocialHistory_TobaccoHistory_QuitSmoking10 = '00000000-0000-0000-0000-000000000052';
  readonly SocialHistory_TobaccoHistory_QuitSmoking10Plus = '00000000-0000-0000-0000-000000000053';
  // uses non smokeless tabacco
  readonly SocialHistory_TobaccoHistory_CurrentlySmokeless = '83569607-629e-4f31-a10b-af2a34326e1e';
  // smoking answer guids
  readonly SocialHistory_TobaccoHistory_CurrentlySmokes = '00000000-0000-0000-0000-000000000048';
  readonly SocialHistory_TobaccoHistory_EverydaySmoker = '292f21b1-9ca3-4ace-b5cd-fe73bf1b3a31';
  readonly SocialHistory_TobaccoHistory_SomeDaySmoker = '809d9f30-5e2c-4552-a7a9-a70e2eeb6031';
  readonly SocialHistory_TobaccoHistory_Smoker_StatusUnknown = '13d7e47e-1634-48cc-bb0c-5bf5675ca044';
  readonly SocialHistory_TobaccoHistory_HeavySmoker = 'f82a9884-3a7b-477e-a3cd-a30ab7afed42';
  readonly SocialHistory_TobaccoHistory_LightSmoker = '30daa871-dd29-44a1-a830-04150363421f';

  // Patient Communication Type
  readonly PatientCommunicationType_SmokingCessation = '8e30897b-39ac-456a-b8db-5f670b1ae009';
  readonly PatientCommunicationType_PhysicalActivity = '8b0798af-346d-4de5-abb9-2c29d59b8325';
  readonly PatientCommunicationType_Nutrition = '84b0c475-ef42-4988-b00a-d0b1d6e352fe';
  readonly PatientCommunicationType_Underweight = '84b0c475-ef42-4988-b00a-d0b1d6e35200';
  readonly PatientCommunicationType_Overweight = '84b0c475-ef42-4988-b00a-d0b1d6e35201';

  // Pregnancy CaseType UID
  readonly OBCaseTypeUid = '5bdc8794-20cb-4253-96af-0907e73b9185';
  readonly Disease_Pregnancy = '00000000-0000-0000-0000-000000101101';

  //  'special' uids for case modules
  readonly CaseTypeOBFlowsheet = '34157ce8-e296-4fdc-a060-e34de957022c';
  readonly CaseTypeOBEDD = '4b29d2ed-4983-4f4a-b16f-070659f6ac30';

  // eRx message type
  readonly eRxMessageType_new_rx = '9f19ed8c-3524-46ea-9f4b-b932d38f8585';
  readonly eRxMessageType_refill_request = '443d1a55-7384-495b-9bdf-15debd9379e2';
  readonly eRxMessageType_refill_response = '5151d647-729f-40f1-afe3-f0cdf31f12a7';
  readonly eRxMessageType_error_script = 'fbfe211c-18ba-4ddd-a5e2-817933847588';
  readonly eRxMessageType_status = '06d3f14d-3da4-4404-a45f-1b3294b487ed';
  readonly eRxMessageType_verify = '4cd98089-2475-4f86-a904-f0086cfc0b0b';
  readonly eRxMessageType_eligibity = '6b18c334-e4a6-4f82-a831-1aaa6cf6009e';
  readonly eRxMessageType_eligibility_response = '5506f18b-084c-489f-8891-89c76fb3dfab';
  readonly eRxMessageType_rx_history_request = '90db44ee-adea-427c-9450-b418f8577ade';
  readonly eRxMessageType_rx_history_response = '1a36a4a2-4b4f-4e49-8dc8-b6f13439fde3';
  readonly eRxMessageType_rx_cancel_request = 'c0bd8e17-1fcf-44b2-a1e8-99bb840e5591';
  readonly eRxMessageType_rx_cancel_response = 'f59bdcb7-8df9-4468-bbba-544415db133e';
  readonly eRxMessageType_rx_change_request = '2c259b74-444f-4f29-9634-5768dda9e572';
  readonly eRxMessageType_rx_change_response = '1c03a342-4fde-4dde-99cf-28bae7d1fa1a';
  readonly eRxMessageType_rx_formularyupdate = '4a412563-f3de-43f2-907e-3e6df8b20e90';
  readonly eRxMessageType_rx_formularylist = 'e76dcef2-7b7e-4640-b750-ea28768ebd2b';
  readonly eRxMessageType_rx_eprescribingactivityreportupdate = 'eff314ca-668b-4823-b206-366921a0d243';
  readonly eRxMessageType_pharmacy_update = '0ffde3e6-a37b-4885-a142-4430c7526fc3';
  readonly eRxMessageType_pharmacy_list = 'a68df433-597d-4e7c-800a-beb65397ff04';
  readonly eRxMessageType_prescriber_update = 'c0c8c0de-96f3-4efe-8db0-125d0b6dbe4f';
  readonly eRxMessageType_prescriber_list = 'f60e147c-81b2-4562-af18-a69e25ca2185';
  readonly eRxMessageType_inboundmessageroutingregistration = 'ce675e1b-3b82-4ec3-8d7b-3b1a027e42b4';
  readonly eRxMessageType_inbound = 'd375fb2c-454f-4175-a253-6a382028fb0a';
  readonly eRxMessageType_providerdirectory = 'be1a51c4-fa09-49fc-8c10-6e7f6f878b07';
  readonly eRxMessageType_formulary_download = 'a54ec9c9-ae68-4381-81fe-14cbff113e4e';
  readonly eRxMessageType_pharmacy_download = '93af01dc-c3a9-49d2-afb8-df6adb39d745';
  readonly eRxMessageType_prescriber_download = 'b0484716-ea09-4eb3-ba50-cbd352d4351f';

  // eRx status uid
  readonly eRxSentSuccessfulStatusUid = 'b1f7efaf-d56d-4bad-ae1f-8205e1d00b58';
  readonly eRxQueueForTransmissionStatusUid = '0605b6f6-2fe4-49cb-b868-9df448ab3e01';
  readonly eRxQueueForRxRefillAcceptUid = 'e2427516-e347-4586-af42-8c9a32872a78';
  readonly eRxControlledDrugStatusUid = '1a2ca5ed-297d-4973-858e-a79180565591';
  readonly eRxControlledDrugRequiresSignatureUid = 'e8b201fe-5540-4f47-bf0b-197331d054f3';
  readonly eRxUserDefinedDrugStatusUid = 'c5c9af90-92f8-479f-81d5-6a327d19cd1d';
  readonly eRxUnknownDrugClassStatusUid = 'e6378e41-2b44-4c76-923d-99994f749c1b';
  readonly eRxNoMailOrderBenefitUid = '369e0bf1-b361-4d27-9e6a-752f2b8c37a1';
  readonly eRxFailedValidationStatusUid = '86250938-b4de-4c85-9e12-5e132086b305';
  readonly eRxSentFailureStatusUid = '5f659708-d32c-4a81-af49-860883626304';
  readonly eRxProcessingErrorStatusUid = '5196d31c-360d-4eac-a294-05245a927f8c';
  readonly eRxProviderMappingErrorStatusUid = 'aa16051b-fdc9-4cf5-b1eb-32135baec80d';
  readonly eRxPatientMappingErrorStatusUid = 'c008a24b-37fd-44f8-b29b-70d141aaee72';

  // eRx Benefits download status.
  readonly eRxDownloadInProgressStatusUid = '09cdf8fb-8678-4084-805e-35d81e35534b';
  readonly eRxNoMedicationHistoryToDownloadStatusUid = 'bb189f74-f733-4552-a036-c305a33a69ba';
  readonly eRxDownloadCompletedWithErrorStatusUid = '378e7d05-06e5-41be-bd41-d1be5b6716f3';
  readonly eRxDownloadCompletedSuccessfulStatusUid = 'c20ca48a-f961-40f7-8eb6-e6f0ecbd1ef5';
  readonly eRxDownloadWaitForRXBenefitsResponsStatusUid = '4769f67c-c4ba-4e77-8fcc-82a967e4c887';

  // Jobs
  readonly JobScheduler = '10101010-0000-0000-0000-000000000101';
  readonly JobScheduledReports = '10101010-0000-0000-0000-000000000301';
  readonly JobScheduledERxMonitor = 'fbc47409-4dda-4457-bd3e-2c2666b45d98';
  readonly JobERAMessageProcessor = '0b5d9460-b33d-4968-a435-d5287c598c6b';
  readonly JobApplyERARules = 'dca52c7c-4d0b-4445-bf23-99a25ffe9719';
  readonly JobEligibility = '3dca8049-8df5-4b4f-a10f-fe6f54c0abad';

  // Job Time Intervals
  readonly JobTimeIntervalSeconds = '10101010-0000-0000-0000-000000000201';
  readonly JobTimeIntervalMinutes = '10101010-0000-0000-0000-000000000202';
  readonly JobTimeIntervalHourly = '10101010-0000-0000-0000-000000000203';
  readonly JobTimeIntervalDaily = '10101010-0000-0000-0000-000000000204';
  readonly JobTimeIntervalWeekly = '10101010-0000-0000-0000-000000000205';
  readonly JobTimeIntervalMonthly = '10101010-0000-0000-0000-000000000206';
  readonly JobTimeIntervalRunOnce = '10101010-0000-0000-0000-000000000207';

  // ListTimespan defaults
  readonly ListTimespanToday = 'f2e52f89-7f2c-466a-a878-459f82fa12c9';
  readonly ListTimespanLast2Weeks = '125f81d9-9055-4020-8927-9ec814b6c05d';
  readonly ListTimespanLast30Days = '95e07b97-aeab-46de-94ee-2674e419307b';
  readonly ListTimespanCustomDates = 'b657552d-872a-4e05-b76a-298754f56ecd';
  readonly ListTimespanCurrentWeek = '43570f5f-909d-4a76-9b9f-a0f293733fd6';
  readonly ListTimespanCurrentMonth = 'b35a144b-b91e-4207-8e26-f7c9fcd06645';
  readonly ListTimespanCurrentQuarter = '87212cb8-01b5-4e49-b206-a94111b7874a';
  readonly ListTimespanCurrentYear = '3863338d-160f-45d8-81c7-62738f3114d0';
  readonly ListTimespanLast2WeeksFromSunday = 'b3b927bf-fee9-4c44-bd0a-3e9587d34f69';
  readonly ListTimespanLast60Days = '11e8ceb9-308d-4fcb-9315-21c4f74214a7';
  readonly ListTimespanLast90Days = '4f614170-b9aa-4f08-a86e-25b509ed0c3e';
  readonly ListTimespanLast365Days = 'c90f9c3e-6a8e-4e2a-8afe-829094df0366';

  // ClaimFormatType
  readonly ClaimFormatElectronic837 = '0baf0b1a-48e6-403a-8981-2b58618dfc07';
  readonly ClaimFormatElectronic837I = '8d4cce55-2211-4ecf-8b01-f996cb5a0cfc';

  // ClaimFormatValueTypeUid
  readonly UPINClaimFormatValueTypeUid = 'be3aae71-5565-4edc-a166-3d7b688548b9';
  readonly DEAClaimFormatValueTypeUid = '018e7542-5de7-405e-9f4a-ee4bffde68d4';
  readonly NPIClaimFormatValueTypeUid = 'ce282f4e-a866-49ba-9636-c4ba0439e907';
  readonly SPIClaimFormatValueTypeUid = '87197e82-1f35-467a-ba37-0d8cf3de04d5';
  readonly CTPClaimFormatValueTypeUid = 'a337b06f-b4e2-4e8b-a439-faa973de3ca5';
  readonly MedicalLicenseNumberClaimFormatValueTypeUid = '2e7c5879-b7dc-4c2f-9e1c-ad157c561c3b';
  readonly StateLicenseNumberClaimFormatValueTypeUid = '37b7ea5e-1e98-46c0-916b-766ab518e99e';
  readonly SSNClaimFormatValueTypeUid = '897ff8b5-5c4f-4289-83eb-d75cdbedc510';
  readonly MedicareProviderIDClaimFormatValueTypeUid = 'b339708b-7b70-432d-8452-f2e199b46a01';
  readonly MedicaidProviderIDClaimFormatValueTypeUid = '33d096f9-ce6d-415a-bf57-9d2aaa5d2b2e';
  readonly PriorAuthorizationClaimFormatValueTypeUid = 'e8654441-940f-4d57-a0ee-f36d13c860b2';
  readonly FileIDClaimFormatValueTypeUid = 'a197989d-4200-4ea6-9e6e-7e3ab6f5f462';
  readonly PPONumberClaimFormatValueTypeUid = '783c246f-b633-4d8a-9cfc-7cd02bf4293c';
  readonly PayerIdentificationNumberClaimFormatValueTypeUid = '1a84232e-dcfd-43bf-8352-0afd0af79f47';
  readonly RxHubParticipantIDClaimFormatValueTypeUid = '4fec0a5f-2412-4408-97ad-d0ea3ad512b8';
  readonly SurescriptsStateLicenseNumberClaimFormatValueTypeUid = '846b9878-70d2-4daf-af6d-451f416f67ea';

  //SC 06/18/08 - may not need
  readonly ProcedureClaimFormatValueTypeUid = '024c64d6-9f63-471b-891f-2e777515668d';

  // ClaimFormatValueTypeTypeUid
  readonly ClaimFormatValueTypeTypeFilingTypeGroupIndividual = '4bbec2af-efee-49e5-8a55-96f837146061';
  readonly ClaimFormatValueTypeTypeClaimValueFormat = '205448ce-1aba-4273-b7bd-b4017e789d0c';
  readonly ClaimFormatValueTypeTypeCodeBasedClaimValueFormat = 'd64d9c6e-ad54-4f27-b5bf-4fb8bbd84412';
  readonly ClaimFormatValueTypeTypePaperBillingAsRef = '5f392cc6-ecc7-45be-9e4c-598ed4b36f8b'; //added for new (modified) ClaimFormat setting (Paper)
  readonly LabUse_BillingProvider = '83a25a0d-bd39-42ed-8324-12670f1d17c6'; //added for new (modified)listclaimformatvaluetype setting

  readonly ClaimFormatValueTypeTypeLabUseBillingProvider = 'aa380c38-3091-44ff-9675-bf8ba2088c7e'; //added for new ClaimFormat setting
  readonly ClaimFormatOptionType2310A = 'fff19d24-8250-4355-9b4b-60333eaef3e4'; //added for new listclaimformatvaluetype setting
  readonly ClaimFormatOptionTypeEPSDT = 'fe1d5047-6059-4051-b041-08c33031bb9f'; //added for new listclaimformatvaluetype setting
  //SC 06/18/08
  readonly ClaimFormatValueTypeTypeProcedureOverrideFormatValue = 'ae114249-40bc-49dd-a0e6-702fe4b7aab1';
  //SC 07/14/08
  readonly ClaimFormatValueTypeTypeTypeOfBillOverrideFormatValue = 'cf54a488-7a3c-473f-8cb7-8fc255899d9f';
  readonly ClaimFormatValueTypeTypeBox29Type = '81fe128f-2c86-42e6-83e1-f44a3edeebaf'; // Box 29 Type

  // ClaimFormatValueType ListMiscUid
  readonly MiscPracticeUid = '39035923-68fd-4276-8616-b027919d7840';
  readonly MiscProviderUid = '2cc009f6-7e06-4197-9b7c-9bb7893f9669';
  readonly MiscServiceSiteUid = '3eea5d17-2d86-4eaf-ab26-cb9016e175b8';
  readonly MiscFinancialCenterUid = '284a3ec8-a39a-4178-aade-e20c6829708b';
  readonly MiscMedicalServicesProviderUid = '4f49b11d-ced0-4e8d-9439-9113eac60b47';
  //SC 06/18/08
  readonly MiscProcedureOwnerUid = 'ebff0a0d-3a79-4e50-b15d-71a998a7e6e0';

  // ListAttachmentType
  readonly AttachmentTypePatientIndication = '8f6c46b0-f17b-41ad-8215-3fb3d116a9bb';
  readonly AttachmentTypeScheduledReport = '10101011-0000-0000-0000-000000000100';
  readonly AttachmentTypeDeposit = '78d03a12-29a8-46a2-8a72-0a31efeee2db';
  readonly AttachmentTypeGeneratedDocument = 'afecb3ab-f316-41b7-884a-94d441103b9a';
  readonly AttachmentTypeAdvancedDirective = '10101011-0000-0000-0000-000000000101';
  readonly AttachmentTypeExternalPatient = '7ef83ec9-cbde-4074-b9fd-bca3e6aac463';
  readonly AttachmentTypeLabResults = 'af6e749e-fbd9-40c8-98f4-09d1d0a5e04e';
  readonly AttachmentTypeAssessmentForms = '10101011-0000-0000-0000-000000000102';
  readonly AttachmentTypeScannedChart = '39981fac-3c26-45a8-b3ee-ea754692d17a';
  readonly AttachmentTypeDirectMail = '91293e86-6aa1-4b30-aa00-7ddd42135d64';
  readonly AttachmentTypeCareManagement = '6ff4a1f0-d9b3-4283-b109-ba492b172c86';
  readonly AttachmentTypeStatement = '3bd598e3-1b14-4b4d-9a0c-49fd31a32edb';

  // Financial Center Data SecurityUid
  readonly FinancialCenterDataSecurityUid = 'efa3bef1-b522-4e9a-ae74-4645851cfbf6';

  readonly BatchSecurityUid = '2bbd5b04-9549-4ef2-b321-489fa93a2324';
  readonly BatchOwnerDataSecurityUid = '6dc8e244-75b4-43a0-9eae-6ee2c7b372cc';

  readonly BatchProcessTypeSecurityUid = 'bfb6c86d-ec0e-435a-a10e-ee8f1f75bf5d';

  // Claim Clearinghouse
  readonly ClaimClearingHousePracticeInsightUid = 'b58b4c69-907d-4ab1-9945-0e4cd53df510';

  // PharmacyClearinghouse Data SecurityUid
  readonly PharmacyClearinghouseMedAvantUid = '371decbd-66d4-4c4f-8dbd-110c00098169';
  readonly PharmacyClearinghouseSureScriptsUid = '118422aa-c165-4a2e-8389-e5b7e89636ea';
  readonly PharmacyClearinghouseFaxUid = 'e5a59c09-a77d-4c08-953d-d92ce1b1135c';

  // eRxWS pharmacy clearing house.
  readonly eRxWSSureScripts = '118422aa-c165-4a2e-8389-e5b7e89636ea';

  // eRxWS job
  readonly eRxWSJobSendMessages = '2438b6b4-6d85-4f8f-91ce-7b26b094e353';
  readonly eRxWSJobPharmacyDirectoryDownloadNightly = 'd2c7de64-1463-4b04-ac6e-0c2cd67bc781';
  readonly eRxWSJobMonitor = '923d2ab4-f0df-47e8-b0c3-42c5f0887554';
  readonly eRxWSJobPharmacyDirectoryDownloadFull = '902cb485-3f44-467d-877a-81c2269eb477';
  readonly eRxWSJobFormularyDirectoryDownload = 'cc2aa760-702e-45f6-abe9-12ce88521744';
  readonly eRxWSJobProcessFormularyDirectory = 'f3bcc7fa-ae86-4954-8097-f637a6a0315c';
  readonly eRxWSJobePrescribingActivityReportUpload = '6a887b40-3446-4c61-a570-10c01a2bcdf5';
  readonly eRxWSJobGenerateePrescribingActivityReport = '20fe8b8f-5dce-4701-9fc4-5fbc4d4ba858';
  readonly eRxWSJobPRVBRD = 'fd66ccc4-10ec-44e6-b4ed-8cef32d1f24b';
  readonly eRxWSJobPrescriberDirectoryDownloadFull = '8c1bb635-7efe-4087-a5b4-667196f0565e';
  readonly eRxWSJobPrescriberDirectoryDownloadNightly = '2a109586-c975-4eb0-a0c4-359ef7b58f0d';

  //eRxWSJobDownload_FormularyAlternative = eRxWSMessageType_Download_FormularyAlternative;
  readonly eRxWSJobProcess_FormularyAlternative = 'dca8121e-06c2-4e0a-9aa4-02cd672691e1';

  //eRxWSJobDownload_FormularyCopay = eRxWSMessageType_Download_FormularyCopay;
  readonly eRxWSJobProcess_FormularyCopay = 'ecb1c118-bafe-4b65-b6b3-397147bb4be6';

  //eRxWSJobDownload_FormularyCoverage = eRxWSMessageType_Download_FormularyCoverage;
  readonly eRxWSJobProcess_FormularyCoverage = 'fb64ceec-908b-4410-95df-7a83b0a9db66';

  //eRxWSJob_Download_FormularyStatus = eRxWSMessageType_Download_FormularyStatus;
  readonly eRxWSJob_Process_FormularyStatus = 'ca4762f2-4f23-469a-b9a9-0b5de63e2141';

  // eRxWSMessageType
  readonly eRxWSMessageType_refill_request = '443d1a55-7384-495b-9bdf-15debd9379e2';
  readonly eRxWSMessageType_pharmacy_update = '0ffde3e6-a37b-4885-a142-4430c7526fc3';
  readonly eRxWSMessageType_provider_update = '83e4ce75-831d-42ff-a8be-5c192a358bba';
  readonly eRxWSMessageType_error_prvbrd = 'eccf1422-6127-4dd5-9897-44585f612c84';
  readonly eRxWSMessageType_error_script = 'fbfe211c-18ba-4ddd-a5e2-817933847588';
  readonly eRxWSMessageType_new_rx = '9f19ed8c-3524-46ea-9f4b-b932d38f8585';
  readonly eRxWSMessageType_pharmacy_list = 'a68df433-597d-4e7c-800a-beb65397ff04';
  readonly eRxWSMessageType_provider_list = '69f6dff1-210e-4258-b246-b50556ff2e0a';
  readonly eRxWSMessageType_refill_response = '5151d647-729f-40f1-afe3-f0cdf31f12a7';
  readonly eRxWSMessageType_Download_Pharmacy_File_Nightly = '9629d151-c4ba-4882-8ed8-6ec143827c30';
  readonly eRxWSMessageType_Download_Pharmacy_File_Full = '3f6c999f-dc86-472d-9f0c-5c0d90675a58';
  readonly eRxWSMessageType_Process_Pharmacy_File_Nightly = '25c70185-07db-47c2-b567-b16de01c2655';
  readonly eRxWSMessageType_Process_Pharmacy_File_Full = 'ed7c8aab-0bb8-4cee-b6d5-23fba39fe3d9';
  readonly eRxWSMessageType_formulary_file_list = '68f79b7e-6d5b-4574-ac4f-17c9f8077cf8';
  readonly eRxWSMessageType_ePrescribingActivityReport = '2e84582e-5947-4a5a-8ec8-c000853796d0';

  readonly eRxWSMessageType_Download_FormularyAlternative = '1543e471-f8d1-4fc2-adb8-15db5958fed3';

  readonly eRxWSMessageType_Download_FormularyCopay = 'dd3851b6-9a93-4b06-b6dc-378af8616475';

  readonly eRxWSMessageType_Download_FormularyCoverage = 'fb64ceec-908b-4410-95df-7a83b0a9db66';

  readonly eRxWSMessageType_Download_FormularyStatus = '8230406d-7e9c-4a2f-948f-4142ac629fe2';

  // Security
  readonly MessageSecurityUid = '10101010-0000-0000-0000-100000000100';
  readonly ListFindTable_Users = 'ec4c281c-12b3-4ca7-8269-7171ad5d8f94';
  readonly ListFindTable_UserSetting = '4dad8226-6097-4892-9d3f-fe5e1e346f42';
  readonly Security_GeneralNoteTypeNotes = '387c1f8e-69aa-4144-85dd-fc7e922ec9c4';

  //  ListReferralTrackingStatus
  readonly ListReferralTrackingStatusInitiated = 'fc29eadd-fa10-4430-b6e5-7328f5b74c62';
  readonly ListReferralTrackingStatusRelationship = 'cac39e49-14e8-4045-bc1e-d051c33ae887';

  // ListInstantMessageStatus
  readonly ListInstantMessageStatusBusy = 'fefb6c5e-d476-432c-ba09-5009b38e5ccc';
  readonly ListInstantMessageStatusHidden = '5004e771-4ffd-4095-9ed2-be38e690b70d';

  // ListRelationship
  readonly Relationship_Self = '7b4c63c7-fe13-48ef-81e5-3afc963f3fee';
  readonly Relationship_Employee = 'fda2b6c2-fb40-416b-bfc8-73118827f8f0';
  readonly Relationship_Spouse = '9863d8b0-e698-4cea-8a3f-b4e1c7e6d094';
  readonly Relationship_Significant = '9b1f93b8-7611-4702-85ad-9ee0b7b99e57';
  readonly Relationship_Mother = 'cc4a41ef-5881-4f77-8a8c-330ebe75556d';
  readonly Relationship_Father = '111a79d8-3641-48dc-b47c-a8f8b374bf68';
  readonly Relationship_Child = '719bed52-7ec2-4240-aa02-00d073fda8c7';
  readonly Relationship_Unknown = '11eadee6-52dc-4fe1-bfd4-e1c03abaaa51';
  readonly Relationship_OtherAdult = 'bfe52efa-e4c2-4ec3-9f20-9637691fbe38';
  readonly Relationship_GuardianUid = '96118232-da86-41a3-9e99-0a7dc9c3ae26';
  readonly Relationship_CareGiverUid = '59aa0df9-3346-4e54-a3b1-3659d5cecaaa';

  // PQRI uids
  readonly PQRIGroupMedicare = 'bf5e56e0-187f-427a-a7ad-2d5eebeb0b43';
  readonly PQRIOperatorOR = '121dce7d-6aed-4974-b72e-0cb0aadacf5c';
  readonly PQRIOperatorAND = '184449fa-bc7c-43c8-9e84-58fb3cdf9261';
  readonly PQRIOperatorNOTOR = '93e9e5b7-e171-47b0-88b8-d9c22469b23d';
  readonly PQRIOperatorNOTAND = '6e1d0d18-ad47-4970-81e1-6c0323821304';
  readonly PQRICodeTypeICD9 = 'a057050d-e0c8-4727-a9e9-86fe9a05b5f9';
  readonly PQRICodeTypeEM = 'da5185e3-539e-4200-9e7a-7133115562d1';
  readonly PQRICodeTypeCPT = '13bfb012-d2af-4d5c-b3a4-8d288992c696';
  readonly PQRICodeTypeCPTII = 'ca39b7a8-2b88-467a-8293-7e76b527e44e';

  // PQRI known measures
  readonly PQRIMeasure125Adoption_e_Prescribing = 'ff13c3e2-b72b-41b8-b483-ed8a41be3a2a';
  readonly PQRIMeasure124Adoption_EMR = 'ce2eb02f-9f13-43fe-913b-b9747f265f25';
  readonly PQRIMeasure114TobaccoUse = 'b4df7b7b-f0be-4791-b0e0-04e60b362eb0';
  readonly PQRIMeasure115QuitSmoking = '14b51863-8ad7-460a-9db7-8cd576f740a0';
  readonly PQRIMeasure116InappropriateAntibiotic = '0bd7ca31-3380-41e0-a9b1-2816fbf553d4';
  readonly PQRIMeasure128BMI = '3263ff24-207b-4423-8adb-19cbfefae3a3';
  readonly PQRIMeasure130CurrentMedication = '361c7e72-00e1-421a-83d4-38c8561ae42e';
  readonly PQRIMeasure142Osteoarthritis = 'be0a135a-91ff-46c1-b6b9-f450ba6e5cd6';

  readonly PQRINumerator_130_NotDocumented_NotEligible_G8430 = '445f3804-cc10-4653-a64e-5a544d07ef41';
  readonly PQRINumerator_130_Documented_NotVerified_G8428 = '9f9fbe2f-0907-4e49-9885-235b9a052811';
  readonly PQRINumerator_130_NotDocumented_G8429 = '1621c1f1-f77c-4a0b-a784-be9dc348259b';
  readonly PQRINumerator_130_Documented_Verified_G8427 = '7216fad4-218f-4042-adad-73ae0d21dd30';

  readonly PQRINumerator_114_TobaccoUseNotAssessed_1000F_8P = '3d971193-17d6-4443-85de-894b1ef6b713';

  readonly PQRINumerator_115_Smokeless_G8456 = '1f26313b-d3fa-4add-a789-e77ec22d2346';
  readonly PQRINumerator_115_NonSmoker_G8457 = '17d1fc9e-82f8-4f80-b7de-a6b86ec7d1c1';

  readonly PQRINumerator_128_BMI_Normal_G8420 = '33db8be1-623b-40f1-be34-044b2514d75c';
  readonly PQRINumerator_128_BMI_Overweight_G8417 = '60c61fce-db9e-4549-882c-71c43eb6871f';
  readonly PQRINumerator_128_BMI_Underweight_G8418 = 'a5aa8faa-ddb4-4079-af5d-4272cc38db79';
  readonly PQRINumerator_128_BMI_NotEligible_G8422 = 'ff0e3c89-22aa-481f-830b-09bbcf7ecbad';
  readonly PQRINumerator_128_BMI_NotPerformed_G8421 = '51119e92-f3fd-4be2-9480-7623abd880d5';
  readonly PQRINumerator_128_BMI_OverUnderWeight_NoEducation_G8419 = '17cb9df2-88ca-4c48-8132-65083aabe660';

  readonly PQRINumerator_142_OST_AntiInflam_Assessed_1007F = '2258d5fb-1505-43d2-9610-1970db8f2558';
  readonly PQRINumerator_142_OST_AntiInflam_NotAssessed_1007F_8P = '617b87f3-9100-4989-885f-921541bdeffa';

  // PQRI known numerator used in E-Prescribing Incentive Program
  readonly PQRINumerator_ERX_Incentive_G8553 = 'fc128494-7632-4b64-84f4-7dedae62d77d';
  // no longer used 2010
  //PQRINumerator_ERX_NoPrescription_G8445 = 'b5539110-4dec-4ff2-9395-050e208e1672';
  //PQRINumerator_ERX_AlleRx_G8443 = '3ad9b779-989e-4c4c-b832-17c59d6bd32d';
  //PQRINumerator_ERX_SomeNonERx_G8446 = 'a5e1539d-9790-4220-a6d5-d6c89e4e4aab';

  // DesktoControl uids
  readonly DesktopControl_Calendar = '10101010-0000-0000-1000-000000000001';
  readonly DesktopControl_Waitlist = '10101010-0000-0000-1000-000000000002';
  readonly DesktopControl_Message = '10101010-0000-0000-1000-000000000003';
  readonly DesktopControl_Note = '10101010-0000-0000-1000-000000000004';

  // AddIn Types Uids
  readonly AddInType_Acquisition_Attachment = '10101010-0000-0000-1001-000000000001';
  readonly AddInType_Acquisition_Vitals = '10101010-0000-0000-1001-000000000002';
  readonly AddInType_PatientDemographics = '10101010-0000-0000-1001-000000000003';
  readonly AddInType_FNC = '10101010-0000-0000-1001-000000000004';
  readonly AddInType_Schedule = '10101010-0000-0000-1001-000000000005';
  readonly AddInType_Messaging = '10101010-0000-0000-1001-000000000006';
  readonly AddInType_PatientToolstrip = '10101010-0000-0000-1001-000000000007';
  readonly AddInType_Desktop = '10101010-0000-0000-1001-000000000008';
  readonly AddInType_CreditCardReader = '10101010-0000-0000-1001-000000000009';
  readonly AddInType_FNC_Slider = '10101010-0000-0000-1001-000000000010';
  readonly AddInType_FNC_SPSO = '10101010-0000-0000-1001-000000000011';
  readonly AddInType_AppointmentTypeFilter = '10101010-0000-0000-1001-000000000012';
  readonly AddInType_SuperbillChargeProcessor = '10101010-0000-0000-1001-000000000013';
  readonly AddInType_Find = '10101010-0000-0000-1001-000000000014';
  readonly AddInType_Find_Background = '10101010-0000-0000-1001-000000000015';
  readonly AddInType_SaveProcessor = '10101010-0000-0000-1001-000000000016';
  readonly AddInType_ServerObjectProcessor = '10101010-0000-0000-1001-000000000017';
  readonly AddInType_BillingRule = '10101010-0000-0000-1001-000000000018';
  readonly AddInType_CombinedServices = '10101010-0000-0000-1001-000000000019';
  readonly AddInType_OrderObservation = 'abeddd4f-b031-4a88-ae39-bfd56f74359c';

  // Compression
  readonly Compression_Zip = '10101011-0000-0000-1001-000000000001';

  // ERA Status
  readonly EraStatus_Error = '8b694843-f568-4097-b862-e4c63487881c';

// ERA Codes ( frequently used )
  readonly ERA_GroupCode_CO = '55ba9568-1c8a-4a6d-a919-f11ce4cc9be5'; // CO
  readonly ERA_ReasonCode_45 = 'd16c8f44-ee79-4f01-ae2f-fdf83d3e048f'; // 45

  // MessageUrgency
  readonly MessageUrgency_Abnormal = '10101010-0000-0000-1000-100000000004';
  readonly MessageUrgency_Urgent = '623496d7-f48e-4ddb-8983-827cacee6901';
  readonly MessageUrgency_LowPriority = '2afc12c6-f24f-422d-800f-bfe534373f3f';
  readonly MessageUrgency_Routine = 'c2a64d38-3be1-4f8a-8947-ef9e9ad861a4';

  // ListCreditType
  readonly ListCreditType_Transfer = '0274c6df-47fb-402e-b19c-ca660b05fce1';
  readonly ListCreditType_Transfer_Adjustment = '3709199d-2a5a-49ed-b9ba-7272627c9ea6';
  readonly ListCreditType_Remark = 'ee8f34fe-4cbe-4f71-9f48-5907b7ff5820';

  readonly ListCreditType_ContractualInsuranceAdjustmentType = 'f8690cac-f9f8-45f6-8782-61246386d480';
  readonly ListCreditType_InsuranceAdjustmentType = '7f839eb0-406b-4ae2-94eb-a1eee171f331';
  readonly ListCreditType_CarrierTransferType = '046ac40d-c58d-4732-8399-266851b9f29d';
  readonly ListCreditType_PatientPaymentType = 'bbfdc458-cac6-4598-8e9c-660696b95e11';
  readonly ListCreditType_InsPaymentType = '83daa0c5-0888-4e57-8ce2-9888f8054889';
  readonly ListCreditType_PatientTransferType = '0274c6df-47fb-402e-b19c-ca660b05fce1';

  // List gender uids
  readonly ListGenderFemale = 'e5ded511-ee8a-4b47-aa68-8ff9f74741a1';
  readonly ListGenderMale = '8202fcb6-c28d-4985-9fc4-0c92e35c2dfd';
  readonly ListGenderUnknown = '9ef147f5-0265-4611-a9c7-d4005d306fd6';

  // Provider Care Types
  readonly ProviderCareTypePrimaryCare = '37e9f42d-9e10-45db-bcdc-cc86d1d4ff90';
  readonly ProviderCareTypeReferring = '9ed1cc47-1e90-41c1-81e4-16110078f9fe';

  // FTP uids
  readonly FtpProtocol_FTP = '11a82019-6756-481d-8491-d20fb4897a59';
  readonly FtpProtocol_FTPSSL = 'b418682d-f146-439d-9e9b-8111d8aa2fd4';
  readonly FtpProtocol_SFTP = '893b891e-ec44-4284-92b2-ccd2102c4cfb';

  // Procedure Code Types
  readonly ProcedureCodeType_CPT = '00000000-0000-0000-0000-000000000001';
  readonly ProcedureCodeType_HCPCS = '00000000-0000-0000-0000-000000000002';
  readonly ProcedureCodeType_LAB = '00000000-0000-0000-0000-000000000003';
  readonly ProcedureCodeType_ProcedureSet = '66164f99-1142-4f7e-8878-83e600fc6ded';
  readonly ProcedureCodeType_PQRI = '0852e87a-a5e0-43b2-808a-c6152251a4cd';
  readonly ProcedureCodeType_CarePlan = 'b4350da1-2202-4566-bac3-60f3d55e312d';
  readonly ProcedureCodeType_RiskAssessment = '4b45bbe4-31c1-4b2d-8df0-67960b9419c9';
  readonly ProcedureCodeType_Lab = 'ed8e7b10-9769-4d4d-9816-1117a95af96a';
  readonly ProcedureCodeType_Radiology = '7b04148e-4902-496b-b027-f0ee5038d157';
  readonly ProcedureCodeType_Referral = '6405ca7e-6739-4cd0-9b12-5350e769ee98';

  // AddIns
  readonly AddIn_CSSNDriverLicense = '10101010-0000-0000-0000-110000000001';
  readonly AddIn_CSSNInsuranceCard = '10101010-0000-0000-0000-110000000002';
  readonly AddIn_ICSDriverLicense = '10101010-0000-0000-0000-110000000003';
  readonly AddIn_ICSInsuranceCard = '10101010-0000-0000-0000-110000000004';
  readonly AddIn_ICS6DriverLicense = '10101010-0000-0000-0000-110000000005';
  readonly AddIn_ICS6InsuranceCard = '10101010-0000-0000-0000-110000000006';
  readonly AddIn_ExistingImage = 'ad312d6f-cd0c-4956-9652-3d12e73ae0ef';
  readonly AddIn_ConsentForm = 'a9aa31c9-be6e-4cb5-905a-ce04da7cb90e';
  readonly AddIn_TopazSignature = 'a4935c4a-dd00-4666-a820-0fdced9bf6ce';
  readonly AddIn_BillTimeRule = 'f33c7695-0233-4bd6-9600-4efa1340b34f';
  readonly AddIn_FeeScheduleRule = '2d90ff99-d43d-442e-a3cd-bfa730d704af';
  readonly AddIn_CombinedServices = '7c3967d2-ac2b-4783-ae2a-c7091022bf24';
  // fix for scenario #34930
  readonly AddIn_PDRNetwork = 'b0273b46-5983-43f2-9b28-432ab9546c8d';
  // fix for scenario #36161
  readonly AddIn_ePASurescripts = '1353fbb7-ed52-4cf7-8665-fb35d6d60497';
  readonly AddIn_VitalBMI = 'ccf82708-f120-43ff-97fc-a1299eef462e';
  readonly AddIn_VitalBP = 'a6425135-9530-4078-a4f3-eecc68358b1e';

  // KDB
  readonly KDB_MFU = 'ffffffff-0000-0000-0000-000000000001';
  readonly KDB_PATIENT_MFU = 'ffffffff-0000-0000-0000-000000000002';
  readonly KDB_DASP = 'ffffffff-0000-0000-0000-000000000003';
  readonly KDB_DESP = 'ffffffff-0000-0000-0000-000000000004';
  readonly KDB_CPP = 'ffffffff-0000-0000-0000-000000000005';
  readonly KDB_ADDIN = 'ffffffff-0000-0000-0000-000000000006';
  readonly KDB_ALL = 'ffffffff-0000-0000-0000-000000000099';

  // ContextReportType
  readonly CONTEXTREPORTTYPE_PATIENT = '10101010-0000-0000-0001-110000000001';
  readonly CONTEXTREPORTTYPE_VISIT = '10101010-0000-0000-0001-110000000002';
  readonly CONTEXTREPORTTYPE_SUPERBILL = '10101010-0000-0000-0001-110000000003';
  readonly CONTEXTREPORTTYPE_APPOINTMENT = '10101010-0000-0000-0001-110000000004';

  // UserGroup Type
  readonly USERGROUPTYPE_CARETEAM = '10101010-0000-0000-0000-000000000001';
  readonly USERGROUPTYPE_AUTHORIZATION = '10101010-0000-0000-0000-000000000002';
  readonly USERGROUPTYPE_SECURITY = '10101010-0000-0000-0001-110000000003';

  // UserGroup
  readonly USERGROUP_CLINICALADMINISTRATORS = '00000101-0000-0000-0000-000000000001';
  readonly USERGROUP_FINANCIALADMINISTRATORS = '00000101-0000-0000-0000-000000000002';
  readonly USERGROUP_ADMINISTRATORS = '00000101-0000-0000-0000-000000000003';
  readonly USERGROUP_PHYSICIANS = '00000101-0000-0000-0000-000000000004';
  readonly USERGROUP_NURSES = '00000101-0000-0000-0000-000000000005';
  readonly USERGROUP_REGISTRATION = '00000101-0000-0000-0000-000000000006';
  readonly USERGROUP_PAYMENTPOSTERS = '00000101-0000-0000-0000-000000000007';

  // medication units - guid are from table "ListMedicationUnit"
  readonly medicationUnitTablets = '944ae82b-8244-4553-9fdd-328fffe901c7';
  readonly medicationUnitCapsules = 'fc42ebe7-5c16-4f91-aa73-b6bc48b0a4aa';
  readonly medicationUnitDrops = '768585b4-71ba-4045-8e9d-77a972e8597a';
  readonly medicationUnitUnits = '513bc21a-b348-43ea-a8b4-276d5e550f1e';
  readonly medicationUnitMilliliters = 'e9b15f0d-cb8f-4364-ac09-b86301b4d585';
  readonly medicationUnitGrams = '495c5e93-5c82-44bf-8ac0-423d83a29280';
  readonly medicationUnitTeaspoons = '9aaba096-f0b5-4f4d-acdb-bce2b131bc22';
  readonly medicationUnitTablespoons = '328bf7c1-fa26-4229-81cf-9cf5b0c019a6';
  readonly medicationUnitOz = 'cb31a6dc-e156-4f5b-9119-5f6d53abcfb0';
  readonly medicationUnitMilligrams = 'd362abc2-4661-4703-80e8-0d506937274c';
  readonly medicationUnitMicrograms = '708613a7-6ac4-4027-8dc9-aba237f8c5ca';
  readonly medicationUnitSpray = 'ce49ba3e-d8c8-4e14-a1e3-7a9b24eb14c8';
  readonly medicationUnitPuffs = '0aba7d84-b35e-4eee-b34b-ac3b9244c2d6';

  // medication discontinue reasons
  readonly DiscontinueReason_DC = 'be13824c-b025-46b2-931c-378c00585ef7';
  readonly DiscontinueReason_Refilled = '8f132a93-e4a7-4b45-bdbe-ac68e63b1ed4';
  readonly DiscontinueReason_Deleted = 'e322d823-f96d-4070-af3b-aab21b21e6a9';
  readonly DiscontinueReason_Unspecified = 'fe706b0e-9df7-485b-b22b-cefc59192c62';
  readonly DiscontinueReason_Reaction = '106bff92-868b-4ad6-a1ea-d26b3e71ffd8';
  // fixed bug #23281
  readonly DiscontinueReason_Revised = '0587b29a-c354-432b-8b41-f34f7916ef1f';

  // FindTableUid
  readonly FINDTABLE_RELCCSYSTEMSYMPTOM = '9c4ca244-69d6-4faf-a16f-60a7645b577c';
  readonly FINDTABLE_DIAGNOSIS = '3b12df01-5df4-44a2-a16b-ba0bc5dd459a';
  readonly FINDTABLE_SO = '21c60022-225f-4ffe-ae7c-b0b3863cfbcf';
  readonly FINDTABLE_SP = '22c60022-225f-4ffe-ae7c-b0b3863cfbcf';
  readonly FINDTABLE_MEDICATION = 'd3e281ce-6db6-4a06-8b1c-7c869041cbdf';
  readonly FINDTABLE_PATIENT = '034f9d24-92ec-4ad5-9b08-dab99d030bc3';
  readonly FINDTABLE_GENERALNOTETYPE = 'c6d90dd0-8d75-4dda-ad40-b661dff8fd09';
  readonly FINDTABLE_LISTASSESSMENTFORM = '632770df-5a4e-4859-b890-50f32e309e67';
  readonly FINDTABLE_LISTLABORATORY = '91716d23-9fed-4e35-8169-2ff1b4360c96';
  readonly FINDTABLE_FINDVITALSOBSERVATION = 'a01cfadf-d7db-4aa6-af55-f130825e6c1a';
  readonly FINDTABLE_WAITINGKLISTDETAIL = '59723a32-10b5-4185-a1ad-f2dbdfd2a27f';
  //FINDTABLE_APPOINTMENTCLIPBOARD = '39b3af2a-0841-49aa-8e4a-b99a655ae80b';

  // PhoneTypes
  readonly PhoneType_Work = '01443a53-7345-4327-a43e-9cc7cf82c98c';
  readonly PhoneType_Fax = '48af7137-1791-48e4-b338-3dd0a7419073';
  readonly PhoneType_Home = '07044f79-126c-4bf5-8a91-5ab4b36ac99a';
  readonly PhoneType_Cell = '13248051-ef12-451e-b27f-71a38ee4c3dc';
  readonly PhoneType_Pager = '4f6d1328-7e5c-4b1b-b3b1-c06e24d0654a';
  readonly PhoneType_Rx = 'f1e28b28-4c23-4f56-ab4f-e03b10c045cc';
  readonly PhoneType_Other = '8f7fc1ab-054d-4c5f-a130-d2a63d9d0a26';
  readonly PhoneType_Night = 'ee7ce61f-24e7-4e15-a500-a8d0ae470c71';
  readonly PhoneType_Office = 'ebb01f3d-6431-4d3d-9f51-62d8efa6ae66';

  //ReimbursementRuleUid for Quest ABN generation
  readonly ReimbursementRuleUidForQuestABN = '4bfe0401-785b-4b00-88ea-974b6e79f432';
  readonly LaboratoryUidQuest = '4524b6e9-2be4-4e55-9389-514159a6f995';

  // Clinical Note Types
  readonly ClinicalNoteType_FNC = '10101010-1111-0000-0000-000000000001';
  readonly ClinicalNoteType_SBC = '10101010-1111-0000-0000-000000000002';
  readonly ClinicalNoteType_Order = '10101010-1111-0000-0000-000000000003';
  readonly ClinicalNoteType_General = '10101010-1111-0000-0000-000000000004';

  // Clinical Note Controls
  readonly ClinicalNoteControl_SP = '5db71e56-0da7-4480-a277-e4f796cbff67';

  //  Visit Types
  // PLEASE NOTE:
  //  When adding new visit types the job "PQRIHistoryUpdate" needs to be review; the new visit type may need to be skipped by the job.
  readonly VisitType_StrikeOut = 'fb954167-9d1c-43b7-9f5b-a76260654c96';
  readonly VisitType_Private = 'dddb34a1-f886-49e3-ad75-e267fafcb1c4';
  readonly VisitType_Refill = 'ada7eb5d-fd9a-4e9f-88e5-84edda9efc42';
  readonly VisitType_Lab = '6a18a657-b912-4c12-828e-a3df8571d205';
  readonly VisitType_CancelRX = '7e9ca276-3445-4dfc-9e6c-972b23d34229';
  // fixed bug #23281
  readonly VisitType_RxChange = '4bca0ee2-7f2f-4a2e-ad74-834c31af92c5';

  readonly VisitDiagnosisType_Differential = '240f7492-a04c-4f57-a2b0-58ef7ab57ff0';
  readonly VisitDiagnosisType_PreOp = 'fcdc0aad-94fe-484e-9202-933606eaf421';
  readonly VisitDiagnosisType_PostOp = 'f004794f-c9fb-4c62-98e2-9594bcba1f8d';

  readonly VisitDiagnosisAxisType_One = 'c60524b9-33a6-44c6-ba8f-0fa133818c50';
  readonly VisitDiagnosisAxisType_Two = 'd41e2a8d-1c05-48df-805e-94f4a5cb3f10';
  readonly VisitDiagnosisAxisType_Three = 'ccf041f6-3e09-4276-b11a-dca172b64f8f';

  // Patient Tracking Event Types
  readonly PatientTrackingEvent_General = 'fb0b78ed-7140-4980-969e-f70039176b5d';
  readonly PatientTrackingEvent_Visit = '87291d36-0d40-4536-8458-1d5c55882804';

  // SIG Quantity Unit Of Measure Guids - guids are from table "ListSIGQuantityUnitOfMeasure"
  readonly sigQtyUomCapsule = '74972c03-9c7f-4af0-b3d9-4030eb8b3c82';
  readonly sigQtyUomInhaler = '90cc0864-aaaf-40fa-b39e-e472783fa9f6';
  readonly sigQtyUomKit = 'faeb9b7f-c4b3-441a-b16d-dc089c472cb5';
  readonly sigQtyUomSuppository = 'e8357870-a050-494d-94fc-391b7ece24e3';
  readonly sigQtyUomTablet = '3720a2af-50ea-4bbe-968d-90434f08cb8c';
  readonly sigQtyUomGum = '352c9425-7c67-407d-a111-870757f019cb';
  readonly sigQtyUomPacket = '3e7cb1b6-fbf8-4626-b327-0b064e68ba72';
  readonly sigQtyUomBottle = 'b11ecea7-3715-494d-979b-7d2120d83e10';
  readonly sigQtyUomCartridge = '0bdd7d87-dfee-4f03-89f7-9e216172bcff';
  readonly sigQtyUomUnit = '24401a56-521e-467a-85c2-b0e6267a4b3c';
  readonly sigQtyUomLozenge = 'b020244f-dcbf-4f74-96c8-e20d07a24928';
  readonly sigQtyUomSyringe = 'd68de1e5-5836-4bd9-85c2-f5d275f637fc';
  readonly sigQtyUomMilliliter = 'b2b811b0-2ad8-4324-a909-a885936f1460';
  readonly sigQtyUomTablespoon = '83c4b10c-6933-4438-bcc0-b322dada5a33';
  readonly sigQtyUomTeaspoon = 'ccec57e0-431f-48ee-a146-4af1829c4b72';
  readonly sigQtyUomNotSpecified = 'a1ef67ed-010e-45f1-a292-38b1fd03419d';

  readonly DosingCalculatorUnitGPerKgPerDay = 'b657d3c5-4a62-4c10-8ccb-c091a5f5ec0b';
  readonly DosingCalculatorUnitMcgPerKgPerDay = '75fc5f60-02fb-4861-a2ca-cefe57dbdeb0';
  readonly DosingCalculatorUnitMgPerKgPerDay = '172b6568-77ee-41cc-956d-5fc15a2a796f';
  readonly DosingCalculatorUnitMlPerKgPerDay = 'a7ba4269-07ab-45b6-9d4c-bf4ca1a3126c';

  // Transaction Credit Types
  readonly TransactionType_Payment = 'e78af210-4992-484d-b804-cc2031868598';
  readonly TransactionType_Adjustment = 'dfe2cdcd-f156-4c5c-a56e-52d7cd3105d3';
  readonly TransactionType_CollectableAdjustment = '9cceb99c-727a-48fd-9474-f91f14f22a97';
  readonly TransactionType_Remark = 'c02494ee-f471-4237-a35f-974555b0f828';
  readonly TransactionType_Transfer = '28a84e67-a2a0-48e4-93ba-ef49607ead17';
  readonly TransactionType_Charge = 'b4c75e1c-4360-453d-b8f7-5091e51ddaf0';
  readonly TransactionType_CarrierTransfer = 'ee9a074d-5660-400c-9975-2f961a38d7b2';

  //SC 07/16/08 Account/Provider Coverage Types
  readonly CoverageType_Medical = '10101010-0000-0000-1001-000000000001';
  readonly CoverageType_Dental = '10101010-0000-0000-1001-000000000002';
  readonly CoverageType_BehavioralHealth = '10101010-0000-0000-1001-000000000003';
  readonly CoverageType_Uninsured = '10101010-0000-0000-1001-000000000004';

  //Lab order bill types
  readonly PatientBill = '4d460de7-fd6c-43b6-9fdb-bec08713ec98';
  readonly InsuranceBill = '2eafdf7d-3b73-46dc-af8b-4f2ba5a1743e';
  readonly ClientBill = '9376a37e-b27f-422b-97c0-d398a5dd86d8';

  // EM Examination Description
  readonly EM1995RulesExamination = 'b78818e4-345f-4a20-b303-7f2146343330';
  readonly CardiovascularExamination = '58dd4ba5-1ec2-49d2-9287-8fef51acf104';
  readonly ENTExamination = '8cca5d54-2f38-44aa-a416-8e62c2b4d2ef';
  readonly EyeExamination = '49949119-8d84-4764-aaba-902698c65cbb';
  readonly GeneralMultiSystemExamination = '9c1c8d66-89f9-4097-b1e7-f601f10e60f8';
  readonly GenitourinaryExamination = '23c8a50e-06bc-4fd4-98aa-a80242cb0374';
  readonly HematologicLymphaticImmunologicExamination = 'f5e2db8f-0884-487e-bc04-5554bb187b4a';
  readonly MusculoskeletalExamination = '20ef398a-50e2-4b42-b350-d563a8c22637';
  readonly NeurologicalExamination = '97b8c00d-d75f-4885-8363-1e6c252dfcd4';
  readonly PsychiatricExamination = '37e40cc7-07af-4520-9d47-d2e6d09b4ed7';
  readonly RespiratoryExamination = '199151d4-0a37-4567-9c67-0bb7858523c7';
  readonly SkinExamination = 'aadde121-1315-46fc-a32d-4440d55fb239';

  // EM Key Component
  readonly EMHistoryPresentIllness = 'bef97f98-fc7b-4938-a611-3961fabe7c82';
  readonly EMReviewOfSystems = '2f4c8c81-20d6-4551-80cd-87fb9403fd0c';
  readonly EMHistory = 'e770d652-dddc-4950-8c23-1941677ec51e';
  readonly EMPhysicalExam = 'dbcd49e7-4d67-4414-9881-f72438eabae6';
  readonly EMComplexity = '8ffbe65c-8e12-4c3f-b4ae-d603c8217692';

  // EM System Body Area
  readonly SystemBodyAreaAbdomen = '88fb7da6-9fd7-45a8-bb9b-525db08174dd';
  readonly SystemBodyAreaAllergicImmunologic = 'd316b3ed-5004-4a69-85b3-2308cdc310c8';
  readonly SystemBodyAreaBack = '745f34ab-10dd-4e22-8aa4-e506d3d77791';
  readonly SystemBodyAreaCardiovascular = '18c37cd0-6b72-41f6-97f1-22171a694da4';
  readonly SystemBodyAreaChestBreasts = '61433637-4dce-4e4e-a8c6-016447fe0251';
  readonly SystemBodyAreaConstitutionalGeneral = 'fee1b725-b1c9-4544-87dd-d9ac27dcc7ed';
  readonly SystemBodyAreaEachExtremity = '3368a111-dedf-44c6-9363-dfddb21367fe';
  readonly SystemBodyAreaEndocrine = '11d2dae7-6d0c-419d-954d-5d9702826f1f';
  readonly SystemBodyAreaENMT = '57824fba-b1eb-4753-aa00-1dd9b245a00c';
  readonly SystemBodyAreaExtremities = '82f5a636-a9d2-483c-86e9-659055efc076';
  readonly SystemBodyAreaEyes = '7df385b7-fd9b-49bc-b951-374f55b45e5a';
  readonly SystemBodyAreaGastrointestinal = '074a4b52-6e98-4b0d-a381-5a59778dd2a1';
  readonly SystemBodyAreaGenitaliaGroinButtocks = '65511a5a-6009-4fc1-9457-fdba294ab963';
  readonly SystemBodyAreaGenitourinary = 'd152486f-74b1-4cce-958d-bfd0108683c1';
  readonly SystemBodyAreaGUfemale = 'c09042c4-3280-4824-9be8-95f736c3f2a2';
  readonly SystemBodyAreaGUmale = '43093213-8382-48b6-9a7c-c5cb230d9c39';
  readonly SystemBodyAreaHeadFace = '546c7b3a-a749-4556-ac1f-e9f224427756';
  readonly SystemBodyAreaHematologicLymphatic = 'a320cbeb-45dd-4ff3-a978-ca9e49031976';
  readonly SystemBodyAreaIntegumentary = '95593059-117e-4f38-b508-14e03e9f0fd6';
  readonly SystemBodyAreaMusculoskeletal = '348a5b05-0c69-4079-8c51-e7fc70d18db9';
  readonly SystemBodyAreaNeck = '258b3fef-2c22-4c14-b766-aaf1be4b7210';
  readonly SystemBodyAreaNeurological = 'dff1ee30-ce8e-4911-a6b6-a89ee1c3ccab';
  readonly SystemBodyAreaPsychiatric = '23970102-c468-42b7-b326-163aa89700ff';
  readonly SystemBodyAreaRespiratory = '348e524f-264d-40eb-91c3-dbc0a6d00ded';

  // EM Bullets needed to count Musculoskeletal Examination
  readonly BulletExaminationGaitAndStation = 'e0b4bf3c-5bca-400e-8a6d-aabea02e0f9b';

  // EM Bullets Musculoskeletal: Head and Neck
  readonly BulletHeadNeckAssessmentMuscleStrength = '87abb1ab-88bd-46fe-b444-f6cfdf2706b8';
  readonly BulletHeadNeckAssessmentRangeMotion = 'c00f5206-3dd7-4415-9421-35633ca04c30';
  readonly BulletHeadNeckAssessmentStability = '89a893e4-cc49-4271-967e-a47edb373887';
  readonly BulletHeadNeckInspectionPercussionPalpation = 'd314963d-9ad4-46c6-8612-e93bdd6e11b4';

  // EM Bullets Musculoskeletal: LLE
  readonly BulletLLEAssessmentMuscleStrength = '7248507d-4079-46ea-bd04-7c93ef8de796';
  readonly BulletLLEAssessmentRangeMotion = 'ff7a2629-3050-451f-b876-90bbf94d1237';
  readonly BulletLLEAssessmentStability = '3e7ff9f5-bde9-40f0-850f-1de5a22ef782';
  readonly BulletLLEInspectionPercussionPalpation = 'eb210e0d-8e14-42bc-a230-c8d217ce4b6f';

  // EM Bullets Musculoskeletal: LUE
  readonly BulletLUEAssessmentMuscleStrength = '23a0260c-ef54-4aae-823a-8872d4e51aa1';
  readonly BulletLUEAssessmentRangeMotion = '4dd4328c-49ae-462a-a4b2-b91244bd336a';
  readonly BulletLUEAssessmentStability = 'e7e3316f-0b29-4ff2-9c4b-97cfe7eeee6d';
  readonly BulletLUEInspectionPercussionPalpation = '5726e7e5-5eb4-4b14-a92b-7c8aa8b0fee1';

  // EM Bullets Musculoskeletal: RLE
  readonly BulletRLEAssessmentMuscleStrength = '5fed9f47-4715-443d-98f0-186409e7e5d4';
  readonly BulletRLEAssessmentRangeMotion = 'ab880484-1d2c-4c57-bfa0-8ef204426613';
  readonly BulletRLEAssessmentStability = '2a30e4cc-8e92-41d3-bf53-ec0a8cb2ae4b';
  readonly BulletRLEInspectionPercussionPalpation = '3bff721c-a1fe-4211-be53-3cb6bfb58272';

  // EM Bullets Musculoskeletal: RUE
  readonly BulletRUEAssessmentMuscleStrength = 'a2a73e16-346d-40c4-9448-0cf35ec252df';
  readonly BulletRUEAssessmentRangeMotion = '68cbf246-3f18-4023-b5de-98cd6f6c4faa';
  readonly BulletRUEAssessmentStability = '8f343b4b-f9d3-4a75-acd0-053a5b2997f2';
  readonly BulletRUEInspectionPercussionPalpation = 'ce254a35-4999-468c-8142-916fc9ed1147';

  // EM Bullets Musculoskeletal: Spine, ribs, and pelvis
  readonly BulletSpineRibsPelvisAssessmentMuscleStrength = 'f9331555-d8ff-4a1b-a23a-87622739cf36';
  readonly BulletSpineRibsPelvisAssessmentRangeMotion = '7fcab774-1053-4a52-893d-0ea2fd4bc8c3';
  readonly BulletSpineRibsPelvisAssessmentStability = '3871ce19-a073-4282-930e-a3d61b1546e4';
  readonly BulletSpineRibsPelvisInspectionPercussionPalpation = '8e4dd741-589a-45c7-a2f9-4b008d261498';

  // EM Bullets Musculoskeletal: Bilateral Lower Extremities
  readonly BulletBilateralLowerExtremitiesAssessmentMuscleStrength = 'feaf086d-a100-4629-8fc8-80784dc10b2d';
  readonly BulletBilateralLowerExtremitiesAssessmentRangeMotion = '85b34895-9afa-4ec4-8e99-8d7f8eacd350';
  readonly BulletBilateralLowerExtremitiesAssessmentStability = '82faba5e-8c37-4725-ac07-6cbb684165ee';
  readonly BulletBilateralLowerExtremitiesInspectionPercussionPalpation = '69c445bf-5d9b-4486-abdd-fd56d411ad2f';

  // EM Bullets Musculoskeletal: Bilateral Upper Extremities
  readonly BulletBilateralUpperExtremitiesAssessmentMuscleStrength = '911d7cc1-5bd4-4e99-a810-3c236c3725cf';
  readonly BulletBilateralUpperExtremitiesAssessmentRangeMotion = 'a3ef1718-b31a-4da5-8d53-3e71b9e8da7d';
  readonly BulletBilateralUpperExtremitiesAssessmentStability = 'f53e7fb0-8ec0-432a-9d57-ba8fa89de18e';
  readonly BulletBilateralUpperExtremitiesInspectionPercussionPalpation = 'a0624d3a-2f36-4705-b3ac-ab397d9e459e';

  // EM Bullet skin: Inspection of eccrine and apocrine glands of skin and subcutaneous tissue
  readonly BulletSkinInspectionEccrineandApocrineGlands = 'bf719d7d-bc45-4507-b162-5227526bd781';
  // EM Bullet skin: Palpation of scalp & inspection of hair of scalp, eyebrows, face, chest, pubic area, & extremities
  readonly BulletSkinPalpationofScalpInspectionofHair = '2b4bfede-73ab-4098-b1ba-e40b717fe352';
  // EM Bullet skin: Chest, breasts, axilla - Inspection and/or palpation of skin and subcutaneous tissue
  readonly BulletSkinChestBreastsAxillaInspectionPalpation = 'adc1c908-a874-485c-85a3-5b9391631bc8';
  // EM Bullet skin: Genitalia, groin, buttocks - Inspection and/or palpation of skin and subcutaneous tissue
  readonly BulletSkinGenitaliaGroinButtocksInspectionPalpation = '94e371f4-36be-4cb4-b904-ba3bc373871a';
  // EM Bullet skin: Back - Inspection and/or palpation of skin and subcutaneous tissue
  readonly BulletSkinBackInspectionpalpation = '869022df-099b-4a5f-8902-30c6ab9b9bae';
  // EM Bullet skin: Abdomen - Inspection and/or palpation of skin and subcutaneous tissue
  readonly BulletSkinAbdomenInspectionPalpation = '059e7a21-124d-4ce9-8faf-32f48c4a3463';
  // EM Bullet skin: Neck - Inspection and/or palpation of skin and subcutaneous tissue
  readonly BulletSkinNeckInspectionPalpation = 'dead1aa4-4474-4cd5-a88c-9804281e7b1b';
  // EM Bullet skin: Head/face - Inspection and/or palpation of skin and subcutaneous tissue
  readonly BulletSkinHeadFaceInspectionPalpation = '12f6ec75-2e61-4ed0-9040-a61dee0528bc';
  // EM Bullet skin: RLE - Inspection and/or palpation of skin and subcutaneous tissue
  readonly BulletSkinRLEInspectionPalpation = '3c82c8fb-87c5-457b-bae2-cd43438692a7';
  // EM Bullet skin: RUE - Inspection and/or palpation of skin and subcutaneous tissue
  readonly BulletSkinRUEInspectionPalpation = 'dd39da78-5a0f-44dd-95e5-0d4baa7ad63d';
  // EM Bullet skin: LUE - Inspection and/or palpation of skin and subcutaneous tissue
  readonly BulletSkinLUEInspectionPalpation = '4ef562f1-3345-4ba2-9806-0985b6bf6cad';
  // EM Bullet skin: LLE - Inspection and/or palpation of skin and subcutaneous tissue
  readonly BulletSkinLLEInspectionPalpation = 'f4331d61-7d78-47ee-a23b-c65720e8e27a';

  // EM Bullet skin: Bilateral Lower Extremities
  readonly BulletSkinBilateralLowerExtremitiesInspectionPalpation = '747bd4ac-a12d-40e4-bee7-210a6a7d059e';
  // EM Bullet skin: Bilateral Upper Extremities
  readonly BulletSkinBilateralUpperExtremitiesInspectionPalpation = '3944567f-767d-44d8-8113-2637997bdf03';

  // history answer group
  readonly AnswerGroupGestationalAge = 'ffffffff-0000-0000-0000-000000000004';

  // history list answer
  readonly GestationalAge24Weeks = '556f48a1-2f5d-409d-bd09-e08057f5be4c';
  readonly GestationalAge25Weeks = '8fe8a052-7bbf-4569-8520-44eca79867c7';
  readonly GestationalAge26Weeks = 'a46638f1-a764-4a1c-9bbf-5bf8279fd6a7';
  readonly GestationalAge27Weeks = '4b3640e3-9563-4703-bcf5-b4bcbdb7790c';
  readonly GestationalAge28Weeks = 'b32c8d4e-d3fd-449e-a5f7-f997893d97b8';
  readonly GestationalAge29Weeks = '74815aac-f0dc-4076-9b38-b716ad8c6f30';
  readonly GestationalAge30Weeks = '22d9d282-8063-457e-82ce-976f2fdbf7ef';
  readonly GestationalAge31Weeks = '60884edd-9bdb-43d4-87a6-d658a10c5b22';
  readonly GestationalAge32Weeks = 'fb996b55-bdad-43d8-8362-69a2f756d3c2';
  readonly GestationalAge33Weeks = 'ad63793e-d690-4774-812c-f8dff9130e8d';
  readonly GestationalAge34Weeks = '80641b5e-f7bd-44e1-915f-da508fe592b0';
  readonly GestationalAge35Weeks = '9124175e-ef21-48de-8d74-09b88147b0fc';
  readonly GestationalAge36Weeks = '47c03c8e-bcc8-4ec0-abce-2256e997f5d9';
  readonly GestationalAge37Weeks = 'f0c15fe4-f9ca-4f98-90bf-21ed0b2562ca';
  readonly GestationalAge38Weeks = '25e2e7af-44dd-4711-9109-613af18aca92';
  readonly GestationalAge39Weeks = '6cd6e108-9266-4bc6-aa2d-046ea54e65cc';

  // history active state
  readonly HistoryStateActive = '506b5714-4dda-4080-81af-e2b37d4c4f19';
  readonly HistoryStateInactive = '8984ba72-9122-4cde-8627-33e740ec0c7c';
  readonly HistoryStateResolved = '9415899e-91ea-4be1-a7c3-d2c44bc0acb1';
  readonly HistoryStateChronic = '044fade0-a022-48a8-9f8d-9a2b14e60580';

  // eRx Job
  readonly JobeRxPollMessageUID = 'c7491df5-12b6-4a94-ac8a-7e238d2e3ac7';
  readonly JobeRxSendMessageUID = '592f87ec-356c-484d-924b-9229d769fa87';
  readonly JobeRxProcessScriptUID = '183495cc-9b64-4f1b-a3c9-e3b728621079';
  readonly JobeRxProcessPharmacyUID = 'fdee0da3-1319-430a-9164-63bf273cc4b2';
  readonly JobeRxSendRXHMessageUID = 'c0c5dab1-abc4-4a44-9df0-cb97e6013b51';
  readonly JobeRxSendELGMessageUID = '38684974-e91a-494b-9f0d-64765f4e0196';
  readonly JobeRxCreateRXHMessagesFromCalendarUID = '160c2f70-efd0-476a-8ca9-8a97340af073';
  readonly JobeRxCreateELGMessagesFromCalendarUID = 'a9b1ec6a-c4ac-44bf-846d-69a3cd13aedf';
  readonly JobeRxProcessPRVBRDUpdateMessagesUID = '5a696db7-0632-4979-981e-8460bc6ff8be';
  readonly JobeRxProcessX12ScriptUID = 'e95ebece-e069-44a6-9a5f-7566db1785e7';
  readonly JobeRxProccessePrescribingActivityReportUID = 'a0010916-897a-4ff1-8e3d-bd567054a7a5';

  readonly JobScheduleeRxPollAllUID = 'c7a28bc3-9b4a-4832-a64c-947f24d4c2b6';
  readonly JobScheduleeRxPollPrescriptionUID = 'bf2aab37-3656-453e-89f8-358e18503793';
  readonly JobScheduleeRxPollPharmacyUID = 'eaff3043-e1ae-437a-833e-b24295b12b69';
  readonly JobScheduleeRxPollPrescriberUID = '4bda1f87-b348-4404-bfbe-5560bee4235d';

  readonly JobScheduleeRxServiceCreateeMedHxMessagesUID = '54556fee-0696-4c52-a4e3-4e9d4e9a3fb3';
  readonly JobScheduleeRxServiceDownloadPrescriberOrganizationDirectoryUID = '4bda1f87-b348-4404-bfbe-5560bee4235d';
  readonly JobScheduleeRxServiceCreateRxBenefitsMessagesUID = 'c45aa546-acf7-4496-a2cc-9da49d173e27';
  readonly JobScheduleeRxServiceDownloadPharmacyDirectoryUID = 'eaff3043-e1ae-437a-833e-b24295b12b69';

  //  Audit type from ListMisc
  readonly AuditTypeSecurity = 'b6d3b9f2-1847-4061-a8bb-36c1dbc2ab87';

  // Audit Event types from ListAuditEventConfiguration
  readonly AuditEventTypeFindControl = 'e4482728-322a-4f68-b71f-66a3f27f4ec3';
  readonly AuditEventTypeWorkflow = 'b5ba8723-6011-45fc-9d42-9c3af3c07b9d';

  // ListControlType guids for controls used for observations
  readonly Control_Type_Blood_Pressure = '34c77b17-5978-478a-a584-85905652e705';
  readonly Control_Type_Generic_Kilogram = 'e7648055-dd5c-4852-8e36-af37daff8478';
  readonly Control_Type_Generic_Length = '4d5c3bd5-1f2a-4936-b5bc-2a8b648596fc';
  readonly Control_Type_Generic_Observation = 'b8df29b0-1df3-42d6-b2d3-a2952313a3fc';
  readonly Control_Type_Head_Circumference = '8b8671a1-c984-4928-add7-9de4b2dabf62';
  readonly Control_Type_Heart_Rate = '16e1d982-3d5f-4a74-8abe-6179b46705dd';
  readonly Control_Type_Height = 'afeb48db-4ac4-45d2-a3fc-aa6dd2a94d03';
  readonly Control_Type_Respiratory_Rate = '731cd44f-50e3-460d-9d75-0c221506279d';
  readonly Control_Type_SpO2 = '4fc9c432-4b6a-4971-99b9-84160cb1b579';
  readonly Control_Type_Temperature = '17912814-2d13-4b9a-ab26-089ba658917e';
  readonly Control_Type_Weight = '9bd6af71-120e-4d58-a99a-c52ef2fd4676';
  readonly Control_Type_Generic_Number = '2219e1a3-3919-43e5-9964-decb281fe826';
  readonly Control_Type_Generic_Date = 'c3ee6e69-c4e8-4b29-b17e-b59de3d0280b';
  readonly Control_Type_Eyeglass_Prescription = '1e10fbd4-37c5-4265-baf5-501904f863f5';

  // general guids needed by observations
  readonly ObservationItemSource = '50ab9c8d-9945-4867-879f-d17ef43f9f2b';
  readonly StandardCustomVitals = '67f9360a-5b25-4b12-83c2-3c10a76f7792';
  readonly GAFObservation = '84a5ff1d-eb34-4528-9e92-c5fc912a279d';

  // default FormattingTemplate uids
  readonly DefaultOPSTemplate = 'f20ee29a-2eab-4be2-bd1c-210c0da89606';
  readonly DefaultVisitTemplate = 'c0b27735-8ad2-4397-bf68-272eb7c1dc86';
  readonly DefaultRPNTemplate = 'e84d2316-2ca1-425b-9955-d8b31b045c2f';
  readonly DefaultRPNPrintAttachmentTemplate = 'abf1577a-9efa-490a-8b5b-cd084a3408d0';
  readonly AccessDeniedTemplate = 'a1382f72-d478-45ab-9524-2618aab76032';
  readonly AccessDeniedTemplatePatientChart = '1d78f979-28ac-4911-8184-9612ad8101e0';
  readonly DefaultPatientWelcomeEmailTemplate = '7c8b6a18-2eed-47c4-80b3-4f092600ce13';
  readonly DefaultPortalMessageNotificationEmailTemplate = 'e5949ce7-84f4-40b2-a9fb-c9a2b83ca126';
  readonly DefaultPortalWelcomeEmailTemplate = '09a0cd36-5ec9-454a-9b36-c8de4a4e9507';
  readonly DefaultPortalChangePasswordEmailTemplate = '14b2a33d-31da-4845-aff1-ce50f731ebae';
  readonly DefaultPortalNewMessageFromPrmEmailTemplate = '6f9a8978-bca6-4a8f-8a68-f83d8d673457';
  readonly DefaultPortalResetPasswordFromPortalTemplate = '2017c0f9-19b0-41fa-8f08-572de811ff93';
  readonly DefaultPortalAccountResponsiblePartyStatementEmailTemplate = 'c1608427-2fbd-4660-8931-54b990c449b7';
  readonly DefaultCheckoutTemplate = '68452037-77d6-4499-91f3-f5533bf9ddcc';
  readonly DefaultOPSPrintHeader = 'a8ae8ca1-e854-40b9-976c-10e86a9f7ced';
  readonly DefaultOPSPrintFilteredHeader = 'd69082ee-8c88-458b-a71c-8cb2d9414b3f';
  readonly CompleteChartZippedAttachment = 'e60e42c0-e9fe-4373-a7d3-5536e2b22b9a';
  readonly CompleteChartNotIncludedAttachment = '1a38397e-9aa1-47a5-9e98-6385f9c35088';
  readonly DefaultClinicalSummaryTemplateUid = '7bc0cad4-06d2-4f5c-8e96-d8df589db6ec';
  //  default Complete Chart Print definitions
  readonly DefaultRPNPrintButton = 'bd75524a-378b-4fdc-b7f8-e7cb438aaf27';
  readonly DefaultOPSPrintButton = 'ae48142a-e4bd-42a6-a34b-b82cad46acdf';
  readonly AllFileTypesToFiles = 'cd57cf3c-d2f9-44f6-8366-a4708a9d88fb';

  // Chart Access Security Uids
  readonly ChartAccessControlsSecurityUid = '426ecfd5-7259-414e-81c7-a7edbccb1b66';
  readonly EmergencyChartAccessSecurityUid = '0e0b6e89-614e-4ee6-a8c4-c6e818cb7f9b';

  // ListVaccineAdmin
  readonly ListVaccineAdmin_PediatricVaccineCard = '6b1b35b9-85a8-499e-83d9-11ebb24cea04';
  readonly ListVaccineAdmin_AdultVaccineCard = '187d09c3-f59f-446f-af14-3d8e1a3bd920';

  //ListImmzCompletion
  readonly ListImmzCompletion_Refused = 'd656418e-f475-4b76-a624-d4697b2b7d7b';
  readonly ListImmzCompletion_NotAdministered = 'a1949f85-01b8-4128-a39c-d0d916ffb2d6';

  // ListProductParameter
  readonly ProductParameter_PatientPortalRouteMessagesByServiceSite = 'ba5d6df9-8124-4a90-94c3-2fe739597f1c';
  readonly ProductParameter_PatientPortalUpdateDemographics = 'db28aa45-8e4e-4646-bd98-332c718ace12';
  readonly ProductParameter_PatientPortalUpdateDemographicsPhoto = '6a47f7f6-41d6-467b-8a7a-be36d6c92d9b';
  readonly ProductParameter_PatientPortalTagLine = '72af95ce-12f5-450a-8c0a-3941a3256ac7';
  readonly ProductParameter_PatientPortalPracticeConsent = '1d1fe66b-54b1-46cd-9d6d-aeeff9e411e3';
  readonly ProductParameter_PatientPortalChartConsent = '8d3b926a-d237-4ba1-99c8-1c40bd5d7dd5';
  readonly ProductParameter_PatientPortalURL = 'd0df5185-9f23-4d2b-8bf3-0e6c48442861';
  readonly ProductParameter_PatientPortal_ViewClinicalSummary = '27d5a4b8-1c68-45bb-a89f-023fbc5fc370';
  readonly ProductParameter_PatientPortal_ViewCompleteChart = 'aa04ca02-089b-4455-8566-cf9d766487b6';
  readonly ProductParameter_PatientPortal_ViewImmunizations = '0354d96b-e135-4753-b06c-1634e6745299';
  readonly ProductParameter_PatientPortal_ViewEducationalMaterial = 'aaad592b-0bff-4141-9f1c-504786cfe873';
  readonly ProductParameter_PatientPortal_UpdateInsurance = '349cdc7a-781c-4586-8414-12f79b1b6d1d';
  readonly ProductParameter_PatientPortal_MakeAPayment = 'a3978397-4796-43b3-9586-7f24e41aee33';
  readonly ProductParameter_PatientPortal_UpdateDemographics = 'db28aa45-8e4e-4646-bd98-332c718ace12';
  readonly ProductParameter_Practice_Email = 'ecdf36fc-d8ae-460c-ad3d-8ebbdef9abe1';
  readonly ProductParameter_Practice_Website = 'da6121e9-0978-4526-9052-ee4e844f0456';
  readonly ProductParameter_Practice_From_Email = 'efbb66f7-afbb-421d-8a99-697d36ff5625';
  readonly ProductParameter_AutoAddOnSetToDX = '0f1a6e38-41f4-48b2-8713-5688c222c9ee';
  readonly ProductParameter_DocumentLinkingWarningNumber = '86a89f8b-6cf1-42ad-be0c-2cfc0a663536';
  readonly ProductParameter_PatientPortalSecurityQuestions = '8c66bafa-eb42-472b-b89c-8b8de09218f1';
  readonly ProductParameter_PatientPortalContentAttributes = '1c190aa8-df1e-49b7-8fff-901d28046d22';
  readonly ProductParameter_PatientPortalMessageSubHeading = 'b348f800-1fcb-4397-9e1a-2226afd836e4';
  readonly ProductParameter_PatientPortalPracticeName = '2ad081a3-a195-4093-9c7f-1563bd3179a3';
  readonly ProductParameterDPNActionPrint = 'dbface34-6154-488d-8f44-c13dcb64b165';
  readonly ProductParameterDPNActionGenerateCCD = 'a6845a5d-2fb1-494c-81c6-1e57fd4eb02e';
  readonly ProductParameterDPNActionSendDocument = '134d73d3-f594-48af-be75-84ebcbd76e99';
  readonly ProductParameterDPNActionCreateReferral = 'a336390a-5c72-4ca5-a9f6-40ee9a741cce';
  // fix for bug #26797
  readonly ProductParameter_ApexAffinity = '5ae76288-3801-4c71-b943-370e7239bbc0';
  readonly ProductParameterFacilityAccountEditingEnabled = '274cf86f-edf0-4b61-ac12-ed5edcfec653';
  readonly ProductParameterDirectMailUserAccountCreationWarning = '96354ac3-d38a-46cc-afae-bf0b532d995e';
  readonly ProductParameterDirectMailUserAccountDeletionWarning = '4fefe5bb-55d0-4275-96e3-9afc101e9f15';
  readonly ProductParameterDirectMailNitorOneFacilityPerDomain = '0de075f1-fddc-4cb6-a5f1-6c30af39b544';
  readonly ProductParameterDirectMailNitorFacilityInactivePollSeconds = '0fecddc7-5174-4311-a7ba-524377e6da3d';
  readonly ProductParameterDirectMailNitorFacilityActivePollSeconds = '78b5d74a-d9ca-4611-981b-74e6275af6e0';

  readonly ProductParameterDiagnosisOneUrl = '719523ee-c072-495d-8e37-4e97d3a832a5';
  readonly ProductParameterDiagnosisOnePassword = '0d3ab0ca-dd67-4870-b8a6-62216481d613';
  readonly ProductParameterDiagnosisOneUserName = '2134b723-15ce-4147-884b-e0f0c2669f89';
  readonly ProductParameterDiagnosisOneRuleSet = '9106f134-b471-487b-a48f-bd69e465e1ca';
  readonly ProductParameterDiagnosisOneSaveToFileEnabled = '924496a8-b7a9-46ed-b59d-ce052c076eef';
  readonly ProductParameterDiagnosisOneMaxBatchSize = 'f0db6d11-ec84-447b-adb4-70fc3485a60b';
  readonly ProductParameterDiagnosisOneOAuthPath = 'ba5e0d70-7bf6-46bd-9646-acc5536b9b5f';
  readonly ProductParameterDiagnosisOneBaseINROrderUrl = '55b1f554-1699-4796-88c1-4821ca3a165b';
  readonly ProductParameterDiagnosisOneBaseINRReportUrl = '777f29c5-122e-41f6-9b4e-31933fa63b21';
  readonly ProductParameterDiagnosisOneEnterpriseID = 'e227b015-4a0e-4397-af18-a1478e438613';
  readonly ProductParameterDiagnosisOneINROrderUsername = '4baeecea-a4bf-4470-bb7b-c6b2138e1aff';
  readonly ProductParameterDiagnosisOneINROrderPassword = '2d2b9c78-d37d-4625-b5dd-018d8dc5491a';
  readonly ProductParameterDiagnosisOneTokenGeneratorUsername = 'b59cb0ee-f4e4-4dd2-9e46-a6bc8f7a97ba';
  readonly ProductParameterDiagnosisOneTokenGeneratorPassword = '9be8782f-bfc2-453f-92a2-67cd4ee62d80';
  readonly ProductParameterDefualtCCM_CPOTime = '680f3ffb-096f-4cdf-a84d-fc68d9da1557';
  // fix for bug #38595.
  readonly ProductParameterValidePAPayors = '5397bb02-9803-4639-9c85-dbff48739e88';
  readonly ProductParameterInfoResponseReviewed = '125f71db-c197-45e1-9c39-3e7561638fab'; // Scenario 39254
  readonly ProductParameterTransmissionSettingsCreateNewMailbox = '783fbc03-825b-4fb6-b8e8-5e84c25275cd';
  readonly ProductParameterAprimaNowLockoutTimeout = 'aab069b0-5b64-44ef-8963-371dfeff4edc';
  readonly ProductParameterFoxitLicenseSN = '2522a9aa-f38c-42f8-9b82-e5ac1a3fa6bf';
  readonly ProductParameterFoxitLicenseKey = '5f867a48-f16e-4d06-b8b7-d786e640eb7f';
  readonly ProductParameterFoxitDefaultTool = 'a407ba56-9179-482c-82cf-7222f028fb78';
  readonly ProductParameterFoxitDefaultViewMode = '48e4746a-bec4-4bef-ab78-0f813d6625dc';

  // Order Result Status
  readonly ResultStatus_Approved = '53bdc86c-2839-4066-9821-c20c214967b1';
  readonly ResultStatus_Resulted = 'e4861953-d5f3-4d86-be78-530b394ae39e';
  readonly ResultStatus_Received = '0017db73-34cc-42fe-974d-555f72088690';
  readonly ResultStatus_Reviewed = '40040bb9-3e07-4829-adf6-55bc637142e1';
  readonly ResultStatus_Collected = '71812cbc-af6d-4c84-aac9-70d0c024f282';
  readonly ResultStatus_Performed = '537aeecc-d952-4b66-831a-c4d6531906b1';
  readonly ResultStatus_Cancelled = '6b6a1578-26d1-452d-a198-dccfbcd9094f';
  readonly ResultStatus_Pending = 'd8acd15c-4f00-41fc-93c2-e1ba0a4edaa8';
  readonly ResultStatus_Ordered = '9114caee-042b-4b2c-a505-e70ba934000f';
  readonly ResultStatus_PendingResulted = 'dd6bc606-fa5f-47ea-a2a2-fd0f3938931c';

  // User Settings
  readonly UserSetting_Default = '10101010-1111-1000-0000-000000000001';
  readonly UserSetting_ICS = '10101010-1111-1000-0000-000000000002';
  readonly UserSetting_Midmark = '10101010-1111-1000-0000-000000000003';

  // Service Site Types
  readonly ServiceSiteType_Home = 'e5cbbaff-919a-44f7-912e-07b014b27bcd';

  // Report Designer - Relational Operators
  readonly RelationalOperator_IsNull = '75c59d5e-d1d6-4cfe-bb3b-1897fb56c268';
  readonly RelationalOperator_IsNotNull = 'ead36730-d30a-49db-b900-d2d9b67ad770';
  readonly RelationalOperator_InList = 'a7203de7-eb48-419d-b8de-5197e60e8a63';
  readonly RelationalOperator_NotInList = '4b1f614c-ce33-4614-90bf-0ce2718cd23c';
  readonly RelationalOperator_GreaterThan = '48eb6b68-552e-453f-ba8b-50ccb2a0988f';
  readonly RelationalOperator_GreaterThanOrEqual = '5fcf409e-2137-4630-886c-e928057dac36';
  readonly RelationalOperator_NotLike = '0885c3fa-be9b-4752-935c-51166f5cb199';
  readonly RelationalOperator_NotEqualTo = 'f4977c61-7ad6-4a4f-ad94-9a858a6a5760';
  readonly RelationalOperator_LessThan = 'e935c919-6b45-417a-a569-c4ad0b719a02';
  readonly RelationalOperator_LessThanOrEqualTo = 'aa3f54b6-f4e9-4031-bffa-ce067e0196f4';
  readonly RelationalOperator_EqualTo = '127889ba-7c69-4f1a-9657-de3bf969a7eb';
  readonly RelationalOperator_Like = 'f4bed64e-be68-4a57-9fb1-fbe242643a36';

  // Report Designer - User Defined Controls
  readonly UserDefinedControl_Find = '8a957d7c-8b86-4a67-8336-b830c30fd123';
  readonly UserDefinedControl_TextRange = '9b225d53-0cbd-4f0d-bd0a-54d05685ccca';
  readonly UserDefinedControl_DateRange = 'f81b4b08-d296-4ee9-b1f3-e61f85644a88';
  readonly UserDefinedControl_NumericRange = 'def86050-eef6-4577-9d2c-f7c172a0f6ea';
  readonly UserDefinedControl_Timespan = 'df87d0d5-bf48-49fb-abbc-cb75cc29eb64';

  // External Patient Type
  readonly CPOExternalType = 'cdb32bb9-25b8-4598-85c2-eca33d3b854c';

  // Patient Care Management Types
  readonly ChronicCareManagementType = '14e95bcd-a9bc-41e4-9743-ecdbad35fb6a';
  readonly CarePlanOversightType = '928a4f18-ff1f-4bba-90d9-66aee2233c9a';

  // HM Rules "ListMisc" Types
  readonly HMRules_OperandUid_LessThan = '3eebb69d-3bc4-4ce9-97fc-815c70f314a8';
  readonly HMRules_OperandUid_GreaterThan = '1263504f-77e8-41e2-aabb-75b10647d7f7';
  readonly HMRules_ConjUid_AND = '8637cc01-aab8-4e8a-a971-9cd91d0ef1eb';
  readonly HMRules_ConjUid_OR = '578cbd26-44df-4b86-b119-c2c4b759042f';

  // Scrub Types
  readonly Scrub_Superbills = '3e6dafcf-ebf1-4dc5-90b5-f596a9ef8498';
  readonly Scrub_Claims = '5bac9c30-6387-4359-9c39-42e2850d924c';

  // TimeFrames
  readonly TimeFrame_Clear = '2ec8c22d-49a2-4289-829d-017457bebfc0';
  readonly TimeFrame_Default = '444e6dc7-df0f-45bc-b8c1-c8b4122eed97';
  readonly TimeFrame_FreeText = 'd6153566-df35-42ae-8cba-b36544c53133';
  readonly TimeFrame_Calendar = '2a65cc34-b700-4be1-bc0c-bc3947abdbb4';
  readonly TimeFrame_Birth = '86317d78-c519-4fe3-8f02-b794ffb00357';
  readonly TimeFrame_Childhood = '4ce23cf0-c9bb-4016-900f-76a567a3c82c';
  readonly TimeFrame_Teenage = '3ff31d6d-0706-429d-9acf-c54e081ecd29';

  // problem status
  readonly ProblemStatus_Active = '613cd9a8-4e48-41d2-9c91-41eb7d5885b4';
  readonly ProblemStatus_Resolved = '116e88e8-daf1-4289-b354-e4cbc8a0103d';
  readonly ProblemStatus_Inactive = '92aeb1dc-a874-4c7a-bee1-53653161266d';

  // problem history control guid; used in ControlDx
  readonly ProblemHistoryCategory = 'df10c01a-d56a-4d97-aeca-5616ff05314a';

  // ListEthnicity
  readonly ListEthnicity_Hispanic = '304621ec-0958-4dc1-ae4d-2438e1d0dbdf';
  readonly ListEthnicity_Hispanic_Or_Latino = 'f34dad44-e417-47b3-b338-515954d242e3';

  // problem diagnosis history sort options
  readonly ProblemDiagnosisSortName = '624601c5-0b33-4c6a-91e6-69ddb47200ee';
  readonly ProblemDiagnosisSortOnset = '97226c02-8856-4d93-88fa-9bf71c9d9c04';
  readonly ProblemDiagnosisSortResolved = '9322c9ee-2ddd-49a1-bb54-308b7b501077';
  readonly ProblemDiagnosisSortProblemStatus = '59afd3b8-36de-4575-ba33-dea43720c2f5';
  readonly ProblemDiagnosisSortProblemDuration = '6d2632e1-8b63-48b9-994a-244a67a3fa7f';
  readonly ProblemDiagnosisSortRecordDate = '33c9190f-8f4b-4f1d-a56f-782759d3202f';

  // problem diagnosis history sort duration options
  readonly ProblemDiagnosisDurationSortUnknown = '187ad067-794a-45f0-b954-f6816668bc54';
  readonly ProblemDiagnosisDurationSortAcute = '38379dd8-cd7a-45af-a7b2-ba3aa4507ba3';
  readonly ProblemDiagnosisDurationSortChronic = '983c3ad7-3524-40cc-816b-1b942236dffd';

  // problem diagnosis history sort level options
  readonly ProblemDiagnosisNameSortLevel = '624601c5-0b33-4c6a-91e6-69ddb47200ee';
  readonly ProblemDiagnosisOnsetSortLevel = '97226c02-8856-4d93-88fa-9bf71c9d9c04';
  readonly ProblemDiagnosisResolvedSortLevel = '9322c9ee-2ddd-49a1-bb54-308b7b501077';
  readonly ProblemDiagnosisProblemStatusSortLevel = '59afd3b8-36de-4575-ba33-dea43720c2f5';
  readonly ProblemDiagnosisProblemDurationSortLevel = '6d2632e1-8b63-48b9-994a-244a67a3fa7f';
  readonly ProblemDiagnosisRecordDateSortLevel = '33c9190f-8f4b-4f1d-a56f-782759d3202f';

  // problem diagnosis status
  readonly ProblemDiagnosisStatusInactive = '92aeb1dc-a874-4c7a-bee1-53653161266d';
  readonly ProblemDiagnosisStatusActive = '613cd9a8-4e48-41d2-9c91-41eb7d5885b4';
  readonly ProblemDiagnosisStatusChronic = 'e7e822a8-edd9-45f9-80b8-dfb3d2383122';
  readonly ProblemDiagnosisStatusResolved = '116e88e8-daf1-4289-b354-e4cbc8a0103d';
  readonly ProblemDiagnosisStatusDeleted = '0ee6820d-a8f8-4364-b0b1-25aab505959a';

  // Collection Status Global
  readonly CollectionStatusGlobal = 'd497b60d-5bd2-414d-aa1e-4f8e558c5ce5';

  // List Disclosure Reasons
  readonly ListDisclosureReasonReleasedToPatient = '23453773-db8a-47bc-b3f5-5d915fa2b313';
  readonly ListDisclosureReasonCompleteChartSummary = 'dc3160a4-95f4-4224-a3f6-eff5d33dd61b';
  readonly ListDisclosureReasonReferral = 'e4b426a5-59aa-416d-b37b-ac7bd782b780';
  readonly ListDisclosureReasonReleasedByPatient = 'f296c968-14d2-4616-a526-1acd35527801';

  // List Custom Note Print
  readonly ListCustomNotePrintCompleteChart = '893996ba-e098-4239-885d-7e3252bba8f9';

  // Fax Servers
  readonly FaxServer_Interfax = 'cc7d8447-2def-4b56-b7fe-675f4f9e7dac';
  readonly FaxServer_Fax2Mail = '3c85e316-d741-4c67-9cdd-fec603be7bc6';
  readonly FaxServer_GFIFaxMaker = '4e064089-ab4a-4015-9ab0-8fdc9d18a99b';
  readonly FaxServer_Biscomfax = 'a1fb09d9-5ef0-492b-945e-b524ee47d5c1';
  readonly FaxServer_RightFax = '1f955d28-c5bd-467b-93c9-fe9c83f95cbf';
  readonly FaxServer_FaXit = 'd9a693f0-e780-4427-a6e3-2a578025f8b8';
  readonly FaxServer_Kno2 = '586f8db3-fdf7-4639-af4f-b5865a6f670f';

  // document linking folders
  readonly DocumentLinkingGeneralFolder = '9e6422c4-3f95-4ce6-bc84-79d068b93135';
  readonly DocumentLinkingIgnoreFolder = 'd762799d-1d5a-4546-9ead-fdfdc2021fc5';
  readonly DocumentLinkingRejectedFolder = 'e38fcf0c-75b9-4624-95c6-346c78fccb2c';
  readonly DocumentLinkingDirectMailFolder = '2dea85bb-503f-40a6-85ac-b2b199f63dcf';
  readonly DocumentLinkingFaXitFolder = '47688f38-cdfb-4b76-b73c-b2f69e36713a';

  // map sources
  readonly MapSource_ClaimsMapping = '18b56921-1614-4e0a-af7f-edd311e682e4';
  readonly MapSource_BeaconCarrierMapping = '45193380-4dcc-4837-99bb-fd145f1cf420';
  readonly MapSource_FromSurescriptsPhoneType = 'b41b3296-f1be-4f41-b90d-6aec99299ff3';
  readonly MapSource_ToSurescriptsPhoneType = '8a2fe4ae-25fd-42eb-9b2a-9b68e1795206';

  // UI Control Type
  readonly UIControlType_ChargeTicket = '362af25f-7a3b-4b37-83d2-3f316d87f8b1';
  readonly UIControlType_DataInput = '1232ccd0-b254-4cb0-af95-bf482de4f1c8';
  readonly UIControlType_ClinicalNoteTab = '26c21b2e-3aec-4cb5-9bc2-c9dc7dc9e34c';
  readonly UIControlType_ClinicalNoteSlider = '690c5ea2-3345-4785-ad1c-a1787feafe1d';
  readonly UIControlType_ClinicalAssessmentForm = 'd5b6f824-c839-4bd5-b660-042a74c43ebc';

  // UI Control
  readonly UIControl_ChargeTicket_Designer = 'f7992625-529f-4862-b984-23fc76a6740b';
  readonly UIControl_ChargeTicket_Standard = '4277e604-1ed7-4d19-b0e6-fc1acd09f10d';
  readonly UIControl_DataEntry_Designer = 'a71ae324-02b7-47e9-bd32-b96dde7e779e';
  readonly UIControl_Note_DesignerInput = '2ff8df13-6adf-497a-87c5-4bf34047b2ea';
  readonly UIControl_Note_DesignerCaseInput = '5ef07c48-9b2e-4551-8199-26e0cdfaaa70';
  readonly UIControl_Note_Plan = '892d6a04-149b-4283-abeb-12495ab3c560';
  readonly UIControl_Note_CC = 'c7aa99b0-b9aa-4570-9357-145f175fad62';
  readonly UIControl_Note_SO = 'd558a00e-a0b8-4425-b6ca-18cfcdd4e5e6';
  readonly UIControl_Note_HPI = '7480235f-7012-4c04-ba36-1aefc1697593';
  readonly UIControl_Note_PE = '32bf6c13-ed87-48de-9fef-52a5143d444d';
  readonly UIControl_Note_VisitText = 'bd6530dc-2373-49de-b284-56ea3d79cc46';
  readonly UIControl_Note_AssessmentForms = 'eca5fc07-e1bf-4325-8dec-5bff94d40925';
  readonly UIControl_Note_Vitals = '7a048322-ab0e-45a1-91be-64b25276950c';
  readonly UIControl_Note_ROS = 'c16a0788-f5af-4b93-b23e-82ecd52aea52';
  readonly UIControl_Note_Confidential = '56d27dff-e1cd-4428-b790-8d5738b020f5';
  readonly UIControl_Note_Results = 'a3aedf6d-720c-4da2-9b9a-945b8d490d37';
  readonly UIControl_Note_CCHPI = '4f22cd53-53a7-4d55-8fde-a3d52561c817';
  readonly UIControl_Note_RX = '79434f26-2cdd-48ad-9295-b03a3a5f02e9';
  readonly UIControl_Note_ROSPE = 'dbe463a3-ab72-44f2-9756-b07f0e1f853c';
  readonly UIControl_Note_DX = '71ba0a7e-39e4-4e94-8edb-c364fe7f736b';
  readonly UIControl_Note_DXDSM = 'a3dfeab4-6579-4743-8873-f96311e07589';
  readonly UIControl_Note_GeneralNote = '8b40d89b-3511-42a4-b07b-decadc72647e';
  readonly UIControl_Note_SP = '5db71e56-0da7-4480-a277-e4f796cbff67';
  readonly UIControl_Note_HX = '7eca6fd5-b1ca-408b-b073-f6feff45e8eb';

  //CYS SC
  readonly PrimaryInsType_IsPrimary = '10101010-0000-7777-0000-000000000001';
  //CYS SC - System Defaults
  readonly CHCSystemDefault = '10101010-0000-0000-1001-000000000001';

  //Qatalys - built in provider for review/approve messages assignment
  readonly DefaultOrderingProvider = '87ccdbcd-4df2-4212-a7dc-33006c98f92e';

  readonly LanguageEnglish = 'e76c7b0b-d485-4307-9921-b2f65c5ba718';
  readonly LanguageSpanish = '311d2501-d8ca-45e5-a171-c7126b4fdd5c';

  // ListClaimBatchStatus
  readonly ListClaimBatchStatus_InProgress = '37671b78-bec4-44cf-babc-5a60cfa25f50';
  readonly ListClaimBatchStatus_ReadyToSend = 'f4ae1494-47d5-4404-bb9d-d2ed4da9fba7';
  readonly ListClaimBatchStatus_Sent = '08076914-83e5-48dd-9260-c56c5ec73412';
  readonly ListClaimBatchStatus_Failed = 'c1e377d7-7948-4473-a9d4-1038887b322b';

  //Declined to specify information - for MU II demographics where patient did not specify information. This guid currently resides in ListEthnicity, ListRace & ListLanguage.
  //Add it to any other table that needs it for a selected item in a findcontrol.
  readonly DeclinedToSpecify = '4176e3b5-c69d-4df0-8d9b-2a059459d859';

  // CallPointe person uid; fixed bug #20423
  readonly CallPointePerson = 'af8f2184-6591-4df8-86be-e91ccf2506e7';

  // ListNotificationMethod
  readonly NotificationMethodEmail = 'd804e208-8525-459d-aa9d-613b174939b0';
  readonly NotificationMethodMail = '6e5a8ab2-4779-4ab6-9a69-09a266da6ba0';
  readonly NotificationMethodPhone = '262854e9-0dfa-46d8-a3b1-ac83cebda18a';
  readonly NotificationMethodText = 'c0edfc41-134b-49b1-b3ab-872def185a76';
  readonly NotificationMethodUnknown = '79f84471-3104-48dc-9331-4cfa1e78c158';

  // ListRecallType
  readonly RecallTypeClinicalDecisionSupport = 'a35165d7-78cc-4aac-b130-7f945a92c35f';
  readonly RecallTypePatientsByCondition = '17eb9743-ac60-4c6f-b6c7-246983b9c69f';

  // ListRuleMeasure
  readonly ListRuleMeasure_HEDIS = '86e6b8d1-a851-4f16-8c33-cfdedcc5c888';
  readonly ListRuleMeasure_MUII_QRDA_Aggregate = '627c0740-381e-4c43-9db5-42cc66ba1969';
  readonly ListRuleMeasure_MUII_QRDA_Aggregate_2014 = 'cf6ef5da-1cb0-4af3-ab1a-48444562ea2e';
  readonly ListRuleMeasure_PQRS = '1c7684c7-fef4-48fd-9b3d-dee660a6e8f8';

  // ListEligibilityStatus
  readonly ListEligibilityStatusCannotProcess = 'a9d72b1e-42fc-495e-ae92-74e6c68ffa6b';

  // ListIntegrationPartner
  readonly ListIntegrationPartnerSurescriptseRxServiceUid = '118422aa-c165-4a2e-8389-e5b7e89636ea';
  readonly ListIntegrationPartnerSurescriptsDirectMessageUid = '4d1bc44b-31f5-4b41-8f50-a95047266ffc';
  readonly ListIntegrationPartnerSurescriptsRLEUid = '0609c279-d522-4596-aeb8-51eda485ad49';
  readonly ListIntegrationPartnerNitorDirectMessageWithCertificateUid = '8053bc8f-0bea-41da-8a2f-4fc7d6da65d5';
  readonly ListIntegrationPartnereTacticsUid = 'da11fe70-9b5a-4318-ae16-550ba170a9b3';
  readonly ListIntegrationPartnerCommonWellUid = '01178549-2cef-462b-89b0-e41ea99ee57a';
  readonly ListIntegrationPartnerCircleLinkUid = '60157d55-360b-4505-bb1a-f09e538826c9';
  readonly ListIntegrationPartnerPersiviaUid = 'c75bd792-315c-425f-8a01-7ba85510d616';

  // TransmissionConfiguration
  readonly TransmissionConfigurationSurescriptsDirectMessageUid = '55ef6d4c-23a2-4ecd-a4e9-b6cd6f8350f2';
  readonly TransmissionConfigurationeRxOrganizationDirectoryUid = '1fd91627-0d23-43ac-a8db-cfca9ec19af0';
  readonly TransmissionConfigurationeRxProviderDirectoryUid = 'd081543c-8f64-4c66-85b0-955eb8f944d0';
  readonly TransmissionConfigurationSurescriptsDirectMessageInboundUid = '1d370169-a34b-45ae-b029-b4ebeb549c4b';

// ClinicalDelgateSecurityAllow
  readonly ClinicalDelgateSecurityCanCreateOrder = '5be7f005-87a8-4dfa-b2a8-7f01db80ab21';
  readonly ClinicalDelgateSecurityCanCanCreatePrescription = '91012e30-80b9-4b68-a719-9e4f65dc606f';
  readonly ClinicalDelgateSecurityCanSendDirectMail = '6247f22f-fbba-4949-ba3c-b44f4a5db747';
  readonly ClinicalDelgateSecurityCanCompleteNote = '1b48ba6e-4799-4975-ab68-f7a91b85c0a6';

  // ListRuleMeasure CCDA Type Documents
  readonly ListRuleMeasureCcdaCcdDocumentTypeUid = 'd2acb40b-b670-4283-b803-88e91f2a18d7';
  readonly ListRuleMeasureC32CcdDocumentTypeUid = '3de2dbe0-f202-4d12-964f-aaf46bfaa347';
  readonly ListRuleMeasureCcrDocumentTypeUid = '56b481a8-7947-4a65-8692-feb7519516e2';
  readonly ListRuleMeasureCqmDocumentTypeUid = '627c0740-381e-4c43-9db5-42cc66ba1969';

  // Procedure Refused NegationReason
  readonly ProcedureNegationReasonUid = 'cc03cd94-0f99-40fb-9c56-fc9649b416c3';

  // ImmunizationRefusal
  readonly ImmzRefusalOtherUid = '15655cf4-170f-4c4a-9dc0-e0cebf51c42b';
  readonly ImmzRefusalParentalDecisionUid = '4d38e0be-33f4-478b-87b2-4239b508a81e';
  readonly ImmzRefusalPatientDecisionUid = 'f2facba0-29f1-42a2-b584-a8f07e4d2435';

  // ListCdaSection
  readonly CdaSectionC32 = '2cb025a2-3918-4988-8f78-5187897ade8e';
  readonly CdaSectionCcda = 'f1d05427-2c72-456a-a45b-a01b08728b5c';

  // ABNStatus
  readonly ABNNotRequiredUid = 'dbf01d6f-5582-4f03-a3f2-23e137f9f9c0';
  readonly ABNRequiredUid = 'abfeae05-10b9-45ff-9139-14276f138790';
  readonly ABNCompletedUid = '52045ae5-a3d2-49bc-8180-b690157954d7';

  //FormTitle
  readonly FormTitleGeneric = '99999999-0000-0000-0000-000000000000';
  readonly FormTitleFNC = '99999999-0000-0000-0000-000000000001';
  readonly FormTitleDemographics = '99999999-0000-0000-0000-000000000002';

  //PatientStatus
  readonly PatientStatus_TestPatient = '77a41e18-5554-4874-9454-54dc3d7a33a0';
  readonly PatientStatus_Transfer = 'a2582df2-e663-4a79-9464-11da43f9bd53';
  readonly PatientStatus_Deceased = '40bad38d-146f-4314-95fc-5388151a4f38';
  readonly PatientStatus_Consult = 'a83dd048-b972-4578-a21d-586b65d563ce';
  readonly PatientStatus_Relocated = '785cc385-0635-47b7-a8c4-7717ca3bbddd';
  readonly PatientStatus_Inactive = 'ecea6163-dc76-4741-b6d0-9101037d2a0b';

  //HMRULE Decline Reasons
  readonly HMRULEPatientDeclineUid = '755dc7cc-affe-4da4-a2e5-1f050f3230c3';
  readonly HMRULENoInsuranceUid = '85868f64-30b2-46e9-a22a-22ca00e916cf';
  readonly HMRULENotNecessaryUid = '4088dace-5ac9-44dc-a428-24ce90cc417b';

  //ListNegationReason
  readonly NegationReasonProcRefusedUid = 'cc03cd94-0f99-40fb-9c56-fc9649b416c3';
  readonly NegationReasonUninsuredUid = 'c59fd3dd-684f-4f27-840d-f7c2538d1d4b';
  readonly NegationReasonProcNotIndicatedUid = '533c24a6-a5f7-4e21-a449-d216420f4e02';

  // fixed bugs #40099 & #40120
  //ListAgeGroup
  readonly AdultAgeGroupUid = '00000000-0000-0000-0000-000000000001';
  readonly PediatricAgeGroupUid = '00000000-0000-0000-0000-000000000002';

  //  MedicalServicesProvider types for CCM/CPO
  readonly MedicalServicesProviderTypeHospice = 'b502c7e7-226a-446d-89ad-1627bba55504';
  readonly MedicalServicesProviderTypeInHome = '144b3ba7-e61c-49be-aaa7-9012317a9ca8';

  // AddressHistoryType - ListMisc
  readonly AddressType_Home = 'a9b8c13f-822b-4f20-8f13-306db46a4c59';
  readonly AddressType_Homeless = 'c27fc3db-4071-4380-acf6-7672bb9521c9';
  readonly AddressType_BirthPlace = '7c111fb1-6fa5-47cc-b888-34982b6d3bf1';

  // Patient Portal Message Routing
  readonly PatientPortalMessageRoutingDefaultMessageSubTypeUid = 'df0201ea-814b-4f2b-852a-8fef5834ed70';
  readonly PatientPortalMessageRoutingDefaultServiceSiteUid = '9fab7f69-127e-4bc3-b0cd-df5de7c96e88';
  readonly PatientPortalMessageRoutingDefaultProviderUid = 'b4f160ea-f938-40e4-9c34-28bdd14952d2';
  readonly PatientPortalDefaultMessageRoutingUid = '1e4a73da-6b53-41ad-b172-b5e29670dbb3';

  // BatchProcesType
  readonly CCDABatchProcessTypeUid = '10000101-0000-0000-0000-000000000001';
  readonly CQMBatchProcessTypeUid = '10000101-0000-0000-0000-000000000002';
  readonly ClaimsBatchProcessTypeUid = '10000101-0000-0000-0000-000000000003';
  readonly RemittanceBatchProcessTypeUid = '10000101-0000-0000-0000-000000000004';
  readonly StatementsBatchProcessTypeUid = '10000101-0000-0000-0000-000000000005';
  readonly CreditCardConsentsBatchProcessTypeUid = '10000101-0000-0000-0000-000000000006';
  readonly BPatientChartsatchProcessTypeUid = '10000101-0000-0000-0000-000000000007';
  readonly CancerRegistryBatchProcessTypeUid = '10000101-0000-0000-0000-000000000077';

  // Plan Now
  readonly DpnGroupGenericNos = '4cae9a8d-baa8-4d5f-a6ad-1b628e575cc7';
  readonly DpnNodePlanRecall = '2bd193b7-7904-4261-8073-4d5308ada0ee';
  readonly DpnNodePlanPatientInstructions = 'e4e7edd0-2b55-47de-a746-3cf134cecd1c';
  readonly DpnNodePlanPlanNotes = '136cf736-82ff-44e0-8cbd-1a52f839aa1a';
  readonly DpnNodePlanInternalNotes = '2552419f-81b6-41eb-8804-8b35652c9778';

  // ListUIConfiguration
  readonly Default_ListUIConfigurationUid = '5175CAF7-9F06-4017-9E4A-506FE7EC110E';

  // ListDxTileConfiguration
  readonly NOS_ListDxTileConfigurationUid = 'C02D67C1-BACB-491E-906E-E7DA411297AA';

  // Full Note Dashboard
  readonly FndHardBreakUid = '6693557C-26AB-4E29-BE0A-22158D9D2598';
  readonly FndActiveProblemsTileUid = '983A37AE-290C-4C42-98B9-30B1067E1F52';
  readonly FndMedicationTileUid = '5cce721e-010e-40ee-a454-baa141b1ef47';
  readonly FndPatientStoryTileUid = 'bd843b3c-0e2e-49d4-8a32-524937f6928b';
  readonly FndVisitActionItemTileUid = '8ac73cea-6c89-4fb4-9b75-fce23b4b252c';
  readonly FndRecentVisitsTileUid = '2f25632e-1923-48aa-8d17-13700b7ba91b';
  readonly FndActivitySinceLastVisitTileUid = '30ae8a3f-c087-7c1e-2a97-3ad809bef893';
  readonly FndVisitInformationTileUid = 'F5E34A86-78F7-4E39-8580-0AABC2133AA9';
  readonly FndClinicalDecisionSupportTileUid = 'DEFB1F1A-C488-4459-A6B2-7D9092D8F9CB';
  readonly FndCCHPITileUid = 'CE149DAB-2B63-455E-B68E-975D1964399E';
  readonly FndROSTileUid = '5858CFCC-7403-4E2C-ABA1-C8543FDF2BDC';
  readonly FndPhysicalExamTileUid = 'A454338B-4319-4E97-A9BD-DF66AA598E9A';
  readonly FndPlanTileUid = 'AF046BF1-3317-4D62-847C-2EECD804667D';
  readonly FndVitalsResultsTileUid = 'c9d5765b-8949-4325-bae8-c1c614ce3b07';
  readonly FndDiagnosisSpecificTileUid = 'e00d6551-5021-471a-8737-30d15407be29';
  readonly FndEducationFormTileUid = '1EB8E5B8-6815-41FC-BF9A-43C31959D4BE';
  readonly FndNoteActionTileUid = '83B586CF-EED4-41EA-84B9-D755EFF8161D';
  readonly FndVisitDiagnosisTileUid = 'c7df9a1e-d8ff-4644-a4be-0a9ce0b1f6f5';
  readonly FndAttachmentsTileUid = '81c59f62-b75b-486c-95f5-20c3810783a4';
  readonly FndServicesOrderedTileUid = '720df3b2-0c8e-4952-aacd-3bc31f2d187f';
  readonly FndServicesPerformedTileUid = '9b0c2d86-9991-4651-bbed-8abd27263d19';
  readonly FndAllergiesTileUid = '83679BFB-575A-4A33-9E41-C5AF9B4DBCEF';
  readonly FndAppointmentHistoryTileUid = '776263E9-2F1B-4CCE-BF5E-B8C3976FC80A';
  readonly FndHistoryTileUid = 'C47A8B76-4B96-4DEB-A9EE-FD645BAEFA08';
  readonly FndPatientCarePlanTileUid = '80FF4AE7-330A-4BD4-A0AE-62DC935092F3';
  readonly FndAssessmentTileUid = '14bd46e7-84db-4269-8d0b-6dfb9fab05d0';
  readonly FndFhirClientsTileUid = '5511954E-1036-4B87-BF22-9F2221F0CD53';

  // Macro Groups
  readonly MacroGroupSystemUid = 'D5ECBD0A-5DCF-4E76-B1F9-B490A00AC60B';
  readonly MacroGroupCommonUid = 'CFC5875B-FD3D-4EDC-BAA8-5CA0291B64ED';

  // Home Dashboard
  readonly HdUnsentPrescriptionsTileUid = 'DAD2A405-8AF3-4831-8BC6-52B5C8959558';
  readonly HdPatientAllergiesTileUid = '2D5F5A45-F25D-4B2F-A779-A8C0A800F6A4';
  readonly HdPatientMedicationsTileUid = '761F9AC2-0B0F-447C-9968-E79737F41874';
  readonly HdPatientActiveProblemsTileUid = '75727057-82A0-412A-BD19-7EDCE6DC1DAF';
  readonly HdPatientVitalsTileUid = 'C9ECFED3-2CB1-44C1-80CA-4895322453E6';
  readonly HdPatientInfoVisitsTileUid = 'D9687CFE-DCC9-435C-B379-5D24941DCCB5';
  readonly HdRecentVisitsTileUid = '7F95EE5A-DC73-4F00-944B-27B8BF022894';
  readonly HdReplicationMonitorTileUid = 'DFEE9D62-92D6-4068-845F-CB11C528D95D';
  readonly HdWaitingListTileUid = 'C68E9C15-86C4-473F-A123-021F6CAE4B97';
  readonly HdDocumentLinkingTileUid = '973B8745-2931-46B9-8BEE-565385B64E84';
  readonly HdMessageCountsTileUid = '0D30D1A6-D56F-4404-BA37-221531FDFE9B';
  readonly HdMessagesTileUid = '4EED7F56-6A65-41E5-B568-80A4701C33E1';
  readonly HdAppointmentQueueTileUid = '72214A6B-49C5-4D11-8303-D08B23FF58F5';
  readonly HdExternalPatientsTileUid = '9AD5225E-4E56-4916-8E27-C7B1DC154520';
  readonly HdVisitNotesTileUid = 'C7B0C52D-C2B3-43E8-A28F-8031D81F29BA';
  readonly HdPromotingInteroperabilityTileUid = '8200F46B-A734-409B-9B14-25E8204EED48';
  readonly HdProviderEMTileUid = '1947C8B8-050E-465C-8217-91EF525BC1EC';
  readonly HdFhirClientsTileUid = 'C5597BE5-A524-420A-AF61-956652479B21';

  // Rx Workflow  Rx Pending ePA
  readonly RxWorkflowPendingEpaUid = '2A360B72-2B79-4539-A264-3FDB799FCF64';
  readonly RxWorkflowPendingSpeUid = '8C0970A8-7373-4395-AB4A-101B757BE584';
  readonly RxWorkflowPendingFormularyUid = 'AB18D690-5635-466E-8B45-E0D70B81877F';

  // Homepage
  readonly HomepageDefaultConfiguration = 'ef5b52e3-cb68-4fa7-8894-3d66d5cbc5c5';
  readonly HomepageClinicalConfiguration = 'b415487a-4616-45a4-8b9a-d501fc83508b';
  readonly HomepageAdministratorConfiguration = '816b8edd-3243-4c17-a151-47ca1e1d02f8';

  readonly hdAppointmentQueueSortOptionAppointmentTimeUid = '0AD174FE-014E-496C-8BE1-3ECEDFEBE6D8';

  // External Material Source Categories

  readonly EmsCategoryHomepageQuickStartGuide = 'F296B042-3353-4E62-9BEB-2A605529922A';
  readonly EmsCategoryAprimaToday = 'F05A959D-142E-4028-8E1D-59A213436CCA';
  readonly EmsCategoryTutorial = 'BF2A0FD9-B0A4-4C9B-B07A-D0DB34DE531F';

  // Find Controls
  readonly HomepageConfigurationFindControl = '60fdb299-887b-4530-b979-9efc5c3105f0';
  readonly FndConfigurationFindControl = '01a60c01-dbb6-405e-9cf1-14a9f56b6f7a';
  readonly ListFindControlBehaviorFindControl = '6cf2b96a-de25-4959-924c-7cdba9ea7572';

  // For retrieving User Settings
  readonly UserSettingAngularHomepageUid = 'f18c92dc-1852-4f05-9d79-875e3838717d';
  readonly UserSettingPrintSetUid = '29a7b66b-479c-4b74-ab2e-c73b7a26399f';
  readonly UserSettingArBatchUid = '110da9c8-ae18-4961-81e4-9abc766594e7';

  // Well known Angular Homepage menu items
  readonly AngularHomepagePatientCenterMenuItemUid = '9832cd10-bfa6-4d79-85a4-c83f0f90d1ee';
  readonly AngularHomepagePatientProviderTrackingMenuItemUid = 'c968cebe-4c2d-4ed4-859d-c70b8353d2bb';
  readonly AngularHomepagePatientRecallsMenuItemUid = 'aade84b7-2b8a-4f65-a08d-219259ee0814';
  readonly AngularHomepageCareManagementReviewMenuItemUid = '074e0921-d9b6-4042-8e44-993236bfbee0';
  readonly AngularHomepageSchedulerMenuItemUid = 'd8936d29-b44f-4205-8d17-3a39037ae1f2';
  readonly AngularHomepageMassAppointmentsUpdateMenuItemUid = 'afd6616f-eac4-4c34-94f4-2c11c71a8073';
  readonly AngularHomepageExternalPatientsMenuItemUid = 'ff15d366-d9f4-4f6a-89b3-50ea243c90dc';
  readonly AngularHomepageNewMessageParentMenuItemUid = '15d5b603-56ca-4ccd-8d7e-81b3e23200fd';
  readonly AngularHomepageMessageCenterMenuItemUid = 'a560dc13-b972-4035-b95b-530626a4ea23';
  readonly AngularHomepageInstantMessageMenuItemUid = 'ff48c058-70cc-4dab-9273-c89a7abf406b';
  readonly AngularHomepageListEditorMenuItemUid = 'f61b1d5e-9323-4315-9a44-81567d23458f';
  readonly AngularHomepageConfigureQuickSearchMenuItemUid = '3555fc70-0f76-45fe-bc62-3657800fca30';
  readonly AngularHomepagePracticeSettingsMenuItemUid = 'd6247b7e-a0bf-45a3-a2f9-8f7b1258e078';
  readonly AngularHomepagePatientPortalSettingsMenuItemUid = '97239d2c-f567-4b97-93bd-b35347e1a36d';
  readonly AngularHomepageFacilityPortalSettingsMenuItemUid = '9230f482-5710-4c47-8d45-c2774f12b4ef';
  readonly AngularHomepageConfigurePatientConnectMenuItemUid = '136e2e8f-0890-4c69-8fdf-c21f2f98b02e';
  readonly AngularHomepageConfigureSignaturePadMenuItemUid = 'f5c6ef8c-20e2-4250-8338-d87228c4cf67';
  readonly AngularHomepageConfigureRequiredFieldsMenuItemUid = 'a0db3620-5a7b-4dad-bb52-71425dba9922';
  readonly AngularHomepageModifyCurrentUserMenuItemUid = '8ae3677b-a391-4437-a1f9-713d55289ceb';
  readonly AngularHomepageModifyCurrentSettingsMenuItemUid = 'b6f91c62-b60d-4b29-a4ef-9a0404a6f848';
  readonly AngularHomepageResetMyConfigurationMenuItemUid = '21b01412-9ce7-425f-a49b-0b765420711e';
  readonly AngularHomepageConfigureMyPrintSetMenuItemUid = '6a170afd-10b0-41b0-893b-1cba6dcf86b2';
  readonly AngularHomepageHomeConfigurationMenuItemUid = '9b9ee7a6-d261-42ac-9b33-fd13ff5f66b6';
  readonly AngularHomepageFndConfigurationMenuItemUid = '018898a6-7317-4b56-97ed-f99fa6298bd0';
  readonly AngularHomepageConfigureFndDstMenuItemUid = '9af238e8-dc8b-439d-bbdb-7d42e5aeedf2';
  readonly AngularHomepageReviewPriorAuthMenuItemUid = '1386d47f-c72c-45da-aade-9b4022f037c7';
  readonly AngularHomepageTrackRxMenuItemUid = '0eee3625-e7d2-4bbc-9cbb-62707ed8c2f4';
  readonly AngularHomepageReferralTrackingMenuItemUid = 'd97cbf35-7f88-4e83-b104-26df1a948f68';
  readonly AngularHomepageTrackOutstandingOrdersMenuItemUid = '9db52eab-61e4-4f76-bc92-8a95520e14dc';
  readonly AngularHomepageTrackResultsMenuItemUid = '0ce29f82-c43e-43de-bf78-e2072467f7fd';
  readonly AngularHomepageInterfaceDataDetailMenuItemUid = '3b89f81a-8f79-4c46-ac75-7caf872c2fb4';
  readonly AngularHomepageTrackEMedHxMenuItemUid = '5f339210-e2d6-46ff-bfc2-26841d27cef4';
  readonly AngularHomepageTrackRxBenefitsMenuItemUid = 'fc329a9b-93cb-47e8-a037-7e86643f5162';
  readonly AngularHomepageTrackFaxMenuItemUid = 'a42699e1-9a98-4ab1-be58-7c912f6bdb41';
  readonly AngularHomepageTrackVoiceTranscriptionsMenuItemUid = 'a81e986f-84b5-4a5e-ab72-e0b9fd9dcb81';
  readonly AngularHomepageTrackingVisitCenterMenuItemUid = '4437fad9-7462-4418-a8e2-3109cf67fa58';
  readonly AngularHomepageFinancialBatchMenuItemUid = '0c9805ad-6672-40b8-872d-1719bdc89f98';
  readonly AngularHomepageTrackSuperbillsMenuItemUid = 'db7b8034-7674-46d1-819f-60feb5dce4c0';
  readonly AngularHomepageTrackSBProceduresMenuItemUid = '993fc6a6-540f-44cf-a6e4-b567e4d6da07';
  readonly AngularHomepageTrackVisitWithoutSBMenuItemUid = '0b78d46d-3c2c-4087-b0ae-6e4ebe4366f6';
  readonly AngularHomepageTrackAppointmentWithoutSBMenuItemUid = '3e6b7a8b-8ec4-4605-8eb2-c1df50bed339';
  readonly AngularHomepageTrackFinancialsMenuItemUid = 'd7217946-d2c8-4923-8279-12e0bc5aa1b3';
  readonly AngularHomepageProcessClaimsMenuItemUid = 'f3873ea3-9b3b-47b9-811d-096f71374c80';
  readonly AngularHomepageSuperbillReportsMenuItemUid = 'dcb976c6-07c3-4359-8b1a-8cd883e484b4';
  readonly AngularHomepageFilingHistoryMenuItemUid = 'be9e9820-0d15-4c57-b36c-24b2701f05db';
  readonly AngularHomepageAlignHcfaMenuItemUid = '1f04248d-4167-4a5c-babb-e39030f404c4';
  readonly AngularHomepageCopayLogMenuItemUid = 'b2bc531c-dcf3-4f30-af7d-e957f77dc94b';
  readonly AngularHomepageTrackPaymentsMenuItemUid = 'c27e3075-29d8-4c4e-a1ee-93c130af96f0';
  readonly AngularHomepageTrackPayerCreditsMenuItemUid = '986779ee-031b-4cc2-929d-d9e3122e1711';
  readonly AngularHomepageTrackTransactionsMenuItemUid = '5488b18f-68d6-4080-ba3a-95866188e075';
  readonly AngularHomepageEdiResponsesMenuItemUid = 'c9786f81-6d2c-45e9-b23f-45465f320a44';
  readonly AngularHomepageCreditCardSettlementsMenuItemUid = '32ee5027-9327-4748-bde7-7ed2e419b4e3';
  readonly AngularHomepageCreditCardTransactionsMenuItemUid = 'c43e2e70-ffa1-4a80-bfdb-ea91f7ec5500';
  readonly AngularHomepageCreditCardPaymentsMenuItemUid = 'f59a19dd-c0f7-4ee2-9356-af7f00f583cd';
  readonly AngularHomepageBatchCreditCardConsentProcessingMenuItemUid = '591bfcdf-007c-4494-94ab-00fed9070939';
  readonly AngularHomepageResponsiblePartyCollectionsMenuItemUid = '22cbeefb-6012-4d6a-9253-266a7189704d';
  readonly AngularHomepageAccountReceivableInsurancePromisesMenuItemUid = '4abaa812-75fc-4e3a-a449-216ba7951dfa';
  readonly AngularHomepagePrintCollectionLettersMenuItemUid = 'd7de2f91-5788-4b58-8dcb-820d366e7105';
  readonly AngularHomepageAccountReceivableStatementMenuItemUid = '4e3a9deb-721f-496e-a0de-5999098d7de3';
  readonly AngularHomepageBillingUpdateClosingDateMenuItemUid = '3defe1ee-a99d-44b3-a47d-a63f29ae1e1a';
  readonly AngularHomepageInsurancePayerPlanCenterMenuItemUid = '7fe863bf-5ade-4adb-ae85-6795b82d9de3';
  readonly AngularHomepageDocumentManagementMenuItemUid = 'fd8ef512-5479-4dbf-b74d-9f68f796bb67';
  readonly AngularHomepageDocumentImportingMenuItemUid = '589334b3-0042-48b8-9bdb-1b0330467d43';
  readonly AngularHomepageDocumentLinkingV1MenuItemUid = 'ed589716-a8af-46c2-a745-a2bd30f0d11f';
  readonly AngularHomepageDocumentLinkingV2MenuItemUid = '0ec3642f-45fc-4e45-9867-711b5bb43dc8';
  readonly AngularHomepageDicomImageViewerMenuItemUid = '468f7594-c7f1-4e04-b4ed-2ce0d95385cd';
  readonly AngularHomepageDocumentBatchProcessingMenuItemUid = '2d2eb653-bf57-4b66-b258-2b40b83cf295';
  readonly AngularHomepageBulkPatientImportMenuItemUid = '00232875-ebad-43ad-83cc-a3ab4949f00d';
  readonly AngularHomepageReportsMenuItemUid = 'bcfbc606-9f2a-441d-b2fc-d7e01b805975';
  readonly AngularHomepageDocBatchMgmtCqmMenuItemUid = '8cca41ef-4fc5-4fe7-8359-efbc6e0c6454';
  readonly AngularHomepageAuditTrailMenuItemUid = '8a65279b-80c3-43ed-86a2-b46fede1e451';
  readonly AngularHomepageJobHistoryMenuItemUid = '69c9dfec-f415-4b2d-8a02-51e3b9722409';
  readonly AngularHomepageReplicationClientMenuItemUid = 'd91047de-57dc-4e11-a7a5-2d51e72f21f7';
  readonly AngularHomepageUnlockPatientMenuItemUid = 'e6205482-3a56-4db4-99cf-0881e5a921c8';
  readonly AngularHomepageReloadLicenseMenuItemUid = '3e8efbb1-9637-4399-be36-b3228a8a21bb';
  readonly AngularHomepageReloadUserMenuItemUid = '31d2c176-7736-440a-973d-680f4b82d31a';
  readonly AngularHomepageQuickStartGuideMenuItemUid = '274f6471-3b48-4cd0-9042-852ac5546c0c';
  readonly AngularHomepageHelpContentsMenuItemUid = '7c1851fc-38e4-4d45-8246-260a35b178c1';
  readonly AngularHomepageReleaseNotesMenuItemUid = '867c08ea-298e-43ed-9734-8aef96b3816d';
  readonly AngularHomepageHelpFaqMenuItemUid = '99f208a8-3928-456a-b9a7-6dc86c9cdc25';
  readonly AngularHomepageHelpSupportMenuItemUid = '17e46679-a5be-4b4a-8585-52f2ebca571c';
  readonly AngularHomepageHelpCgmPortalMenuItemUid = 'C477664E-307D-40EE-AEEA-9B524636275E';
  readonly AngularHomepageHelpEngageMenuItemUid = '1bb99f87-6d0b-47c1-9afc-62b922ee63be';
  readonly AngularHomepageHelpDocumentationMenuItemUid = 'ff7e2d42-d34d-42b4-9872-e37d8d870969';
  readonly AngularHomepageHelpAboutMenuItemUid = '4f88d7d3-a179-47b5-9f63-482f9179739d';
  readonly AngularHomepageFindPatientMenuItemUid = '6ce28061-bcfe-49bb-805a-adee7755133d';
  readonly AngularHomepageFindPatientAndOpenOpsMenuItemUid = '48bfaa93-0fa6-48a7-a333-25f7386f49ea';
  readonly AngularHomepageFindPatientAndOpenRpnMenuItemUid = 'cbf1cee8-c27c-4aa3-b245-a43077cee701';
  readonly AngularHomepageExternalSiteParentMenuItemUid = '49e8dff8-d6be-4038-984f-3e45e09addc1';
  readonly AngularHomepageDisplaySettingMenuItemUid = '8a4e6195-efe9-4dbe-8678-fffe25b6c7d5';
}
