import { Injectable } from '@angular/core';

import { BrowserService } from './BrowserService';
import {StorageRepository, throwInvalidKeyError} from './IStorageRepository';
import { StorageInMemoryRepository } from './StorageInMemoryRepository';

@Injectable({
  providedIn: 'root',
})
export class StoragePerSessionRepository implements StorageRepository {
  constructor(
    browserService: BrowserService,
    private storageInMemoryRepository: StorageInMemoryRepository,
  ) {
    this.windowObj = browserService.getWindow();
    this.supported = this.isSessionStorageSupported();
  }

  private readonly supported: boolean;
  private readonly windowObj: Window;

  private isSessionStorageSupported(): boolean {
    // https://gist.github.com/paulirish/5558557#gistcomment-1755099
    try {
      const storage = this.windowObj.sessionStorage;
      const x = '__storage_test__';
      storage.setItem(x, x);
      storage.removeItem(x);
      return true;
    } catch {
      return false;
    }
  }

  clear(): void {
    if (this.supported) {
      this.windowObj.sessionStorage.clear();
    } else {
      this.storageInMemoryRepository.clear();
    }
  }

  getItemOrThrow(key: string): string {
    return this.getItem(key) ?? throwInvalidKeyError(key);
  }

  getItem(key: string): string | null {
    if (this.supported) {
      return this.windowObj.sessionStorage.getItem(key);
    } else {
      return this.storageInMemoryRepository.getItem(key);
    }
  }

  removeItem(key: string): void {
    if (this.supported) {
      this.windowObj.sessionStorage.removeItem(key);
    } else {
      this.storageInMemoryRepository.removeItem(key);
    }
  }

  setItem(key: string, value: string): void {
    if (this.supported) {
      this.windowObj.sessionStorage.setItem(key, value);
    } else {
      this.storageInMemoryRepository.setItem(key, value);
    }
  }
}
