
export abstract class ErrorServiceUpgrade {
  abstract convertToErrorMessages(
    error: unknown,
  ): string[];
}

export function ErrorServiceFactory(i: angular.auto.IInjectorService) {
  return i.get<ErrorServiceUpgrade>('ErrorService');
}

export const ErrorServiceAjsProvider = {
  deps: ['$injector'],
  provide: ErrorServiceUpgrade,
  useFactory: ErrorServiceFactory,
};
