import { Injectable } from '@angular/core';

import { AprimaAnalyticsService } from './AprimaAnalyticsService';
import { BridgeService } from './BridgeService';
import { WebCommonStorageRepository } from './WebCommonStorageRepository';

@Injectable({
  providedIn: 'root',
})
export class LogOffService {
  static readonly ajsFactoryName = 'LogOffService';

  constructor(
    private aprimaAnalyticsService: AprimaAnalyticsService,
    private bridge: BridgeService,
    private webCommonStorageRepository: WebCommonStorageRepository,
  ) {
  }

  logoff() {
    this.webCommonStorageRepository.removeJsonWebToken();
    this.webCommonStorageRepository.removeUserId();
    this.aprimaAnalyticsService.sendEvent('user', 'click', 'logoff', '0');

    this.bridge.fireEvent('logoff', {
      message: 'logoff',
    });

    return Promise.resolve();
  }
}
