import { Injectable } from '@angular/core';

import { BrowserService } from './BrowserService';
import { StorageRepository, throwInvalidKeyError } from './IStorageRepository';
import { StoragePerSessionRepository } from './StoragePerSessionRepository';

@Injectable({
  providedIn: 'root',
})
export class StorageAcrossSessionRepository implements StorageRepository {
  constructor(
    browserService: BrowserService,
    private storagePerSessionRepository: StoragePerSessionRepository,
  ) {
    this.windowObj = browserService.getWindow();
    this.supported = this.isLocalStorageSupported();
  }

  private readonly supported: boolean;
  private readonly windowObj: Window;

  private isLocalStorageSupported(): boolean {
    // https://gist.github.com/paulirish/5558557#gistcomment-1755099
    try {
      const storage = this.windowObj.localStorage;
      const x = '__storage_test__';
      storage.setItem(x, x);
      storage.removeItem(x);
      return true;
    } catch {
      return false;
    }
  }

  clear(): void {
    if (this.supported) {
      this.windowObj.localStorage.clear();
    } else {
      this.storagePerSessionRepository.clear();
    }
  }

  getItemOrThrow(key: string): string {
    return this.getItem(key) ?? throwInvalidKeyError(key);
  }

  getItem(key: string): string | null {
    if (this.supported) {
      return this.windowObj.localStorage.getItem(key);
    } else {
      return this.storagePerSessionRepository.getItem(key);
    }
  }

  removeItem(key: string): void {
    if (this.supported) {
      this.windowObj.localStorage.removeItem(key);
    } else {
      this.storagePerSessionRepository.removeItem(key);
    }
  }

  setItem(key: string, value: string): void {
    if (this.supported) {
      this.windowObj.localStorage.setItem(key, value);
    } else {
      this.storagePerSessionRepository.setItem(key, value);
    }
  }
}
