import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges, ViewChild,
} from '@angular/core';
import {
  Observable,
  of,
} from 'rxjs';
import {
  finalize, takeUntil,
} from 'rxjs/operators';
import { BridgeService, DeviceSettingsRepository } from 'webcommon/legacy-common';
import {
  ApDocumentBase,
  AttachmentDto,
  AttachmentHandlerOptionsDto,
  AttachmentRequestConfig,
  DownloadableDocument,
} from 'webcommon/shared';
import {AttachmentHandlerSignatureAQN} from 'webcommon/webapi';
import { ApBaseComponent } from '../ap-base-component/index';
import { DocumentService } from './DocumentService';
import {ApSignPdfComponent, DeferredFoxitPdfRenderer} from './pdfs/index';

@Component({
  selector: 'ap-enhanced-document-viewer',
  templateUrl: './ap-enhanced-document-viewer.component.html',
})
export class ApEnhancedDocumentViewerComponent extends ApBaseComponent implements OnChanges {

  @Input() disableFullscreen = false;
  @Input() disableInlinePdf?: boolean;
  @Input() documentId: string;
  @Input() documentLoad: (documentId: string, requestConfig: AttachmentRequestConfig) => Observable<AttachmentDto>;
  @Input() getAttachmentHandler?: (documentId: string) => Observable<AttachmentHandlerOptionsDto>;

  @Input() enableDownload = false;
  @Input() downloadFileName?: string;
  @Input() documentDownload?: (documentId: string) => Observable<DownloadableDocument>;
  @Input() isReadOnly: boolean;
  @Input() messageStatus: string | null = null;

  @Input() hasStaticUrl?: boolean;
  @Input() documentGetStaticUrl?: (documentId: string) => Observable<string>;
  @Input() documentExtension?: string;

  @Input() useViewerOverride?: 0 | 1 | 2;

  @Output() attachmentHandlerChanged: EventEmitter<string> = new EventEmitter();

  @ViewChild(ApSignPdfComponent) private apSignPdf?: ApSignPdfComponent;
  get deferredPdfRenderer(): DeferredFoxitPdfRenderer | undefined {
    return this.apSignPdf?.deferredPdfRenderer;
  }

  // tslint:disable-next-line: variable-name
  private _attachmentHandler: string;
  get attachmentHandler(): string {
    return this._attachmentHandler;
  }
  set attachmentHandler(val: string) {
    this._attachmentHandler = val;
    this.attachmentHandlerChanged.emit(val);
  }

  attachmentHandlerLoading = false;
  attachmentAutoOpen = false;

  downloadingDocument = false;
  loadingDocument = false;
  foxitConvertableExtensions = [
    'pdf',
    'jpg',
    'jpeg',
    'png',
    'tiff',
    'tif',
    'docx',
    'doc',
    'rtf',
  ];
  readonly isPrm: boolean;
  currentView: 0 | 1 | 2 | null = null;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.documentId && changes.documentId.currentValue) {
      this.setAttachmentHandler(changes.documentId.currentValue);
    }
    if (changes.isReadOnly) {
      this.currentView = this.getViewer();
    }
  }

  private onDocumentLoad(documentId: string, requestConfig: AttachmentRequestConfig): Observable<AttachmentDto> {
    return this.documentLoad(documentId, requestConfig);
  }

  // Have to do this to be able to pass a reference to this function without losing the 'this' binding.
  // These are essentially just wrapper functions over the actual logic.
  onDocumentLoadRef = (documentId: string, requestConfig: AttachmentRequestConfig) => {
    return this.onDocumentLoad(documentId, requestConfig);
  }

  private onDocumentDownload(): Observable<DownloadableDocument> {
    if (!this.documentDownload) {
      return of({
        Data: '',
      });
    }

    this.downloadingDocument = true;
    const download$ = this.documentDownload(this.documentId).pipe(
      finalize(() => this.documentDownloadComplete()),
    );
    return download$;
  }

  onDocumentDownloadRef = () => {
    return this.onDocumentDownload();
  }

  constructor(
    private readonly documentService: DocumentService,
    private readonly bridgeService: BridgeService,
    private readonly deviceSettingsRepository: DeviceSettingsRepository,
  ) {
    super();
    this.isPrm = this.deviceSettingsRepository.isPrm;
  }

  // 0 - Windows viewer
  // 1 - Foxit viewer
  // 2 - Other viewer
  getViewer(): 0 | 1 | 2 {
    if (this.useViewerOverride) return this.useViewerOverride;
    if (!this.attachmentHandler) {
      return 0;
    }
    if (this.attachmentHandler === AttachmentHandlerSignatureAQN &&
      this.isReadOnly === false &&
      this.messageStatus !== 'Approve'
    ) {
      return 1;
    }
    return 2;
  }

  openWindowsViewer(): void {
    this.bridgeService.fireEvent('openAttachment', { attachmentId: this.documentId });
  }

  setAttachmentHandler(documentId: string) {
    if (!this.getAttachmentHandler) {
      return;
    }
    this.attachmentHandlerLoading = true;
    this.getAttachmentHandler(documentId).pipe(
      finalize(() => this.onHandlerLoadComplete()),
      takeUntil(this.onDestroy$),
    ).subscribe(
      (data) => this.onHandlerLoadSuccess(data),
    );
  }

  private onHandlerLoadComplete() {
    this.attachmentHandlerLoading = false;
  }

  private onHandlerLoadSuccess(data: AttachmentHandlerOptionsDto) {
    this.attachmentHandler = data.HandlerAQN;
    this.attachmentAutoOpen = data.AutoOpen;

    this.currentView = this.getViewer();

    if (this.currentView === 0) {
      this.currentDocumentChanged(null);

      // Auto open the attachment in the windows viewer
      if (this.attachmentAutoOpen) {
        this.openWindowsViewer();
      }
    }
  }

  currentDocumentChanged(doc: ApDocumentBase | null) {
    // don't try to update hasStaticUrl unless a value wasn't passed in
    if (this.hasStaticUrl !== undefined) {
      return;
    }

    if (!doc) {
      this.hasStaticUrl = false;
      return;
    }

    this.hasStaticUrl = this.documentService.hasStaticUrl(doc);
  }

  canDownloadDocument() {
    return this.enableDownload && this.documentDownload && this.documentService.supportsDownloadToFile();
  }

  canOpenInSeparateTab() {
    return this.hasStaticUrl && this.documentGetStaticUrl && this.documentService.supportsOpenInSeparateTab();
  }

  private documentDownloadComplete() {
    this.downloadingDocument = false;
  }

  onDocumentOpenInSeparateTab(documentId: string) {
    if (!this.documentGetStaticUrl) {
      return;
    }
    this.documentService.openUrlInSeparateTab(this.documentGetStaticUrl(documentId));
  }
}
