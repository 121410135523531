import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AttachmentDto,
  DownloadableDocument,
  PatientPortalAttachmentDto,
} from 'webcommon/shared';
import { PatientDocumentSet } from 'webcommon/shared';
import { PatientPortalHttpClientService } from '../PatientPortalHttpClientService';

@Injectable({
  providedIn: 'root',
})
export class PatientPortalAttachmentRepository {
  constructor(
    private httpClientService: PatientPortalHttpClientService
  ) {
  }

  getDocumentList(patientId: string): Observable<PatientDocumentSet> {
    const endpointUrl = `/person/${patientId}/documents`;
    return this.httpClientService.sendGetRequest(endpointUrl);
  }

  getAttachmentContent(patientId: string, attachmentId: string): Observable<PatientPortalAttachmentDto> {
    const endpointUrl = `person/${patientId}/attachment/${attachmentId}`;
    return this.httpClientService.sendGetRequest(endpointUrl);
  }

  getAttachmentForViewing(
    patientId: string,
    attachmentId: string,
    height: number,
    width: number,
    page: number,
  ): Observable<AttachmentDto> {
    const httpParams = new HttpParams()
    .append('height', height.toString())
    .append('width', width.toString())
    .append('page', page.toString());
    const endpointUrl = `person/${patientId}/view-attachment/${attachmentId}`;
    return this.httpClientService.sendGetRequest(endpointUrl, httpParams);
  }

  getAttachmentForDownload(
    patientId: string,
    attachmentId: string
  ): Observable<DownloadableDocument> {
    const endpointUrl = `person/${patientId}/download-attachment/${attachmentId}`;
    return this.httpClientService.sendGetRequest(endpointUrl);
  }
}
