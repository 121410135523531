import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { APRIMA_HTTP_CONTEXT_CACHE_INSTANCE } from './context-tokens';

function httpLogPrefix(cacheId: string) {
  return 'AprimaCache-' + cacheId + '-Http';
}

export class CacheInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let cacheInstance = req.context.get(APRIMA_HTTP_CONTEXT_CACHE_INSTANCE);

    if (cacheInstance && req.method.toLowerCase() !== 'get') {
      console.error(`Ignoring cacheInstance for ${req.method} to ${req.url}`);
      cacheInstance = undefined;
    }

    if (!cacheInstance) {
      return next.handle(req);
    }

    const cachedValue = cacheInstance.get(req.url);
    const cacheInfo = cacheInstance.info();
    if (typeof cachedValue !== 'undefined') {
      // tslint:disable-next-line:no-console
      console.debug(httpLogPrefix(cacheInfo.name), '-- Retrieved "' + req.url + '" from cache. Result:', cachedValue);

      return of(cachedValue);
    }

    // TODO: The AngularJS implementation handled the scenario where two simultaneous requests
    // were made for the same URL and only sent one request.
    // This implementation doesn't do that yet.
    return next.handle(req).pipe(
      tap(event => {
        // There may be other events besides the response.
        if (event instanceof HttpResponse && cacheInstance) {
          cacheInstance.put(req.url, event); // Update the cache.
        }
      })
    );
  }
}
