import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { IColor } from 'webcommon/shared';

export enum BorderDirection {
  Top = 'Top',
  Bottom = 'Bottom',
  Left = 'Left',
  Right = 'Right',
}

@Directive({
  selector: '[apColorBorder]',
})
export class ApColorBorderDirective implements OnInit {
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) { }

  @Input() apColorBorder: IColor;
  @Input() borderDirection?: BorderDirection;

  ngOnInit(): void {
    if (this.apColorBorder && this.apColorBorder.Color) {
      switch (this.borderDirection) {
        case BorderDirection.Top: {
          this.renderer.addClass(this.el.nativeElement, 'top-border');
          this.renderer.setStyle(this.el.nativeElement, 'border-top-color', this.apColorBorder.Color);
          break;
        }
        case BorderDirection.Bottom: {
          this.renderer.addClass(this.el.nativeElement, 'bottom-border');
          this.renderer.setStyle(this.el.nativeElement, 'border-bottom-color', this.apColorBorder.Color);
          break;
        }
        case BorderDirection.Left: {
          this.renderer.addClass(this.el.nativeElement, 'left-border');
          this.renderer.setStyle(this.el.nativeElement, 'border-left-color', this.apColorBorder.Color);
          break;
        }
        case BorderDirection.Right: {
          this.renderer.addClass(this.el.nativeElement, 'right-border');
          this.renderer.setStyle(this.el.nativeElement, 'border-right-color', this.apColorBorder.Color);
          break;
        }
        default: {
          this.renderer.addClass(this.el.nativeElement, 'left-border');
          this.renderer.setStyle(this.el.nativeElement, 'border-left-color', this.apColorBorder.Color);
          break;
        }
      }
    }
  }
}
