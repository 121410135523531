import {
  DoBootstrap,
  NgModule,
  NgZone,
} from '@angular/core';
import { UrlService } from '@uirouter/core';

import { PatientPortalAngularJSModule } from './ajs/ajs.module';
import { PatientPortalConfigurationRepository } from './core';
import {
  CoreModule,
  LoginModule,
  TelehealthModule,
  DashboardModule,
} from './modules.index';

import { imports as conditionalImports } from './conditional.module';

import {
  WebCommonModule,
} from 'webcommon';
import {
  WebBridgeService,
} from 'webcommon/common-api';
import {
  AprimaCacheService,
  BridgeService,
  ConfigurationRepository,
  DeviceSettingsRepository,
  LogInService,
} from 'webcommon/legacy-common';
import { currentBsVersion, setTheme } from 'ngx-bootstrap/utils';

@NgModule({
  imports: [
    WebCommonModule,
    CoreModule,
    LoginModule,
    PatientPortalAngularJSModule,
    TelehealthModule,
    DashboardModule,
    ...conditionalImports,
  ],
})
export class AppModule implements DoBootstrap {
  constructor(
    private aprimaCacheService: AprimaCacheService,
    private bridgeService: BridgeService,
    private configurationRepository: ConfigurationRepository,
    private deviceSettingsRepository: DeviceSettingsRepository,
    private logInService: LogInService,
    private ngZone: NgZone,
    private portalConfigurationRepository: PatientPortalConfigurationRepository,
    private urlService: UrlService,
    private webBridgeService: WebBridgeService,
  ) { }

  ngDoBootstrap() {
    this.configurationRepository.loadConfiguration(this.portalConfigurationRepository);
    this.deviceSettingsRepository.loadConfiguration({
      isBrowser: true,
      isBrowserOrOverride: true,
      isDevice: false,
      isDeviceOrOverride: false,
      isPhone: false,
      isPhoneOrOverride: false,
      isTablet: false,
      isTabletOrOverride: false,

      isAndroid: false,
      isIos: false,
      isPrm: false,
      isStandaloneBrowser: true,
    });
    this.bridgeService.loadConfiguration(this.webBridgeService);
    this.aprimaCacheService.initialize();
    this.logInService.setDefaultRedirect();

    // Set the theme of ngx-bootstrap to bs3, because we are still stuck on bs3 css.
    // In the current version of ngx-bootstrap, it was defaulting to bs4.
    // (Also, there can be a bit of a race condition for the default,
    // since the guess it makes is based on certain css being loaded on the page.
    // look at https://github.com/valor-software/ngx-bootstrap/blob/v8.0.0/src/utils/theme-provider.ts)
    // This could maybe happen in main.ts as well, I'm not sure what the difference would be
    console.log('guessed bootstrap version for ngx-bootstrap:', currentBsVersion());
    setTheme('bs3');
    console.log('manually set bootstrap version for ngx-bootstrap:', currentBsVersion());

    // This UrlService setup logic previously was run not inside of setTimeout(),
    // but I changed it because of an issue:
    // In the case of going directly to the route of a lazy-loaded module (like refreshing the page on that route),
    // this code was running too early, and there would be an error throw like this:
    // Error: Trying to get the Angular injector before bootstrapping the corresponding Angular module.
    // If it runs at this point in time instead, then the bootstrapping will be done, and there won't be an error.
    // For non-lazy-loaded routes, they should still work just fine.
    //
    // Another potential option would be to have this run in the callback of the Output angularInitialized,
    // by doing something like this in main.ts:
    // ngModule.run(['CustomModuleRunService', (CustomModuleRunService: any) => {
    //   CustomModuleRunService.add(['$urlService', ($urlService: UrlService) => {
    //     $urlService.listen();
    //     $urlService.sync();
    //   }]);
    // }]);
    //
    // Another potential option could be in a setTimeout() in bootstrapModule(AppModule).then()
    // Some info from this helped as well: https://github.com/ui-router/angular-hybrid/issues/98,
    // even though the code doesn't look the same
    //
    // Another potential option would be to call this without setTimeout() in ForceLoadAngularComponent.ngOnInit()
    // because that would be late enough in the process to not cause this error case.
    setTimeout(() => {
      this.ngZone.run(() => {
        this.urlService.listen();
        this.urlService.sync();
      });
    });
  }
}
