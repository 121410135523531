import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {shareReplay} from 'rxjs/operators';

// this file contains utilities for accessing runtime stuff,
// like hashed file names from a manifest

// Represents the structure of the json outputted by webpack-manifest-plugin,
// but I think it's actually a format that is more generic than just that plugin.
// I'm not sure if it could be possible that the value would an []
// but i haven't seen that so far, so just typing it as string for now
export type AssetManifest = Partial<{
  [chunkName: string]: string;
}>;

const manifestJsonUrl = './manifest.json';


@Injectable({
  providedIn: 'root',
})
export class RuntimeUtils {
  // This could be converted to use fetch and moved to js, if we wanted to use to reference the manifest in
  // the index.html file, or something like that.
  // This expects manifest.json to be at the root directory of the build output.
  private assetManifest$ = this.httpClient.get<AssetManifest>(manifestJsonUrl, {
    // we are only loading this once, and don't want a cached file that would cause issues
    // after loading from a new build
    headers: createIgnoreCacheHeaders(),
  }).pipe(
    shareReplay({
      refCount: false,
      bufferSize: 1,
    })
  );

  constructor(
    private httpClient: HttpClient,
  ) {
  }

  // pass in chunkName, return actual fileUrl
  async getFileUrlByChunk(chunkName: string): Promise<string> {
    const manifest = await this.assetManifest$.toPromise();
    const fileUrl = manifest[chunkName];
    if (!fileUrl) {
      throw new Error(`Key '${chunkName}' doesn't exist in manifest.json.` +
      ` Some possible causes: A "bundleName" in angular.json or a chunkName in a dynamic import() may have been changed`);
    }
    return fileUrl;
  }
}

// not sure where this function really belongs, i guess leave it here for now
// just basically copied the logic from AprimaHttpClientService
function createIgnoreCacheHeaders(): HttpHeaders {
  // tslint:disable:object-literal-key-quotes
  return new HttpHeaders({
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    'Pragma': 'no-cache',
    'Expires': new Date().toString(),
  });
  // tslint:enable:object-literal-key-quotes
}
