import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  NgHybridStatesModule,
  UIRouterUpgradeModule,
} from '@uirouter/angular-hybrid';

import {
  CoreUiModule,
} from 'webcommon/core-ui';
import {
  AppRouteService,
} from 'webcommon/legacy-common';
import {
  AppRoutesDeclaration,
} from 'webcommon/shared';
import { CoreModule } from '../core/core.module';
import {
  ThLoginComponent, ThPaymentComponent, ThPaymentSummaryComponent,
} from './index';

const declareComponents = [
  ThLoginComponent,
  ThPaymentComponent,
  ThPaymentSummaryComponent,
];

const telehealthAppRoutes: AppRoutesDeclaration = {
  telehealth: {
    portalSettings: {
      hideFooter: false,
      hideHeader: true,
      hideToolbar: true,
      toolbarItem: 'blank',
    },
    route: '/telehealth/:apptId',
    state: 'telehealth',
  },
  thPayment: {
    portalSettings: {
      hideFooter: false,
      hideHeader: true,
      hideToolbar: true,
      toolbarItem: 'blank',
    },
    route: '/thPayment/:apptId',
    state: 'thPayment',
  },
  thPaymentSummary: {
    portalSettings: {
      hideFooter: false,
      hideHeader: true,
      hideToolbar: true,
      toolbarItem: 'blank',
    },
    route: '/thPaymentSummary/:apptId?txId',
    state: 'thPaymentSummary',
  },
};

const telehealthRoutes: NgHybridStatesModule = {
  states: [
    {
      component: ThLoginComponent,
      name: telehealthAppRoutes.telehealth.state,
      resolve: [
        {
          deps: [ TranslateService ],
          provide: '$title',
          useFactory: (translateService: TranslateService) => {
            return translateService.get('Payment Portal').toPromise();
          },
        },
      ],
      url: telehealthAppRoutes.telehealth.route,
    },
    {
      component: ThPaymentComponent,
      name: telehealthAppRoutes.thPayment.state,
      resolve: [
        {
          deps: [ TranslateService ],
          provide: '$title',
          useFactory: (translateService: TranslateService) => {
            return translateService.get('Payment Portal').toPromise();
          },
        },
      ],
      url: telehealthAppRoutes.thPayment.route,
    },
    {
      component: ThPaymentSummaryComponent,
      name: telehealthAppRoutes.thPaymentSummary.state,
      resolve: [
        {
          deps: [ TranslateService ],
          provide: '$title',
          useFactory: (translateService: TranslateService) => {
            return translateService.get('Payment Portal').toPromise();
          },
        },
      ],
      url: telehealthAppRoutes.thPaymentSummary.route,
    },
  ],
};

@NgModule({
  declarations: declareComponents,
  imports: [
    CommonModule,
    CoreModule,
    CoreUiModule,
    UIRouterUpgradeModule.forChild(telehealthRoutes),
  ],
})
export class TelehealthModule {
  constructor(appRouteService: AppRouteService) {
    appRouteService.setupAppRoutes(telehealthAppRoutes);
  }
}
