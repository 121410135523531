<div id="contact-us" class="container-fluid container-flush">
  <div class="panel panel-demo non-mychart chart-label">
    <div class="panel-heading panel-flush">
      <div class="panel-title" translate>Contact Us</div>
    </div>
    <div *ngIf="!loading && !loadError">
      <div class="row modal-content-lowercase">
        <p translate>Please contact our office if you need assistance or have any questions about the information available here.</p>
          <div>
            <p>
              <div>{{loginAssistanceInfo.PracticeName}}</div>
              <div>{{loginAssistanceInfo.Address1}}</div>
              <div *ngIf="loginAssistanceInfo.Address2">{{loginAssistanceInfo.Address2}}</div>
              <div>{{loginAssistanceInfo.City}}, {{loginAssistanceInfo.State}} {{loginAssistanceInfo.Zip}}</div>
              <div>{{loginAssistanceInfo.PhoneNumber}}</div>
          </div>
      </div>
    </div>
    <div *ngIf="loading">
      <div class="text-center">
        <ap-spinner size="3"></ap-spinner>
      </div>
    </div>
    <div *ngIf="!loading && loadError">
      <alert type="danger" translate>An error has occurred and Contact Us could not be loaded.</alert>
    </div>
  </div>
</div>
