import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PatientLoginInfoDto, TelehealthAppointmentLoginDto } from 'webcommon/shared';
import { PatientPortalHttpClientService } from '../PatientPortalHttpClientService';

@Injectable({
  providedIn: 'root',
})
export class TelehealthRepository {
  constructor(
    private readonly httpClientService: PatientPortalHttpClientService,
  ) { }

  postTelehealthLogin( request: TelehealthAppointmentLoginDto): Observable<PatientLoginInfoDto> {
    const endpointUrl = `telehealth-appointment-login`;
    return this.httpClientService.sendPostRequest(endpointUrl, request);
  }
}
