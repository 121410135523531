import {ApBsModalRef} from 'webcommon/shared';

export class ApActiveModal<T> {
  constructor(
    private _close: (value: T | PromiseLike<T>) => void,
    private _dismiss: (reason?: any) => void,
  ) { }

  bsModalRef: ApBsModalRef;

  private closedOrDismissed = false;
  private readyToHide = false;

  private onHide: () => void = () => {
    this._dismiss(undefined);
  }

  // close the modal with a specific return value
  emitAndClose(result: T | PromiseLike<T>): void {
    this.onHide = () => {
      this._close(result);
    };
    this.closedOrDismissed = true;
    this.tryHide();
  }

  dismiss(reason?: any): void {
    this.onHide = () => {
      this._dismiss(reason);
    };
    this.closedOrDismissed = true;
    this.tryHide();
  }

  markReadyToHide(): void {
    this.readyToHide = true;
    this.tryHide();
  }

  // Don't hide the modal until it's ready to be hidden,
  // and there has been a call to close or dismiss it.
  private tryHide() {
    if (this.closedOrDismissed && this.readyToHide) {
      this.bsModalRef.hide();
      this.onHide();
    }
  }
}
