import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AdvancedDirectiveAttachmentGroupDto,
  AttachmentDto,
  AttachmentHandlerOptionsDto,
  AttachmentRequestConfig,
  EditAttachmentDto,
  PatientAttachmentGroupDto,
} from 'webcommon/shared';
import { PrmHttpClientService } from '../PrmHttpClientService';

import { DeviceSettingsRepository } from 'webcommon/legacy-common';

import { HttpParams } from '@angular/common/http';

// not sure where else to put this for now, but it could certainly be moved
export const AttachmentHandlerSignatureAQN = 'iMedica.Prm.Client.UI.Attachments.ControlAttachment_SignatureViewer';

@Injectable({
  providedIn: 'root',
})
export class AttachmentRepository {
  constructor(
    private httpClientService: PrmHttpClientService,
    private readonly deviceSettingsRepository: DeviceSettingsRepository,
  ) {
  }

  getAttachmentGroup(attachmentGroupId: string): Observable<PatientAttachmentGroupDto> {
    const endpointUrl = `fnd/attachmentgroupdetail/${attachmentGroupId}`;
    return this.httpClientService.sendGetRequest(endpointUrl);
  }

  // retainPdfFormat means don't convert pdfs to images
  getAttachment(attachmentId: string, requestConfig: AttachmentRequestConfig, retainPdfFormat?: boolean): Observable<AttachmentDto> {
    // the default logic is that it will only convert the pdfs if on Android
    const retainPdf = retainPdfFormat === undefined ? !this.deviceSettingsRepository.isAndroid : retainPdfFormat;
    let params = new HttpParams({ fromObject: requestConfig as any });
    params = params.set('inLinePDF', retainPdf.toString());
    params = params.set('forceDBRead', 'true');
    const endpointUrl = `attachments/${attachmentId}`;
    return this.httpClientService.sendGetRequest(endpointUrl, params);
  }

  getAttachmentStaticUrl(attachmentId: string): Observable<string> {
    const endpointUrl = `attachments-url/${attachmentId}`;
    return this.httpClientService.sendGetRequest(endpointUrl);
  }

  setAttachmentContent(attachmentId: string, editAttachmentDto: EditAttachmentDto): Observable<string> {
    const endpointUrl = `attachments/${attachmentId}/save`;
    return this.httpClientService.sendPostRequest(endpointUrl, editAttachmentDto);
  }

  getAttachmentContent(attachmentId: string): Observable<AttachmentDto> {
    const endpointUrl = `attachments/${attachmentId}/content`;
    return this.httpClientService.sendGetRequest(endpointUrl);
  }

  getPatientAdvancedDirective(patientId: string): Observable<AdvancedDirectiveAttachmentGroupDto> {
    const endpointUrl = `fnd/patients/${patientId}/advanced-directive`;
    return this.httpClientService.sendGetRequest(endpointUrl);
  }

  getAttachmentHandler(documentId: string): Observable<AttachmentHandlerOptionsDto> {
    const endpointUrl = `attachment-handler/${documentId}`;
    return this.httpClientService.sendGetRequest(endpointUrl);
  }

  getAttachmentContentRaw(documentId: string): Observable<string>  {
    const endpointUrl = `attachments/${documentId}/content-raw`;
    return this.httpClientService.sendGetRequest(endpointUrl);
  }
}
