import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import {ApModalReason} from './ApModalReason';

export type ApModalResult<T> =
  ApModalResultSuccess<T> |
  ApModalResultFailure;

export interface ApModalResultSuccess<TResult> {
  readonly isSuccess: true;
  readonly reason: ApModalReason.Closed;
  readonly resultValue: TResult;
}

export interface ApModalResultFailure {
  readonly isSuccess: false;
  readonly reason: ApModalReason.UserCancel | ApModalReason.Unknown;
}

// This a guard to help for narrowing to ApModalResultSuccess or ApModalResultFailure
export function isModalSuccess<T>(modalResult: ApModalResult<T>): modalResult is ApModalResultSuccess<T> {
  return modalResult.isSuccess;
}

export function mapToModalSuccessValue<T>() {
  return (source: Observable<ApModalResult<T>>): Observable<T> => {
    return source.pipe(
      filter(isModalSuccess),
      map(r => r.resultValue),
    );
  };
}

export function apModalClosed<T>(result: T): ApModalResult<T> {
  return {
    isSuccess: true,
    reason: ApModalReason.Closed,
    resultValue: result,
  };
}

export function apModalCancelled<T>(reason: ApModalReason.UserCancel | ApModalReason.Unknown): ApModalResult<T> {
  return {
    isSuccess: false,
    reason,
  };
}
