import {
  AppRoute,
  AprimaTransitionRequest,
  WebViewManager,
} from 'webcommon/shared';

export abstract class XamarinWebViewManagerJs implements WebViewManager {
  abstract activateView(appRoute: AppRoute, transition: AprimaTransitionRequest): boolean;
  abstract goBack(): void;
}

export function XamarinWebViewManagerJsFactory(i: angular.auto.IInjectorService) {
  return i.get<XamarinWebViewManagerJs>('XamarinWebViewManager');
}

export const XamarinWebViewManagerJsFactoryAjsProvider = {
  deps: ['$injector'],
  provide: XamarinWebViewManagerJs,
  useFactory: XamarinWebViewManagerJsFactory,
};
