import {
  Component,
  OnInit,
} from '@angular/core';
import {
  finalize,
  takeUntil,
} from 'rxjs/operators';

import {
  ApBaseComponent,
} from 'webcommon/core-ui';
import {
  WebCommonStorageRepository,
} from 'webcommon/legacy-common';
import {
  LoginAssistanceRepository,
} from 'webcommon/webapi';
import {
  LoginAssistanceResultDto,
} from 'webcommon/shared';

@Component({
  selector: 'portal-contact-us',
  templateUrl: './contact-us.component.html',
})
export class ContactUsComponent extends ApBaseComponent implements OnInit {
  static readonly ajsComponentName = 'portalContactUs';
  loadError = false;
  loginAssistanceInfo: LoginAssistanceResultDto;

  constructor(
    private loginAssistanceRepository: LoginAssistanceRepository,
    private webCommonStorageRepository: WebCommonStorageRepository,
  ) {
    super();
    this.loading = false;
  }

  ngOnInit() {
    this.load();
  }

  private load() {
    const clientId = this.webCommonStorageRepository.getClientId();
    if (!clientId) {
      this.loadError = true;
      return;
    }

    this.loading = true;
    this.loginAssistanceRepository.getLoginAssistanceInformation({
      clientId,
    }).pipe(
      finalize(() => this.onLoadComplete()),
      takeUntil(this.onDestroy$),
    ).subscribe(
      (data) => this.onLoadSuccess(data),
      () => this.onLoadError(),
    );
  }

  private onLoadComplete() {
    this.loading = false;
  }

  private onLoadError() {
    this.loadError = true;
  }

  private onLoadSuccess(loginAssistanceInfo: LoginAssistanceResultDto) {
    this.loadError = false;
    this.loginAssistanceInfo = loginAssistanceInfo;
  }
}
