// This has to be used as:
// a provider for a component that extends UpgradeComponent
// or
// a provider for the module that such component is declared in
// or
// a provider inside the Injector that is passed into the constructor of UpgradeComponent.
// HOWEVER, This is only necessary if the UpgradeComponent is loaded dynamically though,
// like by a component modal loaded by ngx-boostrap.
// Using ApModalService will handle this properly though, so that this doesn't need to be used anywhere else for now.
export const ScopeProvider = {
  deps: ['$injector'],
  provide: '$scope',
  useFactory: scopeFunction,
};

export function scopeFunction(i: angular.auto.IInjectorService) {
  return i.get('$rootScope').$new();
}
