import {
  Injectable,
} from '@angular/core';
import {BrowserService} from 'webcommon/legacy-common';

@Injectable({
  providedIn: 'root',
})
export class StyleService {
  private readonly documentObj: Document;
  constructor(
    browserService: BrowserService,
  ) {
    this.documentObj = browserService.getDocument();
  }

  // The current use-case for this method is to add a style that is lazy-loaded with dynamic import(),
  // that only needs to get added to the DOM once.
  // replaceCurrent:
  // true => if a style exists with this id,
  // replace the current style content with this content
  // false => if a style exists with this id, do nothing
  async addStyleToDom(
    id: string,
    content: () => (Promise<string> | string),
    replaceCurrent = false,
  ): Promise<void> {
    const fullId = `ap-lazystyle-${id}`;
    let element = this.documentObj.getElementById(fullId);
    if (element) {
      if (replaceCurrent) {
        element.textContent = await content();
      }
      return;
    }

    element = this.documentObj.createElement('style');
    element.id = fullId;
    element.textContent = await content();
    this.documentObj.head.appendChild(element);
  }

  // The current use-case for this method is to add a style that is lazy-loaded via assets (inject: false)
  // that only needs to get added to the DOM once.
  // If there is already a link element with this file, then it won't get added again.
  async addStyleUrlToDom(
    id: string,
    stylesheetUrl: Promise<string> | string
  ): Promise<void> {
    const fullId = `ap-lazystyle-${id}`;
    if (this.documentObj.getElementById(fullId)) {
      return;
    }

    const _stylesheetUrl = await stylesheetUrl;
    return new Promise<void>((resolve, reject) => {
      const link = this.documentObj.createElement('link');
      link.onload = () => {
        resolve();
      };
      link.onerror = (e) => {
        reject(e);
      };
      link.id = fullId;
      link.rel = 'stylesheet';
      link.href = _stylesheetUrl;
      this.documentObj.head.appendChild(link);
    });
  }
}
