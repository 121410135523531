/**
 * GUID class for Typescript
 * Author:Tom A. Vibeto  see https://gist.github.com/emptyother/1fd97db034ef848f38eca3354fa9ee90
 *
 * A Version 4 UUID "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx" is a universally unique identifier
 * that is generated using random numbers.
 */
import {GuidStrValue} from './GuidStrValue';

export class Guid {

  private static guidPattern = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';

  public static replaceRegex = new RegExp('[xy]', 'g');
  // this regex currently doesn't allow empty guid
  public static validRegex = new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$', 'i');

  private value: GuidStrValue = this.empty;

  /**
   * Creates instance of Guid class
   */
  public static createInstance(): Guid {
    return new Guid(Guid.create());
  }

  /**
   * Creates guid as a string
   */
  public static create(): GuidStrValue {
    return Guid._coerceToGuidStr(Guid.guidPattern.replace(Guid.replaceRegex, Guid.replacer));
  }

  /**
   * Determines whether valid is valid Guid
   */
  public static isValid(str: string): str is GuidStrValue {
    return Guid.validRegex.test(str);
  }

  public static empty(): GuidStrValue {
    return Guid._coerceToGuidStr('00000000-0000-0000-0000-000000000000');
  }

  // added this function, so we don't spread this type assertion everywhere
  // only call this when you know for sure the value is a guid
  public static _coerceToGuidStr(str: string): GuidStrValue {
    return str as GuidStrValue;
  }

  private static replacer(c: string): string {
    // tslint:disable-next-line:no-bitwise
    const r = Math.random() * 16 | 0;
    // tslint:disable-next-line:no-bitwise
    const v = (c === 'x') ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  }

  constructor(value?: string) {
    if (value && Guid.isValid(value)) {
      this.value = value;
    }
  }
  public get empty(): GuidStrValue {
    return Guid.empty();
  }

  public toString(): GuidStrValue {
    return this.value;
  }

  public toJSON(): GuidStrValue {
    return this.value;
  }
}
