import {
  Injectable,
} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {Observable} from 'rxjs';

import {
  BrowserService,
  ServerInfoRepository,
} from 'webcommon/legacy-common';
import {
  ApAudioDocument,
  ApDocumentBase,
  ApDocumentType,
  ApHtmlDocument,
  ApImageDocument,
  ApPdfDocument,
  ApTextDocument,
  AttachmentDto,
  DocumentHelper,
  DownloadableDocument,
  ServerFeature,
} from 'webcommon/shared';

import {DocumentHelperBase} from './DocumentHelperBase';

// start with 1 so none of these return a falsy value
enum ImageFormats {
  bmp = 1,
  doc,
  docx,
  gif,
  jpeg,
  jpg,
  png,
  rtf,
  tif,
  tiff,
}
enum HtmlFormats {
  htm = 1,
  html,
}
enum TextFormats {
  txt = 1,
}
enum PdfFormats {
  pdf = 1,
}

enum CCDFormats {
  ccd = 1,
  ccda,
  xml,
}

enum AudioFormats {
  wav = 1,
}

export const pdfMimeType = 'application/pdf';

const htmlDownloadContentType = 'text/html; charset=utf-8';
const audioContentType = 'audio/vnd.wav';
// const xmlContentType = 'text/xml';

// The logic in this service is a combination of document-related logic that has been cleaned up a bit, and used to exist
// in old apSaveToFile.js, apDocumentViewer.js, apDocumentViewerWithDownload.js, PatientAttachmentCtrl.js,
// IosDocumentHelper.js, WebDocumentHelper.js, and maybe more
@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  private readonly windowObj: Window & typeof globalThis;
  private readonly documentHelper: DocumentHelper;

  private static getImageDataUrl(base64Data: string, extension: string): string {
    const dataUrl = `data:image/${extension};base64,${base64Data}`;
    return dataUrl;
  }

  constructor(
    private readonly browserService: BrowserService,
    private readonly domSanitizer: DomSanitizer,
    private readonly serverInfoRepository: ServerInfoRepository,
    documentHelper: DocumentHelperBase,
  ) {
    this.windowObj = browserService.getWindow();
    this.documentHelper = documentHelper;
  }

  hasStaticUrl(doc: ApDocumentBase): boolean {
    // Currently, for the static url to work for a document/attachment,
    // it needs to be a pdf, and have the feature that guarantees the static url endpoint will be there
    // on the server.
    // This can be updated as necessary in the future.
    const result = doc.isPdf && this.serverInfoRepository.isSupported(ServerFeature.ProviderAttachments_DisplaySeparateTab);
    return result;
  }

  openUrlInSeparateTab(url$: Observable<string>) {
    this.documentHelper.openUrlInSeparateTab(url$);
  }

  supportsOpenInSeparateTab(): boolean {
    return this.documentHelper.supportsOpenInSeparateTab();
  }

  supportsDownloadToFile(): boolean {
    return this.browserService.supportsDownloadToFile();
  }

  tryDownloadToFile(doc: DownloadableDocument, defaultFileName: string): boolean {
    if (!this.supportsDownloadToFile()) {
      return false;
    }

    const result = this.downloadToFile(doc, defaultFileName);
    return result;
  }

  downloadToFile(doc: DownloadableDocument, defaultFileName: string): boolean {
    const extension = doc.Extension;
    const data = doc.Data;
    if (!extension || !data) {
      return false;
    }

    const fileName = doc.FileName || defaultFileName;

    const isImage = !!ImageFormats[extension as any];
    if (isImage) {
      const fullName = `${fileName}.${extension}`;
      const contentType = `image/${extension}`;
      this.browserService.downloadDocumentToFile(fullName, data, contentType);
      return true;
    }

    const isHtml = !!HtmlFormats[extension as any];
    if (isHtml) {
      const fullName = `${fileName}.html`;
      this.browserService.downloadDocumentToFile(fullName, data, htmlDownloadContentType);
      return true;
    }

    const isText = !!TextFormats[extension as any];
    if (isText) {
      const fullName = `${fileName}.txt`;
      this.browserService.downloadDocumentToFile(fullName, data, htmlDownloadContentType);
      return true;
    }

    const isPdf = !!PdfFormats[extension as any];
    if (isPdf) {
      const fullName = `${fileName}.pdf`;
      this.browserService.downloadDocumentToFile(fullName, data, pdfMimeType);
      return true;
    }

    const isCCD = !!CCDFormats[extension as any];
    if (isCCD) {
      const fullName = `${fileName}.ccd`;
      this.browserService.downloadDocumentToFile(fullName, data, htmlDownloadContentType);
      return true;
    }

    const isAudio = !!AudioFormats[extension as any];
    if (isAudio) {
      const fullName = `${fileName}.wav`;
      this.browserService.downloadDocumentToFile(fullName, data, audioContentType);
      console.log('This attachment is an audio file');
      return true;
    }
    return false;
  }

  mapFromAttachment(attachment: AttachmentDto): ApDocumentBase | null {
    const extension = attachment.Extension;
    const data = attachment.Data;
    if (!extension || !data) {
      return null;
    }
    const isImage = !!ImageFormats[extension as any];
    if (isImage) {
      const imageDataUrl = DocumentService.getImageDataUrl(data, extension);
      const imageDoc = new ApImageDocument(
        imageDataUrl,
        false,
        extension,
        attachment.PageCount,
      );
      return imageDoc;
    }

    const isHtml = !!HtmlFormats[extension as any];
    if (isHtml) {
      const htmlData = this.getHtmlData(data);
      const htmlDoc = new ApHtmlDocument(
        htmlData.dataUrl,
        false,
        extension,
        attachment.PageCount,
        htmlData.trustedUrl,
      );
      return htmlDoc;
    }

    const isText = !!TextFormats[extension as any];
    if (isText) {
      const textData = this.getText(data);
      const textDoc = new ApTextDocument(
        textData,
        extension,
        attachment.PageCount,
      );
      return textDoc;
    }

    // If Blob isn't compatible, then don't treat is a normal pdf,
    // because with our current logic, it won't be able to be displayed.
    const isPdf = !!PdfFormats[extension as any] && this.browserService.isBlobCompatible();
    if (isPdf) {
      const pdfData = this.getPdfData(data);
      const pdfDoc = new ApPdfDocument(
        pdfData.dataUrl,
        true,
        extension,
        attachment.PageCount,
        pdfData.trustedUrl,
      );
      return pdfDoc;
    }

    const isCCD = !!CCDFormats[extension as any];
    if (isCCD) {
      const ccdData = this.getHtmlData(data);
      const ccdDoc = new ApHtmlDocument(
        ccdData.dataUrl,
        false,
        extension,
        attachment.PageCount,
        ccdData.trustedUrl,
      );
      return ccdDoc;
    }

    const isAudio = !!AudioFormats[extension as any];
    if (isAudio) {
      const audioData = this.getAudioData(data);
      const audioDoc = new ApAudioDocument(
        audioData.dataUrl,
        false,
        extension,
        attachment.PageCount,
        audioData.trustedUrl,
      );
      console.log('Audio extension: ', audioDoc.extension);
      console.log('Audio trusted Url: ', audioDoc.trustedUrl);
      return audioDoc;
    }

    const genericDoc = new ApDocumentBase(
      attachment.Data,
      false,
      ApDocumentType.unknown,
      extension,
      attachment.PageCount,
    );

    return genericDoc;
  }

  disposeDocument(document: ApDocumentBase | null) {
    // revoke the objectUrl when we are done with the document to
    // let the browser know not to keep the reference to the file any longer
    if (document && document.dataIsObjectUrl) {
      this.windowObj.URL.revokeObjectURL(document.data);
    }
  }

  private getPdfData(base64Data: string): {
    dataUrl: string,
    trustedUrl: SafeResourceUrl,
  } {
    const blob = this.browserService.base64ToBlob(base64Data, pdfMimeType);
    const objectUrl = this.windowObj.URL.createObjectURL(blob);
    const trustedUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(objectUrl);
    return {
      dataUrl: objectUrl,
      trustedUrl,
    };
  }

  private getHtmlData(base64Data: string): {
    dataUrl: string,
    trustedUrl: SafeResourceUrl,
  } {
    const dataUrl = `data:text/html;charset=utf-8; base64,${base64Data}`;
    const trustedUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(dataUrl);
    return {
      dataUrl,
      trustedUrl,
    };
  }

  private getText(base64Data: string): string {
    const text = this.windowObj.atob(base64Data);
    return text;
  }

  private getAudioData(base64Data: string): {
    dataUrl: string,
    trustedUrl: SafeResourceUrl,
  } {
    console.log('Getting audio data');
    const blob = this.browserService.base64ToBlob(base64Data, audioContentType);
    const objectUrl = URL.createObjectURL(blob);
    const trustedUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(objectUrl);
    console.log('Returning audio data');
    return {
      dataUrl: objectUrl,
      trustedUrl,
    };
  }

}
