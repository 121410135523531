// add to this when adding more features that need to be referenced on the client side
// these all come from VersionService.cs on the server
export enum ServerFeature {
  AllowCustomFreqAndDosageUom,
  Appointments_DifferentScheduleTypes,
  Appointments_FinancialCenter,
  CalendarOfficeHoursWarning,
  ChargeCaptureDesktopTile,
  ChargeCaptureSessions,
  ConfigurableUserLockoutTimeout,
  DefaultFinancialCenter,
  DiagnosisSummary,
  DosingCalculation,
  DrugDocumentationV2,
  DrugHistoryDrugs,
  EnhancedDrugSearch,
  EnhancedPharmacySearch,
  SendEpaRequest,
  ExternalPatientType,
  FNC,
  FND_Demographics,
  GeneralNotes,
  GetPatientInsurance,
  GroupVisitAppointment_PatientName,
  HandleAppointmentBlockedSlots,
  HeaderDetails,
  ImoProblem,
  InitUser,
  LockManagement,
  MedicationTypes,
  Messages_PharmacyRefill,
  Messages_PharmacyRefill_ERxMedication,
  Messages_RxRefill,
  NddDrugScreening,
  NewPatientSSN,
  NotesDashboardTile,
  PatientAttachments,
  PatientDiagnosisCodes,
  PatientDosingDetails,
  PatientDosingDetails_Comprehensive,
  PatientPortalMessageRoutingType,
  PatientMedicationStatus,
  PersonPhoto,
  PhoneMessageCreation,
  ProviderAttachments_DisplaySeparateTab,
  ProviderAuthorization,
  ProviderInsuranceExclusionWarning,
  Settings_KdbConfiguration,
  StartRefillWithoutVisit,
  SaveSettings,
  SendPriorAuthIsSpecialty,
  SigMetadata,
  SigWriter_ChronicMed,
  SigwriterResults,
  SurescriptsRFSPMBC,
  VisitByAppointment,
  VisitMedicationStatus,
  Visits,
  Visits_RX,
  V18_DrugScreening,
}
