import { Injectable } from '@angular/core';

import {
  AppRoute,
  AprimaTransitionRequest,
  WebViewManager,
} from 'webcommon/shared';

import {
  DeviceSettingsRepository,
} from './DeviceSettingsRepository';

@Injectable({
  providedIn: 'root',
})
export class WebViewManagerService implements WebViewManager {
  static readonly ajsFactoryName = 'WebViewManager';

  private webViewManager: WebViewManager | null = null;

  constructor(
    private deviceSettingsRepository: DeviceSettingsRepository,
  ) {

  }

  loadConfiguration(browserWebViewManager: WebViewManager, hostedWebViewManager: WebViewManager): void {
    if (this.webViewManager !== null) {
      throw new Error('webViewManager has already been set');
    }

    if (this.deviceSettingsRepository.isStandaloneBrowser) {
      // tslint:disable-next-line:no-console
      console.info('Using browser WebViewManager for standalone browser');
      this.webViewManager = browserWebViewManager;
    } else if (this.deviceSettingsRepository.isDevice || this.deviceSettingsRepository.isPrm) {
      // tslint:disable-next-line:no-console
      console.info('Using hosted WebViewManager');
      this.webViewManager = hostedWebViewManager;
    } else {
      // tslint:disable-next-line:no-console
      console.info('Using browser WebViewManager');
      this.webViewManager = browserWebViewManager;
    }
  }

  private validateConfiguration(): WebViewManager {
    if (this.webViewManager === null) {
      throw new Error('webViewManager has not been set');
    }
    return this.webViewManager;
  }

  activateView(appRoute: AppRoute, transition: AprimaTransitionRequest): boolean {
    const webViewManager = this.validateConfiguration();
    return webViewManager.activateView(appRoute, transition);
  }

  goBack(): void {
    const webViewManager = this.validateConfiguration();
    webViewManager.goBack();
  }
}
