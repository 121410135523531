import { NgModule, Optional, SkipSelf } from '@angular/core';
import {downgradeComponent, downgradeInjectable} from '@angular/upgrade/static';
import {ApPaymentFrameComponent, ContentAttributeServiceUpgradeAjsProvider} from '@app/core';

import {
  WebCommonAngularJSModule,
} from 'webcommon/ajs';
import {PatientDocumentsComponent} from 'webcommon/patient';
import {UrlParsingService} from 'webcommon/legacy-common';
import {LoginAssistanceRepository} from 'webcommon/webapi';
import {ApPersonalHealthAppsModalService} from '../dashboard/third-party/ap-personal-health-apps-modal.service';

@NgModule({
  imports: [
    WebCommonAngularJSModule,
  ],
  providers: [
    ContentAttributeServiceUpgradeAjsProvider,
  ],
})
export class PatientPortalAngularJSModule {
  // This is using the same constructor pattern as BrowserModule
  constructor(
    @Optional() @SkipSelf() parentModule: PatientPortalAngularJSModule | null,
  ) {
    if (parentModule) {
      throw new Error(
        `PatientPortalAngularJSModule is already loaded. Import it only once, usually in the AppModule only`);
    }
  }

  // tslint:disable:max-line-length
  static registerAngularJS(module: angular.IModule): void {
    WebCommonAngularJSModule.registerAngularJS(module);

    module
      .factory(UrlParsingService.ajsFactoryName, downgradeInjectable(UrlParsingService))
      .factory(LoginAssistanceRepository.ajsFactoryName, downgradeInjectable(LoginAssistanceRepository))
      .factory(ApPersonalHealthAppsModalService.ajsFactoryName, downgradeInjectable(ApPersonalHealthAppsModalService));

    module
      .directive(PatientDocumentsComponent.ajsComponentName, downgradeComponent({ component: PatientDocumentsComponent }))
      .directive(ApPaymentFrameComponent.ajsComponentName, downgradeComponent({ component: ApPaymentFrameComponent }));
  }
  // tslint:enable:max-line-length
}
