import { Component, Injector, OnInit } from '@angular/core';
import { finalize, takeUntil } from 'rxjs/operators';
import { ApComplexModal } from 'webcommon/core-ui';
import { PatientIdentityRepository } from 'webcommon/webapi';
import { PatientIdentityResponseDto } from 'webcommon/shared';
import {Clipboard} from '@angular/cdk/clipboard';

@Component
({
  selector: 'ap-personal-health-apps-modal',
  templateUrl: './ap-personal-health-apps-modal.component.html',
  styleUrls: ['./ap-personal-health-apps-modal.component.less'],
})
export class ApPersonalHealthAppsModalComponent extends ApComplexModal<boolean> implements OnInit {
  public baseId: string;
  public patientId: string;
  public isPatientRegisteredForFHIR: boolean;
  fhirResourceUrl = '';
  fhirUserRevokeUrl = '';

  displayRegistrationResponse: boolean;
  Id: string;
  isPatientRegistered: boolean;
  loadError = false;
  registrationError = false;
  registrationErrorMessage = 'The registration process could not be completed.';
  registeringPatient = false;

  constructor(
    elementInjector: Injector,
    private patientIdentityRepository: PatientIdentityRepository,
    private clipboard: Clipboard
  ) {
    super(elementInjector);
    this.loading = false;
  }

  override ngOnInit() {
    super.ngOnInit();

    this.isPatientRegistered = this.isPatientRegisteredForFHIR;
    this.displayRegistrationResponse = false;
    this.setId();
    this.load();
  }

  private load() {
    this.registeringPatient = false;
    this.loading = true;
    this.getPersonIdentitySubject();
  }

  private getPersonIdentitySubject() {
    this.patientIdentityRepository.getPersonIdentitySubject(this.patientId).pipe(
      finalize(() => { this.loading = false; }),
      takeUntil(this.onDestroy$),
    ).subscribe(
      (data: PatientIdentityResponseDto) => this.onGetPersonIdentitySubjectSuccess(data),
      (error: unknown) => {
        this.loadError = true;
        throw error;
      },
    );
  }

  private onGetPersonIdentitySubjectSuccess(result: PatientIdentityResponseDto) {
    if (result) {
      this.isPatientRegistered = true;
      this.fhirUserRevokeUrl = result.FhirUserRevokeUrl;
      this.fhirResourceUrl = result.FhirResourceUrl;
    }
    this.loadError = false;
  }

  register() {
    this.registeringPatient = true;
    this.registrationError = false;
    this.patientIdentityRepository.registerPersonIdentity(this.patientId).pipe(
      finalize(() => { this.registeringPatient = false; }),
      takeUntil(this.onDestroy$),
    ).subscribe(
      () => {
        this.displayRegistrationResponse = true;
      },
      (error: any) => {
        if (error &&
            error.error &&
            error.error.ModelState &&
            error.error.ModelState.commandResult &&
            error.error.ModelState.commandResult.length > 0) {
          this.registrationErrorMessage = error.error.ModelState.commandResult[0];
          this.registrationErrorMessage += '  Patient registration request was not submitted.';
        }

        this.registrationError = true;
        throw error;
      },
    );
  }

  revoke() {
    window.open(this.fhirUserRevokeUrl);
  }

  copyToClipboard() {
    this.clipboard.copy(this.fhirResourceUrl);
  }

  // There is no save button on this page.
  // save() {
  //   this.apClose(this.isRegistrated());
  // }

  close() {
    this.apClose(this.isRegistrated());
  }

  private isRegistrated(): boolean {
    return (this.isPatientRegistered || this.displayRegistrationResponse);
  }

  private setId() {
    this.Id = `${this.baseId}-pham`;
  }
}
