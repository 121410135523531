import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { EditorModule } from '@tinymce/tinymce-angular';
import {
  AlertModule,
} from 'ngx-bootstrap/alert';
import {
  CollapseModule,
} from 'ngx-bootstrap/collapse';
import {
  BsDatepickerModule,
} from 'ngx-bootstrap/datepicker';
import {
  ModalModule,
} from 'ngx-bootstrap/modal';
import {
  PaginationModule,
} from 'ngx-bootstrap/pagination';
import {
  TabsModule,
} from 'ngx-bootstrap/tabs';

import { ToastaModule } from 'ngx-toasta';

import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';

import {
  ReactiveFormsModule,
} from '@angular/forms';

import {
  ApAlertComponent,
} from './ap-alert';
import {
  ApComboboxComponent,
} from './ap-combobox';
import {
  ApUIConfigurationSaveHeaderComponent,
} from './ap-configuration';
import {
  ApConfirmModalComponent,
} from './ap-confirm';
import {
  ApDatePickerComponent,
} from './ap-date-picker';
import {
  ApDatePickerNextPrevComponent,
} from './ap-datepicker-next-prev';
import {
  ApErrorMessageComponent,
} from './ap-error-message';
import {
  ApFileUploadComponent,
} from './ap-file-upload';
import {
  ApNumericUpDownComponent,
} from './ap-numeric-up-down';
import {
  ApSearchListComponent,
} from './ap-search-list';
import {
  ApSelectComponent,
} from './ap-select';
import {
  ApSingleFieldEditorModalComponent,
} from './ap-single-field-editor-modal';
import {
  ApTimeFrameComponent,
  ApTimeFrameSelectorModalComponent,
} from './ap-timeframe';
import {
  ApWarningUnsavedChangesModalComponent,
  ApWarningModalComponent,
} from './ap-warning';
import {
  ApAutofocusDirective,
  ApCheckboxDirective,
  ApColorBorderDirective,
  ApFixedHeaderDirective,
  ApFormFocusDirective,
  ApLongTouchDirective,
  ApMaxHeightDirective,
  ApPhoneNumberMaskDirective,
  ApRequiredDirective,
  ApRequiredFromMetadataDirective,
  ApResizedDirective,
  ApSpinnerDirective,
  ApZoomableDirective,
} from './directives';
import {
  ApDocumentViewerComponent,
  ApEnhancedDocumentViewerComponent,
  ApImageViewerComponent,
  ApPdfComponent,
  ApSaveToFileDirective,
  ApSignPdfComponent,
} from './document-viewing';
import {
  InactiveFilterPipe,
  SafeHtmlPipe,
  CoerceToNullPipe,
} from './pipes';
import {
  ApCheckSizeDirective,
} from './virtual-scrolling';
import { ApTileComponent } from './ap-tile/ap-tile.component';
import { ApDropdownItemMenuComponent } from './ap-dropdown-item-menu';
import { ApIframeComponent } from './ap-iframe';
import { ApIframeModalComponent } from './ap-iframe-modal';
import { ApSliderComponent } from './ap-slider';
import { ApPillComponent } from './ap-pill';
import {
  ToCurrentlyLoadingPipe,
  ToLoadedValuePipe,
  ToLoadingFailurePipe,
  ToErrorMessagesPipe,
} from './loading-util';
import { ApDynamicComponentViewerComponent } from './ap-dynamic-component-viewer';
import { ApInputClearComponent } from './ap-input-clear';

const ajsComponents = [
  ApSignPdfComponent,
];


const documentViewingExports = [
  ApDocumentViewerComponent,
  ApEnhancedDocumentViewerComponent,
  ApImageViewerComponent,
  ApPdfComponent,
  ApSaveToFileDirective,
];

const modalComponents = [
  ApConfirmModalComponent,
  ApIframeModalComponent,
  ApSingleFieldEditorModalComponent,
  ApTimeFrameSelectorModalComponent,
  ApWarningModalComponent,
  ApWarningUnsavedChangesModalComponent,
];

const angularMaterialComponents = [
  MatCheckboxModule,
  MatAutocompleteModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDividerModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatSelectModule,
  MatSliderModule,
  MatSnackBarModule,
  MatRadioModule,
  MatTableModule,
  MatTooltipModule,
  MatTabsModule,
  ReactiveFormsModule,
];

const myExports = [
  ...ajsComponents,
  ...documentViewingExports,
  ...modalComponents,
  ApAlertComponent,
  ApAutofocusDirective,
  ApCheckboxDirective,
  ApCheckSizeDirective,
  ApColorBorderDirective,
  ApComboboxComponent,
  ApConfirmModalComponent,
  ApDatePickerComponent,
  ApDatePickerNextPrevComponent,
  ApDropdownItemMenuComponent,
  ApDynamicComponentViewerComponent,
  ApErrorMessageComponent,
  ApFileUploadComponent,
  ApFixedHeaderDirective,
  ApFormFocusDirective,
  ApIframeComponent,
  ApLongTouchDirective,
  ApMaxHeightDirective,
  ApNumericUpDownComponent,
  ApPhoneNumberMaskDirective,
  ApPillComponent,
  ApRequiredDirective,
  ApRequiredFromMetadataDirective,
  ApResizedDirective,
  ApSearchListComponent,
  ApSelectComponent,
  ApSliderComponent,
  ApSpinnerDirective,
  ApTileComponent,
  ApTimeFrameComponent,
  ApTimeFrameSelectorModalComponent,
  ApUIConfigurationSaveHeaderComponent,
  ApWarningModalComponent,
  ApZoomableDirective,
  CoerceToNullPipe,
  InactiveFilterPipe,
  SafeHtmlPipe,
  ToCurrentlyLoadingPipe,
  ToErrorMessagesPipe,
  ToLoadedValuePipe,
  ToLoadingFailurePipe,
  ApInputClearComponent,
];

@NgModule({
  declarations: myExports,
  exports: [
    ...angularMaterialComponents,
    CommonModule,
    AlertModule,
    BsDatepickerModule,
    CollapseModule,
    DragDropModule,
    EditorModule,
    FormsModule,
    ModalModule,
    PaginationModule,
    ScrollingModule,
    ToastaModule,
    TranslateModule,
    TabsModule,
    ...myExports
  ],
  imports: [
    ...angularMaterialComponents,
    CommonModule,
    AlertModule,
    BsDatepickerModule,
    CollapseModule,
    EditorModule,
    FormsModule,
    ModalModule,
    PaginationModule,
    ScrollingModule,
    TranslateModule,
    TabsModule,
    DragDropModule,
    ToastaModule
  ],
})
export class CoreUiModule {
}
