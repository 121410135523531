import { Injectable } from '@angular/core';

import {StorageRepository, throwInvalidKeyError} from './IStorageRepository';

@Injectable({
  providedIn: 'root',
})
export class StorageInMemoryRepository implements StorageRepository {
  constructor() {
    this.clear();
  }

  private storage: { [key: string]: string; };

  clear(): void {
    this.storage = {};
  }

  getItemOrThrow(key: string): string {
    return this.getItem(key) ?? throwInvalidKeyError(key);
  }

  getItem(key: string): string | null {
    return this.storage[key] ?? null;
  }

  removeItem(key: string): void {
    delete this.storage[key];
  }

  setItem(key: string, value: string): void {
    this.storage[key] = value;
  }
}
