import { ClientConfig } from './ClientConfig';

export abstract class ContentAttributeServiceUpgrade {
  abstract getClientConfig(): Promise<ClientConfig>;
  abstract getPaymentRedirectUrl(): string;
}

export function ContentAttributeServiceUpgradeFactory(i: angular.auto.IInjectorService) {
  return i.get<ContentAttributeServiceUpgrade>('ContentAttributeService');
}

export const ContentAttributeServiceUpgradeAjsProvider = {
  deps: ['$injector'],
  provide: ContentAttributeServiceUpgrade,
  useFactory: ContentAttributeServiceUpgradeFactory,
};
