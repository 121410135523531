import { Injectable } from '@angular/core';
import { BrowserConfig } from 'webcommon/shared';

@Injectable({
  providedIn: 'root',
})
export class BrowserService {
  // Properties can be defined here for what browser, version, etc should be intentionally private.
  // Users of this service should ask for features, not type/version of a browser.

  // Because of the browsers defined in browserslist, and angular dropping support for IE,
  // we don't need to detect some of this stuff anymore.

  constructor(
    // tslint:disable-next-line:no-empty
  ) {
  }

  getConfig(): BrowserConfig {
    const navigatorObj = this.getNavigator();
    const windowObj = this.getWindow();
    const userAgent = navigatorObj.userAgent;

    // I updated this iOS logic in Oct 2020 based on these answers on SO:
    // https://stackoverflow.com/a/58979271/5335355
    // https://stackoverflow.com/a/58065241/5335355
    // TODO: we need to eventually refactor this logic and dependent logic, since navigator.platform is deprecated
    // tslint:disable:deprecation-2
    const isIphone = /iPhone|iPod/.test(navigatorObj.platform) &&
      !(windowObj as any).MSStream;
    const isIpad = (/iPad/.test(navigatorObj.platform) ||
      (navigatorObj.platform === 'MacIntel' && navigatorObj.maxTouchPoints > 2)) &&
      !(windowObj as any).MSStream;
    const isIosOS = isIphone || isIpad;
    // tslint:enable:deprecation-2

    // android detection mentioned here
    // http://googlewebmastercentral.blogspot.com/2011/03/mo-better-to-also-detect-mobile-user.html
    const isAndroidOS = (/android/im).test(userAgent);
    const isAndroidPhone = isAndroidOS && (/mobile/im).test(userAgent);
    const isAndroidTablet = isAndroidOS && !isAndroidPhone;

    const isPhone = isIphone || isAndroidPhone;
    const isTablet = isIpad || isAndroidTablet;

    const isMobileDevice = isPhone || isTablet;

    const settings: BrowserConfig = {
      isAndroidOS,
      isIosOS,
      isMobileDevice,
      isPhone,
      isTablet,
    };
    return settings;
  }

  base64ToBlob(base64String: string, contentType: string) {
    const windowObj = this.getWindow();

    const byteCharacters = windowObj.atob(base64String);
    const byteArrayBuffer = new ArrayBuffer(byteCharacters.length);
    const byteArrayInt = new Uint8Array(byteArrayBuffer);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteArrayInt[i] = byteCharacters.charCodeAt(i);
    }

    return new Blob([byteArrayInt], {type: contentType});
  }

  downloadDocumentToFile(fileNameToSaveAs: string, base64String: string, contentType: string) {
    const blob = this.base64ToBlob(base64String, contentType);
    this.saveBlob(document, blob, fileNameToSaveAs);
  }

  tryDownloadDocumentToFile(fileNameToSaveAs: string, base64String: string, contentType: string): boolean {
    if (!this.supportsDownloadToFile()) {
      return false;
    }

    this.downloadDocumentToFile(fileNameToSaveAs, base64String, contentType);
    return true;
  }

  getDefaultSpinnerName(): string {
    // this originially returned 'android' for IE
    return 'bubbles';
  }

  getDocument(): Document {
    return document;
  }

  getLocation(): Location {
    return location;
  }

  getNavigator(): Navigator {
    return navigator;
  }

  getWindow(): Window & typeof globalThis {
    return window;
  }

  isBlobCompatible(): boolean {
    // this was originally only false for older versions of IE, I think <10
    return true;
  }

  saveBlob(document: Document, blobObj: Blob, fileName: string): void {
    const downloadLink = document.createElement('a');
    downloadLink.download = fileName;
    const objectUrl = URL.createObjectURL(blobObj);
    downloadLink.href = objectUrl;
    downloadLink.onclick = (e: MouseEvent) => {
      document.body.removeChild(e.target as Node);
    };
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    // We should want to call URL.revokeObjectURL() on this objectUrl when it's done downloading,
    // or when the objectUrl is safe to clean up,
    // but it's not clear when that will be.
    // https://developer.mozilla.org/en-US/docs/Web/API/URL/createObjectURL#memory_management
    // Theoretically, the download could take a while.
    // Even FileSaver just uses an arbitrary time:
    // https://github.com/eligrey/FileSaver.js/issues/205
    // https://github.com/eligrey/FileSaver.js/commit/62d219a0fac54b94cd4f230e7bfc55aa3f8dcfa4
    // https://github.com/eligrey/FileSaver.js/commit/485930a#commitcomment-8768047
    // current code for FileSaver:
    // https://github.com/eligrey/FileSaver.js/blob/master/src/FileSaver.js#L105-L107
  }

  shouldAlwaysIgnoreCache(): boolean {
    // this was originally only true for IE
    return false;
  }

  // separating this logic to a different method will allow us to expand this logic if necessary
  // without having to call isBlobCompatible() before calling downloadDocumentToFile()
  supportsDownloadToFile(): boolean {
    const supported = this.isBlobCompatible();
    return supported;
  }

  // ported from old BrowserService.js
  supportsInlinePdf(): boolean {
    // this was originally only false for IE and Legacy Edge (before Chromium)
    return true;
  }
}
