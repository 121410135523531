import {
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { StateService, Transition } from '@uirouter/core';

import {
  ClientConfig,
  ContentAttributeServiceUpgrade,
  PatientPortalConfigurationRepository,
} from '@app/core';

import {
  finalize,
  takeUntil,
} from 'rxjs/operators';
import {
  ApBaseComponent,
} from 'webcommon/core-ui';
import {
  WebCommonStorageRepository,
} from 'webcommon/legacy-common';
import {
  TelehealthRepository,
} from 'webcommon/webapi';
import {
  LoginAssistanceResultDto,
  PatientLoginInfoDto,
  TelehealthAppointmentLoginDto,
} from 'webcommon/shared';

@Component({
  selector: 'th-login',
  templateUrl: './th-login.component.html',
})
export class ThLoginComponent extends ApBaseComponent implements OnInit {
  loadError = false;
  hasLoginError = false;
  loginAssistanceInfo: LoginAssistanceResultDto;
  url = '';
  appointmentId: string;
  userId: string;
  billingCoPayAmount: number;
  billingBalance: number;
  zipCode: string;
  birthDate: any;
  clientId: string;
  practiceName = '';
  submitted = false;
  submitting = false;
  birthDateRegex = /^$|\d{4}\-\d{2}\-\d{2}/;
  zipCodeRegex = /^$|\d{5}/;

  constructor(
    private patientPortalConfigurationRepository: PatientPortalConfigurationRepository,
    private contentAttributeService: ContentAttributeServiceUpgrade,
    private webCommonStorageRepository: WebCommonStorageRepository,
    private telehealthRepository: TelehealthRepository,
    private transition: Transition,
    private stateService: StateService,
  ) {
    super();
    this.loading = false;
  }

  @ViewChild('thLoginForm', { static: true }) thLoginForm: NgForm;

  ngOnInit() {
    this.appointmentId = this.transition.params().apptId;
    this.webCommonStorageRepository.clearAll();
    this.load();
  }

  private load() {
    this.loading = true;
    if (!this.patientPortalConfigurationRepository.DEV_HOSTED) {
      this.contentAttributeService.getClientConfig()
          .then((data: ClientConfig) => {
              this.webCommonStorageRepository.setClientUrl(data.hostUrl);
              this.webCommonStorageRepository.setClientId(data.clientId);
              this.clientId = data.clientId;

          }).catch(function() {
            this.loadError = true;
          });
    } else {
      this.loadError = true;
      return;
    }
  }

  telehealthLogin() {
    this.submitting = true;
    this.submitted = true;
    if (this.thLoginForm && !this.thLoginForm.valid) {
      this.submitting = false;
      return;
    }

    const loginRequestDto: TelehealthAppointmentLoginDto = {
      AppointmentId: this.appointmentId,
      BirthDate: this.birthDate,
      ClientFeatures: [],
      ClientId: this.clientId,
      DeviceId: '',
      ZipCode: this.zipCode,
    };
    this.telehealthRepository.postTelehealthLogin(loginRequestDto).pipe(
      finalize(() => { this.submitting = false; }),
      takeUntil(this.onDestroy$),
    ).subscribe(
      (data: PatientLoginInfoDto) => {

        this.webCommonStorageRepository.setJsonWebToken(data.JsonWebToken);
        this.webCommonStorageRepository.setUserId(data.Id);
        this.stateService.go('thPayment', { apptId: this.appointmentId });
      },
      () => {
        this.hasLoginError = true;
      },
    );
  }
}
