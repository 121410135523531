import {
  Component,
  Input,
} from '@angular/core';

import {SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'ap-pdf',
  templateUrl: './ap-pdf.component.html',
})
export class ApPdfComponent {
  @Input() src: string | SafeResourceUrl;
}
