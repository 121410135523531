import {
  Injectable,
} from '@angular/core';
import {DeviceSettingsRepository} from 'webcommon/legacy-common';

import {DefaultDocumentHelper} from './DefaultDocumentHelper';
import {IosDocumentHelper} from './IosDocumentHelper';
import {WebDocumentHelper} from './WebDocumentHelper';

import {DocumentHelper} from 'webcommon/shared';

import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DocumentHelperBase implements DocumentHelper {
  private readonly innerHelper: DocumentHelper;

  constructor(
    deviceSettingsRepository: DeviceSettingsRepository,
    iosDocumentHelper: IosDocumentHelper,
    webDocumentHelper: WebDocumentHelper,
    defaultDocumentHelper: DefaultDocumentHelper,
  ) {
    if (deviceSettingsRepository.isIos) {
      this.innerHelper = iosDocumentHelper;
    } else if (deviceSettingsRepository.isStandaloneBrowser) {
      this.innerHelper = webDocumentHelper;
    } else {
      this.innerHelper = defaultDocumentHelper;
    }
  }

  openUrlInSeparateTab(url$: Observable<string>) {
    this.innerHelper.openUrlInSeparateTab(url$);
  }

  supportsOpenInSeparateTab(): boolean {
    return this.innerHelper.supportsOpenInSeparateTab();
  }
}
