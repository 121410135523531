<div class="image-viewer">
  <div class="image-container">
    <div *ngIf="!loadingImage" class="image-display-host">
      <img class="image-display"
           [src]="imageData"
           apZoomable [resetSrc]="imageData"
           (swipe)="onSwipeImg($event)"/>
      <div *ngIf="!disableFullscreen" class="fullscreen-overlay">
        <i class="pull-right fa fa-arrows-alt" (click)="isFullscreenMode = true"></i>
      </div>
    </div>
    <div *ngIf="loadingImage" class="flex-col center-h">
      <ap-spinner size="3"></ap-spinner>
    </div>
  </div>
  <div class="text-center image-footer" *ngIf="pageCount && (pageCount > 1)">
    <nav>
      <div>Pages:</div>
      <pagination [totalItems]="pageCount"
                  [(ngModel)]="currentPage"
                  [maxSize]="4"
                  [itemsPerPage]="1"
                  (ngModelChange)="pageChanged()"
                  class="pagination-sm image-viewer-paginator"
                  [boundaryLinks]="true">
      </pagination>
    </nav>
  </div>
  <div *ngIf="isFullscreenMode" class="fullscreen-image-viewer">
    <div class="fullscreen-overlay">
      <i class="pull-right fa fa-times" (click)="isFullscreenMode = false"></i>
    </div>
    <img class="fullscreen-image-display"
         *ngIf="!loadingImage"
         [src]="imageData"
         apZoomable [resetSrc]="imageData"
         (swipe)="onSwipeImg($event)"/>
  </div>
</div>
