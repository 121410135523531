import { Injectable } from '@angular/core';
import { pad } from 'lodash-es';
import moment from 'moment';

import { BridgeService } from './BridgeService';

export interface AprimaAnalyticsTiming {
  stop(): void;
}

@Injectable({
  providedIn: 'root',
})
export class AprimaAnalyticsService {
  static readonly ajsFactoryName = 'AprimaAnalytics';

  constructor(
    private bridge: BridgeService,
  ) {
  }

  sendEvent(category: string, action: string, label: string, value?: string) {
    setTimeout(() => {
      // tslint:disable-next-line:no-console
      console.debug('Event: ' + pad(category, 20) + ' | ' + pad(action, 20) + ' | ' + pad(label, 20) + ' | ' + pad(value, 20));
      this.bridge.fireEvent('trackEvent', {
        category: category || '',
        action: action || '',
        label: label || '',
        value: value || '',
      });
    }, 0);
  }

  sendPageView(location: string) {
    setTimeout(() => {
      // tslint:disable-next-line:no-console
      console.debug('Page Change: ' + pad(location, 30));
      this.bridge.fireEvent('trackPageChange', {
        page: location,
      });
    }, 0);
  }

  sendException(message: string) {
    setTimeout(() => {
      // tslint:disable-next-line:no-console
      console.debug('Exception: ' + message);
      this.bridge.fireEvent('trackException', {
        message,
      });
    }, 0);
  }

  startTiming(page: string, category: string, label: string): AprimaAnalyticsTiming {
    const startTime = new Date().getTime();

    return {
      stop: () => {
        const endTime = new Date().getTime();
        setTimeout(() => {
          this.stopTiming(page, category, label, startTime, endTime);
        }, 0);
      },
    };
  }

  private stopTiming(page: string, category: string, label: string, startTime: number, endTime: number): void {
    const elapsedTime = moment(endTime).diff(moment(startTime), 'millisecond');
    const timingValue = elapsedTime.toString();

    // tslint:disable-next-line:no-console
    console.debug('Timing: ' + pad(page, 30) + ' | ' + pad(category, 7) + ' | ' + pad(label, 30) + ' | ' + pad(timingValue, 5) + 'ms');
    this.bridge.fireEvent('trackTiming', {
      category: category || '',
      label: label || '',
      value: timingValue || '',
      page: page || '',
    });
  }
}
