import {
  Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild, ElementRef,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import {
  ApBaseComponent,
} from 'webcommon/core-ui';

import {
  BrowserService,
} from 'webcommon/legacy-common';

export interface TransactionCompleteEvent {
  eventName: 'tc-redirectUrl';
  data: string;
}

export function isTCEvent(value: any): value is TransactionCompleteEvent {
  return value && (value as TransactionCompleteEvent).eventName === 'tc-redirectUrl';
}

@Component({
  selector: 'ap-payment-frame',
  templateUrl: './ap-payment-frame.component.html',
})
export class ApPaymentFrameComponent extends ApBaseComponent implements OnInit {
  static readonly ajsComponentName = 'apPaymentFrame';
  @Input() url: string;
  @Output() applyPayment = new EventEmitter<string>();

  safeUrl: SafeResourceUrl;

  private readonly expectedMessageOrigin: string;

  // If we change around the view, it may affect this ViewChild,
  // and whether we need static: true, or static: false;
  // which would when also affect the message handler below.
  @ViewChild('paymentWidget', {static: true}) paymentWidgetFrame: ElementRef<HTMLIFrameElement>;

  constructor(
    private sanitizer: DomSanitizer,
    browserService: BrowserService
  ) {
    super();
    // in this case we are expecting to redirect to the same origin as the current window
    this.expectedMessageOrigin = browserService.getLocation().origin;
  }

  ngOnInit() {
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

  // For documentation purposes:
  // This previously used the 'load' event on the iframe to intercept the redirect that EasyPay does.
  // This is documented in ApEasyPayOnloadDirective as an alternative to this strategy.
  @HostListener('window:message', ['$event'])
  onPostMessage(event: MessageEvent) {
    // Return if the origin doesn't match what we expect/trust
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage#notes
    if (event.origin !== this.expectedMessageOrigin) {
      return;
    }
    // Return if the source doesn't match what we expect/trust
    if (event.source !== this.paymentWidgetFrame.nativeElement.contentWindow) {
      return;
    }

    const eventObj = event.data;
    // Handle an event specifically for providing the redirect url returned by a completed EasyPay transaction.
    // Currently, this is only coming from TransactionCompleteCtrl.
    if (isTCEvent(eventObj)) {
      const redirectUrl = eventObj.data;
      if (!redirectUrl) {
        throw new Error('Invalid redirect from completed transaction');
      }
      this.applyPayment.emit(redirectUrl);
    }
  }
}
