import { baseEnvironment } from './base-environment';

const prodEnvironment = {
  devHosted: false,
  production: true,
};

export const environment = {
  ...baseEnvironment,
  ...prodEnvironment,
};
