import {ApDocumentBase} from './ApDocumentBase';
import {ApDocumentType} from './ApDocumentType';

// This is prefixed so that it should never conflict with some built-in type.
export class ApTextDocument extends ApDocumentBase {
  constructor(
    data: string,
    extension: string,
    pageCount?: number | null,
  ) {
    super(
      data,
      false,
      ApDocumentType.text,
      extension,
      pageCount,
    );
  }
}
