import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FoxitLicenseDto } from 'webcommon/shared';
import { PrmHttpClientService } from '../PrmHttpClientService';

@Injectable({
  providedIn: 'root',
})
export class FoxitLicenseRepository {
  constructor(
    private httpClientService: PrmHttpClientService,
  ) {
  }

  getFoxitLicense(
    licenseSNId: string, licenseKeyId: string,
    defaultToolId: string, defaultViewModeId: string,
  ): Observable<FoxitLicenseDto> {
    const endpointUrl = `foxitLicense/${licenseSNId}/${licenseKeyId}/${defaultToolId}/${defaultViewModeId}`;
    return this.httpClientService.sendGetRequest(endpointUrl);
  }
}
