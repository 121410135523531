<div class="container-center">
  <div class="paper paper-th">
    <div class="paper-header">
      <div class="paper-title" translate>Pay For Today's Visit</div>
    </div>
    <hr/>
    <div class="paper-body">
      <div *ngIf="!loading">
        <div class="info-box text-center">
          Your current balance is {{isNegativeBalance && '-$'+billingBalanceString+' (credit)' || '$'+billingBalanceString}}
          <br>
          Your estimated copay is ${{billingCoPayAmount}}
        </div>
        <p class="billing-warn">
          We have estimated your copay for this visit. This amount is not the final bill, which may be more or less depending upon the final services rendered and any insurance coverage benefits. Payments may not be reflected in the current balance shown.
        </p>
        <div *ngIf="!loading && loadError" class="text-danger">
          <alert type="danger" translate>An error has occurred and payment could not be loaded.</alert>
        </div>
        <div *ngIf="!loading && hasPaymentError" class="text-danger">
          <alert type="danger" translate>An error has occurred and your payment may not have been applied to your account. Please contact the office.</alert>
        </div>
        <ap-payment-frame [url]="url" (applyPayment)="applyPayment($event)"></ap-payment-frame>
      </div>
      <div *ngIf="loading">
        <div class="text-center">
          <ap-spinner size="3"></ap-spinner>
        </div>
      </div>
    </div>
  </div>
</div>
