import {
  Component,
  OnInit,
} from '@angular/core';
import { Transition } from '@uirouter/core';
import {
  finalize,
  takeUntil,
} from 'rxjs/operators';

import {
  ApBaseComponent,
} from 'webcommon/core-ui';
import {
  WebCommonStorageRepository,
} from 'webcommon/legacy-common';
import {
  BillingRepository,
} from 'webcommon/webapi';
import {
  LoginAssistanceResultDto,
  ReceiptDto,
  ReceiptRequestDto,
} from 'webcommon/shared';

@Component({
  selector: 'th-payment-summary',
  templateUrl: './th-payment-summary.component.html',
})
export class ThPaymentSummaryComponent extends ApBaseComponent implements OnInit {
  loadError = false;
  errorMessage = '';
  loginAssistanceInfo: LoginAssistanceResultDto;
  url = '';
  receiptHtml = '';
  userId: string | null;
  txId: string;
  continueClicked = false;

  constructor(
    private billingRepository: BillingRepository,
    private transition: Transition,
    private webCommonStorageRepository: WebCommonStorageRepository,
  ) {
    super();
    this.loading = false;
  }

  ngOnInit() {
    this.userId = this.webCommonStorageRepository.getUserId();
    this.txId = this.transition.params().txId;
    this.load();
  }

  private load() {
    this.loading = true;

    this.loadReciept();

  }

  private loadReciept() {
    const receiptRequestDto: ReceiptRequestDto = {
      LoginUserPatientId: this.userId,
      RecipientEnumText: 'CUST',
      RemoteSessionId: this.userId + new Date().toLocaleDateString(),
      TxId: +this.txId,
    };
    this.billingRepository.getReceiptHtml(receiptRequestDto).pipe(
      finalize(() => this.onLoadComplete()),
      takeUntil(this.onDestroy$),
    ).subscribe(
      (data: ReceiptDto) => this.onLoadSuccess(data),
      () => this.onLoadError(),
    );
  }

  private onLoadComplete() {
    this.loading = false;
  }

  private onLoadError() {
    this.loadError = true;
  }

  private onLoadSuccess(result: ReceiptDto) {
    this.loadError = false;
    this.receiptHtml = result.ReceiptHtml;
  }

  print() {
    window.print();
  }

  done() {
    window.close();
    this.continueClicked = true;
  }
}
